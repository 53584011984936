<template>
  <BlockUserDetails :user="user">
    <template #more-meta>
      <slot name="more-meta"></slot>
    </template>
    <template #default>
      <slot name="default"></slot>
    </template>
  </BlockUserDetails>
</template>

<script>
import BlockUserDetails from '@/fw-modules/fw-core-vue/id/components/blocks/BlockUserDetails'

export default {
  name: 'UserDetails',
  components: { BlockUserDetails },
  props: {
    user: {
      type: Object,
      default: function() {
        return {}
      },
    },
  },
}
</script>
