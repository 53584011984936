import { render, staticRenderFns } from "./CardCourse.vue?vue&type=template&id=5b9d8719&scoped=true"
import script from "./CardCourse.vue?vue&type=script&lang=js"
export * from "./CardCourse.vue?vue&type=script&lang=js"
import style0 from "./CardCourse.vue?vue&type=style&index=0&id=5b9d8719&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b9d8719",
  null
  
)

/* custom blocks */
import block0 from "./CardCourse.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports