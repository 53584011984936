<template>
  <div class="fixed top-16 left-0 right-0 bottom-0 flex bg-black/70" style="z-index: 5">
    <div
      class="cursor-pointer w-12 h-12 fixed z-50 top-20 right-5 rounded-full flex justify-center items-center text-white bg-black bg-opacity-30"
      @click="$emit('close')"
    >
      <fw-icon-close class="h-8 w-8" />
    </div>
    <div class="flex-1 relative" @click="togleShowMessage()">
      <v-zoomer v-if="currentFile && currentFile.type == 'image'" class="h-full w-full">
        <div
          class="px-5 pt-5 h-full w-full transition-all duration-300"
          :class="{
            'pb-32': showMessage,
            'pb-5': !showMessage
          }"
        >
          <img :src="currentFileUrl" class="h-full w-full object-contain" />
        </div>
      </v-zoomer>
      <div
        v-else-if="currentFile && currentFile.type == 'video'"
        class="h-full w-full flex items-center justify-center transition-all duration-300"
        :class="{
          'pb-32': showMessage,
          'pb-5': !showMessage
        }"
      >
        <video-player
          :ref="'v-' + currentFile.key"
          :src="getFileViewUrl(currentFile)"
          @play="showMessage = false"
          @pause="showMessage = true"
        />
      </div>
      <PanelPdfViewer v-else-if="currentFile && isPdf" :file="currentFile" :show-header="false" />
      <div v-else-if="currentFile" class="h-full w-full flex items-center justify-center">
        <div class="bg-gray-600 p-5 rounded-2xl border border-gray-500 text-gray-300 flex flex-col gap-2">
          <div class="font-bold text-lg">Pré-visualização não disponível</div>
          <p class="text-sm opacity-70">Descarregue o ficheiro para o seu dispositivo para o visualizar</p>
          <div
            class="bg-white/10 rounded-lg p-1 flex gap-2 items-center select-none cursor-default"
            @click="downloadFile()"
          >
            <div class="h-9 w-9 bg-gray-600 flex items-center justify-center">
              <fw-icon-file class="w-5 h-5 text-gray-300" />
            </div>
            <div class="font-semibold text-sm leading-4">
              {{ currentFile.filename }}
              <div class="opacity-50 text-xs">{{ message.created_date | formatDateTime }}</div>
            </div>
          </div>
          <fw-button icon="download" class="mx-auto mt-3" @click.native="downloadFile()">Descarregar</fw-button>
        </div>
      </div>
      <div
        class="fixed bottom-0 left-0 right-0 bg-gradient-to-t from-black/50 to-transparent p-5 flex justify-center items-end gap-2 transition-all"
        :class="{
          'translate-y-full': !showMessage
        }"
      >
        <UserAvatar :user="user" size="xs" class="flex-shrink-0" />
        <div class="rounded-r-2xl rounded-tl-2xl rounded-bl-md p-3 bg-white">
          <div class="max-w-xl" v-html="message.message"></div>
          <div class="opacity-50 text-xs text-right -mb-1">{{ message.created_date | formatDateTime }}</div>
        </div>
        <div class="px-1.5 pb-0 sm:pb-1.5 flex gap-1.5 flex-col sm:flex-row">
          <b-tooltip class="hidden sm:block" label="Localizar mensagem" type="is-dark" position="is-top">
            <button class="bg-black/30 p-2 rounded-md hover:bg-gray-700" @click.stop="$emit('go-to-message', message)">
              <fw-icon-crosshair class="w-5 h-5 text-white/70" />
            </button>
          </b-tooltip>
          <b-tooltip label="Responder" type="is-dark" position="is-top">
            <button class="bg-black/30 p-2 rounded-md hover:bg-gray-700" @click.stop="$emit('reply-message', message)">
              <fw-icon-reply class="w-5 h-5 text-white/70" />
            </button>
          </b-tooltip>
          <b-tooltip label="Descarregar ficheiro" type="is-dark" position="is-top">
            <button class="bg-black/30 p-2 rounded-md hover:bg-gray-700" @click.stop="downloadFile()">
              <fw-icon-download class="w-5 h-5 text-white/70" />
            </button>
          </b-tooltip>
        </div>
      </div>
    </div>
    <div
      v-if="hasNextFile"
      class="w-12 h-12 right-5 rounded-full cursor-pointer hover:bg-gray-600 top-1/2 -mt-6 fixed text-white bg-black bg-opacity-30 flex items-center justify-center"
      @click="nextFile()"
    >
      <fw-icon-chevron-right class="h-8 w-8" />
    </div>
    <div
      v-if="hasPreviousFile"
      class="w-12 h-12 left-5 rounded-full cursor-pointer hover:bg-gray-600 top-1/2 -mt-6 fixed text-white bg-black bg-opacity-30 flex items-center justify-center"
      @click="previousFile()"
    >
      <fw-icon-chevron-left class="h-8 w-8" />
    </div>
  </div>
</template>

<script>
import VueZoomer from 'vue-zoomer'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import UserAvatar from '@/fw-modules/fw-core-vue/id/components/user/UserAvatar'
import VideoPlayer from '@/fw-modules/fw-core-vue/ui/components/video/VideoPlayer'
import PanelPdfViewer from '@/fw-modules/fw-core-vue/buckets/components/panels/PanelPDFviewer'
export default {
  components: {
    VZoomer: VueZoomer.Zoomer,
    UserAvatar,
    VideoPlayer,
    PanelPdfViewer
  },
  props: {
    message: {
      type: Object,
      required: true
    },
    fileKey: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showMessage: true,
      currentFileIndex: 0
    }
  },
  computed: {
    isPdf() {
      return this.currentFile && this.currentFile.filename.toLowerCase().endsWith('.pdf')
    },
    currentFile() {
      return this.message.files[this.currentFileIndex]
    },
    currentFileUrl() {
      return this.getFileViewUrl(this.currentFile)
    },
    hasNextFile() {
      return this.currentFileIndex < this.message.files.length - 1
    },
    hasPreviousFile() {
      return this.currentFileIndex > 0
    }
  },
  created() {
    //find index of current file
    this.currentFileIndex = this.message.files.findIndex(file => file.key === this.fileKey)
    //bind keyboard keys: right arrow, left arrow, esc
    document.addEventListener('keydown', this.keydown)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keydown)
  },
  methods: {
    togleShowMessage() {
      console.log('togleShowMessage')
      if (this.currentFile.type == 'image') {
        this.showMessage = !this.showMessage
      }
    },
    getFileViewUrl(file) {
      let publicToken = file.token || this.user.token
      return ServiceStorage.getFileUrl(file, publicToken)
    },
    getImageViewUrl(file, size) {
      let publicToken = file.token || this.user.token
      return ServiceStorage.getImageViewUrl(file, size, publicToken)
    },
    downloadFile() {
      let url = this.getFileViewUrl(this.currentFile)
      window.open(url, '_blank')
    },
    nextFile() {
      if (this.hasNextFile) {
        this.currentFileIndex++
      }
    },
    previousFile() {
      if (this.hasPreviousFile) {
        this.currentFileIndex--
      }
    },
    keydown(event) {
      if (event.key === 'ArrowRight') {
        this.nextFile()
      } else if (event.key === 'ArrowLeft') {
        this.previousFile()
      } else if (event.key === 'Escape') {
        this.$emit('close')
      }
    }
  }
}
</script>
