var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"hover:bg-white hover:bg-opacity-20 h-full flex flex-col px-4 py-3 text-left gap-1 hover:shadow-md rounded-xl cursor-pointer w-full min-h-24 items-stretch relative",class:{
    'bg-gray-200 bg-opacity-80': _vm.type == 'light',
    'bg-yellow-800 bg-opacity-10': _vm.type == 'yellow',
    'bg-primary bg-opacity-10': _vm.type == 'primary'
  },attrs:{"aria-label":_vm.title},on:{"click":function($event){return _vm.$emit('clicked')}}},[_c('div',{staticClass:"font-semibold text-sm text-gray-500",class:{ 'pr-12': _vm.tag }},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"flex-1 text-left flex gap-1 mt-2"},[_c('div',{staticClass:"flex-1 flex flex-col justify-end"},[(_vm.icon)?_c('div',{staticClass:"mb-1"},[_c('fw-icon-' + _vm.icon,{tag:"component",class:{
            'w-6 h-6': _vm.size == 'base',
            'w-7 h-7': _vm.size == 'md',
            'w-8 h-8': _vm.size == 'lg',
            'w-9 h-9': _vm.size == 'xl'
          }})],1):_vm._e(),_c('div',{staticClass:"font-bold sm:text-md",class:[`text-${_vm.size}`]},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('div',{staticClass:"flex items-end"},[_c('fw-icon-chevron-right',{staticClass:"w-6 h-6 -mr-1.5"})],1)]),(_vm.tag)?_c('fw-tag',{staticClass:"absolute -top-1 -right-1",attrs:{"type":_vm.tagType}},[_vm._v(_vm._s(_vm.tag))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }