<template>
  <div
    class="flex-col gap-9 bg-white rounded-md flex-1 relative"
    :class="{
      'px-2 py-4': !paddingless
    }"
  >
    <div v-if="showVersionsDropdown && page?.versions?.length" class="absolute top-2 right-2 h-10 z-[1]">
      <b-dropdown aria-role="list" position="is-bottom-left" append-to-body>
        <fw-button-dropdown v-if="selectedVersion" slot="trigger" aria-role="listitem" type="link-light">
          <span class="font-medium text-md mr-1 max-w-[16rem] line-clamp-1 text-ellipsis overflow-hidden inline-block">
            {{ selectedVersion.title ?? 'Sem título' }}
          </span>
          <fw-tag v-if="selectedVersion.state" :type="versionsStates[selectedVersion.state].tag" size="xs">
            {{ versionsStates[selectedVersion.state].label[lang] }}
          </fw-tag>
        </fw-button-dropdown>

        <b-dropdown-item
          v-for="item in page.versions"
          :key="item.key"
          aria-role="listitem"
          class="flex items-center gap-2 justify-between"
          :class="`font-medium ${selectedVersion?.key == item.key ? 'text-primary' : 'text-gray-500'}`"
          @click="selectVersion(item)"
        >
          <div class="font-bold">{{ item.title ?? 'Sem título' }}</div>
          <div v-if="item.state" class="text-xs opacity-80">
            {{ versionsStates[item.state].label[lang] }}
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <fw-content-loader v-if="loading" />
    <template v-else>
      <BlockPageHeader
        :page="page"
        :users="users"
        :show-title="showTitle"
        :selected-version="selectedVersion"
        :show-edit-button="showEditButton"
        @open="$emit('open', $event)"
        @edit="$emit('edit', page)"
      />
      <PanelPagesContentPreviewPage
        v-if="provider"
        :key="selectedVersion?.key"
        :collaboration-provider="provider"
        :page-number="0"
        :editable="false"
        :page="page"
        :version="selectedVersion"
        :show-chapters="showChapters"
        :chapters-offset="chaptersOffset"
        :main-container="mainContainer"
      />
      <div v-else-if="!showVersionsDropdown && !publishedVersion" class="py-10 text-center text-sm text-gray-500">
        Página não publicada
      </div>
    </template>

    <fw-panel-info debug label="Data (raw)" class="my-5">
      <json-viewer :value="{ page, users }"></json-viewer>
    </fw-panel-info>
  </div>
</template>

<script>
import ServicePages from '@/fw-modules/fw-core-vue/pages/services/ServicePages'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import { HocuspocusProvider } from '@hocuspocus/provider'
import PanelPagesContentPreviewPage from '@/fw-modules/fw-core-vue/pages/components/panels/PanelPagesContentPreviewPage'
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import { VERSIONS_STATES } from '@/fw-modules/fw-core-vue/pages/utils/index.js'
import BlockPageHeader from '@/fw-modules/fw-core-vue/pages/components/blocks/BlockPageHeader'
export default {
  name: 'PanelPagePreview',
  components: {
    PanelPagesContentPreviewPage,
    BlockPageHeader
  },
  props: {
    pageId: {
      type: String,
      required: true
    },
    versionId: {
      type: String
    },
    content: {
      type: Object
    },
    paddingless: {
      type: Boolean,
      default: false
    },
    showVersionsDropdown: {
      type: Boolean,
      default: false
    },
    isManager: {
      type: Boolean,
      default: false
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    chaptersOffset: {
      type: String,
      default: '0'
    },
    showChapters: {
      type: Boolean,
      default: false
    },
    mainContainer: {
      type: String,
      default: '.inner-main-content'
    },
    showEditButton: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: true,
      notpublished: false,
      page: null,
      users: {},
      provider: null,
      permissions: {
        read: false,
        write: false
      },
      selectedVersion: null,
      versionsStates: Object.freeze(VERSIONS_STATES)
    }
  },

  computed: {
    userToken() {
      return this.$store.state.session.token
    },
    publishedVersion() {
      return this.page?.versions?.find(v => v.state == 'published')
    },
    lang() {
      return this.$store.state.language ?? 'pt'
    }
  },

  watch: {
    userToken() {
      //prepare editor
      if (this.provider == null) {
        this.prepareEditor()
      }
    }
  },

  created() {
    this.loadPageContent()
  },

  methods: {
    reload() {
      this.provider = null
      this.loadPageContent()
    },
    prepareEditor() {
      console.log('prepareEditor', this.userToken, this.provider, this.selectedVersion)
      if (!this.userToken || this.provider || !this.selectedVersion) return
      this.permissions.write = false
      this.permissions.read = true
      let self = this
      this.provider = new HocuspocusProvider({
        url: FwEnvConfig.apiUrlHocusPocus,
        name: `pages:${this.page.key}:${this.selectedVersion.key}:0`,
        token: this.userToken,
        onAuthenticationFailed: () => {
          console.error('Authentication failed')
          self.permissions.write = false
          self.permissions.read = false
        },
        onSynced: () => {
          console.log('Document synced')
          self.loading = false
          self.$emit('document-loaded')
        }
      })
      console.log('Provider created for: ', `pages:${this.page.key}:${this.selectedVersion.key}:0`)
      this.provider.on('status', event => {
        this.hpstatus = event.status
      })
    },

    async loadPageContent() {
      this.loading = true
      await utils.tryAndCatch(this, async () => {
        const pageVersions = await ServicePages.getPageVersions(this.pageId)
        this.page = pageVersions.page
        this.users = pageVersions.users
        console.log('this.page :>> ', this.page)
        if (this.showVersionsDropdown || this.isManager) {
          this.selectedVersion = this.page?.versions?.[0]
        } else {
          this.selectedVersion = this.publishedVersion
        }
        if (this.selectedVersion) {
          this.prepareEditor()
        } else {
          this.loading = false
        }
      })
      //this.loading = false
    },

    selectVersion(version) {
      this.selectedVersion = version
      this.provider = null
      this.prepareEditor()
    }
  }
}
</script>
