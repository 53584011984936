<template>
  <svg class="fill-current" width="19" height="20" viewBox="0 0 19 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.9681 1.56863H14.4326V0.588235C14.4326 0.432226 14.371 0.282606 14.2612 0.17229C14.1515 0.0619746 14.0027 0 13.8475 0C13.6923 0 13.5435 0.0619746 13.4338 0.17229C13.3241 0.282606 13.2624 0.432226 13.2624 0.588235V1.56863H5.07092V0.588235C5.07092 0.432226 5.00928 0.282606 4.89955 0.17229C4.78982 0.0619746 4.641 0 4.48582 0C4.33064 0 4.18181 0.0619746 4.07208 0.17229C3.96235 0.282606 3.90071 0.432226 3.90071 0.588235V1.56863H1.36525C1.00316 1.56863 0.655906 1.71323 0.399872 1.97064C0.143838 2.22804 1.39364e-08 2.57715 1.39364e-08 2.94118V18.6275C-2.5605e-05 18.8077 0.0352701 18.9862 0.103871 19.1527C0.172472 19.3193 0.273035 19.4706 0.399815 19.598C0.526595 19.7255 0.677109 19.8266 0.84276 19.8956C1.00841 19.9645 1.18595 20 1.36525 20H16.9681C17.3302 20 17.6774 19.8554 17.9334 19.5979C18.0602 19.4705 18.1608 19.3192 18.2294 19.1527C18.298 18.9862 18.3333 18.8077 18.3333 18.6275V2.94118C18.3334 2.76092 18.2981 2.58243 18.2295 2.41589C18.1609 2.24936 18.0603 2.09804 17.9335 1.97058C17.8067 1.84312 17.6562 1.74202 17.4906 1.67305C17.3249 1.60409 17.1474 1.5686 16.9681 1.56863ZM1.36525 2.7451H3.90071V3.72549C3.90071 3.8815 3.96235 4.03112 4.07208 4.14144C4.18181 4.25175 4.33064 4.31373 4.48582 4.31373C4.641 4.31373 4.78982 4.25175 4.89955 4.14144C5.00928 4.03112 5.07092 3.8815 5.07092 3.72549V2.7451H13.2624V3.72549C13.2624 3.8815 13.3241 4.03112 13.4338 4.14144C13.5435 4.25175 13.6923 4.31373 13.8475 4.31373C14.0027 4.31373 14.1515 4.25175 14.2612 4.14144C14.371 4.03112 14.4326 3.8815 14.4326 3.72549V2.7451H16.9681C17.0198 2.7451 17.0694 2.76576 17.106 2.80253C17.1426 2.8393 17.1631 2.88917 17.1631 2.94118V6.27451H1.17021V2.94118C1.17021 2.88917 1.19076 2.8393 1.22734 2.80253C1.26391 2.76576 1.31352 2.7451 1.36525 2.7451ZM16.9681 18.8235H1.36525C1.31352 18.8235 1.26391 18.8029 1.22734 18.7661C1.19076 18.7293 1.17021 18.6795 1.17021 18.6275V7.45098H17.1631V18.6275C17.1631 18.6795 17.1426 18.7293 17.106 18.7661C17.0694 18.8029 17.0198 18.8235 16.9681 18.8235Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconCourseCalendar'
}
</script>
