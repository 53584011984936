<template>
  <b-dropdown aria-role="list" position="is-bottom-left" :append-to-body="appendToBody" close-on-click>
    <fw-button-dropdown slot="trigger" aria-role="listitem" type="transparent" :size="size" :chevron="false">
      <div class="p-0.5">
        <fw-icon-more class="w-5 h-5" />
      </div>
    </fw-button-dropdown>
    <slot name="default" />
  </b-dropdown>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'sm'
    },
    appendToBody: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {}
  }
}
</script>
