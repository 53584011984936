<template>
  <div class="flex justify-between items-center px-4 pt-2" :class="{ 'pb-2': !tabs?.length }">
    <slot name="start">
      <div class="flex-1 flex gap-5 items-center">
        <div v-if="title" class="flex gap-2 items-center" :title="title">
          <component :is="`fw-icon-${titleIcon}`" v-if="titleIcon" class="w-7 h-7" />
          <fw-heading size="h3" class="truncate max-w-[30ch]">{{ title ?? '' }}</fw-heading>
        </div>
        <div v-if="tabs?.length" class="flex gap-5">
          <fw-button
            v-for="(tab, index) in tabs"
            :key="index"
            size="md"
            custom-class="pb-3"
            :type="tab.active ? 'tab-active' : 'tab'"
            @click.native="tab.action"
          >
            {{ tab.label }}
          </fw-button>
        </div>
      </div>
    </slot>
    <slot name="end">
      <div class="flex gap-4 items-center">
        <BlockHeaderActions v-if="showHeaderActions" :items="headerActions" v-on="$listeners" />
        <UsersConnected :users="connectedUsers" />
      </div>
    </slot>
  </div>
</template>

<script>
import UsersConnected from '@/fw-modules/fw-core-vue/ui/components/users/UsersConnected'
import BlockHeaderActions from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockHeaderActions'

export default {
  name: 'Subheader',
  components: {
    BlockHeaderActions,
    UsersConnected
  },
  props: {
    title: {
      type: String,
      default: ''
    },

    titleIcon: {
      type: String
    },

    // Ex: [
    // 	{
    // 		active: true,
    // 		label: 'Dashboard',
    // 		action: () => {},
    // 	},
    // 	{
    // 		active: false,
    // 		label: 'Pessoas',
    // 		action: () => {},
    // 	},
    // ],
    tabs: {
      type: Array
    },

    connectedUsers: {
      type: Array,
      default: () => []
    },

    showHeaderActions: {
      type: Boolean,
      default: false
    },

    // Ex: [
    // 	{
    // 		canShowAction: true,
    // 		customClass: 'text-red-400',
    // 		actionName: 'delete',
    // 		actionLabel: 'Eliminar',
    // 		addToCounter: false,
    // 	},
    // 	{
    // 		canShowAction: true,
    // 		actionName: 'submit',
    // 		actionLabel: 'Submeter',
    // 		addToCounter: true,
    // 	},
    // 	{
    // 		canShowAction: false,
    // 		actionName: 'cancel',
    // 		actionLabel: 'Cancelar',
    // 		addToCounter: false,
    // 	},
    // ]
    headerActions: {
      type: Array,
      default: () => []
    },
    paddinglessVertical: {
      type: Boolean,
      default: false
    }
  },

  computed: {}
}
</script>
