<template>
  <div v-if="options && options.length > 0">
    <b-dropdown aria-role="list" :position="'is-bottom-left'">
      <template #trigger="{ active }">
        <fw-button
          size="sm"
          class="flex"
          :class="{
            'opacity-100': active,
            'font-semibold': orderItemsBy,
            'font-normal': !orderItemsBy
          }"
        >
          <OrderToggle :order="direction" :inactive-color="'text-gray-500'" :size="'sm'"></OrderToggle>
          <div v-if="type === 'normal'" class="hidden md:block text-gray-800 ml-1 max-width-text">
            {{ orderItemsBy ? orderItemsBy.label : $t('order') }}
          </div>
        </fw-button>
      </template>

      <fw-label class="ml-4">{{ $t('orderBy') }}</fw-label>
      <b-dropdown-item
        v-for="item in options"
        :key="item.key"
        aria-role="listitem"
        custom
        class="px-4 select-none py-2 flex justify-start gap-3 items-center cursor-pointer"
        :class="{ 'font-semibold': orderItemsBy && orderItemsBy.key === item.key }"
        @click.native="toogleOrderItems(item)"
      >
        <div class="w-5">
          <OrderToggle
            v-if="orderItemsBy && orderItemsBy.key === item.key"
            class="bg-white"
            :order="direction"
            :inactive-color="'text-gray-500'"
            :size="'sm'"
          ></OrderToggle>
          <fw-icon-order-by v-else-if="item.type === 'date'" class="h-5 w-5 text-gray-900"></fw-icon-order-by>
          <div v-else-if="item.type === 'string'" class="text-gray-900 h-5 w-5 text-center text-base -mt-1">
            Az
          </div>
          <div v-else-if="item.type === 'number'" class="text-gray-900 h-5 w-5 text-center text-base -mt-1">
            12
          </div>
        </div>
        <div class="flex-1 text-left">
          {{ item.label }}
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import OrderToggle from '@/fw-modules/fw-core-vue/ui/components/buttons/OrderToggle'

export default {
  name: 'OrderBy',

  components: {
    OrderToggle
  },

  props: {
    options: {
      type: Array,
      default: () => []
    },
    orderItemsBy: {
      type: Object,
      default: () => {}
    },
    direction: {
      type: String,
      default: 'asc'
    },
    type: {
      type: String,
      default: 'normal'
    }
  },
  methods: {
    toogleOrderItems(option) {
      let orderItemsBy = this.orderItemsBy
      let direction = this.direction
      if (this.orderItemsBy != null && this.orderItemsBy.key == option.key) {
        if (this.direction.toUpperCase() === 'ASC') {
          direction = 'desc'
        } else {
          orderItemsBy = null
          direction = 'none'
        }
      } else {
        orderItemsBy = option
        direction = 'asc'
      }

      this.$emit('update:direction', direction)
      this.$emit('update:order-items-by', orderItemsBy)
      this.$emit('sort-order-changed', { key: orderItemsBy, direction: direction })
    }
  }
}
</script>

<i18n lang="json">
{
	"pt": {
		"order": "Ordenar",
    "orderBy": "Ordenar por"
	},
	"en": {
		"order": "Order",
    "orderBy": "Order by"
	}
}
</i18n>
