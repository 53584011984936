var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.course)?_c('SidebarBase',{scopedSlots:_vm._u([{key:"main",fn:function(){return [(_vm.isManager)?_c('ButtonSidebar',{attrs:{"label":"Modo de edição","icon":"settings","bordered":""},nativeOn:{"click":function($event){return _vm.goToManageCourseEdition.apply(null, arguments)}}}):_vm._e(),_c('div',{staticClass:"mb-3"},[(_vm.classroom || _vm.isManager)?_c('div',[(_vm.isManager)?_c('div',[(_vm.classes && _vm.classes.length > 1)?_c('div',{staticClass:"mb-2"},[_c('fw-label',{staticClass:"mx-3"},[_vm._v("Turma")]),_c('div',[_c('b-dropdown',{attrs:{"aria-role":"list","scrollable":true,"max-height":200,"expanded":""}},[_c('fw-button-dropdown',{attrs:{"slot":"trigger","aria-role":"listitem","expanded":"","type":"light"},slot:"trigger"},[_c('v-clamp',{staticClass:"w-full",attrs:{"max-lines":3,"autoresize":""}},[_vm._v(" "+_vm._s(_vm.classroom.name)+" ")])],1),_vm._l((_vm.classes),function(classItem,classItemKey){return _c('b-dropdown-item',{key:classItemKey,staticClass:"font-medium text-sm cursor-pointer px-1.5 py-1.5 items-center flex gap-2 border-b border-gray-100 hover:bg-gray-100",attrs:{"aria-role":"listitem","has-link":"","focusable":false},nativeOn:{"click":function($event){return _vm.$emit('change-class', classItem.key)}}},[_c('div',{staticClass:"h-2 w-2 bg-opacity-90 rounded-full flex-shrink-0",class:{
                      'bg-primary': classItem.key == _vm.activeClassKey
                    }}),_c('v-clamp',{attrs:{"max-lines":2,"autoresize":""}},[_vm._v(" "+_vm._s(classItem.name)+" ")])],1)})],2)],1)],1):_vm._e()]):_c('div',{staticClass:"mx-3"},[_c('fw-label',{staticClass:"flex gap-1 items-center",attrs:{"size":"sm","marginless":""}},[_vm._v(" Turma ")]),_c('div',{staticClass:"font-semibold"},[_c('v-clamp',{attrs:{"max-lines":3,"autoresize":""}},[_vm._v(" "+_vm._s(_vm.classroom.name)+" ")])],1)],1),(_vm.classroom.meeting && _vm.classroom.meeting.key_alias)?_c('div',{staticClass:"mt-2"},[(_vm.classroom.meeting && _vm.classroom.meeting.key_alias)?_c('fw-button',{attrs:{"type":"light-primary","label":"Sala virtual","expanded":""},nativeOn:{"click":function($event){return _vm.$router.push({ name: 'live', params: { key: _vm.classroom.meeting.key_alias } })}}},[_c('fw-icon-live',{staticClass:"w-5 h-5"}),_c('div',{staticClass:"absolute flex top-1 right-1 items-center gap-1"},[(_vm.classroom.meeting.running)?_c('fw-dot',{attrs:{"inline":""}}):_vm._e(),_c('fw-icon-arrow-right-up',{staticClass:"w-4 h-4"})],1)],1):_vm._e()],1):_vm._e()]):_vm._e()]),(_vm.validations.is_enrolled && _vm.validations.is_definitive)?_c('div',{staticClass:"mx-2 mt-2 mb-4"},[_c('fw-label',{attrs:{"size":"sm"}},[_vm._v("O seu progresso")]),_c('ProgressIcon',{attrs:{"progress":_vm.progress,"show-percentage":"","percentage-pos":"right","color":!_vm.activeUnitKey || _vm.progress == 100
            ? 'bg-gradient-to-r from-teal-300 to-teal-400'
            : 'bg-gray-500 bg-opacity-80'}})],1):_vm._e(),_c('ButtonSidebar',{attrs:{"active":!_vm.view || _vm.view == 'dashboard',"label":"Dashboard"},nativeOn:{"click":function($event){return _vm.$emit('go-to-view', 'dashboard')}},scopedSlots:_vm._u([{key:"svg",fn:function(){return [_c('fw-icon-dashboard',{staticClass:"w-6 h-6"})]},proxy:true}],null,false,3849824173)}),_c('ButtonSidebar',{attrs:{"active":_vm.view == 'units' || _vm.view == 'unit',"disabled":!(_vm.validations.is_enrolled && _vm.validations.is_definitive) && !_vm.validations.is_manager,"label":"Módulos"},nativeOn:{"click":function($event){return _vm.$emit('go-to-unit', _vm.editionUnits[0]?.key)}},scopedSlots:_vm._u([{key:"svg",fn:function(){return [_c('fw-icon-book-mark-solid',{staticClass:"w-6 h-6"})]},proxy:true}],null,false,3073216200)}),(_vm.editionUnits && _vm.activeUnitKey && (_vm.view == 'units' || _vm.view == 'unit'))?_c('ul',{staticClass:"ml-1.5"},_vm._l((_vm.editionUnits),function(unit,index){return _c('li',{key:unit.key + '_' + _vm.edition.key,staticClass:"my-2"},[_c('button',{staticClass:"w-full font-medium transition-colors duration-150",class:{
            'text-primary hover:text-primary':
              _vm.activeUnitKey &&
              _vm.activeUnitKey == unit.key &&
              ((_vm.validations.is_enrolled && _vm.validations.is_definitive) || _vm.validations.is_manager),
            'text-gray-500 hover:text-primary':
              (!_vm.activeUnitKey || _vm.activeUnitKey != unit.key) &&
              ((_vm.validations.is_enrolled && _vm.validations.is_definitive) || _vm.validations.is_manager),
            'text-gray-400 cursor-not-allowed':
              !(_vm.validations.is_enrolled && _vm.validations.is_definitive) && !_vm.validations.is_manager,
            'mb-2': _vm.activeUnitKey && _vm.activeUnitKey == unit.key
          },attrs:{"disabled":!(_vm.validations.is_enrolled && _vm.validations.is_definitive) || (_vm.activeUnitKey && _vm.activeUnitKey == unit.key)},on:{"click":function($event){return _vm.$emit('go-to-unit', unit.key)}}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"ml-2 mr-2.5 opacity-50"},[(!(_vm.validations.is_enrolled && _vm.validations.is_definitive) && !_vm.validations.is_manager)?_c('fw-icon-lock',{staticClass:"w-5 h-5"}):_c('fw-icon-list-view',{staticClass:"w-5 h-5"})],1),_c('span',{staticClass:"hidden font-bold w-10 text-sm mr-1"},[_vm._v("M"+_vm._s(index + 1))]),_c('div',{staticClass:"flex flex-1 flex-col"},[_c('v-clamp',{staticClass:"w-full text-sm text-left",attrs:{"autoresize":"","max-lines":2}},[_vm._v(" "+_vm._s(unit.title)+" ")]),(
                  _vm.activeUnitKey && _vm.activeUnitKey == unit.key && _vm.validations.is_enrolled && _vm.validations.is_definitive
                )?_c('ProgressIcon',{staticClass:"mt-1",attrs:{"progress":unit.progress,"show-percentage":"","percentage-pos":"right","color":"bg-primary bg-opacity-80"}}):_vm._e()],1)])])])}),0):_vm._e(),(
        ((_vm.validations.is_enrolled && _vm.validations.is_definitive) || _vm.validations.is_manager) &&
          _vm.classroom.meeting &&
          _vm.classroom.meeting.key_alias
      )?_c('ButtonSidebar',{attrs:{"active":_vm.view == 'chats',"label":"Chat"},nativeOn:{"click":function($event){return _vm.$emit('go-to-view', 'chats')}},scopedSlots:_vm._u([{key:"svg",fn:function(){return [_c('fw-icon-chats',{staticClass:"w-6 h-6"})]},proxy:true}],null,false,928736132)}):_vm._e(),_c('ButtonSidebar',{attrs:{"active":_vm.view == 'discussions',"disabled":!_vm.validations.is_definitive && !_vm.validations.is_manager,"icon":"chat-thread","label":"Fórum"},nativeOn:{"click":function($event){return _vm.$emit('go-to-view', 'discussions')}}}),_c('ButtonSidebar',{attrs:{"active":_vm.view == 'about',"label":"Sobre o espaço"},nativeOn:{"click":function($event){return _vm.$emit('go-to-view', 'about')}},scopedSlots:_vm._u([{key:"svg",fn:function(){return [_c('fw-icon-article',{staticClass:"w-6 h-6"})]},proxy:true}],null,false,358610701)})]},proxy:true}],null,false,798185030)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }