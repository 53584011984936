<template>
  <div class="bg-white shadow-md hover:shadow-xl rounded-xl p-4">
    <div class="flex gap-2 items-center">
      <Avatar :user="user" />
      <div class="flex-1">
        <fw-heading size="h4">{{ user.name }}</fw-heading>
        <div v-if="user.email" class="has-text-muted has-text-small content">
          {{ user.email }}
        </div>
      </div>
    </div>
    <div v-if="user.auto === true" class="flex mt-3 text-gray-500 text-xs font-semibold items-center">
      <div
        class="flex rounded-md bg-opacity-70 border pr-2 border-gray-300 border-opacity-70 gap-2 items-center overflow-hidden"
      >
        <div class="bg-gray-300 text-gray-600 py-0.5 text-xs px-2 bg-opacity-50">
          Autorização automática
        </div>
        <div v-if="isManager">Gestor</div>
        <div v-if="!isManager && canAddItems">Adicionar itens</div>
        <div v-if="!isManager && canEditItems">Editar itens</div>
        <div v-if="!isManager && canDeleteItems">Remover itens</div>
        <div v-if="!isManager && !canAddItems && !canEditItems && !canDeleteItems">Leitura</div>
      </div>
    </div>
    <div v-if="user.auto !== true && loggedUser.key != user.key" class="flex-1">
      <fw-label>Permissões</fw-label>
      <form class="text-sm p-2 flex flex-col gap-2" @submit.prevent="updateUser">
        <b-checkbox v-model="isManager" size="is-small">Gestor</b-checkbox>
        <b-checkbox v-if="!isManager" v-model="canAddItems" size="is-small">Adicionar itens</b-checkbox>
        <b-checkbox v-if="!isManager" v-model="canEditItems" size="is-small">Editar itens</b-checkbox>
        <b-checkbox v-if="!isManager" v-model="canDeleteItems" size="is-small">Remover itens</b-checkbox>
        <b-field v-if="errorMessage" class="notification is-danger has-padding">{{ errorMessage }}</b-field>
      </form>
    </div>
    <div v-if="user.auto !== true && loggedUser.key != user.key" class="-mb-2 flex justify-end">
      <fw-button type="link-muted" outlined size="sm" @click.native="deleteUser(user.key)">
        Remover
      </fw-button>
    </div>
  </div>
</template>

<script>
import ServiceBuckets from '@/fw-modules/fw-core-vue/buckets/services/ServiceBuckets'
import Avatar from '@/fw-modules/fw-core-vue/ui/components/users/Avatar'

export default {
  components: {
    Avatar
  },

  props: {
    bucket: Object,
    user: {
      type: Object,
      deep: true
    }
  },

  data() {
    return {
      isManager: this.user.bucket_permissions.includes('is_manager'),
      canAddItems: this.user.bucket_permissions.includes('add_items'),
      canEditItems: this.user.bucket_permissions.includes('edit_items'),
      canDeleteItems: this.user.bucket_permissions.includes('delete_items'),
      errorMessage: '',
      errorCount: 0
    }
  },

  computed: {
    loggedUser() {
      return this.$store.getters.getUser
    }
  },

  watch: {
    isManager(value) {
      this.updatePermission('is_manager', 'isManager', value)
    },
    canAddItems(value) {
      this.updatePermission('add_items', 'canAddItems', value)
    },
    canEditItems(value) {
      this.updatePermission('edit_items', 'canEditItems', value)
    },
    canDeleteItems(value) {
      this.updatePermission('delete_items', 'canDeleteItems', value)
    }
  },

  methods: {
    async updatePermission(permissionKey, thisKey, value) {
      this.errorMessage = ''
      if (value !== this.user.bucket_permissions.includes(permissionKey)) {
        try {
          const data = {}
          data[permissionKey] = value

          const users = await ServiceBuckets.updateBucketPermission(this.bucket.key, this.user.key, data)
          this.$emit('set-users', users)
        } catch (error) {
          console.error(
            `Failed to update user ${this.user.key} "${permissionKey}" permission in bucket ${this.bucket.key}`,
            error
          )
          this.setError('Ocorreu um erro')
          this.$nextTick(() => {
            this[thisKey] = this.user.bucket_permissions.includes(permissionKey)
          })
        }
      }
    },

    setError(message) {
      this.errorMessage = message
      this.errorCount += 1

      let errorCount = this.errorCount
      setTimeout(() => {
        if (errorCount === this.errorCount) {
          this.errorMessage = ''
        }
      }, 3000)
    },
    async deleteUser(key) {
      const users = await ServiceBuckets.deleteBucketPermission(this.bucket.key, key)
      this.$emit('set-users', users)
    }
  }
}
</script>
