var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white option-cards",class:{
    'rounded-xl': _vm.size === 'big',
    'rounded-md': _vm.size !== 'big',
    disabled: _vm.disabled
  }},_vm._l((_vm.options),function(option,o){return _c('button',{key:'option_' + o,staticClass:"fw-option w-full text-left flex group transition duration-150 items-center gap-3",class:{
      'rounded-t-xl': o === 0 && _vm.size === 'big',
      'rounded-b-xl': o === _vm.options.length - 1 && _vm.size === 'big',
      'rounded-t-md': o === 0 && _vm.size !== 'big',
      'rounded-b-md': o === _vm.options.length - 1 && _vm.size !== 'big',
      'border-b border-gray-100': o !== _vm.options.length - 1,
      selected: _vm.multiple ? _vm.selected.includes(option.value) : option.value === _vm.selected,
      'px-3 py-2 lg:text-md font-semibold': _vm.size === 'big',
      'px-3 py-2 text-md font-semibold': _vm.size === 'small',
      'opacity-70 hover:opacity-90 hover:text-primary cursor-pointer ': !_vm.disabled && !option.disabled,
      'opacity-50 cursor-not-allowed': _vm.disabled || option.disabled
    },attrs:{"disabled":_vm.disabled || option.disabled,"type":"button"},on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.clickSelect(option)}}},[_c('div',{staticClass:"check border-2 rounded-2xl flex-shrink-0",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.clickSelect(option)}}}),_c('div',{staticClass:"flex-1",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.clickSelect(option)}}},[_vm._v(" "+_vm._s(option.text == null || typeof option.text == 'undefined' ? 'Sem nome' : typeof option.text === 'string' ? option.text : option.text[_vm.language])+" ")]),(option.right_text)?_c('div',{staticClass:"text-gray-500 font-medium",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.clickSelect(option)}}},[_vm._v(" "+_vm._s(option.right_text)+" ")]):_vm._e(),(_vm.showSecondaryTrailButton)?_c('fw-button',{attrs:{"type":"xlight"},nativeOn:{"click":function($event){return _vm.$emit('secondary-trail-button-clicked', option.value)}}},[_vm._v(" "+_vm._s(_vm.secondaryTrailButtonText)+" ")]):_vm._e(),(_vm.showTrailButton)?_c('fw-button',{attrs:{"type":"xlight"},nativeOn:{"click":function($event){return _vm.$emit('trail-button-clicked', option.value)}}},[_vm._v(" "+_vm._s(_vm.trailButtonText)+" ")]):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }