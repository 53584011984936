const EXIF_OPTIONS = {
  Flash: {
    0: 'No Flash',
    1: 'Fired',
    5: 'Fired, Return not detected',
    7: 'Fired, Return detected',
    8: 'On, Did not fire',
    9: 'On, Fired',
    13: 'On, Return not detected',
    15: 'On, Return detected',
    16: 'Off, Did not fire',
    20: 'Off, Did not fire, Return not detected',
    24: 'Auto, Did not fire',
    25: 'Auto, Fired',
    29: 'Auto, Fired, Return not detected',
    31: 'Auto, Fired, Return detected',
    32: 'No flash function',
    48: 'Off, No flash function',
    65: 'Fired, Red-eye reduction',
    69: 'Fired, Red-eye reduction, Return not detected',
    71: 'Fired, Red-eye reduction, Return detected',
    73: 'On, Red-eye reduction',
    77: 'On, Red-eye reduction, Return not detected',
    79: 'On, Red-eye reduction, Return detected',
    80: 'Off, Red-eye reduction',
    88: 'Auto, Did not fire, Red-eye reduction',
    89: 'Auto, Fired, Red-eye reduction',
    93: 'Auto, Fired, Red-eye reduction, Return not detected',
    95: 'Auto, Fired, Red-eye reduction, Return detected'
  },
  ColorSpace: {
    1: 'sRGB',
    2: 'Adobe RGB',
    65533: 'Wide Gamut RGB',
    65534: 'ICC Profile',
    65535: 'Uncalibrated'
  },
  Orientation: {
    1: 'Horizontal (normal)',
    2: 'Mirror horizontal',
    3: 'Rotate 180',
    4: 'Mirror vertical',
    5: 'Mirror horizontal and rotate 270 CW',
    6: 'Rotate 90 CW',
    7: 'Mirror horizontal and rotate 90 CW',
    8: 'Rotate 270 CW'
  },
  CustomRendered: {
    0: 'Normal',
    1: 'Custom',
    2: 'HDR (no original saved)',
    3: 'HDR (original saved)',
    4: 'Original (for HDR)',
    6: 'Panorama',
    7: 'Portrait HDR',
    8: 'Portrait'
  }
}

export default {
  translate(key, value) {
    const options = EXIF_OPTIONS[key]
    if (options) {
      return options[value]
    } else {
      return value
    }
  }
}
