<template>
  <figure class="rounded-2xl overflow-hidden">
    <img :src="url.replace('{SIZE}', size)" />
  </figure>
</template>

<script>
export default {
  props: {
    url: String,
    size: {
      type: String,
      default: '400'
    }
  }
}
</script>
