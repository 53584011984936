<template>
  <fw-modal v-if="showModal" :active.sync="showModal" :can-cancel="true" size="md" @close="showModal = false">
    <template #default>
      <div class="flex flex-col gap-3 text-left">
        <fw-heading size="h3">{{ title }}</fw-heading>
        <fw-label>{{ label }}</fw-label>
        <TranslatedInput
          v-model="editingdata"
          :placeholder="{ pt: label + $t('label.pt'), en: label + $t('label.en') }"
          :disable-autowrite="true"
          :multiline="false"
          :class="{
            error: $v.editingdata.$error,
          }"
        />
        <fw-tip v-if="$v.editingdata.$error" error>
          {{ $t('label.required') }}
        </fw-tip>
      </div>
    </template>
    <template #footer>
      <fw-button type="black" :loading="loading" expanded @click.native="save()">{{ $t('save') }}</fw-button>
    </template>
  </fw-modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import TranslatedInput from '@/fw-modules/fw-core-vue/ui/components/form/TranslatedInput'

export default {
  name: 'ModalMulilanguageEdit',
  components: { TranslatedInput },
  props: {
    title: {
      type: String,
      default: 'Editar',
    },
    label: {
      type: String,
      default: 'Descrição',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editingdata: {
        pt: '',
        en: '',
      },
      showModal: false,
    }
  },
  methods: {
    edit(data) {
      this.editingdata = JSON.parse(JSON.stringify(data))
      this.show()
    },
    save() {
      this.$v.editingdata.$touch()
      if (this.$v.editingdata.$invalid) return
      this.$emit('save', this.editingdata)
      this.reset()
    },
    show() {
      this.showModal = true
    },
    reset() {
      this.editingdata = {
        pt: '',
        en: '',
      }
      this.showModal = false
    },
  },
  validations() {
    return {
      editingdata: {
        pt: { required, min: minLength(1) },
        en: { required, min: minLength(1) },
      },
    }
  },
}
</script>

<i18n>
    {
      "pt": {
        "save": "Guardar",
        "label": {
          "pt": " em Português",
          "en": " em Inglês",
          "required": "Insira texto em Português e Inglês."
        }
      },
      "en": {
        "save": "Save",
        "label": {
          "pt": " in Portuguese",
          "en": " in English",
          "required": "Insert text in Portuguese and English."
        }
      }
    }
</i18n>
