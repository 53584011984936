<template>
  <component
    :is="asLink && !disabled ? 'router-link' : 'button'"
    :to="url"
    :disabled="disabled"
    class="sidebar-button"
    @click="goToOrRun"
  >
    <div
      class="group text-sm flex-shrink-0 rounded flex gap-1 p-2 items-center transition-colors duration-150 font-medium"
      :class="{
        'hover:text-black cursor-pointer': !disabled,
        'text-gray-500 opacity-60 cursor-not-allowed': disabled,
        'w-full': fullWidth,
        'text-primary': isRouteActive,
        'bg-gray-300 bg-opacity-30 rounded-lg': selected,
        'border-b pb-3 mb-2': bordered
      }"
    >
      <div
        v-if="hasIcon"
        class="rounded-xl w-8 flex-shrink-0"
        :class="{
          'text-primary': isRouteActive && !disabled,
          'text-gray-900 opacity-90': !isRouteActive && !disabled
        }"
      >
        <slot name="svg">
          <component :is="'fw-icon-' + icon" class="w-6 h-6" />
        </slot>
      </div>
      <div class="group-opacity-100 text-left flex-1 flex-shrink-0" :class="[`text-${size}`]">
        <div
          v-if="truncateLabel"
          class="line-clamp-1"
          :class="{
            'text-primary': isRouteActive && !disabled,
            'text-gray-800 group-hover:text-black group-hover:opacity-100': !isRouteActive && !disabled
          }"
        >
          {{ label }}
        </div>
        <div
          v-else
          :class="{
            'text-primary': isRouteActive && !disabled,
            'text-gray-800 opacity-80': !isRouteActive && !disabled,
            'group-hover:text-primary group-hover:opacity-90': !disabled
          }"
        >
          {{ label }}
        </div>
      </div>
      <div
        v-if="counter"
        class="text-xs px-1.5 py-0.5 rounded-full flex-shrink-0 min-w-7 text-center font-bold"
        :class="{
          'bg-primary text-white': counterType === 'primary',
          'bg-gray-500 bg-opacity-20 text-gray-500': counterType === 'light',
          'bg-gray-500 bg-opacity-10 text-gray-500': counterType === 'xlight'
        }"
      >
        {{ counter }}
      </div>
      <div v-if="$slots.suffix" class="flex-shrink-0"><slot name="suffix"></slot></div>
    </div>
  </component>
</template>

<script>
export default {
  props: {
    to: {
      type: [String, Object],
      default: ''
    },
    asLink: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'sm'
    },
    icon: {
      type: String,
      default: null
    },
    exact: {
      type: Boolean,
      default: false
    },
    checkActualRoute: {
      type: Boolean,
      default: true
    },
    active: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    noIcon: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: true
    },
    counter: {
      //String or number
      type: [String, Number],
      default: null
    },
    counterType: {
      type: String,
      default: 'primary'
    },
    truncateLabel: {
      type: Boolean,
      default: true
    },
    emitOnly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasIcon() {
      return this.icon || this.$slots.svg
    },

    url() {
      return typeof this.to === 'object' && !Array.isArray(this.to) && this.to !== null
        ? this.$router.resolve(this.to).href
        : this.to
    },

    isRouteActive() {
      return this.active || (this.checkActualRoute && this.$router.currentRoute.path == this.url)
    }
  },

  methods: {
    goToOrRun() {
      if (this.disabled) return
      if (this.emitOnly) return this.$emit('click')
      if (this.url && !this.asLink) this.$router.push(this.url)
    }
  }
}
</script>
