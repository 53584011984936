import NotFound from '@/fw-modules/fw-core-vue/id/views/NotFound'
import Forbidden from '@/fw-modules/fw-core-vue/id/views/Forbidden'
import ViewAccount from '@/fw-modules/fw-core-vue/id/views/ViewAccount'
import InternalServerError from '@/fw-modules/fw-core-vue/id/views/InternalServerError'

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/fw-modules/fw-core-vue/id/views/Login'),
    meta: { requiresNoAuth: true }
  },

  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/fw-modules/fw-core-vue/id/views/Logout'),
    meta: { requiresAuth: true }
  },

  {
    path: '/external-account',
    name: 'external-account-signup',
    component: () => import('@/fw-modules/fw-core-vue/id/views/ExternalAccountSignup'),
    meta: { requiresNoAuth: true }
  },

  {
    path: '/confirm',
    name: 'external-account-confirm',
    component: () => import('@/fw-modules/fw-core-vue/id/views/ExternalAccountConfirm'),
    meta: { requiresNoAuth: true }
  },

  {
    path: '/reset',
    name: 'external-account-recover-password',
    component: () => import('@/fw-modules/fw-core-vue/id/views/ExternalAccountRecoverPassword'),
    meta: { requiresNoAuth: true }
  },

  {
    path: '/recover',
    name: 'external-account-change-password',
    component: () => import('@/fw-modules/fw-core-vue/id/views/ExternalAccountChangePassword'),
    meta: { requiresNoAuth: true }
  },

  {
    path: '*',
    name: 'error',
    component: NotFound
  },

  {
    path: '/internal-error',
    name: 'internalError',
    component: InternalServerError
  },

  {
    path: '/forbidden',
    name: 'forbidden',
    component: Forbidden,
    meta: { requiresAuth: true }
  },

  {
    path: '/account',
    name: 'account',
    component: ViewAccount,
    meta: { requiresAuth: true }
  }
]
