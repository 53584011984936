import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import Janus from '@/fw-modules/fw-meetings-vue/probojs/janus'
import probo from '@/fw-modules/fw-meetings-vue/probojs'
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'

const KB = 1024
const CPU = navigator && navigator.hardwareConcurrency ? navigator.hardwareConcurrency : 0

const videoFrameRate = parseInt(localStorage.getItem('device.video.frameRate'))
const fHDVideo = !!localStorage.getItem('device.video.fhd')
const unlimitedHDVideo = !!localStorage.getItem('device.video.hd.unlimited')
const normalHDBitRate = (parseInt(localStorage.getItem('device.video.hd.bitRate.normal')) || 0) * KB
const highHDBitRate = (parseInt(localStorage.getItem('device.video.hd.bitRate.high')) || 0) * KB

const unlimitedHDScreenShare = !!localStorage.getItem('device.screenshare.hd.unlimited')

export default {
  data() {
    return this.getJanusSelfDefaultData()
  },

  methods: {
    getJanusSelfDefaultData() {
      const self = this

      let cameraBitratePercentage = 100
      let screenShareBitratePercentage = 100
      if (CPU && CPU < 8) {
        cameraBitratePercentage = CPU >= 4 ? 90 : 80
        screenShareBitratePercentage = CPU >= 4 ? 90 : 80
        console.info(
          `Bitrate start percentage camera:${cameraBitratePercentage} ss:${screenShareBitratePercentage} cpu:${CPU}`
        )
      }

      return {
        showMediaSelection: false,
        showPodsGalleryConfig: false,
        issuesFlagLastUpdate: new Date(),
        issuesFlagBackoff: 10 * 1000,

        devicesError: false,
        cameraOptions: [],
        audioInputOptions: [],
        audioOutputOptions: [],

        audio: {
          type: 'audio',
          active: false,
          loading: false,
          startDate: null,
          errorType: null,
          description: 'audio',
          toggle: function() {
            if (this.active) self.stopAudio()
            else self.startAudio()
          },
          deviceId: localStorage.getItem('device.audio.input') || null,
          setDeviceIdCore(deviceId) {
            console.info(`Microphone updated ${this.deviceId} => ${deviceId} active:${this.active}`)
            this.deviceId = deviceId
            if (deviceId && deviceId !== 'default') localStorage.setItem('device.audio.input', deviceId)
            else localStorage.removeItem('device.audio.input')
          },
          setDevice(deviceId) {
            if (deviceId !== this.deviceId) {
              this.setDeviceIdCore(deviceId)
              if (this.active) this.reload()
            }
          },
          reload() {
            if (!this.active) return
            self.stopAudio({ reload: true })
          },
          // Private attributes
          publisher: null,
          stream: null,
          getJanusMediaConfig() {
            const media = {
              audioRecv: false,
              videoRecv: false,
              audioSend: true,
              videoSend: false
            }

            if (this.deviceId) {
              media.audio = { deviceId: { exact: this.deviceId } }
            }

            return media
          },
          withCPUIssues: false,
          bitrate: {
            vars: {}
          },
          on: async function() {
            const audios = {}
            audios[self.attendee.key] = { id: this.publisher.id, name: self.attendee.name }
            self.setAudios(audios)

            await ServiceMeetings.updateStreamId(self.meeting.key, 'audio', this.publisher.id)
          },
          off: async function() {
            self.removeAudios([self.attendee.key])

            this.bitrate = { vars: {} }
            if (self.isRunning && self.attendee.withRole('audio_allowed')) {
              await ServiceMeetings.updateStreamId(self.meeting.key, 'audio', null)
            }
          }
        },
        camera: {
          type: 'camera',
          active: false,
          loading: false,
          startDate: null,
          errorType: null,
          description: 'camera',
          toggle: function(deviceId) {
            if (deviceId) this.deviceId = deviceId

            if (this.active) self.stopCamera()
            else self.startCamera()
          },
          mirrorDisabled: localStorage.getItem('device.video.mirror.disabled') || false,
          deviceId: localStorage.getItem('device.video.input') || null,
          setDeviceIdCore(deviceId) {
            console.info(`Camera updated ${this.deviceId} => ${deviceId} active:${this.active}`)
            this.deviceId = deviceId
            if (deviceId && deviceId !== 'default') localStorage.setItem('device.video.input', deviceId)
            else localStorage.removeItem('device.video.input')
          },
          setDevice(deviceId) {
            if (deviceId !== this.deviceId) {
              this.setDeviceIdCore(deviceId)
              if (this.active) this.reload()
            }
          },
          async toggleQuality() {
            if (self.attendee.with_low_quality) {
              await ServiceMeetings.removeAttendeeFlag(self.meeting.key, 'low')
              self.attendee.with_low_quality = false
              console.debug('Self low camera quality removed')
            } else {
              await ServiceMeetings.setAttendeeFlag(self.meeting.key, 'low')
              self.attendee.with_low_quality = true
              console.debug('Self low camera quality defined')
            }

            this.checkBitrate()
          },
          reload() {
            if (!this.active) return
            self.stopCamera({ reload: true })
          },
          checkBitrate() {
            self.checkPublisherBitrate(this)
          },
          selectFrameRate(defaultFrameRate) {
            return Math.max(1, Math.min(60, videoFrameRate || defaultFrameRate))
          },
          // Private attributes
          publisher: null,
          stream: null,
          elementId: 'video-my-webcam',
          getJanusMediaConfig() {
            const media = {
              audioRecv: false,
              videoRecv: false,
              audioSend: false,
              videoSend: true
            }

            if (unlimitedHDVideo) {
              media.video = {
                width: { ideal: 3840 },
                height: { ideal: 2160 },
                frameRate: this.selectFrameRate(20)
              }
            } else if (fHDVideo) {
              media.video = {
                width: { ideal: 1920 },
                height: { ideal: 1080 },
                frameRate: this.selectFrameRate(20)
              }
            } else if (!self.attendee.with_hd) {
              media.video = {
                width: { ideal: 640 },
                height: { ideal: 360 },
                frameRate: this.selectFrameRate(20)
              }
            } else {
              media.video = {
                width: { ideal: 1280 },
                height: { ideal: 720 },
                frameRate: this.selectFrameRate(20)
              }
            }

            if (this.deviceId) {
              media.video.deviceId = { exact: this.deviceId }
            }

            return media
          },
          getMediaConstraints() {
            return this.getJanusMediaConfig().video
          },
          useSimulcast: true,
          withCPUIssues: false,
          bitrateLevel: null,
          forceBitrateLevel: null,
          bitratePercentage: cameraBitratePercentage,
          bitrateCrowdedPercentage: 100,
          bitrate: {},
          bitrateConfig: {
            low: 300 * KB,
            lowHD: 400 * KB,
            normal: 850 * KB,
            normalHD: unlimitedHDVideo ? 0 : normalHDBitRate || (fHDVideo ? 3000 : 2000) * KB,
            high: 1500 * KB,
            highHD: unlimitedHDVideo ? 0 : highHDBitRate || (fHDVideo ? 3600 : 2600) * KB,

            minPercentage: 60,
            maxPercentage: cameraBitratePercentage,
            backoff: 5 * 1000,
            updated: null
          },
          on: async function() {
            await ServiceMeetings.updateStreamId(self.meeting.key, 'camera', this.publisher.id)
          },
          off: async function() {
            this.bitrate = {}
            this.bitrateLevel = null
            if (self.isRunning && self.attendee.withRole('camera_allowed')) {
              await ServiceMeetings.updateStreamId(self.meeting.key, 'camera', null)
            }
          }
        },
        screenShare: {
          type: 'screenShare',
          active: false,
          loading: false,
          startDate: null,
          errorType: null,
          description: 'screen share',
          toggle: function() {
            if (this.active) self.stopShareScreen()
            else self.startShareScreen()
          },
          reload() {
            if (!this.active) return
            self.stopShareScreen({ reload: true })
          },
          checkBitrate() {
            self.checkPublisherBitrate(this)
          },
          // Private attributes
          publisher: null,
          stream: null,
          elementId: 'video-my-screen',
          getJanusMediaConfig() {
            const media = {
              audioRecv: false,
              videoRecv: false,
              audioSend: true,
              videoSend: true,
              video: 'screen',
              captureDesktopAudio: true
            }

            if (!self.attendee.with_hd) {
              media.screenshareFrameRate = 5
              media.screenshareWidth = 1280
              media.screenshareHeight = 800
            } else if (!unlimitedHDScreenShare) {
              media.screenshareFrameRate = 8
              media.screenshareWidth = 1600
              media.screenshareHeight = 1200
            } else {
              media.screenshareFrameRate = 15
              media.screenshareWidth = 1920
              media.screenshareHeight = 1080
            }

            if (self.isSafari) {
              media.promise = navigator.mediaDevices.getDisplayMedia(Janus.buildDisplayConstraints(media))
            }

            return media
          },
          withCPUIssues: false,
          bitrateLevel: null,
          forceBitrateLevel: null,
          bitratePercentage: screenShareBitratePercentage,
          bitrateCrowdedPercentage: 100,
          bitrate: {
            vars: {}
          },
          bitrateConfig: {
            low: 300 * KB,
            lowHD: 400 * KB,
            normal: 750 * KB,
            normalHD: unlimitedHDScreenShare ? 0 : 1400 * KB,
            high: 1500 * KB,
            highHD: unlimitedHDScreenShare ? 0 : 2000 * KB,

            minPercentage: 60,
            maxPercentage: screenShareBitratePercentage,
            backoff: 5 * 1000,
            updated: null
          },
          on: async function() {
            await ServiceMeetings.updateStreamId(self.meeting.key, 'screen_share', this.publisher.id)
          },
          off: async function() {
            this.bitrate = { vars: {} }
            if (self.isRunning && self.attendee.withRole('sharescreen_allowed')) {
              await ServiceMeetings.updateStreamId(self.meeting.key, 'screen_share', null)
            }
          }
        }
      }
    },

    async buildMediaOptions() {
      try {
        const data = await utils.buildMediaDevices()
        this.cameraOptions = data.cameras
        this.audioInputOptions = data.audioInputs
        this.audioOutputOptions = data.audioOutputs
        console.debug(
          `Media devices loaded camera:${this.cameraOptions.length} audioInput:${this.audioInputOptions.length} audioOutput:${this.audioOutputOptions.length}`
        )
      } catch (error) {
        console.error('Failed to get devices', error)
        this.devicesError = true
      }
    },

    toggleMediaSelection() {
      this.showMediaSelection = !this.showMediaSelection
      if (this.showMediaSelection) {
        this.buildMediaOptions()
      }
    },

    togglePodsGalleryConfig() {
      this.showPodsGalleryConfig = !this.showPodsGalleryConfig
    },

    streamIsLocal: function(streamId) {
      const streamIdInt = parseInt(streamId)
      return (
        (this.audio.publisher && this.audio.publisher.id == streamIdInt) ||
        (this.camera.publisher && this.camera.publisher.id == streamIdInt) ||
        (this.screenShare.publisher && this.screenShare.publisher.id == streamIdInt) ||
        (this.subscriber && this.subscriber.id == streamIdInt)
      )
    },
    adjustBitratePercentage() {
      const issues = []
      if (this.withNetworkIssues) issues.push('network')
      if (this.camera.withCPUIssues) issues.push('cameraCPU')
      if (this.screenShare.withCPUIssues) issues.push('ssCPU')
      const issueDescription = issues.join(',')
      const incPercentage = issueDescription ? -5 : 5

      let updated = false
      if (this.camera.active && !this.checkPublisherBitrate(this.camera, incPercentage, issueDescription)) {
        updated = true
      }
      if (this.screenShare.active && !this.checkPublisherBitrate(this.screenShare, incPercentage, issueDescription)) {
        updated = true
      }

      this.setAttendeeIssuesFlag(issues.length, updated)
      return updated
    },
    async setAttendeeIssuesFlag(withIssues, bitrateUpdated) {
      if (!this.attendee) return

      if (withIssues) {
        if (!bitrateUpdated && !this.attendee.with_issues) {
          await ServiceMeetings.setAttendeeFlag(this.meeting.key, 'issues')
          this.attendee.with_issues = true
        }
      } else if (this.attendee.with_issues) {
        const now = new Date()
        if (now - this.issuesFlagLastUpdate >= this.issuesFlagBackoff) {
          this.issuesFlagLastUpdate = now
          await ServiceMeetings.removeAttendeeFlag(this.meeting.key, 'issues')
          this.attendee.with_issues = false
        }
      }
    },
    checkPublisherBitrate(obj, increment = null, issueDescription = null) {
      let atBitrateLimit = false
      if (!obj.active) return atBitrateLimit

      const config = obj.bitrateConfig
      let mainLevel = obj.forceBitrateLevel || 'normal'
      if (obj.type == 'camera' && this.attendee.with_low_quality) mainLevel = 'low'

      let level = mainLevel
      if (this.attendee.with_hd) level += 'HD'

      // Reduce bitrate in big open meetings
      let wantCrowdedPercentage = 100
      if (mainLevel !== 'low' && this.instance.withRole('show_to_everyone') && !this.attendee.withRole('is_host')) {
        if (obj.type == 'camera' && this.podsLength > 60) wantCrowdedPercentage = 60
        else if (obj.type == 'camera' && this.podsLength > 45) wantCrowdedPercentage = 70
        else if (this.podsLength > 30) wantCrowdedPercentage = 80
        else if (this.podsLength > 15) wantCrowdedPercentage = 90
      }

      // Force update if level is different
      let wantPercentage = obj.bitratePercentage
      if (level !== obj.bitrateLevel) {
        // Reset bitrate
        wantPercentage = config.maxPercentage
      } else {
        const now = new Date()
        if (config.updated && now - config.updated < config.backoff) return atBitrateLimit
        config.updated = now

        if (increment) {
          wantPercentage += increment
          if (wantPercentage < config.minPercentage) {
            wantPercentage = config.minPercentage
            atBitrateLimit = true
          } else if (wantPercentage > config.maxPercentage) {
            wantPercentage = config.maxPercentage
            atBitrateLimit = true
          }

          if (!atBitrateLimit) {
            console.warn(`${obj.type} bitrate percentage set to ${wantPercentage} issue:${issueDescription}`)
          }
        }

        if (wantPercentage === obj.bitratePercentage && wantCrowdedPercentage === obj.bitrateCrowdedPercentage) {
          return atBitrateLimit
        }
      }

      obj.bitrateLevel = level
      obj.bitratePercentage = wantPercentage
      obj.bitrateCrowdedPercentage = wantCrowdedPercentage

      let bitrate = config[level]
      if (wantPercentage < 100) bitrate *= wantPercentage / 100
      if (wantCrowdedPercentage < 100) bitrate *= wantCrowdedPercentage / 100

      bitrate = parseInt(bitrate)
      obj.publisher.handle.send({
        message: {
          request: 'configure',
          bitrate: bitrate
        },
        success: function() {
          console.info(
            `Set ${obj.description} bitrate ${level}:${bitrate} issues:${obj.bitratePercentage} crowded:${obj.bitrateCrowdedPercentage}`
          )
        },
        error: function(message) {
          console.error(`Failed to set ${obj.description} bitrate ${level}:${bitrate}`, message)
        }
      })

      return atBitrateLimit
    },
    startPublisher(obj, force) {
      if (!force && obj.loading) {
        console.debug(`${obj.description} already loading, cannot start`)
        return
      } else if (obj.publisher) {
        console.debug(`${obj.description} already started`)
        return
      }

      if (obj.bitratePercentage) {
        obj.bitratePercentage = obj.bitrateConfig.maxPercentage
      }

      obj.loading = true
      obj.startDate = new Date()
      console.debug(`${obj.description} starting`)
      const self = this
      const publishMedia = obj.getJanusMediaConfig()

      try {
        console.info(`Media defined for ${obj.description}`, publishMedia)
        obj.publisher = new probo.Publisher({
          session: this.oldSession,
          publish_media: publishMedia,
          master: false,
          local_stream_listener: {
            on: async function(stream) {
              if (obj.deviceId === null) {
                try {
                  obj.deviceId = stream.getTracks()[0].getSettings().deviceId
                } catch (error) {
                  console.error('Failed to get deviceId from track', error)
                }
              }

              if (obj.elementId) {
                let element = document.getElementById(obj.elementId)

                if (!element) {
                  console.error(`${obj.description} HTML element ${obj.elementId} not found`)
                } else {
                  Janus.attachMediaStream(element, stream)

                  const src = element.srcObject ? element.srcObject : element.src
                  if (!src) {
                    console.error(`${obj.description} not attached`)
                  } else {
                    console.debug(`${obj.description} attached srcObject:${!!element.srcObject} src:${!!element.src}`)
                    src.getTracks().forEach(function(track) {
                      track.onended = function() {
                        obj.publisher.handle.send({ message: { request: 'leave' } })
                        console.debug(`${obj.description} track ended`)
                      }
                    })
                  }
                }
              }

              if (obj.on) await obj.on()
              obj.stream = stream
              obj.active = true
              obj.loading = false
              console.debug(`${obj.description} started`)
              self.buildMediaOptions()
              self.reloadPage()
            },
            off: async function(error) {
              const durationMs = obj.startDate ? new Date() - obj.startDate : null

              await self.clearPublisher(obj)
              obj.loading = false

              if (!error) {
                const duration = durationMs ? Math.trunc(duration / 1000) : null
                console.info(`${obj.description} stopped - duration:${duration}`)
              } else {
                let errorKey
                let ignoreError = false
                let newErrorType = null
                const typeOfError = typeof error
                if (typeOfError === 'object') {
                  errorKey = String(error.name).toLocaleLowerCase()
                  if (errorKey === 'notallowederror') {
                    newErrorType = 'notAllowed'
                    if (obj.type === 'screenShare' && durationMs && durationMs > 700) ignoreError = true
                  } else if (errorKey === 'notreadableerror' || errorKey === 'trackstarterror') {
                    newErrorType = 'alreadyInUse'
                  }
                } else if (typeOfError === 'string') {
                  const lowerErrorStr = error.toLocaleLowerCase()
                  if (lowerErrorStr === 'no capture device found') newErrorType = 'noDevices'
                }

                if (newErrorType) {
                  if (ignoreError) {
                    console.info(
                      `Failed to start ${obj.description} "${newErrorType}" duration:${durationMs} ` +
                        JSON.stringify(error)
                    )
                  } else {
                    obj.errorType = newErrorType
                    console.warn(`Failed to start ${obj.description} "${newErrorType}": ${JSON.stringify(error)}`)
                  }
                } else if (errorKey === 'overconstrainederror' && obj.deviceId) {
                  console.error(`Failed to start ${obj.description} with device ${obj.deviceId}, retrying`)
                  if (obj.deviceId) obj.setDeviceIdCore(null)
                  self.startPublisher(obj)
                } else {
                  console.error(
                    `Failed to start ${obj.description}: ${JSON.stringify(error)}`,
                    error.toString ? error.toString() : null
                  )
                }
              }

              self.reloadPage()
            }
          },
          token: this.instance.janus.token,
          use_simulcast: obj.useSimulcast || false,
          media_state(type, receiving) {
            console.debug(`Media state defined for ${obj.type} - ${type} receiving:${receiving}`)
            // TODO reload if we stop sending? Try to reattach - if (!receiving && obj.reload) obj.reload()
          },
          slow_link(uplink, lost) {
            self.setLostPackets(obj.type === 'audio' ? 'audio' : 'video', uplink, lost)
          }
        }).attach()
      } catch (error) {
        console.error(`Failed to start ${obj.description}`, error)
        obj.startDate = null
        obj.loading = false
      }
    },
    async stopPublisher(obj, config) {
      if (obj.loading) {
        console.debug(`${obj.description} already loading, cannot stop`)
        return
      } else if (!obj.publisher) {
        console.debug(`${obj.description} already stopped`)
        return
      }

      const shouldReload = config && config.reload
      console.info(`${obj.description} stop required reload:${shouldReload}`)

      obj.loading = true
      try {
        await obj.publisher.left()
        await this.clearPublisher(obj)
      } finally {
        if (!shouldReload) obj.loading = false
      }

      if (shouldReload) this.startPublisher(obj, true)
    },
    async clearPublisher(obj) {
      const wasActive = obj.active

      obj.active = false
      obj.publisher = null
      obj.withCPUIssues = false
      if (wasActive && obj.off) await obj.off()

      const duration = obj.startDate ? Math.trunc((new Date() - obj.startDate) / 1000) : null
      obj.startDate = null
      console.debug(`${obj.description} stop done - duration:${duration}`)

      const element = document.getElementById(obj.elementId)
      if (element) {
        if (element.srcObject) element.srcObject = null
        if (element.src) element.src = null
      }
    },
    async stopAllPublishers() {
      await this.stopShareScreen()
      await this.stopCamera()
      await this.stopAudio()
    },

    startAudio() {
      if (this.attendee.withRole('audio_allowed')) {
        this.startPublisher(this.audio)
      }
    },
    async stopAudio(config) {
      await this.stopPublisher(this.audio, config)
    },

    startCamera() {
      if (this.attendee.withRole('camera_allowed')) {
        this.startPublisher(this.camera)
      }
    },
    async stopCamera(config) {
      await this.stopPublisher(this.camera, config)
    },

    startShareScreen() {
      if (this.attendee.withRole('sharescreen_allowed')) {
        this.startPublisher(this.screenShare)
      }
    },
    async stopShareScreen(config) {
      await this.stopPublisher(this.screenShare, config)
    },

    async setStreamHDCore(obj, enable) {
      if (!enable) {
        obj.bitratePercentage = obj.bitrateConfig.maxPercentage
      }

      let shouldReload = true
      if (obj.getMediaConstraints && obj.active && obj.stream) {
        let tracks = obj.stream.getTracks().filter(track => {
          return track.kind == 'video'
        })

        if (tracks && tracks.length) {
          shouldReload = false
          if (tracks.length > 1) console.error(`Got multiple video tracks ${tracks.length} for ${obj.description}`)

          const constraints = obj.getMediaConstraints()
          try {
            await tracks[0].applyConstraints(constraints)
            console.info(`HD ${obj.description} ${enable ? 'enabled' : 'disabled'}`, constraints)
            this.reloadPage()
          } catch (error) {
            console.error(`HD ${obj.description} error`, error)
            obj.reload()
          }
        }
      }

      if (shouldReload) {
        obj.reload()
        console.info(`HD ${obj.description} ${enable ? 'enabled' : 'disabled'}`)
      }
    },
    toggleMirrorVideo() {
      if (localStorage.getItem('device.video.mirror.disabled')) {
        localStorage.removeItem('device.video.mirror.disabled')
        this.camera.mirrorDisabled = false
        console.info('Video mirror enabled')
      } else {
        localStorage.setItem('device.video.mirror.disabled', 'true')
        this.camera.mirrorDisabled = true
        console.info('Video mirror disabled')
      }
    },
    async toggleHdMode() {
      if (this.attendee.with_hd) {
        this.attendee.with_hd = false
        try {
          await this.setStreamHDCore(this.camera, false)
          await this.setStreamHDCore(this.screenShare, false)
          await ServiceMeetings.removeAttendeeFlag(this.meeting.key, 'hd')
          localStorage.removeItem('device.video.hd')
        } catch (error) {
          this.attendee.with_hd = true
          console.error(`Failed to remove HD`, error)
        }
      } else {
        const self = this
        this.$buefy.dialog.confirm({
          type: 'is-primary',
          confirmText: 'Ativar modo HD',
          title: `Vídeo em modo HD`,
          message: `O modo de alta-definição melhora a qualidade de vídeo do seu dispositivo
          para os outros participantes. No entanto, é um modo mais exigente
          quer em termos de ligação quer de processamento do seu computador.`,
          onConfirm: async () => {
            self.attendee.with_hd = true
            try {
              await self.setStreamHDCore(self.camera, true)
              await self.setStreamHDCore(self.screenShare, true)
              await ServiceMeetings.setAttendeeFlag(this.meeting.key, 'hd')
              localStorage.setItem('device.video.hd', true)
            } catch (error) {
              self.attendee.with_hd = false
              console.error(`Failed to add HD`, error)
            }
          }
        })
      }
    },

    async updateSelfStreamStats(withStats) {
      const audioPublisher = this.audio.publisher
      if (audioPublisher) {
        const audioPC = audioPublisher.handle ? audioPublisher.handle.webrtcStuff.pc : null
        if (audioPC) {
          const audioBitrate = this.audio.bitrate
          for (let statsItem of await audioPC.getStats()) {
            if (!statsItem) continue
            statsItem = statsItem[1]

            if (
              statsItem.type === 'outbound-rtp' &&
              !statsItem.id.includes('rtcp') &&
              (statsItem.mediaType === 'audio' || statsItem.id.toLowerCase().includes('audio'))
            ) {
              this.buildBitrateStats(audioBitrate, statsItem, statsItem.bytesSent, withStats)
            }
          }
        }
      }

      // TODO: let withCPUIssuesOnCamera = false
      const cameraPublisher = this.camera.publisher
      if (cameraPublisher) {
        const cameraPC = cameraPublisher.handle ? cameraPublisher.handle.webrtcStuff.pc : null
        if (cameraPC) {
          const cameraBitrate = this.camera.bitrate
          const foundSSRC = new Set()
          for (let statsItem of await cameraPC.getStats()) {
            if (!statsItem) continue
            statsItem = statsItem[1]

            if (
              statsItem.type === 'outbound-rtp' &&
              statsItem.frameWidth &&
              !statsItem.id.includes('rtcp') &&
              (statsItem.mediaType === 'video' || statsItem.id.toLowerCase().includes('video'))
            ) {
              foundSSRC.add(statsItem.ssrc + '')
              let ssrcBitrate = cameraBitrate[statsItem.ssrc]
              if (!ssrcBitrate) ssrcBitrate = cameraBitrate[statsItem.ssrc] = { vars: {} }
              this.buildBitrateStats(ssrcBitrate, statsItem, statsItem.bytesSent, withStats)
              // TODO: if (ssrcBitrate.qualityLimitationReason == 'cpu') withCPUIssuesOnCamera = true
            }
          }

          for (let ssrc of Object.keys(cameraBitrate)) {
            if (!foundSSRC.has(ssrc)) delete cameraBitrate[ssrc]
          }
        }
      }

      // TODO: let withCPUIssuesOnScreenShare = false
      const screenSharePublisher = this.screenShare.publisher
      if (screenSharePublisher) {
        const screenSharePC = screenSharePublisher.handle ? screenSharePublisher.handle.webrtcStuff.pc : null
        if (screenSharePC) {
          const screenShareBitrate = this.screenShare.bitrate
          for (let statsItem of await screenSharePC.getStats()) {
            if (!statsItem) continue
            statsItem = statsItem[1]

            if (
              statsItem.type === 'outbound-rtp' &&
              statsItem.frameWidth &&
              !statsItem.id.includes('rtcp') &&
              (statsItem.mediaType === 'video' || statsItem.id.toLowerCase().includes('video'))
            ) {
              this.buildBitrateStats(screenShareBitrate, statsItem, statsItem.bytesSent, withStats)
              // TODO: if (screenShareBitrate.qualityLimitationReason == 'cpu') withCPUIssuesOnScreenShare = true
            }
          }
        }
      }

      /* TODO ignore for now, qualityLimitationReason=cpu don't work very well
      if (withCPUIssuesOnCamera != this.camera.withCPUIssues) {
        this.camera.withCPUIssues = withCPUIssuesOnCamera
      }
      if (withCPUIssuesOnScreenShare != this.screenShare.withCPUIssues) {
        this.screenShare.withCPUIssues = withCPUIssuesOnScreenShare
      }

      this.adjustBitratePercentage()
      */
    }
  }
}
