var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.mode == 'tablet')?_c('div',{staticClass:"fw-multiplechoice flex flex-col gap-2"},_vm._l((_vm.options_tmp),function(option,o){return _c('div',{key:_vm.$vnode.key + '_multiple_choice_option_' + o,staticClass:"multiplechoice_option -mt-2 relative focus:border-b rounded focus:border-gray-200 active:border-b active:border-gray-200 border-b border-gray-200 py-3 pl-12 pr-24 font-medium block w-full text-left",class:{
      selected: option.selected && !_vm.saving,
      saving: _vm.saving,
      disabled: !_vm.canOptionBeSelected(option),
      inactive: _vm.isOptionDisabled(option)
    }},[_c('div',{staticClass:"multiplechoice_letter absolute text-center py-2 bg-white uppercase rounded-xl leading-4 text-lg font-medium left-0.5",staticStyle:{"width":"2rem"}},[_c('div',[_vm._v(_vm._s(String.fromCharCode(97 + o)))])]),(_vm.language && typeof option.text !== 'string')?_c('div',[_vm._v(_vm._s(option.text[_vm.language]))]):_c('div',[_vm._v(_vm._s(option.text))]),(option.selected && !_vm.disabled)?_c('div',{staticClass:"action_label deselect_label",on:{"click":function($event){return _vm.selectOption(o)}}},[_vm._v(" Retirar ")]):(_vm.canOptionBeSelected(option) && !_vm.maxSelectedOptionsReached && !_vm.disabled)?_c('div',{staticClass:"action_label select_label",on:{"click":function($event){return _vm.selectOption(o)}}},[_vm._v(" Escolher ")]):_vm._e()])}),0):_c('div',{staticClass:"fw-multiplechoice flex flex-col gap-2"},_vm._l((_vm.options_tmp),function(option,o){return _c('button',{key:_vm.$vnode.key + '_multiple_choice_option_' + o,staticClass:"multiplechoice_option -mt-2 relative focus:border-b rounded focus:border-gray-200 active:border-b active:border-gray-200 border-b border-gray-200 py-2.5 pl-12 pr-24 font-medium block w-full text-left",class:{
      selected: option.selected && !_vm.saving,
      saving: option.selected && _vm.saving,
      disabled: !_vm.canOptionBeSelected(option),
      inactive: _vm.isOptionDisabled(option)
    },on:{"click":function($event){return _vm.selectOption(o)}}},[_c('div',{staticClass:"multiplechoice_letter absolute text-center py-2 bg-white uppercase rounded-xl leading-4 text-lg font-medium left-0.5",staticStyle:{"width":"2rem"}},[_c('div',[_vm._v(_vm._s(String.fromCharCode(97 + o)))])]),(_vm.language && typeof option.text !== 'string')?_c('div',[_vm._v(_vm._s(option.text[_vm.language]))]):_c('div',[_vm._v(_vm._s(option.text))]),(option.selected)?_c('div',{staticClass:"action_label deselect_label"},[_vm._v("Retirar")]):(_vm.canOptionBeSelected(option) && !_vm.maxSelectedOptionsReached)?_c('div',{staticClass:"action_label select_label"},[_vm._v(" Escolher ")]):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }