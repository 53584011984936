<template>
  <DashboardHero :background="cover" :background-image-url="portfolio.cover" @click.native="$emit('edit')">
    <div v-if="editable" class="absolute top-5 right-5">
      <fw-button type="transparent" class="text-gray-100 gap-2">
        <fw-icon-edit class="h-5 w-5" />
        {{ $t('editimage') }}
      </fw-button>
    </div>
    <div class="flex flex-col justify-end items-stretch min-h-80">
      <div class="flex gap-5 items-center text-left">
        <UserAvatar :user="user" size="lg" class="flex-shrink-0" />
        <div>
          <div class="text-2xl font-bold ">{{ user.name }}</div>
          <div v-if="user.number" class="font-semibold">{{ user.number }}</div>
        </div>
      </div>
    </div>
  </DashboardHero>
</template>
<script>
import UserAvatar from '@/fw-modules/fw-core-vue/id/components/user/UserAvatar'
import DashboardHero from '@/fw-modules/fw-core-vue/ui/components/dashboard/DashboardHero'

export default {
  name: 'PortfolioBanner',
  components: {
    UserAvatar,
    DashboardHero,
  },
  props: {
    portfolio: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => {},
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cover() {
      return this.portfolio.options.cover == null ? 'abstract-01' : this.portfolio.options.cover
    },
  },
}
</script>

<i18n>
  {
    "pt": {
      "editimage": "Editar imagem"
    },
    "en": {
      "editimage": "Edit image"
    }
  }
</i18n>
