<template>
  <span class="flex items-center justify-center">
    <span
      class="animate-ping absolute w-full h-full inline-flex rounded-full opacity-50"
      :style="{ backgroundColor: activeColor }"
    ></span>
    <span class="relative inline-flex w-full h-full rounded-full " :style="{ backgroundColor: activeColor }"></span>
  </span>
</template>
<script>
export default {
  name: 'DotActive',
  props: {
    activeColor: {
      type: String,
      default: '#30a898'
    }
  }
}
</script>
