<template>
  <b-modal
    :active.sync="isActive"
    :on-cancel="closeModal"
    has-modal-card
    aria-role="dialog"
    aria-modal
    class="meetings-modal-pods-enter-queue"
  >
    <div class="modal-card is-width-small">
      <section class="modal-card-body">
        <div class="is-flex is-flex-space-between has-margin-bottom-medium">
          <div class="is-flex is-flex-align-center">
            <div class="label is-size-3">Configurar painel de participantes</div>
          </div>
          <div>
            <b-button type="is-text" icon-right="times" @click="closeModal">Fechar</b-button>
          </div>
        </div>

        <h2 class="is-size-4 has-margin-top-medium">Participantes por página</h2>
        <div class="has-text-small has-margin-top-small">
          Indique o número <strong>máximo</strong> de participantes a apresentar por página.
        </div>
        <div>
          <div class="has-margin-top has-margin-bottom is-flex is-flex-align-center">
            <div
              class="has-background-light has-text-weight-bold has-padding is-rounded is-flex-inline has-margin-right-medium"
              :class="{
                'has-background-warning': pageSettings.current > pageSettings.warnAfter
              }"
            >
              {{ pageSettings.current }}
            </div>
            <b-slider
              v-model="pageSettings.current"
              type="is-dark"
              indicator
              :min="pageSettings.min"
              :max="pageSettings.max"
              @change="pageSettings.setPods(pageSettings.current)"
            >
              <template v-for="val in podsPerPageOptions">
                <b-slider-tick :key="val" :value="val">{{ val }}</b-slider-tick>
              </template>
            </b-slider>
          </div>
          <div
            v-if="pageSettings.current > pageSettings.warnAfter"
            class="has-text-small notification is-warning has-padding"
          >
            A definição de um número alto de participantes por página poderá afetar a performance da aplicação no seu
            dispositivo.
          </div>
        </div>
        <h2 class="is-size-4 has-margin-top-medium">Vista de oradores ativos</h2>
        <div class="has-text-small has-margin-top-small">
          Indique se os participantes que falam devem ser colocados automaticamente na primeira página.
        </div>
        <div class="has-margin-top has-margin-bottom">
          <b-dropdown aria-role="list">
            <button slot="trigger" slot-scope="{ active }" class="button is-light">
              <span>{{ pageSettings.activeSpeaker ? 'Sim' : 'Não' }}</span>
              <b-icon size="is-small" :icon="active ? 'chevron-up' : 'chevron-down'"></b-icon>
            </button>
            <b-dropdown-item aria-role="listitem" @click="pageSettings.setActiveSpeaker(true)">Sim</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="pageSettings.setActiveSpeaker(false)">Não</b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="has-text-small has-margin-top-small">
          Indique se os participantes com video ligado devem ser colocados automaticamente na primeira página.
        </div>
        <div class="has-margin-top has-margin-bottom">
          <b-dropdown aria-role="list">
            <button slot="trigger" slot-scope="{ active }" class="button is-light">
              <span>{{ pageSettings.activeVideo ? 'Sim' : 'Não' }}</span>
              <b-icon size="is-small" :icon="active ? 'chevron-up' : 'chevron-down'"></b-icon>
            </button>
            <b-dropdown-item aria-role="listitem" @click="pageSettings.setActiveVideo(true)">Sim</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="pageSettings.setActiveVideo(false)">Não</b-dropdown-item>
          </b-dropdown>
        </div>
        <h2 class="is-size-4 has-margin-top-medium">Formato do vídeo dos participantes</h2>
        <div class="has-text-small has-margin-top-small">Escolha como pretende ver os participantes.</div>
        <div class="flex mt-2">
          <button
            :class="{
              'ring-2 ring-primary ring-inset font-semibold': pageSettings.fillPod,
              'shadow hover:shadow-sm -translate-y-0.5 hover:translate-y-0 ring-1 ring-gray-100 ring-inset': !pageSettings.fillPod
            }"
            class="rounded-l-lg w-1/2 bg-white py-3 px-3 items-center justify-around text-left flex gap-2.5"
            @click="pageSettings.setFillPod(true)"
          >
            <div class="w-9 h-9 flex-shrink-0 rounded border-2 border-gray-400 text-center">
              <fw-icon-smile class="h-8 w-8"></fw-icon-smile>
            </div>
            <div class="text-sm leading-4">Preencher janela do participante com vídeo</div>
          </button>
          <button
            :class="{
              'ring-2 ring-primary ring-inset font-semibold': !pageSettings.fillPod,
              'shadow hover:shadow-sm -translate-y-0.5 hover:translate-y-0 ring-1 ring-gray-100 ring-inset':
                pageSettings.fillPod
            }"
            class="rounded-r-lg w-1/2 bg-white py-3 px-3 items-center justify-around text-left flex gap-2.5"
            @click="pageSettings.setFillPod(false)"
          >
            <div class="w-9 h-9 rounded border-2 bg-gray-400 border-gray-400 text-center flex-shrink-0">
              <div class="h-6 mt-1 bg-white text-center flex justify-center items-center">
                <fw-icon-smile class="h-5"></fw-icon-smile>
              </div>
            </div>
            <div class="text-sm leading-4">Ver vídeo todo, com barras pretas laterais</div>
          </button>
        </div>
        <div class="has-text-tiny has-margin-top-medium has-text-muted">
          Estas definições são guardadas para todas as suas sessões neste dispositivo.
        </div>
      </section>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalPodsGalleryConfigurations',
  props: {
    showPodsGalleryConfig: Boolean,
    pageSettings: {
      type: Object,
      deep: true
    }
  },
  data() {
    const optionStep = 10
    let podsPerPageOptions = [this.pageSettings.min]
    for (let i = optionStep; i < this.pageSettings.max; i += optionStep) {
      podsPerPageOptions.push(i)
    }
    if (podsPerPageOptions[podsPerPageOptions.length - 1] !== this.pageSettings.max) {
      podsPerPageOptions.push(this.pageSettings.max)
    }

    return {
      isActive: this.showPodsGalleryConfig,
      loading: true,
      podsPerPageOptions: podsPerPageOptions
    }
  },

  watch: {
    showPodsGalleryConfig(value) {
      this.isActive = value
    }
  },

  methods: {
    closeModal() {
      this.$emit('toggle-pods-gallery-config')
      this.$emit('close', false)
    }
  }
}
</script>
