<template>
  <div class="row_class">
    <label class="fw-row-checkbox relative select-none">
      <input
        v-if="selectable"
        v-model="currentValue"
        type="checkbox"
        class="opacity-0 absolute"
        @change="selectClass"
      />
      <div
        class="fw-row-checkbox-inside flex w-full gap-1 items-center relative fw-card-file justify-between p-2 my-1 group rounded-lg bg-opacity-10 shadow-sm"
        :class="{
          'hover:bg-opacity-40': clickable,
          'hover:shadow-xl': clickable,
          'cursor-pointer': clickable || selector,
          border: selector,
          'border-gray-200': selector,
          'hover:shadow-md': selector
        }"
      >
        <svg
          v-if="selector"
          class="fill-current absolute top-4 right-4 h-4 w-4 text-gray-700"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M12 8l6 6H6z" />
        </svg>
        <svg
          v-if="selector"
          class="fill-current absolute bottom-4 right-4 h-4 w-4 text-gray-700"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M12 16l-6-6h12z" />
        </svg>
        <div v-if="selectable" class="check h-4 w-4 rounded p-1"></div>
        <div class="flex-1 text-sm md:text-base ml-1">
          <div class="flex flex-col">
            <div class="flex">
              <v-clamp autoresize :max-lines="1">
                {{ classTitle }}
              </v-clamp>
            </div>
          </div>
          <div class="text-xs md:text-sm flex opacity-60 gap-1 justify-start leading-5">
            <div>{{ unitTitle }}</div>
            <div v-if="unitTitle.length > 0 && profile.length > 0">&nbsp;-&nbsp;</div>
            <div v-if="profile.length > 0">{{ profile }}</div>
            <div v-if="mainCourse.length > 0">&nbsp;-&nbsp;{{ mainCourse }}</div>
            <div v-if="numExtraCourses > 0">+ {{ numExtraCourses }}</div>
            <span
              v-if="ocorrence !== ''"
              class="text-xs font-normal leading-3 inline-flex bg-gray-300 bg-opacity-60 text-gray-600 rounded-full align-middle items-center m-0.5 ml-2 px-2"
              >{{ ocorrence }}</span
            >
          </div>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'RowClass',
  props: {
    classobj: Object,
    bucket: Object,
    selectable: {
      type: Boolean,
      default: false
    },
    selector: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    unitName: {
      type: String,
      default: ''
    }
  },
  data: function() {
    return {
      currentValue: false
    }
  },
  computed: {
    ocorrence() {
      if (this.classobj && typeof this.classobj !== 'undefined' && this.classobj.regime === 'semiannual') {
        return this.classobj.regime_occurrence + 'º Semestre'
      }
      return ''
    },
    classTitle() {
      if (this.bucket && this.bucket.context && this.bucket.context.class_title) {
        return this.bucket.context.class_title
      }
      if (this.classobj) {
        return this.classobj.title
      }
      return ''
    },
    unitTitle() {
      if (this.unitName.length > 0) {
        return this.unitName
      }

      if (this.bucket && this.bucket.context && this.bucket.context.unit_title) {
        return this.bucket.context.unit_title
      }
      return ''
    },
    profile() {
      if (this.classobj) {
        return this.classobj.profile
      }
      return ''
    },
    courses() {
      if (this.bucket && this.bucket.context && this.bucket.context.courses.length > 0) {
        return this.bucket.context.courses
      }
      return []
    },
    mainCourse() {
      if (this.courses.length > 0) {
        return this.courses[0]
      }
      return ''
    },
    extraCourses() {
      return this.courses.slice(1).join(', ')
    },
    numExtraCourses() {
      if (this.courses.length >= 1) {
        return this.courses.length - 1
      }
      return 0
    }
  },
  created() {
    this.currentValue = this.checked
  },
  methods: {
    selectClass: function() {
      if (this.selectable) {
        Vue.set(this.classobj, 'selected', this.currentValue)
        this.$emit('selected', this.currentValue)
      }
    }
  }
}
</script>
<style>
.fw-row-checkbox input[type='checkbox']:checked + .fw-row-checkbox-inside {
  background-color: rgba(45, 212, 191, 0.1);
}

.fw-row-checkbox:hover input[type='checkbox']:checked + .fw-row-checkbox-inside {
  background-color: rgba(45, 212, 191, 0.5);
}
.fw-row-checkbox input[type='checkbox'] + .fw-row-checkbox-inside {
  background-color: white;
}
.fw-row-checkbox input[type='checkbox'] + .fw-row-checkbox-inside .check {
  position: absolute;
  background: white;
  border: solid 1px #6accb3;
}
.fw-row-checkbox input[type='checkbox']:checked + .fw-row-checkbox-inside .check {
  background: #30a898
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:%23fff' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat center center;
  border-color: #30a898;
  background-size: 80%;
}
.fw-row-checkbox input[type='checkbox'] + .fw-row-checkbox-inside .repo-icon {
  background-color: rgba(229, 231, 235, 0.5);
}
.fw-row-checkbox input[type='checkbox']:checked + .fw-row-checkbox-inside .repo-icon {
  background-color: white;
}
.fw-row-checkbox input[type='checkbox'] + .fw-row-checkbox-inside .id-label {
  background-color: rgb(229, 229, 229);
  color: rgb(64, 64, 64);
}
.fw-row-checkbox input[type='checkbox']:checked + .fw-row-checkbox-inside .id-label {
  background-color: #30a898;
  color: white;
}
.fw-row-checkbox .fw-row-checkbox-inside {
  background-color: white;
}
</style>
