<template>
  <div class="w-full h-full flex flex-col justify-between bg-white">
    <!-- Unit header -->
    <div class="flex flex-col gap-2 px-4 py-2 md:p-4 items-center">
      <div class="w-full flex gap-2 lg:gap-3 items-center">
        <!-- Teachers -->
        <div v-if="!isMobile" class="flex items-center gap-2">
          <div v-for="user in sessionTeachers" :key="user.key" class="flex gap-1 items-center">
            <fw-avatar :user="user" size="md" />
          </div>
        </div>
        <!-- Unit ID -->
        <div class="flex-1 flex flex-col gap-1">
          <div class="flex gap-2">
            <fw-heading size="h3">Aula {{ session.start_date | formatDayWithTime }}</fw-heading>
            <div
              v-if="(isTeacher && !isMobile) || (isMobile && session.done_date && !session.is_active)"
              class="lg:bg-opacity-10 lg:rounded-lg font-bold text-sm flex items-center gap-1 justify-center lg:px-2 lg:py-1 order-first lg:order-none"
              :class="{
                'lg:bg-primary text-primary': session.done_date && !session.is_active,
                'lg:bg-gray-500 text-gray-500': !session.done_date && !session.is_active
              }"
            >
              <fw-icon-checkbox-solid v-if="session.done_date && !session.is_active" class="w-6 h-6 lg:w-4 lg:h-4" />
              <span class="hidden lg:flex">
                {{ session.done_date && !session.is_active ? 'Aula realizada' : 'Aula não realizada' }}
              </span>
            </div>
          </div>
          <div v-if="session.space.title" class="text-sm md:text-md flex items-center gap-1">
            <fw-icon-map-pin3 class="w-4 h-4" /> {{ session.space.title }}
            <span v-if="session.space.capacity" class="text-xs text-gray-500 p-2"
              >{{ session.space && session.space.capacity }} lugares</span
            >
          </div>
          <div class="text-xs md:text-sm text-gray-500">{{ edition.title }} / {{ edition.unit.title }}</div>
        </div>
        <!-- Unit main navigation -->
        <div class="flex gap-2 items-center">
          <fw-button
            type="basic"
            aria-label="Sumário"
            class="rounded-full w-8 h-8 md:w-12 md:h-12 items-center flex justify-center"
            :class="{
              'bg-white bg-opacity-70 text-primary': view == 'summary'
            }"
            @click.native="changeView('summary')"
          >
            <fw-icon-align-left class="w-5 h-5 md:w-6 md:h-6" />
          </fw-button>
          <fw-button
            v-if="isTeacher"
            type="basic"
            aria-label="Presenças"
            class="rounded-full w-8 h-8 md:w-12 md:h-12 items-center flex justify-center"
            :class="{
              'bg-white bg-opacity-70 text-primary': view == 'users'
            }"
            @click.native="changeView('users')"
          >
            <fw-icon-people class="w-5 h-5 md:w-6 md:h-6" />
          </fw-button>
          <fw-button
            v-if="!inMeeting"
            type="basic"
            label="Sala virtual"
            class="rounded-full w-8 h-8 md:w-12 md:h-12 items-center flex justify-center"
            @click.native="$router.push({ name: 'live', params: { key: edition.meeting.key_alias } })"
          >
            <fw-icon-live
              class="w-5 h-5 md:w-6 md:h-6"
              :class="{
                'animate-pulse text-primary ': edition.meeting.running
              }"
            />
          </fw-button>
        </div>
      </div>
      <fw-panel v-if="view == 'users' && isTeacher" class="w-full mt-5" title="Estudantes">
        <div>
          <div v-if="!studentsSearchOpen && session.presences.length > 10" class="opacity-50 hover:opacity-90">
            <fw-button
              class="flex items-center gap-2"
              type="link-muted"
              @click.native="studentsSearchOpen = !studentsSearchOpen"
            >
              <fw-icon-search class="w-5 h-5 mr-2" /> Pesquisar
            </fw-button>
          </div>
          <div v-if="studentsSearchOpen || studentsSearchQuery.length" class="pb-2">
            <b-input v-model="studentsSearchQuery" placeholder="Pesquisar..." autofocus type="search"></b-input>
          </div>
          <div class="flex items-end gap-3">
            <fw-button
              v-for="filter in presencesListFilters"
              :key="filter.key"
              :type="filterStudentsActive == filter.key ? 'tab-active' : 'tab'"
              @click.native="filterStudentsActive = filter.key"
            >
              {{ filter.label }}
              <fw-badge v-if="filterStudentsActive == filter.key" inline type="simple">{{
                presencesStudentsFilteredByStatus.length
              }}</fw-badge>
            </fw-button>
          </div>
        </div>
      </fw-panel>
    </div>

    <!-- Unit view -->
    <div class="flex-1 overflow-y-auto h-full relative">
      <!-- Summary -->
      <div v-if="!view || view == 'summary'" class="p-5">
        <!-- Student panel -->
        <fw-panel v-if="!isTeacher && session.is_active" title="Assiduidade nesta aula">
          <div class="flex flex-col gap-2 justify-center items-center">
            <fw-avatar size="lg" :user="$store.getters.getUser" />
            <fw-heading size="h4">{{ $store.getters.getUser.name }}</fw-heading>
          </div>
          <div class="flex flex-col gap-2 justify-center items-center my-2">
            <div
              class="font-bold text-md text-center"
              :class="{
                'text-gray-600': !session.presence_type,
                'text-primary': session.presence_type
              }"
            >
              <span v-if="!session.presence_type">Ausente</span>
              <span v-else-if="session.presence_type === 'local'">Presente no local</span>
              <span v-else-if="session.presence_type === 'online'">Presente online</span>
            </div>
          </div>
          <div v-if="session.presence_locked" class="text-sm font-medium text-gray-500 text-center">
            Registo de assiduidade bloqueado pelo/a docente
          </div>
          <div v-else class="flex flex-col gap-2 justify-center items-center my-5">
            <fw-label marginless>Marcar presença</fw-label>
            <div class="flex items-center gap-3">
              <fw-button
                :type="session.presence_type == 'online' ? 'primary' : 'light'"
                :checked="session.presence_type == 'online'"
                @click.native="toggleSelfStudentPresence('online')"
              >
                Online
              </fw-button>
              <fw-button
                :type="session.presence_type == 'local' ? 'primary' : 'light'"
                :checked="session.presence_type == 'local'"
                @click.native="toggleSelfStudentPresence('local')"
              >
                Local
              </fw-button>
            </div>
          </div>
          <div
            v-if="session.meeting_presence_type == 'online'"
            class="text-gray-500 flex justify-center items-center flex-col"
          >
            <div class="text-lg font-medium flex items-center gap-2 mb-2">
              <fw-icon-live class="text-gray-500 w-7 h-7"></fw-icon-live> Online
            </div>
            <div class="text-sm">
              A sua participação nesta aula deve ser online, através da sala virtual da turma.
            </div>
          </div>
        </fw-panel>
        <fw-panel
          v-if="!isTeacher && session.meeting_presence_type == 'online'"
          title="Modo de participação"
          class="my-5"
        >
          <div class="text-gray-500 flex flex-col gap-1 px-3">
            <div class="text-md font-medium flex items-center gap-2">
              <fw-icon-live class="w-5 h-5"></fw-icon-live> Online
            </div>
            <div class="text-sm">
              A sua participação nesta aula deve ser online, através da sala virtual da turma.
            </div>
          </div>
        </fw-panel>
        <fw-panel v-if="isTeacher" title="Menu rápido" class="mb-12" boxed custom-class="bg-gray-50">
          <div class="grid gap-1 md:gap-2 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
            <button
              v-if="isTeacher && !session.done_date && session.permissions && session.permissions.set_as_done"
              class="bg-white h-full flex items-center justify-center flex-col p-4 shadow-lg hover:shadow-2xl rounded-xl cursor-pointer"
              aria-label="Estado"
              @click="setSessionAsDone()"
            >
              <div class="flex-1 flex items-center flex-col gap-1">
                <div class="my-2 font-bold text-center">Marcar aula como realizada</div>
                <fw-icon-checkbox class="w-5 h-5"></fw-icon-checkbox>
              </div>
            </button>
            <button
              class="bg-white flex items-center justify-center flex-col p-4 shadow-lg hover:shadow-2xl rounded-xl cursor-pointer"
              aria-label="Presenças"
              @click="changeView('users')"
            >
              <div class="flex-1 flex items-center flex-col gap-1">
                <div class="my-2 font-bold text-center">Abrir presenças e ausências</div>
                <fw-icon-people class="w-5 h-5"></fw-icon-people>
              </div>
            </button>
            <button
              v-if="!inMeeting"
              class="bg-white flex items-center justify-center flex-col p-4 shadow-lg hover:shadow-2xl rounded-xl cursor-pointer"
              aria-label="Sala virtual"
              @click="goToClassroom"
            >
              <div class="flex-1 flex items-center flex-col gap-1">
                <div class="my-2 font-bold text-center">Entrar na sala virtual</div>
                <fw-icon-live class="w-5 h-5"></fw-icon-live>
              </div>
            </button>
          </div>
        </fw-panel>
        <fw-panel title="Sumário">
          <div class="flex items-end gap-3 mb-5">
            <fw-button
              v-for="language in languages"
              :key="language"
              :type="summaryActiveLanguage == language ? 'tab-active' : 'tab'"
              @click.native="changeSummary(language)"
            >
              <v-clamp autoresize :max-lines="1">
                {{ languageTitles[language] }}
              </v-clamp>
            </fw-button>
          </div>
          <div v-if="isTeacher" class="flex flex-col gap-2">
            <fw-editor v-model="session.summaries[summaryActiveLanguage]" @changed="editorContentChanged"></fw-editor>
            <div class="flex items-center justify-end">
              <fw-button type="primary" @click.native="submitSummary">Guardar</fw-button>
            </div>
          </div>
          <div v-else>
            <div
              v-if="session.summaries[summaryActiveLanguage]"
              class="bg-gray-100 p-3 rounded-lg"
              v-html="session.summaries[summaryActiveLanguage]"
            ></div>
            <div v-else class="text-sm text-gray-500 px-3">O sumário não foi definido.</div>
          </div>
        </fw-panel>

        <fw-panel v-if="allowMeetingsActive" title="Alocação virtual" class="mt-5">
          <div v-if="!session.allowed_to_update_meetings_feature" class="text-sm">
            As restrições de participação online já não são aplicáveis nesta aula.
          </div>
          <div v-else class="p-5">
            <fw-heading size="h4" class="flex items-center gap-2">
              <fw-icon-robot class="w-5 h-5"></fw-icon-robot> Alocação automática
            </fw-heading>
            <div v-if="!sessionAllowMeetingsUsers.length" class="text-sm text-gray-500 mt-1 max-w-prose">
              Não existem restrições de ocupação presencial. A percentagem definida de lugares a ocupar no espaço é
              suficiente para acomodar todos os estudantes inscritos.
            </div>
            <div v-else>
              <div class="text-gray-500 text-sm mb-5 mt-1 max-w-prose">
                Lista dos estudantes alocados automaticamente para participar remotamente na aula.
              </div>
              <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 gap-2">
                <fw-card
                  v-for="student in sessionAllowMeetingsUsers"
                  :key="student.key"
                  no-clamp
                  no-link
                  :title="student.full_name"
                >
                  <template #header>
                    <fw-avatar size="md" :user="student" />
                  </template>
                  <template #main>
                    <div class="text-sm text-gray-500">{{ student.number }}</div>
                  </template>
                  <template v-if="student.chat" #footer>
                    <fw-button
                      label="Abrir canal de conversação"
                      :type="student.chat.unread ? 'light-primary' : 'light'"
                      class="flex gap-2 items-center justify-center relative"
                      @click.native="$emit('go-to-user-chat', student)"
                    >
                      <span>Conversa</span>
                      <fw-icon-chats class="w-5 h-5" />
                      <fw-badge v-if="student.chat.unread" inline color="primary">{{ student.chat.unread }}</fw-badge>
                    </fw-button>
                  </template>
                </fw-card>
              </div>
            </div>
          </div>
          <div v-if="editionAllowMeetingsUsers.length" class="p-5">
            <fw-heading size="h4" class="flex items-center gap-2">
              <fw-icon-user class="w-5 h-5"></fw-icon-user> Autorizados
            </fw-heading>
            <div class="text-gray-500 text-sm mb-5 mt-1 max-w-prose">
              A lista seguinte identifica os estudantes autorizados a participar online, conforme o grupo definido nas
              configurações da turma.
            </div>
            <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 gap-2">
              <fw-card
                v-for="student in editionAllowMeetingsUsers"
                :key="student.user.key"
                no-clamp
                no-link
                :title="student.user.full_name"
              >
                <template #header>
                  <fw-avatar size="md" :user="student.user" />
                </template>
                <template #main>
                  <div class="text-sm text-gray-500">{{ student.user.number }}</div>
                </template>
                <template v-if="student.user.chat" #footer>
                  <fw-button
                    label="Abrir canal de conversação"
                    :type="student.user.chat.unread ? 'light-primary' : 'light'"
                    class="flex gap-2 items-center justify-center relative"
                    @click.native="$emit('go-to-user-chat', student.user)"
                  >
                    <span>Conversa</span>
                    <fw-icon-chats class="w-5 h-5" />
                    <fw-badge v-if="student.user.chat.unread" inline color="primary">{{
                      student.user.chat.unread
                    }}</fw-badge>
                  </fw-button>
                </template>
              </fw-card>
            </div>
          </div>
        </fw-panel>
      </div>

      <!-- Students and participants -->
      <div v-if="view == 'users' && isTeacher" class="px-5 pb-5 pt-0 h-full">
        <!-- Students list -->
        <div v-if="!presencesStudentsFilteredByStatus.length" class="text-sm text-gray-500 text-center p-5">
          Não existem registos para apresentar neste filtro.
        </div>
        <div v-else>
          <div
            v-if="unlockedPresencesKeys.length"
            class="text-xs text-gray-500 flex flex-col items-center justify-center text-center p-2 mb-2 gap-3"
          >
            <div class="max-w-screen-md mx-auto">
              Agora pode bloquear a assiduidade do aluno, impedindo que o mesmo a consiga alterar. A confirmação deixa
              de ser necessária, porque é feita automaticamente. O estudante só consegue alterar a presença durante a
              aula.
            </div>
            <b-button
              size="is-small"
              type="is-light"
              class="has-margin-right-small"
              @click="lockPresences(unlockedPresencesKeys)"
            >
              Bloquear todos ({{ unlockedPresencesKeys.length }})
            </b-button>
          </div>
          <RecycleScroller
            v-slot="{ item }"
            :items="presencesStudentsFilteredByStatus"
            :item-size="55"
            :buffer="20"
            key-field="user"
            class="scroller"
          >
            <div
              class="flex gap-2 items-center relative justify-between p-2 bg-opacity-20 group hover:bg-opacity-40 hover:shadow-xl shadow-sm rounded-lg"
            >
              <fw-avatar :user="item.user" />
              <div class="flex-1">
                <v-clamp class="text-sm md:text-base" autoresize :max-lines="1">{{ item.user.full_name }}</v-clamp>
                <div class="text-xs md:text-sm text-gray-500">{{ item.user.number || item.user.email }}</div>
              </div>
              <div v-if="allowMeetingsActive && session.allow_meetings_users.includes(item.user.key)">
                <!-- TODO: validar Online permitido -->
                <div class="flex items-center gap-1 relative">
                  <fw-icon-live class="text-gray-500 w-5 h-5"></fw-icon-live>
                  <span class="absolute -top-1 -left-2 bg-gray-500 p-0.5 rounded-full text-white">
                    <fw-icon-check class="h-3 w-3" />
                  </span>
                </div>
              </div>
              <div class="flex items-center gap-2">
                <div class="text-xs md:text-sm font-bold">
                  <span v-if="!item.type" class="text-gray-500">
                    <span>Ausente</span>
                  </span>
                  <span v-else-if="item.type === 'local'" class="text-primary  flex flex-col">
                    <span class="text-xs font-normal">Presente</span>
                    <span>Local</span>
                  </span>
                  <span v-else-if="item.type === 'online'" class="text-primary  flex flex-col">
                    <span class="text-xs font-normal">Presente</span>
                    <span>Online</span>
                  </span>
                </div>
                <div v-if="session.permissions && session.permissions.update_presences" class="flex items-center gap-3">
                  <fw-button
                    :type="item.type == 'online' ? 'primary' : 'light'"
                    :checked="item.type == 'online'"
                    @click.native="item.type === 'online' ? removePresence(item) : setPresence(item, 'online')"
                  >
                    Online
                  </fw-button>
                  <fw-button
                    :type="item.type == 'local' ? 'primary' : 'light'"
                    :checked="item.type == 'local'"
                    @click.native="item.type === 'local' ? removePresence(item) : setPresence(item, 'local')"
                  >
                    Local
                  </fw-button>
                </div>
                <!-- NONIO -->
                <div v-if="item.type === 'nonio' || (item.type && !item.locked)" class="is-flex is-flex-align-center">
                  <b-button
                    v-if="item.type === 'nonio'"
                    expanded
                    size="is-small"
                    type="is-text"
                    class="has-text-dark"
                    @click="removePresence(item)"
                    >Remover</b-button
                  >
                  <b-button
                    v-else
                    expanded
                    size="is-small"
                    type="is-text"
                    class="has-text-primary"
                    @click="lockPresences([item.student.key])"
                  >
                    Bloquear
                  </b-button>
                </div>
              </div>
            </div>
          </RecycleScroller>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceAcademic from '@/fw-modules/fw-core-vue/academic/services/ServiceAcademic'
import { RecycleScroller } from 'vue-virtual-scroller'

export default {
  components: {
    RecycleScroller
  },

  props: {
    edition: Object,
    session: Object,
    isTeacher: Boolean
  },

  data() {
    let languages = []
    for (let language of Object.keys(this.session.summaries)) languages.push(language)
    languages.sort((a, b) => a.localeCompare(b))
    if (!languages.length) languages.push('pt')

    // Set PT first
    let ptIndex = languages.indexOf('pt')
    if (ptIndex > 0) {
      languages.splice(ptIndex, 1)
      languages.unshift('pt')
    }

    let summaryEditor = null
    const summaryActiveLanguage = languages[0]
    if (this.isTeacher) {
      // summaryEditor = new Editor({
      //   extensions: [new Underline(), new Bold(), new Italic(), new History()],
      //   onUpdate: ({ getHTML }) => {
      //     this.session.summaries[this.summaryActiveLanguage] = getHTML()
      //   },
      // })
      // summaryEditor.setContent(this.session.summaries[summaryActiveLanguage] || '')
    }

    const classTeacherKeys = new Set(this.session.teachers.map(teacher => teacher.key))
    for (let teacher of this.edition.teachers) {
      if (teacher.user) classTeacherKeys.add(teacher.user.key)
    }

    const usersRef = {}
    if (this.edition.students) {
      for (let student of this.edition.students) {
        usersRef[student.user.key] = student.user
      }
    }

    return {
      view: 'summary',
      classTeacherKeys: classTeacherKeys,
      studentsSearchQuery: '',
      studentsSearchOpen: false,
      viewDataMode: localStorage.getItem('settings.viewdata.mode') || 'as-gallery',
      forceViewData: 'as-list',
      sessionTeachers:
        this.session.teachers && this.session.teachers.length ? this.session.teachers : this.edition.teachers || [],
      filterStudentsActive: 'all',
      summaryActiveLanguage: summaryActiveLanguage,
      summaryEditor: summaryEditor,
      languages: languages,
      languageTitles: {
        ru: 'Russo',
        it: 'Italiano',
        ja: 'Japonês',
        pt: 'Português',
        es: 'Espanhol',
        zh: 'Chinês',
        en: 'Inglês',
        fr: 'Francês',
        de: 'Alemão',
        lt: 'Lituana'
      },
      presencesListFilters: [
        {
          key: 'all',
          label: 'Todos'
        },
        {
          key: 'online',
          label: 'Online'
        },
        {
          key: 'local',
          label: 'Local'
        },
        {
          key: 'present',
          label: 'Presentes'
        },
        {
          key: 'not-present',
          label: 'Ausentes'
        }
      ],
      showAddForm: false,
      formAddStudentKey: null,
      formAddStartDate: null,
      formAddEndDate: null,
      formAddDescription: null,
      formAddErrorMessage: '',
      usersRef: usersRef,
      previousAllowMeetingsPercentage: this.edition.allow_meetings ? this.edition.allow_meetings.percentage : null
    }
  },

  computed: {
    summaryDirty() {
      return this.$store.state.dirtyData
    },
    isMobile() {
      return window.innerWidth < 640
    },
    presencesStudentsFilteredByStatus() {
      if (this.filterStudentsActive == 'online') {
        return this.studentsFilteredBySearch.filter(presence => {
          return presence.type === 'online'
        })
      } else if (this.filterStudentsActive == 'local') {
        return this.studentsFilteredBySearch.filter(presence => {
          return presence.type === 'local'
        })
      } else if (this.filterStudentsActive == 'present') {
        return this.studentsFilteredBySearch.filter(presence => {
          return !!presence.type
        })
      } else if (this.filterStudentsActive == 'not-present') {
        return this.studentsFilteredBySearch.filter(presence => {
          return !presence.type
        })
      } else {
        return this.studentsFilteredBySearch
      }
    },
    presencesOthersFilteredByStatus() {
      return this.session.presences.filter(presence => {
        return !presence.student && !this.classTeacherKeys.has(presence.user.key)
      })
    },
    studentsFilteredBySearch() {
      if (this.studentsSearchQuery) {
        const find = utils.existsInSearchFactory(this.studentsSearchQuery)
        return this.session.presences.filter(presence => {
          return !!presence.student && find(presence.user.full_name)
        })
      } else {
        return this.session.presences.filter(presence => {
          return !!presence.student
        })
      }
    },
    unlockedPresencesKeys() {
      let keys = []
      for (let presence of this.presencesStudentsFilteredByStatus) {
        if (presence.type && presence.type !== 'nonio' && !presence.locked) {
          keys.push(presence.student.key)
        }
      }
      return keys
    },
    allowMeetingsFeature() {
      return Boolean(this.edition.allow_meetings_feature && this.edition.students.length)
    },
    allowMeetingsActive() {
      return Boolean(this.allowMeetingsFeature && this.edition.allow_meetings.active)
    },
    formAddIsReady() {
      return Boolean(this.formAddStudentKey && this.formAddStartDate && this.formAddEndDate)
    },
    editionAllowMeetingsUsers() {
      const users = []
      if (this.edition.allow_meetings.users && this.edition.allow_meetings.users.length) {
        for (let userInfo of this.edition.allow_meetings.users) {
          const user = this.usersRef[userInfo.user_key]
          if (user) {
            users.push({
              key: userInfo.user_key,
              user: user,
              dates: userInfo.dates
            })
          }
        }
      }
      users.sort((a, b) => a.user.name.localeCompare(b.user.name))
      return users
    },
    sessionAllowMeetingsUsers() {
      const users = []
      if (this.session.allow_meetings_users && this.session.allow_meetings_users.length) {
        for (let key of this.session.allow_meetings_users) {
          const user = this.usersRef[key]
          if (user) {
            users.push(user)
          }
        }
      }
      users.sort((a, b) => a.name.localeCompare(b.name))
      return users
    },
    inMeeting() {
      return this.$router.currentRoute.name == 'live'
    }
  },

  beforeDestroy() {
    if (this.summaryEditor) this.summaryEditor.destroy()
  },

  methods: {
    goToClassroom() {
      if (this.summaryDirty) {
        this.$buefy.dialog.confirm({
          cancelText: 'Cancelar',
          confirmText: 'Confirmar',
          message: 'O sumário não foi guardado. Tem a certeza que quer mudar de página?',
          onConfirm: () => {
            this.$store.commit('setDirtyData', false)
            this.$router.push({ name: 'live', params: { key: this.edition.meeting.key_alias } })
          }
        })
      } else {
        this.$router.push({ name: 'live', params: { key: this.edition.meeting.key_alias } })
      }
    },
    changeView(view) {
      if (this.summaryDirty) {
        this.$buefy.dialog.confirm({
          cancelText: 'Cancelar',
          confirmText: 'Confirmar',
          message: 'O sumário não foi guardado. Tem a certeza que quer mudar de página?',
          onConfirm: () => {
            this.view = view
            this.$store.commit('setDirtyData', false)
          }
        })
      } else {
        this.view = view
      }
    },
    editorContentChanged() {
      this.$store.commit('setDirtyData', true)
    },
    async toggleSelfStudentPresence(type) {
      try {
        if (type === this.session.presence_type) {
          await ServiceAcademic.deleteSelfStudentPresence(this.edition.key, this.session.key)
          this.session.presence_type = null
        } else {
          await ServiceAcademic.setSelfStudentPresence(this.edition.key, this.session.key, type)
          this.session.presence_type = type
        }
      } catch (error) {
        const firstError = utils.errors(error).get()
        if (firstError && firstError.key == 'TeacherAlreadyDefinedPresence') {
          console.info(
            `Presence already blocked with ${firstError.presence_type} on ${this.edition.key}:${this.session.key}`
          )
          this.session.presence_type = firstError.presence_type
          this.session.presence_locked = true
        } else {
          console.error(`Fail to set presence ${type} on ${this.edition.key}:${this.session.key}`, error)
        }
      }
    },

    changeSummary(language) {
      if (this.summaryActiveLanguage !== language) {
        if (this.summaryDirty) {
          this.$buefy.dialog.confirm({
            cancelText: 'Cancelar',
            confirmText: 'Confirmar',
            message: 'O sumário não foi guardado. Tem a certeza que quer mudar o idioma do sumário?',
            onConfirm: () => {
              this.summaryActiveLanguage = language
              if (this.summaryEditor) this.summaryEditor.setContent(this.session.summaries[language] || '')
              this.$store.commit('setDirtyData', false)
            }
          })
        } else {
          this.summaryActiveLanguage = language
          if (this.summaryEditor) this.summaryEditor.setContent(this.session.summaries[language] || '')
        }
      }
      if (this.summaryEditor) this.summaryEditor.focus()
    },
    async submitSummary() {
      if (!this.isTeacher) return
      const summary = await ServiceAcademic.setClassSessionSummaries(
        this.edition.key,
        this.session.key,
        this.session.summaries
      )
      this.$store.commit('setDirtyData', false)
      if (summary) {
        this.$buefy.snackbar.open({
          message: `O sumário foi guardado.`,
          type: 'is-primary',
          position: 'is-top-right',
          duration: 5000
        })
      }
    },

    async setSessionAsDone() {
      this.$buefy.dialog.confirm({
        type: 'is-primary',
        message: `<p class="is-size-4 has-margin-top">Tem a certeza que deseja marcar a aula como dada?</p>`,
        indefinite: true,
        queue: false,
        cancelText: 'Cancelar',
        confirmText: 'Marcar',
        canCancel: true,
        onConfirm: async () => {
          const updated = await ServiceAcademic.setSessionAsDone(this.edition.key, this.session.key)
          if (!updated) {
            this.session.permissions.set_as_done = false
          } else {
            this.session.done_date = Dates.now()
          }
        }
      })
    },
    async setPresence(presence, type) {
      const updated = await ServiceAcademic.setPresence(this.edition.key, this.session.key, presence.student.key, type)
      if (!updated) {
        this.session.permissions.update_presences = false
      } else {
        presence.type = type
        presence.locked = true

        if (this.edition.students) {
          // Sync students list
          for (let student of this.edition.students) {
            if (student.key === presence.student.key) {
              student.presences += 1
              if (student.absences > 0) student.absences -= 1
              break
            }
          }
        }
      }
    },
    async removePresence(presence) {
      const updated = await ServiceAcademic.removePresence(this.edition.key, this.session.key, presence.student.key)
      if (!updated) {
        this.session.permissions.update_presences = false
      } else {
        presence.type = null
        presence.locked = true

        if (this.edition.students) {
          // Sync students list
          for (let student of this.edition.students) {
            if (student.key === presence.student.key) {
              if (student.presences > 0) student.presences -= 1
              student.absences += 1
              break
            }
          }
        }
      }
    },
    async lockPresences(keys) {
      const updated = await ServiceAcademic.lockPresences(this.edition.key, this.session.key, keys)
      if (!updated) {
        this.session.permissions.update_presences = false
      } else {
        for (let presence of this.presencesStudentsFilteredByStatus) {
          if (keys.includes(presence.student.key)) {
            presence.locked = true
          }
        }
      }
    },
    toggleViewMode(mode) {
      this.viewDataMode = mode
      localStorage.setItem('settings.viewdata.mode', mode)
    },

    async updateTeacherClass(data) {
      data.session_key_context = this.session.key
      const response = await ServiceAcademic.updateTeacherClass(this.edition.key, data)
      Object.assign(this.session, response.session)
      delete response.session
      Object.assign(this.edition, response)
    },
    async updateAllowMeetings(key) {
      const data = {}
      data[`allow_meetings_${key}`] = this.edition.allow_meetings[key]
      await this.updateTeacherClass(data)

      if (key == 'percentage') {
        this.previousAllowMeetingsPercentage = this.edition.allow_meetings.percentage
      }
    },
    async addUser() {
      try {
        const data = {
          allow_meetings_student_key: this.formAddStudentKey,
          allow_meetings_start_date: Dates.toDateString(this.formAddStartDate),
          allow_meetings_end_date: Dates.toDateString(this.formAddEndDate),
          allow_meetings_description: this.formAddDescription
        }
        await this.updateTeacherClass(data)

        this.formAddErrorMessage = ''
        this.showAddForm = false
        this.formAddStudentKey = ''
        this.formAddStartDate = null
        this.formAddEndDate = null
        this.formAddDescription = null
      } catch (error) {
        const errors = utils.errors(error)
        const errorKey = errors.getKey()
        if (errorKey == 'ItemNotFound') {
          this.formAddErrorMessage = 'O estudante não existe'
        } else {
          this.formAddErrorMessage = errors.getTranslated()
          if (!this.formAddErrorMessage) {
            this.formAddErrorMessage = `Não foi possível adicionar o estudante. Por favor, tente mais tarde.
              Caso o problema persista, contacte-nos através do nosso canal de suporte.`
          }
        }
      }
    },
    async deleteUser(userKey, dateKey) {
      const data = {
        allow_meetings_delete_user_key: userKey,
        allow_meetings_delete_date_key: dateKey
      }
      await this.updateTeacherClass(data)
    }
  }
}
</script>
