import Api from '@/fw-modules/fw-core-vue/api/Api'
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'

export default {
  base(config) {
    if (!config) config = {}
    config.baseURL = FwEnvConfig.apiUrlIduc
    return Api(config)
  },

  async getCountries() {
    const response = await this.base().get('/v1/countries')
    return response.data
  },

  async getPhoneCountries() {
    const response = await this.base().get('/v1/phone-countries')
    return response.data
  }
}
