<template>
  <div class="flex items-center">
    <b-tooltip :label="label" position="is-top" type="is-light">
      <fw-button
        :type="!meeting.running ? 'light-primary' : 'primary'"
        :custom-class="
          expanded
            ? 'flex gap-3 items-center is-family-code lowercase justify-between flex-1'
            : 'flex gap-3 items-center is-family-code lowercase justify-between'
        "
        :label="label"
        @click.native="$router.push({ name: 'live', params: { key: meeting.key_alias } })"
      >
        <fw-icon-live class="w-6 h-6" :class="{ 'animate-pulse': meeting.running }" />
        <v-clamp v-if="!short" autoresize :max-lines="1" tag="span" class="text-lg tex-left">
          {{ meeting.key_alias }}
        </v-clamp>
        <fw-icon-arrow-up class="w-6 h-6" />
      </fw-button>
    </b-tooltip>
    <b-tooltip v-if="!short" label="Copiar ID da sala" type="is-light">
      <fw-button :type="copied ? 'light-primary' : 'link'" size="xs" class="ml-2" @click.native="toClipboardMeetingId">
        <fw-icon-check v-if="copied"></fw-icon-check>
        <fw-icon-copy v-else></fw-icon-copy>
      </fw-button>
    </b-tooltip>
  </div>
</template>

<script>
const timoutTime = 3000
export default {
  name: 'MeetingButton',
  props: {
    meeting: {
      type: Object,
      required: true
    },
    expanded: {
      type: Boolean,
      default: false
    },
    short: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Entrar na sala virtual'
    }
  },
  data: function() {
    return {
      copied: false,
      timer: null
    }
  },
  methods: {
    toClipboardMeetingId() {
      this.$copyText(`${this.meeting.key_alias}`).then(() => {
        this.copied = true

        if (this.timer !== null) {
          clearTimeout(this.timer)
        } else {
          this.$buefy.toast.open({
            duration: timoutTime,
            message: `ID copiado para a área de transferência.`,
            position: 'is-top'
          })
        }

        this.timer = setTimeout(() => {
          this.copied = false
          this.timer = null
        }, timoutTime)
      })
    }
  }
}
</script>

<style scoped></style>
