<template>
  <div class="max-w-3xl mx-auto">
    <slot name="header">
      <div v-if="showNavigation" class="py-3 font-semibold text-gray-400 text-xl flex items-center gap-4">
        <fw-button type="light" size="sm" @click.native="goBack()">
          <fw-icon-arrow-left class="w-4 h-4" />
        </fw-button>
        Publicação
      </div>
    </slot>
    <div class="bg-white rounded-xl">
      <div v-if="post == null && !loading" class="text-gray-300 text-center py-14 rounded-b-xl">
        Publicação não encontrada
      </div>
      <div v-else-if="loading" class="text-gray-300 text-center py-14 rounded-b-xl flex justify-center">
        <fw-icon-loading class="w-8 h-8" />
      </div>
      <div v-else>
        <BlockPost
          :post="post"
          :users="users"
          :comments="comments"
          :show-comment-action="true"
          :hide-comment-button="true"
          class="border-b border-gray-200"
          @new-comment="newComment(true)"
          @updated="$emit('saved', $event)"
          @edit="editPost($event)"
          @deleted="deletePost($event)"
        />
        <BlockEditPost
          v-if="editingType === 'comment'"
          class="border-b border-gray-200"
          :collection-key="collectionKey"
          :parent-post-key="parentPostKey"
          :post="editing"
          :type="editingType"
          @saved="postSaved($event)"
          @comment-saved="commentSaved($event)"
          @close="close"
        />
        <BlockPost
          v-for="(comment, c) in comments"
          :key="comment.key"
          :post="comment"
          :users="users"
          :show-comment-action="false"
          type="comment"
          :class="{ 'border-b border-gray-200': c != comments.length - 1 }"
          @new-comment="newComment(true)"
          @edit="editPost($event)"
          @deleted="deletePost($event)"
        />
        <BlockPagination
          v-if="pagination.totalPages > 1"
          :per-page="pagination.limit"
          :total="pagination.totalResults"
          :total-pages="pagination.totalPages"
          :current.sync="pagination.page"
          @page-changed="pageChanged($event)"
        />
      </div>
    </div>

    <fw-modal
      v-if="showModalEditPost"
      :active.sync="showModalEditPost"
      :can-cancel="true"
      paddingless
      height-fit-screen
      size="min"
      width="42rem"
      @close="close"
    >
      <template #default>
        <BlockEditPost
          :collection-key="collectionKey"
          :parent-post-key="parentPostKey"
          :post="editing"
          :type="editingType"
          @saved="postSaved($event)"
          @comment-saved="commentSaved($event)"
          @close="close"
        />
      </template>
    </fw-modal>
  </div>
</template>

<script>
import BlockPost from '@/fw-modules/fw-core-vue/posts/components/blocks/BlockPost'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import ServicePosts from '@/fw-modules/fw-core-vue/posts/services/ServicePosts'
import BlockEditPost from '@/fw-modules/fw-core-vue/posts/components/blocks/BlockEditPost'

export default {
  name: 'PanelPosts',
  components: {
    BlockPost,
    BlockPagination,
    BlockEditPost
  },
  props: {
    id: {
      type: String,
      required: true
    },
    collectionKey: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'post'
    },
    showNavigation: {
      type: Boolean,
      default: false
    },
    emitNavigation: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      post: null,
      comments: [],
      users: {},
      pagination: {
        page: 1,
        totalResults: 0,
        totalPages: 1,
        limit: 25
      },
      loading: true,
      showModalEditPost: false,
      editing: null,
      editingType: null,
      loadingComments: false
    }
  },
  computed: {
    allowComments() {
      return this.post && this.post.validations.can_comment
    },

    parentPostKey() {
      return this.post.key
    },

    me() {
      return this.$store.getters.getUser
    },

    wsSubscription() {
      if (!this.collectionKey) return null
      return this.$store.state.socket.subscriptions?.[`content-${this.collectionKey}`]
    }
  },

  beforeDestroy() {
    this.unsubscribeWs()
  },

  mounted() {
    this.loadPost()
    this.loadComments()
    this.subscribeWs()
  },

  methods: {
    handleWsMessages(messages) {
      // Can be: newPost, updatedPost, deletedPost, newReaction, deletedReaction
      if (messages.newReaction?.length) {
        const { post_key, comment_key, post_type, data } = messages.newReaction[0]
        if (post_type == 'comment') {
          // reaction was in a comment
          const index = this.comments.findIndex(p => p.key == comment_key)
          console.log('found comment for new reaction :>> ', this.comments[index], index)
          if (index > -1) {
            this.comments[index].reactions[data.emoji].total += 1
          }
        } else if (this.post.key == post_key) {
          console.log('new post reaction :>> ', this.post)
          this.post.reactions[data.emoji].total += 1
        }
      }

      if (messages.deletedReaction?.length) {
        const { post_key, comment_key, post_type, data } = messages.deletedReaction[0]
        if (post_type == 'comment') {
          // reaction was in a comment
          const index = this.comments.findIndex(p => p.key == comment_key)
          if (index > -1) {
            console.log('found comment for delete reaction :>> ', this.comments[index], index)
            this.comments[index].reactions[data.emoji].total -= 1
          }
        } else if (this.post.key == post_key) {
          console.log('delete post reaction :>> ', this.post)
          this.post.reactions[data.emoji].total -= 1
        }
      }

      if (messages.newPost?.length) {
        const { comment_key, post_type } = messages.newPost[0]
        if (post_type != 'comment') return
        this.getComment(comment_key, 'new')
      }

      if (messages.updatedPost?.length) {
        const { post_key, comment_key, post_type } = messages.updatedPost[0]
        if (post_type == 'comment') {
          // reaction was in a comment
          const index = this.comments.findIndex(p => p.key == comment_key)
          if (index > -1) {
            this.getComment(comment_key, 'update')
          }
        } else if (this.post.key == post_key) {
          this.getComment(post_key, 'update')
        }
      }

      if (messages.deletedPost?.length) {
        const { post_key, comment_key, post_type } = messages.deletedPost[0]
        if (post_type == 'comment') {
          // reaction was in a comment
          const index = this.comments.findIndex(p => p.key == comment_key)
          if (index > -1) {
            console.log('deleted comment :>> ', this.comments[index], index)
            this.getComment(comment_key, 'update')
          }
        } else if (this.post.key == post_key) {
          console.log('deletedPost')
          this.deletePost(this.post)
        }
      }
    },

    subscribeWs() {
      if (!this.wsSubscription) {
        ServicePosts.createCollectionSubscription(this.collectionKey, this.handleWsMessages)
        window.addEventListener('beforeunload', this.unsubscribeWs, {
          capture: true
        })
      }
    },

    unsubscribeWs() {
      if (this.wsSubscription) {
        this.wsSubscription.destroy()
      }
    },

    async pageChanged(page) {
      console.log('pageChangedPanelPost', page)
      this.pagination.page = page
      await this.loadComments()
    },

    goBack() {
      if (this.emitNavigation) this.$emit('go-back')
      else this.$router.go(-1)
    },

    newPost() {
      console.log('newPostPanelPost')
      this.editing = null
      this.showModalEditPost = true
      this.editingType = 'post'
    },

    editPost(post) {
      console.log('editPostPanelPost', post)
      this.editing = post
      this.showModalEditPost = true
      this.editingType = post.type
    },

    newComment(inline = true) {
      console.log('newComment PanelPost')
      this.editing = null
      if (!inline) {
        this.showModalEditPost = true
      }
      this.editingType = 'comment'
    },

    editComment(post) {
      console.log('editComment PanelPost', post)
      this.editing = post
      this.showModalEditPost = true
      this.editingType = 'comment'
    },

    close() {
      this.showModalEditPost = false
      this.editing = null
      this.editingType = null
    },

    deletePost(post) {
      if (this.post && this.post.key == post.key) {
        this.$emit('reset-route')
      } else {
        const index = this.comments.findIndex(p => p.key == post.key)
        if (index > -1) {
          this.$set(this.comments, index, post)
        }
      }
      this.$emit('deleted', post)
    },

    async getComment(key, action) {
      if (!key) return

      try {
        const data = await ServicePosts.getPost(key)
        console.log('getComment PanelPost :>> ', data, this.comments)
        if (action === 'new') {
          this.comments = [data.post].concat(this.comments)
          console.log('this.comments :>> ', this.comments)
          this.post.comments.total += 1
          this.users = { ...this.users, ...data.users }
        } else if (action === 'update') {
          if (this.post.key == data.post.key) {
            this.post = data.post
          } else {
            //is a comment
            this.comments = this.comments.map(p => {
              if (p.key == data.key) {
                return data.post
              }
              return p
            })
          }
          this.users = { ...this.users, ...data.users }
        }
        return data
      } catch (error) {
        return null
      }
    },

    async loadPost() {
      if (!this.id) return
      this.loading = true
      try {
        let data = await ServicePosts.getPost(this.id)
        console.log('loadPost PanelPost :>> ', data)
        let users = data.users
        //add me info to users
        if (this.me) {
          users[this.me.key] = this.me
        }
        this.users = { ...this.users, ...users }
        this.post = data.post
      } finally {
        this.loading = false
      }
    },

    async loadComments() {
      if (!this.id) return
      this.loadingComments = true
      try {
        let data = await ServicePosts.getPostComments(this.id, this.pagination.page, this.pagination.limit)
        console.log('loadComments PanelPost :>> ', data)
        this.pagination = data.pagination
        this.comments = data.comments
        let users = { ...this.users, ...data.users }
        if (this.me) {
          users[this.me.key] = this.me
        }
        this.users = { ...this.users, ...users }
      } finally {
        this.loadingComments = false
      }
    },

    postSaved(post) {
      console.log('savePost PanelPost', post)
      if (this.editing != null) {
        if (this.post.key == post.key) {
          this.post = post
        } else {
          //is a comment
          this.comments = this.comments.map(p => {
            if (p.key == post.key) {
              return post
            }
            return p
          })
        }
      }
      this.$emit('saved', post)
      this.close()
    },

    commentSaved(post) {
      console.log('comment saved PanelPost', post)
      if (this.post.key == post.key) {
        this.post = post
      } else {
        this.comments.find(p => p.key == post.key)
          ? (this.comments = this.comments.map(p => (p.key == post.key ? post : p)))
          : this.comments.unshift(post)
        this.post.comments.total += 1
      }
      this.close()
    }
  }
}
</script>
