<template>
  <div class="py-2 border-b border-gray-200">
    <div class="flex">
      <div
        class="flex-1 text-lg font-bold"
        :class="{
          'text-gray-500': !item.completed,
          'text-black': item.completed,
        }"
      >
        {{ item.name[language] }}
      </div>
      <div v-if="item.completed" class="portfolio-completed">{{ $t('completed') }}</div>
      <div v-else class="portfolio-label">{{ $t('planned') }}</div>
    </div>
    <div v-if="item.competences.length > 0" class="flex flex-wrap gap-3">
      <div v-if="item.completed">{{ $t('competencesdeveloped') }}</div>
      <div v-else>{{ $t('competencestobedeveloped') }}</div>
      <div
        v-for="(competence, c) in item.competences"
        :key="'academicitem' + c"
        class="font-semibold"
        :class="{ 'text-primary': item.completed }"
      >
        {{ competence.name[language] }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AcademicItem',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    language() {
      return this.$store.state.language
    },
  },
}
</script>

<style scoped>
.aboutme-title {
  @apply text-primary font-semibold text-lg;
}
.portfolio-label {
  @apply font-semibold text-gray-500;
}
.portfolio-completed {
  @apply bg-primary text-sm rounded-full text-white px-3 py-0.5 font-semibold;
}
.portfolio-date {
  @apply bg-gray-500 text-sm rounded-full text-white px-3 py-0.5 font-semibold;
}
.aboutme-text {
  @apply text-black text-base;
}
</style>

<i18n>
  {
    "pt": {
      "completed": "Realizado",
      "planned": "Planeado",
      "competencesdeveloped": "Competências desenvolvidas",
      "competencestobedeveloped": "Competências a desenvolver",
      "label": {
        "pt": " em Português",
        "en": " em Inglês",
        "required": "Insira texto em Português e Inglês."
      }
    },
    "en": {
      "completed": "Completed",
      "planned": "Planned",
      "competencesdeveloped": "Competences developed",
      "competencestobedeveloped": "Competences to be developed",
      "save": "Save",
      "label": {
        "pt": " in Portuguese",
        "en": " in English",
        "required": "Insert text in Portuguese and English."
      }
    }
  }
</i18n>
