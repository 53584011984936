<template>
  <div class="relative" style="min-height: 180px">
    <div v-if="!loading" class="absolute -top-1 right-0 px-5">
      <b-dropdown
        ref="academicYearsDropdown"
        scrollable
        max-height="500"
        aria-role="list"
        position="is-bottom-left"
        class="h-full"
      >
        <fw-button
          slot="trigger"
          aria-label="Escolher ano letivo"
          type="light"
          size="xs"
          class="flex gap-1 items-center"
        >
          <fw-icon-map-pin3 class="w-4 h-4" /> {{ activeAcademicYear }} <fw-icon-chevron-down class="w-4 h-4" />
        </fw-button>
        <b-dropdown-item
          v-for="academicYear in academicYears"
          :key="academicYear"
          aria-role="menu-item"
          :focusable="false"
          custom
        >
          <fw-button class="flex gap-1 items-center" @click.native="changeAcademicYear(academicYear)">
            <fw-icon-map-pin3 class="w-5 h-5" /> {{ academicYear }}
          </fw-button>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div v-if="loading" class="px-5">
      <LoadingPlaceholder :number="5"></LoadingPlaceholder>
    </div>
    <div v-else-if="selectedUnit !== null">
      <div v-if="!results.length > 0" class="text-sm text-gray-500 px-5">
        Não existem turmas para apresentar.
      </div>
      <div v-else class="w-full">
        <div class="relative text-sm text-gray-500 pl-9 ml-5 pt-0.5">
          <button
            class="absolute left-0 top-0 text-sm inline-flex flex items-center font-bold transition-colors duration-150 rounded-lg hover:opacity-75 focus:outline-none bg-gray-100"
            @click="selectUnit(null)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              class="fill-current w-6 h-6 text-gray-400 rounded-md p-1"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path
                d="M5.828 7l2.536 2.536L6.95 10.95 2 6l4.95-4.95 1.414 1.414L5.828 5H13a8 8 0 1 1 0 16H4v-2h9a6 6 0 1 0 0-12H5.828z"
              ></path>
            </svg>
          </button>
          Turmas
        </div>
        <div
          :style="{
            maxHeight: maxHeight != null ? maxHeight + 'px' : 'auto',
            overflowY: maxHeight != null ? 'auto' : 'visible'
          }"
          class="px-5 mt-2 pb-10"
        >
          <label
            v-for="resultClass in results"
            :key="resultClass.key"
            class="fw-row-checkbox relative select-none py-5"
          >
            <input
              v-if="multipleSelection"
              type="checkbox"
              class="opacity-0 absolute"
              @change="emitSelected(resultClass)"
            />
            <!-- <label :for="'class_' + resultClass.key">
            :value="copyToSelectedRecipients.includes(resultClass.key)"
          />
          -->
            <div
              class="fw-row-checkbox-inside flex w-full gap-1 items-center relative fw-card-file justify-between p-2 my-1 group hover:bg-opacity-40 hover:shadow-xl rounded-lg bg-opacity-10 shadow-sm"
              :class="{ 'cursor-pointer': !multipleSelection }"
              @click="clickSelected(resultClass)"
            >
              <div v-if="multipleSelection" class="check h-4 w-4 rounded p-1"></div>
              <div :class="{ 'pl-6': multipleSelection }">
                <div
                  class="group-hover:scale-105 bg-opacity-50 bg-gray-200 overflow-hidden group-hover:bg-opacity-90 group-hover:bg-white rounded-full inline-flex items-center flex-shrink-0 justify-center w-10 h-10"
                >
                  <FwIconPeople class="text-gray-600 w-6 h-6"></FwIconPeople>
                </div>
              </div>
              <div class="flex-1 text-sm md:text-base ml-1">
                <div class="flex flex-col">
                  <div class="flex items-center justify-between">
                    <div class="inline-flex break-all">
                      <span :aria-label="resultClass.title">
                        <v-clamp autoresize :max-lines="1">
                          {{ resultClass.title }}
                        </v-clamp>
                      </span>
                    </div>
                  </div>
                  <div class="text-xs md:text-sm flex opacity-60 gap-1 justify-between leading-5">
                    <div class="flex gap-3 break-all">
                      <div style="overflow: hidden;">
                        <span style="box-shadow: transparent 0px 0px;">
                          <span :aria-label="resultClass.profile">
                            <v-clamp autoresize :max-lines="1">
                              <span v-if="resultClass.regime == 'semiannual'"
                                >{{ resultClass.regime_occurrence }} Semestre</span
                              ><span v-else>Anual</span> {{ resultClass.profile }}
                            </v-clamp>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- </label> -->
          </label>
        </div>
      </div>
    </div>
    <div v-else-if="!results.length > 0" class="text-sm text-gray-500">
      Não existem disciplinas para apresentar.
    </div>
    <div v-else class="w-full">
      <div class="text-sm text-gray-500 pl-5">Disciplinas</div>
      <div
        :style="{
          maxHeight: maxHeight != null ? maxHeight + 'px' : 'auto',
          overflowY: maxHeight != null ? 'auto' : 'visible'
        }"
        class="px-5 mt-2"
      >
        <div
          v-for="resultClass in results"
          :key="resultClass.id"
          class="w-full relative p-2 pr-7 my-1 group hover:bg-opacity-40 hover:shadow-xl rounded-lg bg-opacity-10 shadow-sm cursor-pointer"
          @click="selectUnit(resultClass.key)"
        >
          <v-clamp autoresize :max-lines="1">
            {{ resultClass.title }}
          </v-clamp>

          <span class="text-gray-500 text-sm">
            <v-clamp autoresize :max-lines="1">{{ resultClass.profile }}</v-clamp></span
          >
          <div class="absolute absolute right-3" style="top: 50%; margin-top: -10px;">
            <faicon icon="chevron-right" class="text-gray-300"></faicon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceAcademic from '../../../academic/services/ServiceAcademic'
import LoadingPlaceholder from '../../../ui/components/animation/LoadingPlaceholder'

export default {
  name: 'ClassSelectorList',
  components: {
    LoadingPlaceholder
  },
  props: {
    maxHeight: {
      default: null,
      type: Number
    },
    multipleSelection: {
      default: false,
      type: Boolean
    }
  },
  data: function() {
    return {
      selectedUnit: null,
      results: [],
      activeAcademicYear: null,
      academicYears: [],
      loading: true,
      selectedResults: []
    }
  },
  created: function() {
    this.getTeacherUnits()
  },
  methods: {
    selectUnit(unit) {
      this.selectedUnit = unit
      if (unit == null) {
        this.getTeacherUnits()
      } else {
        this.getTeacherClasses()
      }
    },
    changeAcademicYear(year) {
      this.activeAcademicYear = year
      this.getTeacherUnits()
      this.$refs.academicYearsDropdown.toggle()
    },
    async getTeacherUnits() {
      try {
        this.loading = true
        let result = await ServiceAcademic.getTeacherUnits(this.activeAcademicYear)
        this.activeAcademicYear = result.academic_year
        this.academicYears = result.academic_years
        this.results = result.units
        this.loading = false
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    },
    async getTeacherClasses() {
      try {
        this.loading = true
        let result = await ServiceAcademic.getTeacherUnitClassEditions(this.selectedUnit)
        console.log(result)
        //this.activeAcademicYear = result.academic_year
        //this.academicYears = result.academic_years
        this.results = result.editions
        this.loading = false
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    },
    clickSelected(selected) {
      if (this.multipleSelection === false) {
        this.$emit('selected', selected)
      }
    },
    emitSelected(selected) {
      if (this.multipleSelection) {
        //TODO: send multiple selections on change
        let index = this.selectedResults.indexOf(selected)
        if (index !== -1) {
          this.selectedResults.splice(index, 1)
        } else {
          this.selectedResults.push(selected)
        }
        this.$emit('selected', this.selectedResults)
      }
    }
  }
}
</script>

<style scoped></style>
