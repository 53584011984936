import { Node } from '@tiptap/core'
import { mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import DraggableBlock from '../editor/DraggableBlock.vue'
const customFiles = Node.create({
  name: 'files',
  draggable: true,
  addNodeView() {
    return VueNodeViewRenderer(DraggableBlock)
  },
  addAttributes() {
    return {
      files: {
        default: []
      }
    }
  },
  group: 'block',
  parseHTML() {
    return []
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  }
})

/*const customImage = Image.extend({
  name: 'files',
  draggable: true,
  addNodeView() {
    return VueNodeViewRenderer(DraggableBlock)
  },
  addAttributes() {
    return {
      src: {
        default: []
      },
      alt: {
        default: null
      },
      title: {
        default: null
      }
    }
  },
  parseHTML() {
    return []
  },

  addCommands() {
    return {
      setFilesSection: options => ({ commands }) => {
        return commands.insertContent({
          type: this.name,
          attrs: options
        })
      }
    }
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  }
})*/
export default customFiles
