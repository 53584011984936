<template>
  <div v-if="userLoggedIn">
    <b-dropdown scrollable max-height="500" aria-role="list" position="is-bottom-left" class="h-full">
      <fw-button-dropdown
        slot="trigger"
        type="transparent"
        label="Ver canais"
        :chevron="false"
        :class="{ 'text-primary': Boolean(unread) }"
      >
        <fw-icon-message class="w-5 h-5" />
        <span
          v-if="unread"
          aria-hidden="true"
          :class="{ 'px-1': unread >= 10, 'px-2': unread < 10 }"
          class="absolute top-1 shadow-lg -right-1 py-0.5 text-xs font-bold text-white inline-block w-auto h-auto transform translate-x-1 -translate-y-1 bg-primary rounded-full dark:border-gray-800"
          >{{ unread }}</span
        >
      </fw-button-dropdown>
      <b-dropdown-item
        aria-role="menu-item"
        :focusable="false"
        custom
        class="px-4 flex justify-between gap-2 items-center"
      >
        <fw-heading size="h3" class="text-gray-500">Canais de conversação</fw-heading>
        <fw-button type="link" size="sm" @click.native="$router.push('/chats')">Ver todos</fw-button>
      </b-dropdown-item>
      <b-dropdown-item :focusable="false" custom class="md:w-96">
        <div v-if="messages.length" class="w-full">
          <div v-for="message in messages" :key="message.key" class="w-full mt-2" aria-role="menuitem">
            <div class="p-1 rounded-xl bg-white hover:shadow-lg group">
              <div class="px-1 flex gap-2">
                <Avatar :user="message.user" size="sm" />
                <div class="flex-1">
                  <div class="flex justify-between">
                    <v-clamp v-if="message.unread > 1" class="font-bold text-sm" autoresize :max-lines="1">
                      {{ message.user.name }} ({{ message.unread }})
                    </v-clamp>
                    <v-clamp v-else class="font-bold text-sm" autoresize :max-lines="1">
                      {{ message.user.name }}
                    </v-clamp>
                    <div class="text-xs text-gray-500">
                      {{ message.created_date | formatDateTime }}
                    </div>
                  </div>
                  <v-clamp
                    class="cursor-pointer"
                    autoresize
                    :max-lines="2"
                    @click.native="
                      $router.push({
                        name: 'chats',
                        hash: '#' + message.channel_key
                      })
                    "
                    v-html="message.message"
                  ></v-clamp>
                </div>
              </div>
              <div class="flex items-center justify-between gap-2 group-hover:opacity-100 opacity-40">
                <fw-button
                  size="xs"
                  type="link"
                  @click.native="
                    $router.push({
                      name: 'chats',
                      hash: '#' + message.channel_key
                    })
                  "
                >
                  Abrir canal
                  <svg
                    class="fill-current w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </fw-button>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="text-base text-gray-400 text-center pt-24 pb-36">
          <svg
            class="opacity-70 fill-current h-14 w-14 mx-auto mb-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 11h-3.416a5.001 5.001 0 0 1-9.168 0H4v5h16v-5zm0-2V5H4v7h5a3 3 0 0 0 6 0h5z"
            />
          </svg>
          <div>Sem mensagens novas</div>
          <fw-button
            class="rounded-full mt-6 bg-gray-100 px-4 bg-opacity-90 h-9 justify-center hover:opacity-80 text-gray-700"
            type="link"
            size="sm"
            @click.native="$router.push('/chats')"
            >Ver mensagens antigas</fw-button
          >
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import Avatar from '@/fw-modules/fw-core-vue/ui/components/users/Avatar'

export default {
  components: {
    Avatar
  },

  computed: {
    userLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    user() {
      return this.$store.getters.getUser
    },
    unread() {
      return this.$store.getters.getUnreadChatMessagesCount
    },
    messages() {
      return this.$store.getters.getUnreadChatMessages || []
    }
  }
}
</script>
