<template>
  <div class="row-bucket">
    <label
      class="relative select-none"
      :class="{
        'fw-row-checkbox-selectable': selectable,
        'fw-row-checkbox': !selectable
      }"
    >
      <fw-dot-active
        v-if="bucket.unread && !selectable"
        class="h-2 w-2 absolute top-2 left-2 w-2 h-2 z-10"
      ></fw-dot-active>
      <!-- :value="copyToSelectedRecipients.includes(resultClass.key)"
      @change="selectCopyToRecipient(resultClass.key)" -->
      <input
        v-if="selectable"
        v-model="currentValue"
        type="checkbox"
        class="opacity-0 absolute"
        @change="selectBucket"
      />
      <!-- <label :for="'class_' + resultClass.key"> -->
      <div
        class="fw-row-checkbox-inside flex w-full gap-1 items-center relative fw-card-file justify-between p-2 my-1 group hover:bg-opacity-40 hover:shadow-xl rounded-lg bg-opacity-10 shadow-sm"
        :class="{
          'border border-gray-100 md:border-none pr-4': !selectable && view === 'row'
        }"
        @click="processClick()"
      >
        <div v-if="selectable" class="check h-4 w-4 rounded p-1"></div>
        <div class="pl-6" :class="{ 'pl-1.5': !selectable }">
          <div
            class="bg-opacity-50 rounded-full inline-flex items-center flex-shrink-0 justify-center"
            :class="{
              'repo-icon w-10 h-10 group-hover:scale-105 group-hover:bg-opacity-90 group-hover:bg-white overflow-hidden':
                view !== 'row',
              'w-7 h-7 relative leading-5 pl-0.5': view === 'row'
            }"
          >
            <FwIconBoxOpen
              class="text-primary"
              :class="{
                'w-6 h-6': view !== 'row',
                'w-5 h-5 -mb-1': view === 'row'
              }"
            ></FwIconBoxOpen>
          </div>
        </div>
        <div class="flex-1 text-sm md:text-base ml-1">
          <div class="flex flex-col">
            <div class="flex items-center gap-2">
              <v-clamp :aria-label="bucket.title" autoresize :max-lines="1">
                {{ bucket.type == 'unit_edition' || bucket.type == 'unit' ? bucket.context.unit_title : bucket.title }}
              </v-clamp>
              <div
                v-if="bucket.type === 'unit'"
                class="ml-1.5 py-0.5 font-semibold text-xs h-5 mt-0.5 flex items-center"
                :class="{
                  'text-gray-700 bg-gray-100 rounded px-1.5 gap-1': view !== 'row' && view !== 'row_expanded',
                  'text-white bg-primary opacity-80 rounded-md rounded-l-xl px-2 gap-1.5 leading-5':
                    view === 'row' || view === 'row_expanded'
                }"
              >
                <svg
                  v-if="view === 'row' || view === 'row_expanded'"
                  title="Repositório da disciplina"
                  class="fill-current w-3 h-3"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M2 19h20v2H2v-2zM2 5l5 3 5-6 5 6 5-3v12H2V5z" />
                </svg>
                GERAL
              </div>
              <div
                v-else-if="bucket.context && typeof bucket.context.profile_initial !== 'undefined'"
                class="ml-1.5 py-0.5 font-semibold text-xs h-5 mt-0.5 flex items-center"
                :class="{
                  'text-gray-700 bg-gray-100 rounded px-1.5 gap-1': view !== 'row' && view !== 'row_expanded',
                  'text-gray-700 bg-gray-100 rounded-md rounded-l-xl px-2 gap-1.5 leading-5':
                    view === 'row' || view === 'row_expanded'
                }"
              >
                <svg
                  v-if="view === 'row' || view === 'row_expanded'"
                  title="Repositório da disciplina"
                  class="fill-current w-3 h-3 text-primary text-opacity-80"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M2 19h20v2H2v-2zM2 5l5 3 5-6 5 6 5-3v12H2V5z" />
                </svg>
                {{ bucket.context.profile_initial }}
              </div>
              <svg
                v-if="
                  bucket.shared &&
                    bucket.type !== 'class_edition' &&
                    bucket.type !== 'groups' &&
                    bucket.type !== 'unit' &&
                    bucket.type !== 'unit_edition' &&
                    (view === 'row' || view === 'row_expanded')
                "
                title="Repositório partilhado"
                class="shared-bucket text-gray-400 fill-current w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M2 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H2zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm8.284 3.703A8.002 8.002 0 0 1 23 22h-2a6.001 6.001 0 0 0-3.537-5.473l.82-1.824zm-.688-11.29A5.5 5.5 0 0 1 21 8.5a5.499 5.499 0 0 1-5 5.478v-2.013a3.5 3.5 0 0 0 1.041-6.609l.555-1.943z"
                />
              </svg>
              <svg
                v-if="!bucket.shared && (view === 'row' || view === 'row_expanded')"
                title="Repositório privado"
                class="shared-bucket text-gray-400 fill-current w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M12.414 5H21a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7.414l2 2zM4 5v14h16V7h-8.414l-2-2H4zm11 8h1v4H8v-4h1v-1a3 3 0 0 1 6 0v1zm-2 0v-1a1 1 0 0 0-2 0v1h2z"
                />
              </svg>
              <div
                v-if="bucket.stats !== null && view === 'row'"
                class="flex-1 flex justify-end items-center text-xs gap-2 text-gray-500 opacity-90"
              >
                <div class="flex items-center gap-0.5 leading-6">
                  <fw-icon-file class="w-4 h-4"></fw-icon-file>
                  {{ bucket.stats.files }}
                </div>
              </div>
            </div>
            <div v-if="view !== 'row'" class="text-xs md:text-sm flex gap-1 justify-between leading-5">
              <div class="flex gap-3 break-all flex-1">
                <div class="flex gap-3 w-full overflow-hidden items-center">
                  <span v-if="showKey" class="id-label rounded-full px-2 opacity-80" :aria-label="bucket.key"
                    >{{ bucket.key }}
                  </span>
                  <span
                    v-if="false && bucket.context && bucket.context.regime && bucket.context.regime === 'semiannual'"
                    :aria-label="bucket.context.regime_occurrence + ' Semestre'"
                    class="whitespace-nowrap"
                  >
                    {{ bucket.context.regime_occurrence }}º&nbsp;Semestre
                  </span>
                  <span v-if="false && bucket.context" :aria-label="bucket.context.academic_year">{{
                    bucket.context.academic_year
                  }}</span>

                  <span v-if="bucket.context && false" :aria-label="bucket.context">{{
                    bucket.context.unit_title
                  }}</span>
                  <div
                    v-if="bucket.stats !== null"
                    class="flex flex-1 mr-1 justify-end items-center gap-2 text-gray-500"
                  >
                    <fw-icon-file class="w-4 h-4"></fw-icon-file>
                    {{ bucket.stats.files }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- {{ getFilterBuckets('class_edition', resultClass.key) }} -->
      <!-- </label> -->
    </label>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'RowBucket',
  props: {
    bucket: Object,
    selectable: {
      type: Boolean,
      default: false
    },
    showKey: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    view: {
      type: String,
      default: 'row_expanded'
    }
  },
  /*model: {
    prop: 'checked',
    event: 'change',
  },*/
  data: function() {
    return {
      currentValue: false
    }
  },
  created() {
    this.currentValue = this.checked
  },
  methods: {
    selectBucket: function() {
      if (this.selectable) {
        Vue.set(this.bucket, 'selected', this.currentValue)
        this.$emit('selected', this.currentValue)
      }
    },
    processClick: function() {
      if (!this.selectable) {
        this.$router.push({ name: 'bucket', params: { key: this.bucket.key } })
      }
    }
  }
}
</script>
<style>
.fw-row-checkbox-selectable input[type='checkbox']:checked + .fw-row-checkbox-inside {
  background-color: rgba(45, 212, 191, 0.1);
}

.fw-row-checkbox-selectable:hover input[type='checkbox']:checked + .fw-row-checkbox-inside {
  background-color: rgba(45, 212, 191, 0.5);
}
.fw-row-checkbox-selectable input[type='checkbox'] + .fw-row-checkbox-inside {
  background-color: transparent;
}
.fw-row-checkbox-selectable input[type='checkbox'] + .fw-row-checkbox-inside .check {
  position: absolute;
  background: white;
  border: solid 1px #6accb3;
}
.fw-row-checkbox-selectable input[type='checkbox']:checked + .fw-row-checkbox-inside .check {
  background: #30a898
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:%23fff' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat center center;
  border-color: #30a898;
  background-size: 80%;
}
.fw-row-checkbox-selectable input[type='checkbox'] + .fw-row-checkbox-inside .repo-icon {
  background-color: rgba(229, 231, 235, 0.5);
}
.fw-row-checkbox-selectable input[type='checkbox']:checked + .fw-row-checkbox-inside .repo-icon {
  background-color: white;
}
.fw-row-checkbox-selectable input[type='checkbox'] + .fw-row-checkbox-inside .id-label {
  background-color: rgb(234, 234, 234);
  color: rgb(59, 58, 58);
}
.fw-row-checkbox-selectable input[type='checkbox']:checked + .fw-row-checkbox-inside .id-label {
  background-color: #30a898;
  color: white;
}

.fw-row-checkbox .fw-row-checkbox-inside {
  background-color: white;
  cursor: pointer;
}
.fw-row-checkbox .fw-row-checkbox-inside .repo-icon {
  background-color: rgba(230, 232, 234, 0.5);
}
.fw-row-checkbox:hover .fw-row-checkbox-inside .repo-icon {
  background-color: #def8ef;
}

.fw-row-checkbox .fw-row-checkbox-inside .id-label {
  background-color: rgb(234, 234, 234);
  color: rgb(59, 58, 58);
}
.fw-row-checkbox .fw-row-checkbox-inside .id-label {
  background-color: #30a898;
  color: white;
}
</style>
