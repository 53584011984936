<template>
  <LayoutDefault>
    <template #main>
      <div class="w-full h-full flex flex-col gap-2 justify-center items-center max-w-prose mx-auto text-center">
        <fw-heading>Esta página não está<br />disponível para este dispositivo</fw-heading>
        <div>Por favor, utilize um computador<br />para aceder a este recurso.</div>
        <div class="text-center mt-10">
          <fw-button type="link-muted" @click.native="$router.push('/')">Voltar à página inicial</fw-button>
        </div>
      </div>
    </template>
  </LayoutDefault>
</template>

<script>
import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'

export default {
  components: {
    LayoutDefault
  }
}
</script>

<i18n>
{
  "en": {
    "403Forbidden": "(403) Forbidden",
    "noPermissions": "You are not allowed to access this resource.",
    "backToPreviousPage": "Back to previous page"
  },
  "pt": {
    "403Forbidden": "(403) Forbidden",
    "noPermissions": "Não tem permissões para aceder ao recurso solicitado.",
    "backToPreviousPage": "Voltar à página anterior"
  }
}
</i18n>
