<template>
  <div class="w-full h-full flex justify-between">
    <!-- Sidebar -->
    <div
      v-if="!isMobile || (isMobile && viewList)"
      class="w-full md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/6 h-full flex flex-col md:border-r border-gray-200"
      :style="!isMobile ? 'max-width: 300px' : ''"
    >
      <div class="px-5 py-4 flex flex-col">
        <div
          v-if="isMobile"
          class="mb-5 shadow bg-gradient-to-r from-teal-300 to-teal-500 w-full rounded-xl overflow-hidden text-teal-800 h-28 p-5 flex flex-col gap-1 text-sm font-medium items-center justify-center"
        >
          <fw-heading size="h2">{{ edition.title }}</fw-heading>
          <div>{{ edition.unit.title }}</div>
          {{ edition.academic_year }}
        </div>
        <fw-heading size="h3">
          Aulas
          <fw-badge inline type="simple">{{ edition.sessions.length }}</fw-badge>
        </fw-heading>
      </div>
      <div class="flex flex-1 flex-col gap-2 overflow-y-auto">
        <RecycleScroller
          v-if="edition.sessions.length"
          v-slot="{ item, index }"
          :items="edition.sessions"
          :item-size="55"
          :buffer="50"
          key-field="key"
          class="px-4"
        >
          <button
            class="flex p-2 gap-2 relative items-center w-full text-left group rounded-lg my-1"
            :class="{
              'text-primary bg-white shadow-md': item.key == activeSessionKey,
              'text-gray-600 bg-gray-200 shadow-sm bg-opacity-30 hover:text-gray-800': item.key != activeSessionKey
            }"
            @click="loadSession(item, false)"
          >
            <fw-icon-class-unit
              class="w-8 h-8 opacity-50"
              :class="{
                'opacity-100': item.key == activeSessionKey
              }"
            />
            <div class="flex-1 flex justify-between">
              <div class="flex flex-col">
                <div class="font-medium">Aula {{ index + 1 }}</div>
                <div class="text-sm">
                  {{ item.start_date | formatDayWithTime }}
                </div>
              </div>
              <div v-if="item.is_running">
                <fw-icon-live class="w-5 h-5 text-primary animate-pulse" />
              </div>
            </div>
          </button>
        </RecycleScroller>
      </div>
    </div>
    <!-- Main -->
    <div v-if="!isMobile || (isMobile && !viewList)" class="flex-1 h-full">
      <div class="flex flex-col h-full relative">
        <PanelUnit
          v-if="!activeLoading && activeSession"
          :edition="edition"
          :session="activeSession"
          :is-teacher="isTeacher"
          @go-to-user-chat="$emit('go-to-user-chat', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller'
import PanelUnit from '@/fw-modules/fw-core-vue/academic/components/panels/PanelUnit'
import ServiceAcademic from '@/fw-modules/fw-core-vue/academic/services/ServiceAcademic'

export default {
  components: {
    RecycleScroller,
    PanelUnit
  },

  props: {
    view: {
      type: String,
      default: 'liveclasses'
    },
    edition: Object,
    viewList: {
      type: Boolean,
      default: true
    },
    isTeacher: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: true,
      activeLoading: true,
      activeSession: null,
      activeSessionKey: null,
      classSessionSubscription: null
    }
  },

  computed: {
    dirtyData() {
      return this.$store.state.dirtyData
    },
    isMobile() {
      return window.innerWidth < 640
    }
  },

  mounted() {
    if (!this.isMobile) this.loadSession()
  },

  methods: {
    async loadSession(session = null, ignoreDirtyData = true) {
      console.log('loadSession', session, ignoreDirtyData)
      if (this.dirtyData && ignoreDirtyData !== true) {
        this.$buefy.dialog.confirm({
          cancelText: 'Cancelar',
          confirmText: 'Confirmar',
          title: 'Dados não guardados',
          message: 'A página tem dados que não foram guardados. Tem a certeza que quer mudar de página?',
          onConfirm: () => {
            this.$store.commit('setDirtyData', false)
            this.loadSession(session, true)
          }
        })
      } else {
        if (this.classSessionSubscription) {
          this.classSessionSubscription.destroy()
          this.classSessionSubscription = null
        }

        this.activeLoading = true
        try {
          const newSession = await ServiceAcademic.loadActiveSession(
            this.isTeacher,
            this.edition,
            this.activeSessionKey,
            session ? session.key : null
          )

          if (newSession) {
            this.activeSession = newSession
            this.activeSessionKey = newSession.key
            this.classSessionSubscription = ServiceAcademic.createSubscription(this.edition.key, this.activeSession)
          }
        } finally {
          this.activeLoading = false
        }

        // Make sure mobile works
        this.$emit('load-session', session)
      }
    }
  }
}
</script>
