<template>
  <div class="opacity-70 hover:opacity-100">
    <fw-button v-if="view === 'slides'" size="sm" type="xlight" expanded @click.native="$emit('view', 'form')">
      <svg class="w-5 h-5 mr-2 opacity-80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
          d="M19 11V5H5V11H19ZM19 13H5V19H19V13ZM4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3Z"
        ></path>
      </svg>
      Horizontal
    </fw-button>
    <fw-button v-else size="sm" type="xlight" expanded @click.native="$emit('view', 'slides')">
      <svg class="w-5 h-5 mr-2 opacity-80 rotate-90" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
          d="M3 4H21C21.5523 4 22 4.44772 22 5V19C22 19.5523 21.5523 20 21 20H3C2.44772 20 2 19.5523 2 19V5C2 4.44772 2.44772 4 3 4ZM4 6V18H20V6H4Z"
        ></path>
      </svg>
      Vertical
    </fw-button>
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: 'ViewMode',
  props: {
    view: {
      type: String,
      default: 'form'
    }
  },
  watch: {
    selectedLanguage() {
      store.dispatch('setLanguage', this.selectedLanguage)
      this.$i18n.locale = this.selectedLanguage
    }
  }
}
</script>
