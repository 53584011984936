<template>
  <fw-panel title="Calendário da turma" featured class="p-5">
    <div v-if="!loading" class="mb-5">{{ edition.sessions.length }} aulas registadas</div>
    <div class="max-w-screen-lg mx-auto">
      <vc-calendar
        :columns="$screens({ default: 1, md: 3 })"
        :rows="$screens({ default: 1, md: 4 })"
        :is-expanded="$screens({ default: true, lg: true })"
        :attributes="attributes"
        :from-date="fromDate"
        :to-date="toDate"
        mode="range"
        locale="pt"
      />
    </div>
  </fw-panel>
</template>

<script>
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  props: {
    edition: Object
  },

  data() {
    return {
      attributes: [],
      fromDate: null,
      toDate: null,
      loading: false
    }
  },

  mounted() {
    this.loadSessions()
  },

  methods: {
    async loadSessions() {
      this.loading = true
      let years = this.edition.academic_year.split('/')
      this.fromDate = new Date('September, ' + years[0])
      this.toDate = new Date('August, ' + years[1])

      let attributes = []
      for (let session of this.edition.sessions) {
        let startDate = Dates.build(session.start_date)
        attributes.push({
          highlight: {
            color: 'gray'
          },
          popover: {
            label: 'Aula ' + startDate.format('H:mm')
          },
          dates: [
            {
              start: startDate.toDate(),
              end: Dates.toDate(session.end_date)
            }
          ]
        })
      }

      this.attributes = attributes
      setTimeout(() => {
        this.loading = false
      }, 200)
    }
  }
}
</script>
