import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import Api from '@/fw-modules/fw-core-vue/api/Api'
import store from '@/store'

export default {
  base() {
    let api = Api()
    api.defaults.baseURL = FwEnvConfig.apiUrlContent
    return api
  },

  async getCollectionPosts(collectionKey, type = 'post', params) {
    let response = await this.base().get(`/v1/collections/${collectionKey}`, {
      params: {
        ...params,
        type: type
      }
    })
    return response.data
  },

  async getPost(postKey) {
    let response = await this.base().get(`/v1/posts/${postKey}`)
    return response.data
  },

  async updatePost(postKey, data, metadata = {}) {
    if (metadata) {
      data.metadata = metadata
    }
    let response = await this.base().put(`/v1/posts/${postKey}`, data)
    return response.data
  },

  async createPost(data, collectionKey, metadata = {}) {
    if (metadata) {
      data.metadata = metadata
    }
    let response = await this.base().post(`/v1/collections/${collectionKey}/posts`, data)
    return response.data
  },

  async detelePost(postKey) {
    let response = await this.base().delete(`/v1/posts/${postKey}`)
    return response.data
  },

  async reactPost(postKey, emoji) {
    let response = await this.base().post(`/v1/posts/${postKey}/reactions`, {
      emoji: emoji
    })
    return response.data
  },

  async getPostComments(postKey, page = 1, limit = 30) {
    let response = await this.base().get(`/v1/posts/${postKey}/comments`, {
      params: {
        page: page,
        limit: limit
      }
    })
    return response.data
  },

  async createPostComment(postKey, data, metadata = {}) {
    if (metadata) {
      data.metadata = metadata
    }
    let response = await this.base().post(`/v1/posts/${postKey}/comments`, data)
    return response.data
  },

  async getFeed(collections = [], type = 'post', params = {}) {
    let response = await this.base().get('/v1/posts', {
      params: {
        ...params,
        collection_key: collections,
        type: type
      }
    })
    return response.data
  },

  createCollectionSubscription(collectionKey, callbackFunc) {
    if (!collectionKey) return null
    const cls = {
      authUserKey: store.getters.getUser.key,
      collectionKey: collectionKey,
      callbackFunc: callbackFunc,
      key: `content-${collectionKey}`,

      subscribe() {
        store.commit('sendWSMessage', {
          application: 'content',
          code: 'subscribe_collection',
          collection_key: cls.collectionKey
        })
        console.log('collection subscribe', cls.collectionKey)
        store.commit('setWsSubscription', { key: cls.key, subscription: cls })
      },

      unsubscribe() {
        store.commit('sendWSMessage', {
          application: 'content',
          code: 'unsubscribe_collection',
          collection_key: cls.collectionKey
        })
        console.log('collection unsubscribe', cls.collectionKey)
        store.commit('setWsSubscription', { key: cls.key, subscription: null })
      },

      reconnect() {
        cls.subscribe()
      },

      WSMessages(message) {
        console.log('Received WSMessages', message)
        if (message) cls.callbackFunc(message)
      },

      destroy() {
        cls.unsubscribe()
        store.commit('unsubscribeWS', { code: 'ws-reconnect', name: cls.key })
        store.commit('unsubscribeWS', { code: 'content', name: cls.key })
      }
    }

    store.commit('subscribeWS', { code: 'ws-reconnect', name: cls.key, callback: cls.reconnect })
    store.commit('subscribeWS', { code: 'content', name: cls.key, callback: cls.WSMessages })
    cls.subscribe()
    return cls
  }
}
