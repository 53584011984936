<template>
  <div
    class="w-full group h-full flex-col rounded-xl group bg-gray-200 flex gap-3 p-4 hover:bg-opacity-70"
    :class="[{ 'cursor-pointer': !noLink, shadow: noLink }, customClass]"
  >
    <div v-if="$slots.header" class="flex flex-col items-center justify-center w-full">
      <slot name="header"></slot>
    </div>
    <div v-else-if="label || icon || tag" class="flex gap-2 items-center w-full">
      <div>
        <component :is="'fw-icon-' + icon" v-if="icon" class="w-6 h-6 text-gray-500 flex-shrink-0" />
      </div>
      <div class="flex-1 font-semibold text-gray-500">
        <v-clamp v-if="label" autoresize :max-lines="1">{{ label }}</v-clamp>
      </div>
      <div v-if="tag" class="flex">
        <fw-tag v-if="tag" :type="tagType" size="xs">{{ tag }}</fw-tag>
      </div>
    </div>
    <div v-if="title" class="flex-1">
      <fw-heading :size="titleSize">
        <v-clamp autoresize :max-lines="titleClamp">
          {{ title }}
        </v-clamp>
      </fw-heading>
    </div>
    <div v-if="$slots.main || $slots.default">
      <slot name="main"></slot>
      <slot></slot>
    </div>
    <div v-if="$slots.footer" class="text-xs text-gray-500">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    titleSize: {
      type: String,
      default: 'lg'
    },
    titleClamp: {
      type: Boolean,
      default: null
    },
    icon: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    tag: {
      type: String,
      default: ''
    },
    tagType: {
      type: String,
      default: 'light'
    },
    noLink: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ''
    }
  }
}
</script>
