<template>
  <div
    class="w-full h-full flex flex-col justify-between p-5"
    :class="{
      'bg-white': view === 'introduction'
    }"
  >
    <div v-if="view === 'introduction'" class="max-w-screen-lg mx-auto w-full">
      <div class="flex items-start justify-between gap-5 mb-2 px-2.5">
        <div class="text-2xl font-bold">{{ unit.title }}</div>
        <fw-button v-if="isManager" :type="'xlight'" @click.native="editTopic()"> Editar conteúdo </fw-button>
      </div>
      <fw-panel-info v-if="isManager" type="orange" icon boxed class="mt-3 mx-2.5">
        Enquanto gestor(a) desta edição do espaço consegue visualizar este conteúdo em qualquer altura.
      </fw-panel-info>
      <PanelPagePreview
        v-if="unit.page_key"
        ref="pageUnit"
        :show-title="false"
        :page-id="unit.page_key"
        paddingless
        class="pt-5"
      />
      <div v-else class="flex flex-col gap-5 items-center justify-center flex-1 m-5">
        <fw-icon-sun class="w-16 h-16 text-gray-500 opacity-40"></fw-icon-sun>
        <div class="text-center text-sm font-medium text-gray-500">Sem conteúdos para apresentar neste tema.</div>
      </div>
      <fw-panel
        v-if="instructors.length"
        title="Formadores"
        :boxed="instructorsCount ? 'xs' : null"
        custom-class="bg-white"
        class="my-5 mx-2.5"
        :counter="instructorsCount"
      >
        <fw-person v-for="(roles, userkey) in instructors" :key="userkey" :person="users[userkey]"></fw-person>
      </fw-panel>
    </div>

    <div v-else-if="view === 'posts'" class="max-w-screen-lg mx-auto w-full">
      <PanelPost
        v-if="post?.key"
        :id="post.key"
        :key="post.key"
        class="pt-16"
        :collection-key="post.collection.key"
        @deleted="post = null"
      >
        <template #header>
          <div class="flex justify-between items-center pb-2">
            <fw-heading size="h2">Publicação</fw-heading>
            <fw-button icon="close-circle" @click.native="post = null"></fw-button>
          </div>
        </template>
      </PanelPost>
      <PanelPosts
        v-else
        type="discussions"
        :collections="collections"
        :params="{ status: ['published'] }"
        @open="openPost"
      />
    </div>
  </div>
</template>

<script>
import PanelPagePreview from '@/fw-modules/fw-core-vue/pages/components/panels/PanelPagePreview'
import PanelPosts from '@/fw-modules/fw-core-vue/posts/components/panels/PanelPosts.vue'
import PanelPost from '@/fw-modules/fw-core-vue/posts/components/panels/PanelPost'

export default {
  components: {
    PanelPagePreview,
    PanelPosts,
    PanelPost
  },

  props: {
    course: {
      type: Object,
      default: () => {}
    },
    edition: {
      type: Object,
      default: () => {}
    },
    checks: {
      type: Object,
      default: () => {}
    },
    unit: {
      type: Object,
      default: () => {}
    },
    collections: {
      type: Array,
      default: () => []
    },
    instructors: {
      type: Object,
      default: () => {}
    },
    users: {
      type: Object,
      default: () => {}
    },

    isManager: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      currentUnit: null,
      post: null
    }
  },

  computed: {
    instructorsCount() {
      return Object.keys(this.instructors).length || 0
    },

    courseKey() {
      return this.$route.params.courseKey
    },

    editionKey() {
      return this.$route.params.editionKey
    },

    unitKey() {
      return this.$route.params.unitKey
    },

    view() {
      return this.$route.meta.view
    }
  },

  methods: {
    reload() {
      this.$refs.pageUnit?.reload()
    },
    editTopic() {
      this.$router.push({
        name: 'content-pages-editor',
        params: {
          key: this.unit.page_key
        }
      })
    },
    openPost(post) {
      console.log('openPost', post)
      this.post = post
    }
  }
}
</script>

<style lang="scss">
.content.fixed-content p:not(:last-child) {
  margin-bottom: 1rem;
}
</style>
