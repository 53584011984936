<template>
  <fw-modal :active.sync="isActive" :cancel="closeModal">
    <div class="p-5">
      <fw-panel title="Entrar em exame" class="mb-5">
        <form @submit.prevent="checkKeyAndGo">
          <fw-label>ID / Chave do exame</fw-label>
          <b-input ref="examkey" v-model="examKey" :autofocus="true" placeholder="Indique o ID do exame" />
        </form>
      </fw-panel>
      <div class="flex gap-3 justify-end items-center">
        <fw-button type="link-muted" @click.native="$emit('close')">Cancelar</fw-button>
        <fw-button type="primary" @click.native="checkKeyAndGo">Entrar</fw-button>
      </div>
    </div>
  </fw-modal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isActive: this.show || false,
      examKey: null,
      loading: false
    }
  },

  computed: {
    loggedUser() {
      return this.$store.getters.getUser
    }
  },

  watch: {
    show(value) {
      this.isActive = value

      // Force autofocus
      if (this.isActive) {
        this.$nextTick(() => {
          this.$refs.examkey.focus()
        })
      }
    }
  },

  methods: {
    closeModal() {
      this.isActive = false
      this.entryKeyToJoin = null
      this.$emit('close')
    },
    checkKeyAndGo() {
      console.log("I'm here...")
      return
    }
  }
}
</script>
