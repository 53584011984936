var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat-editor"},[_c('div',{staticClass:"bubble-menu-chat"},[(_vm.editor)?_c('div',{staticClass:"flex gap-1.5 px-2"},[_c('button',{class:{ 'is-active': _vm.editor.isActive('bold') },on:{"click":function($event){_vm.editor
            .chain()
            .focus()
            .toggleBold()
            .run()}}},[_vm._v(" Negrito ")]),_c('button',{class:{ 'is-active': _vm.editor.isActive('italic') },on:{"click":function($event){_vm.editor
            .chain()
            .focus()
            .toggleItalic()
            .run()}}},[_vm._v(" Itálico ")]),_c('button',{class:{ 'is-active': _vm.editor.isActive('strike') },on:{"click":function($event){_vm.editor
            .chain()
            .focus()
            .toggleStrike()
            .run()}}},[_vm._v(" Sublinhado ")])]):_vm._e()]),_c('editor-content',{staticClass:"px-2 py-1",attrs:{"editor":_vm.editor}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }