<template>
  <div v-if="isRunning" class="meetings-controlbar is-box flex gap-3 items-center justify-between p-4 text-white">
    <div v-if="!isMobile" class="flex gap-3 items-center justify-center">
      <div v-if="activeView == 'participants'">
        <b-tooltip label="Participantes" :delay="500" position="is-top" animated type="is-light">
          <fw-button :type="podsSubmenu ? 'light-primary' : 'transparent'" label="Mostrar participantes">
            <fw-icon-list-check class="w-6 h-6" @click.native="$emit('toggle-pods-submenu')" />
            <fw-badge v-if="activePods.length" color="dark">{{ activePods.length }}</fw-badge>
          </fw-button>
        </b-tooltip>
      </div>
      <div v-if="mainChatsLength && activeView == 'participants'">
        <b-tooltip label="Chat" :delay="500" position="is-top" animated type="is-light">
          <fw-button
            :type="chatSubmenu ? 'light-primary' : 'transparent'"
            :aria-label="chatSubmenu ? 'Fechar o chat' : 'Abrir o chat'"
            @click.native="$emit('toggle-chat-submenu')"
          >
            <fw-icon-chats class="w-6 h-6" />
            <fw-badge v-if="chatMainUnread">{{ chatMainUnread }}</fw-badge>
          </fw-button>
        </b-tooltip>
      </div>
      <div v-if="withFullscreen && activePods.length > 1 && activeView == 'participants'">
        <b-tooltip label="Mostrar vídeos dos participantes" :delay="500" position="is-top" animated type="is-light">
          <fw-button
            :type="floatingStreamsVisible ? 'light-primary' : 'transparent'"
            aria-label="Mostrar vídeos dos participantes"
            class="flex items-center gap-1"
            @click.native="$emit('toggle-floating-streams-visibility')"
          >
            <fw-icon-account-box class="w-4 h-4 opacity-75" />
            <fw-icon-account-box class="w-6 h-6 -mx-2" />
            <fw-icon-account-box class="w-4 h-4 opacity-75" />
          </fw-button>
        </b-tooltip>
      </div>
    </div>
    <div class="flex gap-3 items-center justify-center">
      <BlockCamera :with-janus="withJanus" :attendee="attendee" :camera="camera" :camera-options="cameraOptions" />
      <BlockAudio
        :with-janus="withJanus"
        :attendee="attendee"
        :audio="audio"
        :audio-input-options="audioInputOptions"
      />
      <div v-if="!withJanus && !$device.isMobile() && !isMobile">
        <div class="p-1 text-gray-500 font-semibold text-xs">Funcionalidade de vídeo desativada</div>
      </div>
      <div v-if="withJanus && attendee.withRole('sharescreen_allowed') && !$device.isMobile() && !isMobile">
        <b-tooltip label="Partilhar ecrã" :delay="500" position="is-top" animated type="is-light">
          <fw-button
            v-shortkey="['meta', 'shift', 's']"
            type="transparent"
            aria-label="Partilhar ecrã"
            :disabled="screenShare.loading"
            @shortkey="screenShare.toggle()"
            @click.native="screenShare.toggle()"
          >
            <svg
              class="fill-current w-6 h-6"
              :class="{ 'text-primary': screenShare.active }"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M4 16h16V5H4v11zm9 2v2h4v2H7v-2h4v-2H2.992A.998.998 0 0 1 2 16.993V4.007C2 3.451 2.455 3 2.992 3h18.016c.548 0 .992.449.992 1.007v12.986c0 .556-.455 1.007-.992 1.007H13z"
              />
            </svg>
            <span v-if="screenShare.active" class="flex h-3 w-3 absolute top-1 right-1">
              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75"></span>
              <span class="relative inline-flex rounded-full h-3 w-3 bg-primary"></span>
            </span>
          </fw-button>
        </b-tooltip>
      </div>
      <div v-if="withJanus">
        <b-tooltip label="Pedir palavra" :delay="500" position="is-top" animated type="is-light">
          <fw-button
            v-shortkey="['alt', 'y']"
            :type="attendee.hand_up ? 'primary' : 'transparent'"
            :class="{
              'text-primary animate-pulse': attendee.hand_up
            }"
            class="flex items-center gap-1"
            aria-label="Pedir palavra"
            @click.native="$emit('toggle-hand')"
          >
            <svg class="fill-current w-5 h-5" viewBox="-65 0 455 456" xmlns="http://www.w3.org/2000/svg">
              <path
                d="m76.320312 456h98.015626c27.351562 0 52.660156-15.882812 66.046874-41.601562l84.785157-162.835938-4.785157-3.917969c-.089843-.078125-.179687-.15625-.265624-.234375-7.367188-6.929687-17.28125-10.488281-27.371094-9.816406-10.304688.785156-19.820313 5.820312-26.269532 13.898438l-24.457031 29.773437c-1.875 2.28125-4.984375 3.140625-7.765625 2.144531-2.785156-.996094-4.640625-3.628906-4.644531-6.585937l-.140625-204.832031c-.011719-13.960938-10.410156-25.316407-23.183594-25.316407-12.492187 0-22.699218 10.828125-23.171875 24.34375 0 20.6875-.011719 43.207031-.015625 64.640625-.007812 48.476563-.015625 90.316406 0 94.375.007813 1.851563-.726562 3.628906-2.035156 4.9375-1.3125 1.304688-3.089844 2.035156-4.941406 2.027344h-.023438c-1.847656.015625-3.621094-.707031-4.9375-2.003906-1.3125-1.296875-2.054687-3.066406-2.0625-4.910156-.015625-4.085938-.007812-45.902344 0-94.402344 0-20.921875.007813-42.890625.011719-63.171875-.011719-.164063-.019531-.316407-.019531-.480469 0-.398438.007812-.78125.019531-1.179688 0-17.027343 0-32.753906 0-45.628906.007813-13.957031-10.394531-25.222656-23.175781-25.222656h-.710938c-12.78125 0-23.386718 11.175781-23.386718 25.144531v204.972657c0 3.863281-3.136719 7-7 7-3.867188 0-7-3.136719-7-7v-178.472657c0-13.96875-10.183594-25.332031-22.964844-25.332031-12.855469 0-23.035156 11.363281-23.035156 25.332031v178.472657c0 3.863281-3.136719 7-7 7-3.867188 0-7-3.136719-7-7v-105.203126c0-13.972656-10.628907-24.914062-23.414063-24.914062h-.667969c-12.75 0-23.152344 11.074219-23.183594 25-.132812 57.488281-.050781 158.84375.035157 249.570312.039062 44.953126 34.007812 81.429688 75.714843 81.429688zm0 0"
              />
            </svg>
            <span v-if="attendee.hand_up && !isMobile" class="text-sm opacity-60">Pediu a palavra!</span>
          </fw-button>
        </b-tooltip>
      </div>
      <div v-if="isMobile && instance.recording == 'running'">
        <fw-icon-rec class="w-5 h-5 animate-pulse text-red-600" />
      </div>
      <div v-if="canRecord && !$device.isMobile() && !isMobile">
        <b-tooltip
          v-if="instance.recording == 'stopped'"
          label="Iniciar gravação"
          :delay="500"
          position="is-top"
          animated
          type="is-light"
        >
          <fw-button
            type="transparent"
            aria-label="Iniciar gravação"
            @click.native="$emit('update-recordings', 'start')"
          >
            <fw-icon-record-circle class="w-6 h-6" />
          </fw-button>
        </b-tooltip>
        <div v-else style="height: 35px" class="flex items-center shadow-md bg-white rounded-xl">
          <span class="is-flex is-flex-align-center has-text-danger has-margin-left has-margin-right">
            <span class="has-margin-right-small" :class="{ 'animate-flicker': instance.recording == 'running' }">
              <svg
                class="fill-current w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                />
              </svg>
            </span>
            <span
              v-if="instance.recording == 'running'"
              class="tag is-bg-transparent has-text-danger is-small"
              style="width: 60px"
              >A gravar</span
            >
            <span v-else class="tag is-small is-bg-transparent" style="width: 60px">Em pausa</span>
          </span>
          <b-tooltip
            v-if="instance.recording == 'running'"
            label="Pausar gravação"
            :delay="500"
            position="is-top"
            animated
            type="is-light"
          >
            <b-button
              icon-left="pause"
              aria-label="Pausar gravação"
              class="text-gray-300"
              @click="$emit('update-recordings', 'pause')"
            ></b-button>
          </b-tooltip>
          <b-tooltip v-else label="Retomar gravação" :delay="500" position="is-top" animated type="is-light">
            <b-button
              icon-left="play"
              aria-label="Retomar gravação"
              class="text-gray-300"
              @click="$emit('update-recordings', 'start')"
            ></b-button>
          </b-tooltip>
          <b-tooltip label="Parar gravação" :delay="500" position="is-top" animated type="is-light">
            <b-button
              icon-left="square"
              aria-label="Parar gravação"
              class="text-gray-300"
              @click="$emit('update-recordings', 'stop')"
            ></b-button>
          </b-tooltip>
        </div>
      </div>
    </div>
    <div class="flex gap-3 items-center justify-center">
      <div v-if="withJanus">
        <b-dropdown
          :triggers="['click']"
          aria-role="list"
          position="is-top-left"
          :mobile-modal="false"
          animation="none"
        >
          <fw-button slot="trigger" type="transparent">
            <fw-icon-list-settings class="w-6 h-6" />
          </fw-button>
          <b-dropdown-item v-if="attendee.withRole('can_mute_others')" aria-role="listitem" @click="$emit('mute-all')">
            <faicon style="width: 20px" class="has-margin-right-small" icon="microphone-slash"></faicon> Silenciar sala
          </b-dropdown-item>
          <b-dropdown-item v-if="!$device.isMobile()" @click="toggleDeviceFullscreen">
            <faicon
              style="width: 20px"
              class="has-margin-right-small"
              :icon="isFullscreenOn ? 'compress' : 'expand'"
            ></faicon>
            Ecrã inteiro
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" @click="$emit('toggle-quality-modal')">
            <faicon style="width: 20px" class="has-margin-right-small" icon="tachometer-alt"></faicon> Opções de
            desempenho
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" @click="$emit('toggle-media-selection')">
            <faicon style="width: 20px" class="has-margin-right-small" icon="cog"></faicon> Configurar áudio e vídeo
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" @click="$emit('toggle-pods-gallery-config')">
            <faicon style="width: 20px" class="has-margin-right-small" icon="grip-horizontal"></faicon> Configurar
            painel de participantes
          </b-dropdown-item>
          <b-dropdown-item
            v-if="allowMeetingChanges"
            aria-role="listitem"
            class="flex items-center"
            @click="$emit('toggle-bulk-permissions')"
          >
            <svg
              class="fill-current w-5 mr-3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M17.5 2.474L23 12l-5.5 9.526h-11L1 12l5.5-9.526h11zm-1.155 2h-8.69L3.309 12l4.346 7.526h8.69L20.691 12l-4.346-7.526zM8.634 8.17l1.732-1 5 8.66-1.732 1-5-8.66z"
              />
            </svg>
            Configurar sala
          </b-dropdown-item>
          <b-dropdown-item
            v-if="attendee.withRole('camera_allowed')"
            aria-role="listitem"
            class="is-flex"
            @click="$emit('toggle-hd-mode')"
          >
            <span style="width: 22px" class="tag is-light is-small has-margin-right-small">HD</span>
            <span v-if="attendee.with_hd">Desativar modo alta-definição</span>
            <span v-else>Ativar modo alta-definição</span>
          </b-dropdown-item>
          <b-dropdown-item v-if="canRecord && isMobile">
            <button
              v-if="instance.recording == 'stopped'"
              class="flex items-center"
              aria-label="Iniciar gravação"
              @click="$emit('update-recordings', 'start')"
            >
              <span class="w-7"><fw-icon-rec class="w-4 h-4"/></span>
              Iniciar gravação
            </button>
            <div v-else class="flex items-center shadow-md bg-white rounded-xl">
              <span class="is-flex is-flex-align-center has-text-danger has-margin-left has-margin-right">
                <span class="has-margin-right-small" :class="{ 'animate-flicker': instance.recording == 'running' }">
                  <svg
                    class="fill-current w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                    />
                  </svg>
                </span>
                <span
                  v-if="instance.recording == 'running'"
                  class="tag is-bg-transparent has-text-danger is-small"
                  style="width: 60px"
                  >A gravar</span
                >
                <span v-else class="tag is-small is-bg-transparent" style="width: 60px">Em pausa</span>
              </span>
              <b-tooltip
                v-if="instance.recording == 'running'"
                label="Pausar gravação"
                :delay="500"
                position="is-top"
                animated
                type="is-light"
              >
                <b-button
                  icon-left="pause"
                  aria-label="Pausar gravação"
                  class="text-gray-300"
                  @click="$emit('update-recordings', 'pause')"
                ></b-button>
              </b-tooltip>
              <b-tooltip v-else label="Retomar gravação" :delay="500" position="is-top" animated type="is-light">
                <b-button
                  icon-left="play"
                  aria-label="Retomar gravação"
                  class="text-gray-300"
                  @click="$emit('update-recordings', 'start')"
                ></b-button>
              </b-tooltip>
              <b-tooltip label="Parar gravação" :delay="500" position="is-top" animated type="is-light">
                <b-button
                  icon-left="square"
                  aria-label="Parar gravação"
                  class="text-gray-300"
                  @click="$emit('update-recordings', 'stop')"
                ></b-button>
              </b-tooltip>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div v-if="!$device.isMobile() && attendee.withRole('can_end')">
        <b-dropdown aria-role="list" position="is-top-left" :mobile-modal="false">
          <fw-button slot="trigger" type="link-danger" class="bg-red-500 bg-opacity-10 px-5"> Sair </fw-button>
          <b-dropdown-item
            v-if="attendee.withRole('can_end')"
            class="has-text-danger"
            aria-role="listitem"
            @click="$emit('end-meeting')"
            >Terminar para todos
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" @click="$emit('leave-meeting')">Sair da sessão</b-dropdown-item>
        </b-dropdown>
      </div>
      <div v-if="$device.isMobile() || !attendee.withRole('can_end')">
        <fw-button type="link-danger" class="bg-red-500 bg-opacity-10 px-5" @click.native="$emit('leave-meeting')">
          Sair
        </fw-button>
      </div>
    </div>
  </div>
</template>

<script>
import device from '@/fw-modules/fw-core-vue/utilities/device'
import { detect } from 'detect-browser'
import BlockCamera from '@/fw-modules/fw-meetings-vue/components/blocks/BlockCamera'
import BlockAudio from '@/fw-modules/fw-meetings-vue/components/blocks/BlockAudio'

export default {
  components: {
    BlockCamera,
    BlockAudio
  },

  props: {
    isRunning: Boolean,
    meeting: Object,
    instance: Object,
    attendee: Object,
    audio: Object,
    camera: Object,
    screenShare: Object,
    activeView: String,
    activePods: Array,
    showStreams: {
      type: Boolean,
      default: false
    },
    isToolboxCallExpanded: Boolean,
    isToolboxCallForceCollapsed: Boolean,
    floatingStreamsVisible: Boolean,
    withFullscreen: Boolean,
    allowMeetingChanges: Boolean,
    podsLength: Number,
    podsSubmenu: Boolean,
    mainChatsLength: Number,
    withJanus: Boolean,
    canRecord: Boolean,
    chatSubmenu: Boolean,
    chatMainUnread: {
      type: String,
      default: ''
    },
    cameraOptions: Array,
    audioInputOptions: Array
  },

  data() {
    return {
      browser: detect(),
      localView: this.activeView,
      isFullscreenOn: false,
      delayedExpanded: this.isToolboxCallExpanded,
      isMovedToCorner: false
    }
  },

  computed: {
    isMobile() {
      return window.innerWidth < 640
    }
  },

  watch: {
    activeView(value) {
      this.localView = value
    },
    isToolboxCallForceCollapsed(value) {
      if (value && this.isToolboxCallExpanded) {
        this.isMovedToCorner = true
        setTimeout(() => {
          this.delayedExpanded = false
        }, 0)
      } else if (!value && this.isToolboxCallExpanded) {
        this.isMovedToCorner = false
        this.delayedExpanded = true
      }
    },
    isToolboxCallExpanded(value) {
      if (value) {
        this.isMovedToCorner = false
        this.delayedExpanded = true
      } else {
        this.isMovedToCorner = true
        setTimeout(() => {
          this.delayedExpanded = false
        }, 70)
      }
    }
  },

  methods: {
    toggleDeviceFullscreen() {
      if (window.innerWidth == screen.width && window.innerHeight == screen.height) {
        try {
          device.exitFullscreen()
          this.isFullscreenOn = false
        } catch (error) {
          console.error('Failed to remove device from fullscreen', error)
        }
      } else {
        device.enterFullscreen()
        this.isFullscreenOn = true
      }
    },
    toggleFloatingStreamsVisibility() {
      this.$emit('toggle-floating-streams-visibility')
    }
  }
}
</script>
