<template>
  <div>
    <button-quick-access
      :disabled="showEnterMeetingToJoin"
      label="Acesso rápido"
      title="Entrar em exame"
      footer="Nova versão"
      @clicked="toggleModal"
    />
    <ModalEnterExamToJoin :show="showEnterMeetingToJoin" @close="toggleModal" />
  </div>
</template>

<script>
import ButtonQuickAccess from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonQuickAccess'
import ModalEnterExamToJoin from '@/fw-modules/fw-core-vue/exams/components/modals/ModalEnterExamToJoin'

export default {
  components: {
    ButtonQuickAccess,
    ModalEnterExamToJoin
  },
  data() {
    return {
      showEnterMeetingToJoin: false
    }
  },
  methods: {
    toggleModal() {
      this.showEnterMeetingToJoin = !this.showEnterMeetingToJoin
    }
  }
}
</script>
