<template>
  <div class=" flex-shrink-0">
    <div class="flex items-end gap-2 pb-2">
      <div class="flex-1">
        <div class="font-semibold">Como avalia os conteúdos apresentados, de acordo com a escala seguinte?</div>
        <div class="text-gray-500 text-xs">
          1 = Discordo fortemente 2 = Discordo 3 = Não concordo nem discordo 4 = Concordo 5 = Concordo fortemente
        </div>
      </div>
      <div class="w-7 text-gray-500 text-sm pl-1.5">
        1
      </div>
      <div class="w-7 text-gray-500 text-sm pl-1.5">
        2
      </div>
      <div class="w-7 text-gray-500 text-sm pl-1.5">
        3
      </div>
      <div class="w-7 text-gray-500 text-sm pl-1.5">
        4
      </div>
      <div class="w-7 text-gray-500 text-sm pl-1.5">
        5
      </div>
    </div>
    <div
      v-for="(item, i) in feedbackItems"
      :key="'feedbackitem_' + i"
      class="flex items-center ml-2 feedback gap-2 py-3 border-t border-gray-100 hover:bg-gray-100"
    >
      <div class="pr-5 flex-1 pl-1">
        {{ item.text }}
      </div>
      <b-field class="w-7 text-center">
        <b-radio v-model="feedback[item.key]" native-value="1"> </b-radio>
      </b-field>
      <b-field class="w-7 text-center">
        <b-radio v-model="feedback[item.key]" native-value="2"> </b-radio>
      </b-field>
      <b-field class="w-7 text-center">
        <b-radio v-model="feedback[item.key]" native-value="3"> </b-radio>
      </b-field>
      <b-field class="w-7 text-center">
        <b-radio v-model="feedback[item.key]" native-value="4"> </b-radio>
      </b-field>
      <b-field class="w-7 text-center">
        <b-radio v-model="feedback[item.key]" native-value="5"> </b-radio>
      </b-field>
    </div>
    <div v-if="writeComment" class="my-5">
      <div class="text-lg flex gap-3 items-center">
        <fw-label>Adicione os seus comentários <span class="text-xs">(Opcional)</span></fw-label>
      </div>
      <b-field>
        <b-input v-model="feedback['comment']" maxlength="250" type="textarea"></b-input>
      </b-field>
    </div>
    <div class="flex">
      <div class="flex-1"></div>
      <fw-button :type="saving ? 'disabled' : 'primary'" @click.native="$emit('save')">Guardar</fw-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PanelFeedback',
  props: {
    feedback: {
      type: Object,
      required: true
    },
    saving: {
      type: Boolean,
      default: false
    },
    feedbackItems: {
      type: Array,
      required: true
    },
    writeComment: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  }
}
</script>
<style>
.feedback .b-radio.radio:not(.button) {
  @apply m-0;
}
.feedback .field {
  @apply flex items-center;
}
.feedback .field,
.feedback .field:not(:last-child) {
  @apply m-0;
}
</style>
