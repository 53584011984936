import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import Api from '@/fw-modules/fw-core-vue/api/Api'
// import ServiceStorage from '../../storage/services/ServiceStorage'

export default {
  base() {
    let api = Api()
    api.defaults.baseURL = FwEnvConfig.apiUrlIduc
    return api
  },
  async checkAppVersion() {
    const path = `/v1/app/${process.env.VUE_APP_KEY}/${process.env.VUE_APP_VERSION}`
    const response = await this.base().get(path)

    if (response.data.is_new) {
      console.info(`We have a new version current:${process.env.VUE_APP_VERSION}`)
      // await ServiceStorage.sendLogsToBackend(true)
      window.location.reload()
    }
  },

  getSetting(key) {
    return this.base()
      .get('/v1/setting/' + key)
      .then(response => {
        return response.data
      })
  },

  setSetting(key, value) {
    return this.base()
      .post('/v1/setting/' + key, { value })
      .then(response => {
        return response.data
      })
  },

  deleteSetting(key) {
    return this.base()
      .delete('/v1/setting/' + key)
      .then(response => {
        return response.data
      })
  }
}
