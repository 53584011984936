<template>
  <div
    :key="message.key"
    class="chat-message group overflow-visible px-2"
    :data-key="message.key"
    :class="{
      isSelf: isSelf,
      isNotSelf: !isSelf,
      'pt-1': isPreviousSelf && timeDifferenceBetweenMessages() <= 5 && !isAnnouncement,
      'pt-5':
        (!isPreviousSelf && !isAnnouncement) ||
        (isAnnouncement && !isPreviousAnnouncement && featured) ||
        (isPreviousSelf && timeDifferenceBetweenMessages() > 5)
    }"
    @mouseleave="showCmds = false"
  >
    <div
      v-if="isAnnouncement"
      class="w-full"
      :class="{
        'bg-black/5 py-5 px-2': featured,
        'px-0 pt-1': !featured
      }"
    >
      <div
        class="grid grid-cols-1 gap-0 relative overflow-visible mx-auto w-full max-w-prose"
        :class="{
          'border border-black/20 shadow-sm border-b-2 rounded-2xl p-3': featured,
          'text-white bg-primary rounded-2xl': isSelf && featured,
          'bg-white text-gray-800 rounded-2xl': !isSelf && featured,
          'text-gray-800 border-b border-gray-200 pt-1 pb-4 px-2': !featured
        }"
      >
        <div
          class="message-attention absolute -top-1 -left-1 -right-1 -bottom-1 animate-pulse border-2 pointer-events-none transition-all duration-700"
          :class="{
            'rounded-[1.20rem]': featured
          }"
        ></div>
        <div class="flex justify-between gap-2 items-start mb-1">
          <div class="pb-1 rounded-full font-semibold text-xs flex items-center gap-1.5 opacity-70">
            <fw-icon-mega-phone class="w-4 h-4" />
            Anúncio
          </div>
          <b-tooltip
            v-if="announcementDaysLeft > 0"
            :label="expireLabel"
            type="is-light"
            :position="featured ? 'is-right' : 'is-bottom'"
          >
            <div class="pb-1 rounded-full font-semibold text-xs flex items-center gap-1 opacity-70">
              <fw-icon-timer class="w-4 h-4" />
              {{ announcementDaysLeft }}d
            </div>
          </b-tooltip>
          <div class="flex-1"></div>
        </div>
        <div
          class="flex absolute"
          :class="{
            'top-2 right-2': featured,
            '-top-1 right-2': !featured
          }"
        >
          <MessageOptions
            :message="message"
            :can-delete="canDeleteAll || isSelf"
            :can-reply="true"
            :can-promote="canPromoteAll"
            :can-translate="!isSelf && isTranslated"
            :is-translated="!showOriginal"
            :is-last="isLast"
            @untranslate-message="showOriginal = true"
            @translate-message="showOriginal = false"
            @reply-message="$emit('reply-message', message)"
            @promote-message="$emit('promote-message', $event)"
            @unpromote-message="$emit('unpromote-message', $event)"
            @delete-message="$emit('delete-message', chatKey, message.key)"
          />
        </div>
        <div class="flex gap-2 text-base">
          <fw-icon-robot v-if="isTranslated && !showOriginal" class="w-5 h-5 text-primary flex-shrink-0" />
          <div
            class="break-words fix-links-overflow font-medium"
            :class="{ 'text-primary': !showOriginal && !isSelf && isTranslated }"
            v-html="isTranslated && !showOriginal ? message.translated : parseMessage(message.message)"
          ></div>
        </div>
        <!-- Attachments and multimedia -->
        <div
          v-if="multimediaFiles.length"
          class="flex overflow-hidden rounded-lg bg-gray-900 flex-wrap justify-center my-1"
        >
          <div
            v-for="(file, f) in multimediaFiles"
            :key="file.key"
            class="relative flex items-center justify-center cursor-pointer hover:opacity-90"
            :class="{
              'min-w-48 min-h-48 w-1/2 max-h-48': multimediaFiles.length > 1 && f != message.files.length - 1,
              'min-h-80 min-w-80 max-h-80 max-w-[20rem]': multimediaFiles.length === 1,
              'min-w-[24rem] min-h-48 max-h-48 w-full':
                multimediaFiles.length > 1 && f == message.files.length - 1 && oddNumberOfMultimediaFiles
            }"
            style="background-color: rgba(255, 255, 255, 0.2)"
            @click="$emit('preview-file', file)"
          >
            <img v-if="file.type === 'image'" :src="getImageViewUrl(file)" class="h-full w-full object-cover" />
            <fw-icon-play-circle v-if="file.type === 'video'" class="h-8 w-8 absolute top-1/2 left-1/2 -ml-4 -mt-4" />
          </div>
        </div>
        <div
          v-if="otherFiles.length"
          :class="{
            'mt-1': multimediaFiles.length == 0,
            'mt-2': multimediaFiles.length > 0
          }"
          class="rounded-md py-0.5"
        >
          <ChatFiles :message="message" paddingless only-others @preview-file="$emit('preview-file', $event)" />
        </div>
        <div
          v-if="
            (message.recordings && message.recordings.length) ||
              (message.quiz && message.quiz.length) ||
              (message.bucketItems && message.bucketItems.length)
          "
          class="px-5 pt-2 pb-5 bg-white bg-opacity-50 rounded-xl mb-1"
        >
          <div v-if="message.recordings && message.recordings.length" class="group">
            <div v-for="rec in message.recordings" :key="rec.key">
              <div class="text-gray-500 opacity-40 flex items-center gap-1 text-xs">
                <fw-icon-attachment class="w-5 h-5 inline-flex" />
                <fw-label>CID mrec-{{ rec.key }}</fw-label>
              </div>
              <video-player
                :ref="'mrec-' + rec.key"
                :src="getFileViewUrl(rec.file)"
                :poster="recordingsPosterURL(rec)"
              />
            </div>
          </div>
          <div v-if="message.quiz && message.quiz.length">
            <div class="text-gray-500 opacity-40 flex items-center gap-1 text-xs">
              <fw-icon-attachment class="w-5 h-5 inline-flex" />
            </div>
            <div v-for="quiz in message.quiz" :key="quiz.key">
              <h1>{{ quiz.title }}</h1>
              <div><b>Users:</b> {{ quiz.users_count }}</div>
              <div><br /><b>Não respondeu:</b> {{ quiz.no_answer.count }}</div>
              <div><br /><b>Sem resposta:</b> {{ quiz.answers[0].none.count }}</div>
              <div v-for="(option, index) in quiz.answers[0].options" :key="index">
                <br /><b>{{ option.title }}:</b> {{ option.count }}
              </div>
            </div>
          </div>
          <div v-if="message.bucketItems && message.bucketItems.length" class="group">
            <div v-for="item in message.bucketItems" :key="item.key">
              <div class="text-gray-500 opacity-40 flex items-center gap-1 text-xs">
                <fw-icon-attachment class="w-5 h-5 inline-flex" />
                <fw-label>CID {{ item.key }}</fw-label>
              </div>
              <video-player
                v-if="item.metadata.file_type == 'video'"
                :ref="'cid-' + item.key"
                :options="{
                  muted: true,
                  fluid: true,
                  responsive: true,
                  language: 'pt',
                  preload: 'none',
                  html5: {
                    nativeVideoTracks: true
                  },
                  controlsList: item.lock_download ? 'nodownload' : null,
                  poster: null,
                  sources: [
                    {
                      type: 'video/mp4',
                      src: getFileViewUrl(item.file)
                    }
                  ]
                }"
              >
              </video-player>
              <div v-else-if="allowDownloads">
                <a target="_blank" :href="getFileViewUrl(item.file, true)">Download</a>
              </div>
            </div>
          </div>
        </div>
        <div class="flex gap-2 mt-3">
          <UserAvatar :user="user" size="xs" class="flex-shrink-0" />
          <div class="flex-1">
            <v-clamp autoresize :max-lines="1" class="font-bold text-sm leading-4">
              {{ user.name }}
            </v-clamp>
            <div class="text-xs opacity-60">{{ message.created_date | formatDateTime }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="max-w-[65rem] mx-auto px-2">
      <div class="flex gap-2 items-end" :class="{ 'justify-end': isSelf }">
        <UserAvatar
          v-if="
            (!isSelf && !isNextSelf) ||
              (!isSelf && timeDifferenceBetweenMessages() > 5 && isPreviousSelf) ||
              (!isSelf && isNextSelf && timeDifferenceBetweenNextMessages() > 5)
          "
          :user="user"
          size="xs"
          class="flex-shrink-0"
        />
        <div v-else class="h-8 w-8 flex-shrink-0"></div>
        <div class="flex items-center text-sm">
          <div
            class="mx-4 opacity-0 group-hover:opacity-90 flex-shrink-0"
            :class="{
              'order-last': !isSelf
            }"
          >
            <MessageOptions
              :message="message"
              :can-delete="canDeleteAll || isSelf"
              :can-reply="true"
              :can-promote="canPromoteAll"
              :can-translate="!isSelf && isTranslated"
              :is-translated="!showOriginal"
              :is-last="isLast"
              @translate-message="showOriginal = false"
              @untranslate-message="showOriginal = true"
              @reply-message="$emit('reply-message', message)"
              @promote-message="$emit('promote-message', $event)"
              @unpromote-message="$emit('unpromote-message', $event)"
              @delete-message="$emit('delete-message', chatKey, message.key)"
            />
          </div>
          <div
            class="px-2 py-1 font-medium relative z-0 rounded max-w-xl"
            :class="{
              'text-white bg-primary': isSelf,
              'bg-gray-50 text-gray-800': !isSelf,
              'rounded-r-2xl rounded-bl': !isSelf,
              'rounded-l-2xl rounded-br': isSelf,
              'rounded-tl-2xl': (!isPreviousSelf && !isSelf) || (!isSelf && timeDifferenceBetweenMessages() > 5),
              'rounded-tr-2xl': (!isPreviousSelf && isSelf) || (isSelf && timeDifferenceBetweenMessages() > 5),
              //'rounded-2xl': oneToOneChat,
              'order-last': isSelf
            }"
          >
            <div
              class="message-attention absolute -top-1 -left-1 -right-1 -bottom-1 animate-pulse border-2 pointer-events-none transition-all duration-700"
              :class="{
                'rounded-tl-[1.20rem] rounded-r-[1.20rem]': !oneToOneChat && !isSelf,
                'rounded-l-[1.20rem] rounded-tr-[1.20rem]': !oneToOneChat && isSelf,
                'rounded-[1.20rem]': oneToOneChat
              }"
            ></div>
            <div
              v-if="(!isSelf && !isPreviousSelf) || (!isSelf && timeDifferenceBetweenMessages() > 5)"
              class="flex justify-between gap-2 items-center px-1.5"
              :class="{ 'mt-1': isSelf }"
            >
              <v-clamp v-if="!isSelf" autoresize :max-lines="1" class="font-bold flex-1 text-sm pt-1.5">
                {{ user.name }}
              </v-clamp>
            </div>
            <ChatMessageReply
              v-if="message.parent_message"
              class="mb-1 mt-1"
              :message="message.parent_message"
              :user="parentUser"
              :is-self="isSelf"
              is-attachement
              @click.native="$emit('click-reply', message.parent_message)"
            />
            <div v-if="!isDefaultFilesMessage" class="flex gap-2 text-base px-1.5 py-1">
              <fw-icon-robot v-if="isTranslated && !showOriginal" class="w-5 h-5 text-primary flex-shrink-0" />
              <div
                class="break-words fix-links-overflow"
                :class="{ 'text-primary': !showOriginal && !isSelf && isTranslated }"
                v-html="isTranslated && !showOriginal ? message.translated : parseMessage(message.message)"
              ></div>
            </div>
            <!-- Attachments and multimedia -->
            <div
              v-if="multimediaFiles.length"
              class="flex overflow-hidden rounded-lg bg-gray-900 flex-wrap justify-center my-1"
            >
              <div
                v-for="(file, f) in multimediaFiles"
                :key="file.key"
                class="relative flex items-center justify-center cursor-pointer hover:opacity-90"
                :class="{
                  'min-w-48 min-h-48 w-1/2 max-h-48': multimediaFiles.length > 1 && f != message.files.length - 1,
                  'min-h-80 min-w-80 max-h-80 max-w-[20rem]': multimediaFiles.length === 1,
                  'min-w-[24rem] min-h-48 max-h-48 w-full':
                    multimediaFiles.length > 1 && f == message.files.length - 1 && oddNumberOfMultimediaFiles
                }"
                style="background-color: rgba(255, 255, 255, 0.2)"
                @click="$emit('preview-file', file)"
              >
                <img v-if="file.type === 'image'" :src="getImageViewUrl(file)" class="h-full w-full object-cover" />
                <fw-icon-play-circle
                  v-if="file.type === 'video'"
                  class="h-8 w-8 absolute top-1/2 left-1/2 -ml-4 -mt-4"
                />
              </div>
            </div>
            <div
              v-if="otherFiles.length"
              :class="{
                'mt-1': multimediaFiles.length == 0,
                'mt-2': multimediaFiles.length > 0
              }"
              class="rounded-md py-0.5"
            >
              <ChatFiles :message="message" only-others @preview-file="$emit('preview-file', $event)" />
            </div>
            <div
              v-if="
                (message.recordings && message.recordings.length) ||
                  (message.quiz && message.quiz.length) ||
                  (message.bucketItems && message.bucketItems.length)
              "
              class="px-5 pt-2 pb-5 bg-white bg-opacity-50 rounded-xl mb-1"
            >
              <div v-if="message.recordings && message.recordings.length" class="group">
                <div v-for="rec in message.recordings" :key="rec.key">
                  <div class="text-gray-500 opacity-40 flex items-center gap-1 text-xs">
                    <fw-icon-attachment class="w-5 h-5 inline-flex" />
                    <fw-label>CID mrec-{{ rec.key }}</fw-label>
                  </div>
                  <video-player
                    :ref="'mrec-' + rec.key"
                    :src="getFileViewUrl(rec.file)"
                    :poster="recordingsPosterURL(rec)"
                  />
                </div>
              </div>
              <div v-if="message.quiz && message.quiz.length">
                <div class="text-gray-500 opacity-40 flex items-center gap-1 text-xs">
                  <fw-icon-attachment class="w-5 h-5 inline-flex" />
                </div>
                <div v-for="quiz in message.quiz" :key="quiz.key">
                  <h1>{{ quiz.title }}</h1>
                  <div><b>Users:</b> {{ quiz.users_count }}</div>
                  <div><br /><b>Não respondeu:</b> {{ quiz.no_answer.count }}</div>
                  <div><br /><b>Sem resposta:</b> {{ quiz.answers[0].none.count }}</div>
                  <div v-for="(option, index) in quiz.answers[0].options" :key="index">
                    <br /><b>{{ option.title }}:</b> {{ option.count }}
                  </div>
                </div>
              </div>
              <div v-if="message.bucketItems && message.bucketItems.length" class="group">
                <div v-for="item in message.bucketItems" :key="item.key">
                  <div class="text-gray-500 opacity-40 flex items-center gap-1 text-xs">
                    <fw-icon-attachment class="w-5 h-5 inline-flex" />
                    <fw-label>CID {{ item.key }}</fw-label>
                  </div>
                  <video-player
                    v-if="item.metadata.file_type == 'video'"
                    :ref="'cid-' + item.key"
                    :options="{
                      muted: true,
                      fluid: true,
                      responsive: true,
                      language: 'pt',
                      preload: 'none',
                      html5: {
                        nativeVideoTracks: true
                      },
                      controlsList: item.lock_download ? 'nodownload' : null,
                      poster: null,
                      sources: [
                        {
                          type: 'video/mp4',
                          src: getFileViewUrl(item.file)
                        }
                      ]
                    }"
                  >
                  </video-player>
                  <div v-else-if="allowDownloads">
                    <a target="_blank" :href="getFileViewUrl(item.file, true)">Download</a>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                !nextMessage ||
                  //(timeDifferenceBetweenMessages() > 5 && isPreviousSelf) ||
                  !isNextSelf ||
                  (isNextSelf && timeDifferenceBetweenNextMessages() > 5)
              "
              class="flex-1 text-xs opacity-60 px-1.5 mb-1.5"
              :class="{
                'mt-2.5': multimediaFiles.length > 0 || otherFiles.length > 0,
                'mt-0.5': multimediaFiles.length == 0 && otherFiles.length == 0
              }"
            >
              {{ message.created_date | formatDateTime }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Autolinker from 'autolinker'
import ChatUtils from '@/fw-modules/fw-core-vue/chats/services/utilities'
import VideoPlayer from '@/fw-modules/fw-core-vue/ui/components/video/VideoPlayer'
import UserAvatar from '@/fw-modules/fw-core-vue/id/components/user/UserAvatar'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import MessageOptions from './blocks/MessageOptions.vue'
import ChatMessageReply from './ChatMessageReply.vue'
import ChatFiles from '@/fw-modules/fw-core-vue/chats/components/ChatFiles'

export default {
  components: {
    UserAvatar,
    VideoPlayer,
    MessageOptions,
    ChatMessageReply,
    ChatFiles
  },

  props: {
    message: Object,
    chatType: String,
    chatKey: String,
    user: Object,
    parentUser: Object,
    canDeleteAll: Boolean,
    canPromoteAll: Boolean,
    aiTranslateOn: Boolean,
    allowDownloads: {
      type: Boolean,
      default: true
    },
    show: {
      type: Boolean,
      default: false
    },
    featured: {
      type: Boolean,
      default: false
    },
    previousMessage: {
      type: Object
    },
    nextMessage: {
      type: Object
    },
    isLast: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showOriginal: !this.aiTranslateOn,
      showCmds: false,
      promote: false
    }
  },

  computed: {
    isPreviousAnnouncement() {
      return this.previousMessage && this.previousMessage.announcement.is_announcement
    },
    isPreviousSelf() {
      return (
        this.previousMessage &&
        this.previousMessage.user_key == this.message.user_key &&
        !this.previousMessage.announcement.is_announcement
      )
    },
    isNextSelf() {
      return (
        this.nextMessage &&
        this.nextMessage.user_key == this.message.user_key &&
        !this.nextMessage.announcement.is_announcement
      )
    },
    oddNumberOfMultimediaFiles() {
      return this.multimediaFiles.length % 2 === 1
    },
    multimediaFiles() {
      return this.message.files.filter(file => file.type === 'image' || file.type === 'video' || file.type === 'audio')
    },
    otherFiles() {
      return this.message.files.filter(file => file.type !== 'image' && file.type !== 'video' && file.type !== 'audio')
    },
    expireLabel() {
      return 'Expira a ' + this.$options.filters.formatDateTime(this.message.announcement.expiry_date)
    },
    announcementDaysLeft() {
      return ChatUtils.announcementDaysLeft(this.message.announcement)
    },
    isDefaultFilesMessage() {
      return this.message.message == 'Mensagem para partilha de ficheiros.'
    },
    isTranslated() {
      return this.message.translated && this.message.message != this.message.translated
    },
    loggedUser() {
      return this.$store.getters.getUser
    },
    oneToOneChat() {
      return this.chatType == 'user' || false
    },
    isSelf() {
      return this.loggedUser.key == this.user.key
    },
    isAnnouncement() {
      return this.message.announcement.is_announcement
    }
  },

  watch: {
    aiTranslateOn(value) {
      this.showOriginal = !value
    }
  },

  methods: {
    timeDifferenceBetweenMessages() {
      return ChatUtils.timeDifferenceBetweenMessages(this.message, this.previousMessage)
    },
    timeDifferenceBetweenNextMessages() {
      return ChatUtils.timeDifferenceBetweenMessages(this.message, this.nextMessage)
    },
    showOptions() {
      this.$emit('show-options', {
        target: this.$refs.options,
        pos: 'left',
        message: this.message
      })
    },
    getFileUrl(file) {
      return ServiceStorage.getFileUrl(file)
    },
    getFileViewUrl(file, download = false) {
      return ServiceStorage.getFileViewUrl(file, download)
    },
    getImageViewUrl(file) {
      let url = ServiceStorage.getImageViewUrl(file, '600')
      console.log('image url', encodeURI(url))
      return encodeURI(url)
    },
    recordingsPosterURL(recording) {
      return utils.recordingsPosterURL(recording)
    },

    parseMessage(content) {
      return Autolinker.link(content, {
        newWindow: true,
        stripPrefix: false
      })
    },

    // Video player
    onPlayerPlay(player) {
      console.debug('player play!', player)
    },
    onPlayerPause(player) {
      console.debug('player pause!', player)
    },
    playerStateChanged(playerCurrentState) {
      console.debug('player current update state', playerCurrentState)
    },
    playerReadied(player) {
      console.debug('the player is readied', player)
      if (this.chatActive) {
        ChatUtils.scrollToBottom(10, () => {
          this.chatActive.selfUnread = 0
        })
      }
    }
  }
}
</script>

<style lang="scss">
.message-attention {
  @apply border-transparent;
}
.show-message {
  .message-attention {
    @apply border-primary;
  }
}
.chat-message {
  .text-primary {
    * {
      @apply text-primary text-base;
    }
  }
  pre {
    @apply bg-opacity-50 bg-white p-4 rounded-xl;
  }
  .fix-links-overflow {
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
  }
}
.chat-message.isSelf {
  strong {
    @apply text-white;
  }
}
.chat-message.isNotSelf {
  strong {
    @apply text-gray-800;
  }
}
</style>
