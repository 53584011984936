<template>
  <fw-button :type="type" :size="size" :expanded="expanded">
    <div class="sr-only">{{ upperLabel }}{{ label }}</div>
    <div class="flex gap-1 items-center" :class="{ 'flex-1': expanded && (label || $slots.default) }">
      <div v-if="upperLabel" class="text-xs font-normal opacity-60 flex-shrink-0 leading-5">{{ upperLabel }}</div>
      <div
        class="flex gap-0.5 items-center"
        :class="{ 'text-center': centered, 'text-left': !centered, 'flex-1': expanded }"
      >
        <div v-if="$slots['icon']" class="mr-2">
          <slot name="icon"></slot>
        </div>
        <div v-if="icon" :class="{ 'mr-2': label || $slots.default }">
          <component :is="'fw-icon-' + icon" v-if="icon" class="w-5 h-5 flex-shrink-0" />
        </div>
        <slot name="default">
          <div v-if="label" class="flex-shrink-0" :class="{ uppercase: uppercase, 'flex-1': expanded }">
            {{ label }}
          </div>
        </slot>
        <fw-icon-chevron-down v-if="chevron" class="w-5 h-5 -mr-1 flex-shrink-0" />
      </div>
    </div>
  </fw-button>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: null
    },
    upperLabel: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'sm'
    },
    type: {
      type: String,
      default: 'light'
    },
    chevron: {
      type: Boolean,
      default: true
    },
    expanded: {
      type: Boolean,
      default: true
    },
    uppercase: {
      type: Boolean,
      default: false
    },
    centered: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: null
    }
  }
}
</script>
