<template>
  <div
    class="bg-white/60 border cursor-default select-none pr-2.5 border-black/20 rounded-md flex relative h-12 overflow-hidden pl-3 gap-2 items-start"
    :class="{ 'border-b': isAttachement, 'border-b-2': !isAttachement }"
  >
    <div
      class="absolute left-0 top-0 bottom-0 w-1"
      :class="{
        'bg-primary': !isSelf,
        'bg-white': isSelf
      }"
    ></div>
    <UserAvatar :user="user" size="xs" class="flex-shrink-0 mt-1.5" />
    <div class="py-1.5 pr-0.5 flex-1 flex flex-col items-start justify-center">
      <div class="text-sm font-semibold text-black leading-4">{{ user.name }}</div>
      <div class="flex items-center gap-2">
        <div
          v-if="message.message && message.message.length && !isDefaultFilesMessage && !isEmptyMessage"
          class="overflow-hidden text-sm font-medium leading-4 line-clamp-1 flex-1"
          :class="{
            'text-gray-500': !isSelf,
            'text-black/50': isSelf
          }"
          v-html="message.message"
        ></div>
        <div
          v-if="message.files.length"
          class="flex gap-0.5 items-center text-xs"
          :class="{
            'text-gray-500': !isSelf,
            'text-black/50': isSelf
          }"
        >
          <fw-icon-attachment class="h-3.5 w-3.5" /> {{ message.files.length }}
        </div>
      </div>
    </div>
    <div
      class="hidden absolute bg-gradient-to-t from-white to-transparent pointer-events-none left-12 right-0 bottom-0 h-4"
    ></div>
    <fw-button
      v-if="showClose"
      class="mt-2"
      type="xlight"
      icon="close"
      size="xs"
      @click.native="$emit('close')"
    ></fw-button>
  </div>
</template>

<script>
import UserAvatar from '@/fw-modules/fw-core-vue/id/components/user/UserAvatar'

export default {
  components: {
    UserAvatar
  },

  props: {
    message: Object,
    user: Object,
    showClose: {
      type: Boolean,
      default: false
    },
    isAttachement: {
      type: Boolean,
      default: false
    },
    isSelf: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isDefaultFilesMessage() {
      return this.message.message == 'Mensagem para partilha de ficheiros.'
    },
    isEmptyMessage() {
      return this.message.message == '' || this.message.message == '<p></p>'
    }
  }
}
</script>
