import Janus from '@/fw-modules/fw-meetings-vue/probojs/janus'

export default {
  data() {
    return this.getJanusDefaultData()
  },

  methods: {
    getJanusDefaultData() {
      if (this.janus) {
        // TODO
        this.leaveJanus().then(() => {})
      }

      return {
        janus: null,
        isJanusInitialized: false,
        oldSession: null, // TODO delete
        isSafari: false,
        startWithAudio: true,
        startWithCamera: true
      }
    },

    // Janus stuff
    joinJanus() {
      const self = this
      console.info(`Going to connect janus ${self.instance.janus.server}:${self.instance.janus.room_id}`)

      let iceTransportPolicy = localStorage.getItem('ice.transport.policy')
      if (!iceTransportPolicy || !['all', 'relay'].includes(iceTransportPolicy)) {
        iceTransportPolicy = 'all'
      }

      Janus.init({
        debug: localStorage.getItem('debug-janus') ? 'all' : 'error',
        callback: function() {
          self.janus = new Janus({
            server: self.instance.janus.server,
            iceServers: self.instance.janus.ice_servers,
            iceTransportPolicy: iceTransportPolicy,
            // bundlePolicy: "max-bundle",
            rtcpMuxPolicy: 'require',
            iceCandidatePoolSize: 0,
            sdpSemantics: 'unified-plan',
            success: function() {
              console.info(
                `Janus ${self.instance.janus.server}:${self.instance.janus.room_id} connected iceTransport:${iceTransportPolicy}`
              )

              self.isJanusInitialized = true
              self.isSafari = Janus.webRTCAdapter.browserDetails.browser === 'safari'

              self.oldSession = {
                janus: self.janus,
                user_name: self.attendee.key,
                room_id: self.instance.janus.room_id,
                opaque_id: `${self.attendee.key}-${Janus.randomString(12)}`,
                plugin_name: 'janus.plugin.videoroom',
                detach_all: function() {
                  if (self.subscriber) {
                    let subscribers = self.subscriber.subscribers
                    for (let key in subscribers) {
                      let subscriber = subscribers[key]
                      self.subscriber.remote_stream_listener.off({ subscriber: subscriber })
                      if (subscriber.handle) subscriber.handle.detach()
                      delete subscribers[key]
                    }
                  }

                  self.stopAllPublishers()
                },
                streamIsLocal: self.streamIsLocal,
                leftById: function(streamId) {
                  if (self.audio.publisher && self.audio.publisher.id == streamId) {
                    self.audio.publisher.left()
                    return true
                  } else if (self.camera.publisher && self.camera.publisher.id == streamId) {
                    self.camera.publisher.left()
                    return true
                  } else if (self.screenShare.publisher && self.screenShare.publisher.id == streamId) {
                    self.screenShare.publisher.left()
                    return true
                  } else if (self.subscriber && self.subscriber.id == streamId) {
                    self.subscriber.left()
                    return true
                  } else {
                    return false
                  }
                }
              }

              self.startSubscriberWatch()
              if (self.startWithAudio) self.startAudio()
              if (self.startWithCamera) self.startCamera()
            },
            error: function(error) {
              console.error('Janus error', error)

              if (error === Janus.ERRORS.LOST_CONNECTION) {
                self.$store.commit('checkWebsocket', {
                  name: 'janus-disconnected',
                  callback: self.meetingReconnectJanus
                })
              }
            }
          })
        }
      })
    },
    async leaveJanus() {
      let janus = this.janus
      await this.stopAllPublishers()
      await this.stopSubscriberWatch()

      if (janus) {
        await janus.destroy()
        if (janus === this.janus) {
          this.isJanusInitialized = false
          this.janus = null
        }
      }
    }
  }
}
