<template>
  <fw-layout full mobile-ready back-to="/buckets">
    <template #main-content>
      <PanelBucketAdd @go-back="$router.go(-1)" />
    </template>
  </fw-layout>
</template>

<script>
import PanelBucketAdd from '../components/panels/PanelBucketAdd'

export default {
  components: {
    PanelBucketAdd
  }
}
</script>
