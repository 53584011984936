<template>
  <LayoutDefault>
    <template #main>
      <div class="w-full h-full flex flex-col justify-center items-center max-w-prose mx-auto">
        <div class="has-margin-medium">
          <faicon icon="heart-broken" size="3x"></faicon>
        </div>
        <div class="has-text-tiny">{{ $t('error500') }}</div>
        <h1 class="is-size-1">{{ $t('internalErrorFound') }}</h1>
        <div class="is-size-2">{{ $t('pleaseTryAgain') }}</div>
        <div class="has-text-small has-margin-medium">
          {{ $t('errorLongDescription') }}
        </div>
        <div class="has-margin-top-large">
          <b-button type="is-text is-small has-text-muted" @click="$router.go(-1)">{{ $t('tryAgain') }}</b-button>
        </div>
      </div>
    </template>
  </LayoutDefault>
</template>

<script>
import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'

export default {
  components: {
    LayoutDefault
  },

  data() {
    if (process.env.VUE_APP_ERRORS_TO) {
      this.$router.push({ name: process.env.VUE_APP_ERRORS_TO })
    }
    return {}
  }
}
</script>

<i18n>
{
  "en": {
    "error500": "(500) Internal Server Error",
    "internalErrorFound": "We found an internal error that needs to be fixed.",
    "pleaseTryAgain": "Please try again later.",
    "errorLongDescription": "",
    "tryAgain": "Try again"
  },
  "pt": {
    "error500": "(500) Internal Server Error",
    "internalErrorFound": "Ocorreu um erro interno",
    "pleaseTryAgain": "Por favor, tente novamente.",
    "errorLongDescription": "O erro ficou registado e iremos procurar ser breves na sua resolução.",
    "tryAgain": "Tentar novamente"
  }
}
</i18n>
