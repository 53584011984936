var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.message.key,staticClass:"chat-message group overflow-visible px-2",class:{
    isSelf: _vm.isSelf,
    isNotSelf: !_vm.isSelf,
    'pt-1': _vm.isPreviousSelf && _vm.timeDifferenceBetweenMessages() <= 5 && !_vm.isAnnouncement,
    'pt-5':
      (!_vm.isPreviousSelf && !_vm.isAnnouncement) ||
      (_vm.isAnnouncement && !_vm.isPreviousAnnouncement && _vm.featured) ||
      (_vm.isPreviousSelf && _vm.timeDifferenceBetweenMessages() > 5)
  },attrs:{"data-key":_vm.message.key},on:{"mouseleave":function($event){_vm.showCmds = false}}},[(_vm.isAnnouncement)?_c('div',{staticClass:"w-full",class:{
      'bg-black/5 py-5 px-2': _vm.featured,
      'px-0 pt-1': !_vm.featured
    }},[_c('div',{staticClass:"grid grid-cols-1 gap-0 relative overflow-visible mx-auto w-full max-w-prose",class:{
        'border border-black/20 shadow-sm border-b-2 rounded-2xl p-3': _vm.featured,
        'text-white bg-primary rounded-2xl': _vm.isSelf && _vm.featured,
        'bg-white text-gray-800 rounded-2xl': !_vm.isSelf && _vm.featured,
        'text-gray-800 border-b border-gray-200 pt-1 pb-4 px-2': !_vm.featured
      }},[_c('div',{staticClass:"message-attention absolute -top-1 -left-1 -right-1 -bottom-1 animate-pulse border-2 pointer-events-none transition-all duration-700",class:{
          'rounded-[1.20rem]': _vm.featured
        }}),_c('div',{staticClass:"flex justify-between gap-2 items-start mb-1"},[_c('div',{staticClass:"pb-1 rounded-full font-semibold text-xs flex items-center gap-1.5 opacity-70"},[_c('fw-icon-mega-phone',{staticClass:"w-4 h-4"}),_vm._v(" Anúncio ")],1),(_vm.announcementDaysLeft > 0)?_c('b-tooltip',{attrs:{"label":_vm.expireLabel,"type":"is-light","position":_vm.featured ? 'is-right' : 'is-bottom'}},[_c('div',{staticClass:"pb-1 rounded-full font-semibold text-xs flex items-center gap-1 opacity-70"},[_c('fw-icon-timer',{staticClass:"w-4 h-4"}),_vm._v(" "+_vm._s(_vm.announcementDaysLeft)+"d ")],1)]):_vm._e(),_c('div',{staticClass:"flex-1"})],1),_c('div',{staticClass:"flex absolute",class:{
          'top-2 right-2': _vm.featured,
          '-top-1 right-2': !_vm.featured
        }},[_c('MessageOptions',{attrs:{"message":_vm.message,"can-delete":_vm.canDeleteAll || _vm.isSelf,"can-reply":true,"can-promote":_vm.canPromoteAll,"can-translate":!_vm.isSelf && _vm.isTranslated,"is-translated":!_vm.showOriginal,"is-last":_vm.isLast},on:{"untranslate-message":function($event){_vm.showOriginal = true},"translate-message":function($event){_vm.showOriginal = false},"reply-message":function($event){return _vm.$emit('reply-message', _vm.message)},"promote-message":function($event){return _vm.$emit('promote-message', $event)},"unpromote-message":function($event){return _vm.$emit('unpromote-message', $event)},"delete-message":function($event){return _vm.$emit('delete-message', _vm.chatKey, _vm.message.key)}}})],1),_c('div',{staticClass:"flex gap-2 text-base"},[(_vm.isTranslated && !_vm.showOriginal)?_c('fw-icon-robot',{staticClass:"w-5 h-5 text-primary flex-shrink-0"}):_vm._e(),_c('div',{staticClass:"break-words fix-links-overflow font-medium",class:{ 'text-primary': !_vm.showOriginal && !_vm.isSelf && _vm.isTranslated },domProps:{"innerHTML":_vm._s(_vm.isTranslated && !_vm.showOriginal ? _vm.message.translated : _vm.parseMessage(_vm.message.message))}})],1),(_vm.multimediaFiles.length)?_c('div',{staticClass:"flex overflow-hidden rounded-lg bg-gray-900 flex-wrap justify-center my-1"},_vm._l((_vm.multimediaFiles),function(file,f){return _c('div',{key:file.key,staticClass:"relative flex items-center justify-center cursor-pointer hover:opacity-90",class:{
            'min-w-48 min-h-48 w-1/2 max-h-48': _vm.multimediaFiles.length > 1 && f != _vm.message.files.length - 1,
            'min-h-80 min-w-80 max-h-80 max-w-[20rem]': _vm.multimediaFiles.length === 1,
            'min-w-[24rem] min-h-48 max-h-48 w-full':
              _vm.multimediaFiles.length > 1 && f == _vm.message.files.length - 1 && _vm.oddNumberOfMultimediaFiles
          },staticStyle:{"background-color":"rgba(255, 255, 255, 0.2)"},on:{"click":function($event){return _vm.$emit('preview-file', file)}}},[(file.type === 'image')?_c('img',{staticClass:"h-full w-full object-cover",attrs:{"src":_vm.getImageViewUrl(file)}}):_vm._e(),(file.type === 'video')?_c('fw-icon-play-circle',{staticClass:"h-8 w-8 absolute top-1/2 left-1/2 -ml-4 -mt-4"}):_vm._e()],1)}),0):_vm._e(),(_vm.otherFiles.length)?_c('div',{staticClass:"rounded-md py-0.5",class:{
          'mt-1': _vm.multimediaFiles.length == 0,
          'mt-2': _vm.multimediaFiles.length > 0
        }},[_c('ChatFiles',{attrs:{"message":_vm.message,"paddingless":"","only-others":""},on:{"preview-file":function($event){return _vm.$emit('preview-file', $event)}}})],1):_vm._e(),(
          (_vm.message.recordings && _vm.message.recordings.length) ||
            (_vm.message.quiz && _vm.message.quiz.length) ||
            (_vm.message.bucketItems && _vm.message.bucketItems.length)
        )?_c('div',{staticClass:"px-5 pt-2 pb-5 bg-white bg-opacity-50 rounded-xl mb-1"},[(_vm.message.recordings && _vm.message.recordings.length)?_c('div',{staticClass:"group"},_vm._l((_vm.message.recordings),function(rec){return _c('div',{key:rec.key},[_c('div',{staticClass:"text-gray-500 opacity-40 flex items-center gap-1 text-xs"},[_c('fw-icon-attachment',{staticClass:"w-5 h-5 inline-flex"}),_c('fw-label',[_vm._v("CID mrec-"+_vm._s(rec.key))])],1),_c('video-player',{ref:'mrec-' + rec.key,refInFor:true,attrs:{"src":_vm.getFileViewUrl(rec.file),"poster":_vm.recordingsPosterURL(rec)}})],1)}),0):_vm._e(),(_vm.message.quiz && _vm.message.quiz.length)?_c('div',[_c('div',{staticClass:"text-gray-500 opacity-40 flex items-center gap-1 text-xs"},[_c('fw-icon-attachment',{staticClass:"w-5 h-5 inline-flex"})],1),_vm._l((_vm.message.quiz),function(quiz){return _c('div',{key:quiz.key},[_c('h1',[_vm._v(_vm._s(quiz.title))]),_c('div',[_c('b',[_vm._v("Users:")]),_vm._v(" "+_vm._s(quiz.users_count))]),_c('div',[_c('br'),_c('b',[_vm._v("Não respondeu:")]),_vm._v(" "+_vm._s(quiz.no_answer.count))]),_c('div',[_c('br'),_c('b',[_vm._v("Sem resposta:")]),_vm._v(" "+_vm._s(quiz.answers[0].none.count))]),_vm._l((quiz.answers[0].options),function(option,index){return _c('div',{key:index},[_c('br'),_c('b',[_vm._v(_vm._s(option.title)+":")]),_vm._v(" "+_vm._s(option.count)+" ")])})],2)})],2):_vm._e(),(_vm.message.bucketItems && _vm.message.bucketItems.length)?_c('div',{staticClass:"group"},_vm._l((_vm.message.bucketItems),function(item){return _c('div',{key:item.key},[_c('div',{staticClass:"text-gray-500 opacity-40 flex items-center gap-1 text-xs"},[_c('fw-icon-attachment',{staticClass:"w-5 h-5 inline-flex"}),_c('fw-label',[_vm._v("CID "+_vm._s(item.key))])],1),(item.metadata.file_type == 'video')?_c('video-player',{ref:'cid-' + item.key,refInFor:true,attrs:{"options":{
                muted: true,
                fluid: true,
                responsive: true,
                language: 'pt',
                preload: 'none',
                html5: {
                  nativeVideoTracks: true
                },
                controlsList: item.lock_download ? 'nodownload' : null,
                poster: null,
                sources: [
                  {
                    type: 'video/mp4',
                    src: _vm.getFileViewUrl(item.file)
                  }
                ]
              }}}):(_vm.allowDownloads)?_c('div',[_c('a',{attrs:{"target":"_blank","href":_vm.getFileViewUrl(item.file, true)}},[_vm._v("Download")])]):_vm._e()],1)}),0):_vm._e()]):_vm._e(),_c('div',{staticClass:"flex gap-2 mt-3"},[_c('UserAvatar',{staticClass:"flex-shrink-0",attrs:{"user":_vm.user,"size":"xs"}}),_c('div',{staticClass:"flex-1"},[_c('v-clamp',{staticClass:"font-bold text-sm leading-4",attrs:{"autoresize":"","max-lines":1}},[_vm._v(" "+_vm._s(_vm.user.name)+" ")]),_c('div',{staticClass:"text-xs opacity-60"},[_vm._v(_vm._s(_vm._f("formatDateTime")(_vm.message.created_date)))])],1)],1)])]):_c('div',{staticClass:"max-w-[65rem] mx-auto px-2"},[_c('div',{staticClass:"flex gap-2 items-end",class:{ 'justify-end': _vm.isSelf }},[(
          (!_vm.isSelf && !_vm.isNextSelf) ||
            (!_vm.isSelf && _vm.timeDifferenceBetweenMessages() > 5 && _vm.isPreviousSelf) ||
            (!_vm.isSelf && _vm.isNextSelf && _vm.timeDifferenceBetweenNextMessages() > 5)
        )?_c('UserAvatar',{staticClass:"flex-shrink-0",attrs:{"user":_vm.user,"size":"xs"}}):_c('div',{staticClass:"h-8 w-8 flex-shrink-0"}),_c('div',{staticClass:"flex items-center text-sm"},[_c('div',{staticClass:"mx-4 opacity-0 group-hover:opacity-90 flex-shrink-0",class:{
            'order-last': !_vm.isSelf
          }},[_c('MessageOptions',{attrs:{"message":_vm.message,"can-delete":_vm.canDeleteAll || _vm.isSelf,"can-reply":true,"can-promote":_vm.canPromoteAll,"can-translate":!_vm.isSelf && _vm.isTranslated,"is-translated":!_vm.showOriginal,"is-last":_vm.isLast},on:{"translate-message":function($event){_vm.showOriginal = false},"untranslate-message":function($event){_vm.showOriginal = true},"reply-message":function($event){return _vm.$emit('reply-message', _vm.message)},"promote-message":function($event){return _vm.$emit('promote-message', $event)},"unpromote-message":function($event){return _vm.$emit('unpromote-message', $event)},"delete-message":function($event){return _vm.$emit('delete-message', _vm.chatKey, _vm.message.key)}}})],1),_c('div',{staticClass:"px-2 py-1 font-medium relative z-0 rounded max-w-xl",class:{
            'text-white bg-primary': _vm.isSelf,
            'bg-gray-50 text-gray-800': !_vm.isSelf,
            'rounded-r-2xl rounded-bl': !_vm.isSelf,
            'rounded-l-2xl rounded-br': _vm.isSelf,
            'rounded-tl-2xl': (!_vm.isPreviousSelf && !_vm.isSelf) || (!_vm.isSelf && _vm.timeDifferenceBetweenMessages() > 5),
            'rounded-tr-2xl': (!_vm.isPreviousSelf && _vm.isSelf) || (_vm.isSelf && _vm.timeDifferenceBetweenMessages() > 5),
            //'rounded-2xl': oneToOneChat,
            'order-last': _vm.isSelf
          }},[_c('div',{staticClass:"message-attention absolute -top-1 -left-1 -right-1 -bottom-1 animate-pulse border-2 pointer-events-none transition-all duration-700",class:{
              'rounded-tl-[1.20rem] rounded-r-[1.20rem]': !_vm.oneToOneChat && !_vm.isSelf,
              'rounded-l-[1.20rem] rounded-tr-[1.20rem]': !_vm.oneToOneChat && _vm.isSelf,
              'rounded-[1.20rem]': _vm.oneToOneChat
            }}),((!_vm.isSelf && !_vm.isPreviousSelf) || (!_vm.isSelf && _vm.timeDifferenceBetweenMessages() > 5))?_c('div',{staticClass:"flex justify-between gap-2 items-center px-1.5",class:{ 'mt-1': _vm.isSelf }},[(!_vm.isSelf)?_c('v-clamp',{staticClass:"font-bold flex-1 text-sm pt-1.5",attrs:{"autoresize":"","max-lines":1}},[_vm._v(" "+_vm._s(_vm.user.name)+" ")]):_vm._e()],1):_vm._e(),(_vm.message.parent_message)?_c('ChatMessageReply',{staticClass:"mb-1 mt-1",attrs:{"message":_vm.message.parent_message,"user":_vm.parentUser,"is-self":_vm.isSelf,"is-attachement":""},nativeOn:{"click":function($event){return _vm.$emit('click-reply', _vm.message.parent_message)}}}):_vm._e(),(!_vm.isDefaultFilesMessage)?_c('div',{staticClass:"flex gap-2 text-base px-1.5 py-1"},[(_vm.isTranslated && !_vm.showOriginal)?_c('fw-icon-robot',{staticClass:"w-5 h-5 text-primary flex-shrink-0"}):_vm._e(),_c('div',{staticClass:"break-words fix-links-overflow",class:{ 'text-primary': !_vm.showOriginal && !_vm.isSelf && _vm.isTranslated },domProps:{"innerHTML":_vm._s(_vm.isTranslated && !_vm.showOriginal ? _vm.message.translated : _vm.parseMessage(_vm.message.message))}})],1):_vm._e(),(_vm.multimediaFiles.length)?_c('div',{staticClass:"flex overflow-hidden rounded-lg bg-gray-900 flex-wrap justify-center my-1"},_vm._l((_vm.multimediaFiles),function(file,f){return _c('div',{key:file.key,staticClass:"relative flex items-center justify-center cursor-pointer hover:opacity-90",class:{
                'min-w-48 min-h-48 w-1/2 max-h-48': _vm.multimediaFiles.length > 1 && f != _vm.message.files.length - 1,
                'min-h-80 min-w-80 max-h-80 max-w-[20rem]': _vm.multimediaFiles.length === 1,
                'min-w-[24rem] min-h-48 max-h-48 w-full':
                  _vm.multimediaFiles.length > 1 && f == _vm.message.files.length - 1 && _vm.oddNumberOfMultimediaFiles
              },staticStyle:{"background-color":"rgba(255, 255, 255, 0.2)"},on:{"click":function($event){return _vm.$emit('preview-file', file)}}},[(file.type === 'image')?_c('img',{staticClass:"h-full w-full object-cover",attrs:{"src":_vm.getImageViewUrl(file)}}):_vm._e(),(file.type === 'video')?_c('fw-icon-play-circle',{staticClass:"h-8 w-8 absolute top-1/2 left-1/2 -ml-4 -mt-4"}):_vm._e()],1)}),0):_vm._e(),(_vm.otherFiles.length)?_c('div',{staticClass:"rounded-md py-0.5",class:{
              'mt-1': _vm.multimediaFiles.length == 0,
              'mt-2': _vm.multimediaFiles.length > 0
            }},[_c('ChatFiles',{attrs:{"message":_vm.message,"only-others":""},on:{"preview-file":function($event){return _vm.$emit('preview-file', $event)}}})],1):_vm._e(),(
              (_vm.message.recordings && _vm.message.recordings.length) ||
                (_vm.message.quiz && _vm.message.quiz.length) ||
                (_vm.message.bucketItems && _vm.message.bucketItems.length)
            )?_c('div',{staticClass:"px-5 pt-2 pb-5 bg-white bg-opacity-50 rounded-xl mb-1"},[(_vm.message.recordings && _vm.message.recordings.length)?_c('div',{staticClass:"group"},_vm._l((_vm.message.recordings),function(rec){return _c('div',{key:rec.key},[_c('div',{staticClass:"text-gray-500 opacity-40 flex items-center gap-1 text-xs"},[_c('fw-icon-attachment',{staticClass:"w-5 h-5 inline-flex"}),_c('fw-label',[_vm._v("CID mrec-"+_vm._s(rec.key))])],1),_c('video-player',{ref:'mrec-' + rec.key,refInFor:true,attrs:{"src":_vm.getFileViewUrl(rec.file),"poster":_vm.recordingsPosterURL(rec)}})],1)}),0):_vm._e(),(_vm.message.quiz && _vm.message.quiz.length)?_c('div',[_c('div',{staticClass:"text-gray-500 opacity-40 flex items-center gap-1 text-xs"},[_c('fw-icon-attachment',{staticClass:"w-5 h-5 inline-flex"})],1),_vm._l((_vm.message.quiz),function(quiz){return _c('div',{key:quiz.key},[_c('h1',[_vm._v(_vm._s(quiz.title))]),_c('div',[_c('b',[_vm._v("Users:")]),_vm._v(" "+_vm._s(quiz.users_count))]),_c('div',[_c('br'),_c('b',[_vm._v("Não respondeu:")]),_vm._v(" "+_vm._s(quiz.no_answer.count))]),_c('div',[_c('br'),_c('b',[_vm._v("Sem resposta:")]),_vm._v(" "+_vm._s(quiz.answers[0].none.count))]),_vm._l((quiz.answers[0].options),function(option,index){return _c('div',{key:index},[_c('br'),_c('b',[_vm._v(_vm._s(option.title)+":")]),_vm._v(" "+_vm._s(option.count)+" ")])})],2)})],2):_vm._e(),(_vm.message.bucketItems && _vm.message.bucketItems.length)?_c('div',{staticClass:"group"},_vm._l((_vm.message.bucketItems),function(item){return _c('div',{key:item.key},[_c('div',{staticClass:"text-gray-500 opacity-40 flex items-center gap-1 text-xs"},[_c('fw-icon-attachment',{staticClass:"w-5 h-5 inline-flex"}),_c('fw-label',[_vm._v("CID "+_vm._s(item.key))])],1),(item.metadata.file_type == 'video')?_c('video-player',{ref:'cid-' + item.key,refInFor:true,attrs:{"options":{
                    muted: true,
                    fluid: true,
                    responsive: true,
                    language: 'pt',
                    preload: 'none',
                    html5: {
                      nativeVideoTracks: true
                    },
                    controlsList: item.lock_download ? 'nodownload' : null,
                    poster: null,
                    sources: [
                      {
                        type: 'video/mp4',
                        src: _vm.getFileViewUrl(item.file)
                      }
                    ]
                  }}}):(_vm.allowDownloads)?_c('div',[_c('a',{attrs:{"target":"_blank","href":_vm.getFileViewUrl(item.file, true)}},[_vm._v("Download")])]):_vm._e()],1)}),0):_vm._e()]):_vm._e(),(
              !_vm.nextMessage ||
                //(timeDifferenceBetweenMessages() > 5 && isPreviousSelf) ||
                !_vm.isNextSelf ||
                (_vm.isNextSelf && _vm.timeDifferenceBetweenNextMessages() > 5)
            )?_c('div',{staticClass:"flex-1 text-xs opacity-60 px-1.5 mb-1.5",class:{
              'mt-2.5': _vm.multimediaFiles.length > 0 || _vm.otherFiles.length > 0,
              'mt-0.5': _vm.multimediaFiles.length == 0 && _vm.otherFiles.length == 0
            }},[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(_vm.message.created_date))+" ")]):_vm._e()],1)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }