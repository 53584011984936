<template>
  <div class="flex flex-1 flex-col gap-2 p-4">
    <fw-heading size="h3" muted>
      Temas
      <fw-badge inline type="simple">{{ topics.length }}</fw-badge>
    </fw-heading>

    <router-link :to="{ name: 'course-edition-unit', params: { unitKey: unit.key, view: 'introduction' } }">
      <div
        class="px-4 py-3 font-medium gap-3 my-1 relative w-full text-left group rounded-lg flex items-center"
        :class="{
          'text-primary bg-white shadow-md': view == 'introduction',
          'text-gray-600 bg-gray-300 bg-opacity-20 shadow-sm hover:text-gray-800': view != 'introduction'
        }"
      >
        <fw-icon-book-mark-solid class="w-5 h-5 flex-shrink-0" />
        Apresentação do módulo
      </div>
    </router-link>

    <router-link :to="{ name: 'course-edition-unit-posts', params: { unitKey: unit.key, view: 'posts' } }">
      <div
        class="px-4 py-3 flex font-medium gap-3 my-1 relative w-full text-left group rounded-lg items-center"
        :class="{
          'text-primary bg-white shadow-md': view == 'posts',
          'text-gray-600 bg-gray-300 bg-opacity-20 shadow-sm hover:text-gray-800': view != 'posts'
        }"
      >
        <fw-icon-discuss class="w-5 h-5 flex-shrink-0" />
        Tópicos de discussão
      </div>
    </router-link>

    <RecycleScroller
      v-if="topics && topics.length > 0"
      v-slot="{ item, index }"
      :items="topics"
      :item-size="57"
      :buffer="25"
      key-field="key"
    >
      <router-link
        class="flex p-2 gap-3 my-1 relative w-full text-left group rounded-lg"
        :class="{
          'text-primary bg-white shadow-md': activeTopicKey == item.key,
          'text-gray-600 bg-gray-300 bg-opacity-20 shadow-sm hover:text-gray-800': activeTopicKey != item.key
        }"
        :to="{ name: 'course-edition-unit-topic', params: { unitKey: unit.key, topicKey: item.key } }"
      >
        <div
          class="font-bold text-sm p-1 w-5"
          :class="{
            'text-primary': checkTopicAvailable(item),
            'text-gray-500': !checkTopicAvailable(item)
          }"
        >
          <span v-if="item.type == 'normal'">{{ item.index + 1 }}</span>
          <fw-icon-survey v-else class="w-4 h-4" />
        </div>
        <div class="flex-1">
          <v-clamp class="font-medium" autoresize :max-lines="1">{{ item.title }} </v-clamp>
          <div v-if="item.type == 'normal'" class="text-xs opacity-80">
            <div v-if="checkTopicAvailable(item)" class="font-medium">Disponível</div>
            <div v-else-if="item.session?.time_to_start > 0" class="font-medium">
              Disponível a {{ item.session.start_date | formatDateTime }}
            </div>
            <div v-else-if="checkTopicExpired(item) && item.seen_date" class="font-medium">
              Terminado
            </div>
            <div v-else-if="checkTopicBlocked(item)" class="font-medium">Bloqueado</div>
            <div v-else>Indisponível</div>
          </div>
          <div v-else class="text-xs opacity-80">
            <div class="font-medium">Disponível a {{ item.session.start_date | formatDateTime }}.</div>
          </div>
        </div>
        <div class="relative">
          <fw-icon-check v-if="item.seen_date" class="w-5 h-5 text-primary" />
          <fw-dot v-else-if="checkTopicAvailable(item)" />
        </div>
      </router-link>
    </RecycleScroller>
    <div v-else class="flex text-center justify-center items-center flex-col h-full text-sm text-gray-400 py-10">
      Sem temas para apresentar.
    </div>

    <fw-panel-info debug label="Data (raw)">
      <json-viewer :value="topics"></json-viewer>
    </fw-panel-info>
  </div>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
export default {
  components: {
    RecycleScroller
  },

  props: {
    course: Object,
    edition: Object,
    checks: Object,
    unit: Object,
    isManager: Boolean
  },

  computed: {
    courseKey() {
      return this.$route.params.courseKey
    },
    activeTopicKey() {
      return this.$route.params.topicKey
    },
    now() {
      return this.$store.state.now
    },
    isMobile() {
      return window.innerWidth < 640
    },
    topics() {
      return this.unit.topics
    },
    view() {
      return this.$route.meta.view
    },
    nextAvailableTopic() {
      return this.topics?.find(t => !t.seen_date) ?? {}
    }
  },

  mounted() {
    console.log('mounted :>> ', this.unit)
  },

  methods: {
    //Check if the topic is blocked because the previous topics were not completed
    checkTopicBlocked(topic) {
      if (!this.checkTopicHasSession(topic)) return false
      if (this.isManager) return false

      const sessionStart = Dates.build(topic.session.start_date)
      let inDateRange = this.now >= sessionStart.valueOf()
      if (inDateRange && topic.session.end_date != null) {
        const sessionEnd = Dates.build(topic.session.end_date)
        inDateRange = this.now >= sessionStart.valueOf() && this.now < sessionEnd.valueOf()
      }
      // console.log('checkTopicAvailable', sessionStart.valueOf(), sessionEnd.valueOf(), this.now)
      let isBlocked = !topic.seen_date && !this.isNextTopic(topic)
      return inDateRange && isBlocked
    },
    checkTopicExpired(topic) {
      if (!this.checkTopicHasSession(topic)) return false
      //allow consumption of the topic if its content its available after ending date
      if (this.edition.options && this.edition.options.access_course_content == 'always') return true
      if (topic.session.end_date) {
        const sessionEnd = Dates.build(topic.session.end_date)
        return sessionEnd.valueOf() < this.now && topic.session.can_view === false
      } else {
        return false
      }
    },
    checkTopicAvailableSoon(topic) {
      if (!this.checkTopicHasSession(topic)) return false
      return topic.session.time_to_start > 0
    },
    isNextTopic(topic) {
      //if free consumption, we override this saying true
      if (this.edition && this.edition.options && this.edition.options.topics_consume_mode == 'free') return true
      return this.nextAvailableTopic.key == topic.key
    },
    checkTopicHasSession(topic) {
      return topic && topic.session && topic.session.start_date
    },
    checkTopicAvailable(topic) {
      if (!this.checkTopicHasSession(topic)) return false
      if (this.isManager && topic.type === 'normal') return true

      const sessionStart = Dates.build(topic.session.start_date)
      let isInDateRange = this.now >= sessionStart.valueOf()
      if (isInDateRange && topic.session.end_date != null) {
        const sessionEnd = Dates.build(topic.session.end_date)
        isInDateRange = this.now >= sessionStart.valueOf() && this.now < sessionEnd.valueOf()
      }
      let isAvailable = topic.type == 'evaluation' || topic.seen_date || this.isNextTopic(topic)
      return isInDateRange && isAvailable
    }
  }
}
</script>

<style lang="scss">
.content.fixed-content p:not(:last-child) {
  margin-bottom: 1rem;
}
</style>
