<template>
  <SidebarBase v-if="unit">
    <template #main>
      <div>
        <fw-heading size="h4" class="mb-1">{{ unit.title }}</fw-heading>
        <div class="text-sm">{{ unit.organic_unit }}</div>
        <div class="text-sm">{{ unit.organic_unit_short }}</div>
        <!-- <div class="text-sm">{{ unit.profile }}</div>
        <div class="text-sm">{{ unit.academic_year }}</div> -->
      </div>
      <div class="flex-1 mt-5">
        <!--<ButtonSidebar to="/units" :exact="true" label="Voltar">
          <template #svg>
            <button
              class="relative -ml-1 flex items-center opacity-40 group hover:opacity-100 text-sm px-2 py-2 bg-gray-200 bg-opacity-80 text-gray-500 font-bold flex items-center justify-center p-2 text-sm rounded-lg focus:outline-none "
            >
              <span class="sr-only">Voltar à página anterior</span
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="fill-current w-4 h-4"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path
                  d="M5.828 7l2.536 2.536L6.95 10.95 2 6l4.95-4.95 1.414 1.414L5.828 5H13a8 8 0 1 1 0 16H4v-2h9a6 6 0 1 0 0-12H5.828z"
                ></path>
              </svg>
            </button>
          </template>
        </ButtonSidebar>-->
        <ButtonSidebar :active="view == 'classes'" label="Turmas" @click.native="$emit('go-to-view', 'classes')">
          <template #svg>
            <fw-icon-people class="w-6 h-6" />
          </template>
        </ButtonSidebar>
        <ButtonSidebar :active="view == 'buckets'" label="Materiais" @click.native="$emit('go-to-view', 'buckets')">
          <template #svg>
            <fw-icon-box-open class="w-6 h-6" />
          </template>
        </ButtonSidebar>
      </div>
    </template>
  </SidebarBase>
</template>

<script>
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'

export default {
  components: {
    ButtonSidebar,
    SidebarBase
  },

  props: {
    unit: {
      type: Object
    },
    view: {
      type: String,
      default: 'classes'
    },
    profile: {
      type: String,
      default: 'teacher'
    },
    teachers: {
      type: Array
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },
    isTeacher() {
      return Boolean(this.user && this.user.key && this.user.roles.includes('teacher'))
    },
    isStudent() {
      return Boolean(this.user && this.user.key && this.user.roles.includes('student'))
    }
  },

  methods: {
    toClipboardMeetingId() {
      this.$copyText(`${this.unit.meeting.key_alias}`).then(() => {
        this.$buefy.toast.open({
          duration: 3000,
          message: `ID copiado para a área de transferência.`,
          position: 'is-top'
        })
      })
    }
  }
}
</script>

<style>
/*a span:last-child {
  @apply text-black;
}*/
</style>
