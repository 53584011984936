<template>
  <fw-modal v-if="showModal" :active.sync="showModal" :can-cancel="true" size="md" @close="showModal = false">
    <template #default>
      <div class="text-center flex flex-col gap-3">
        <fw-heading size="h3">{{ $t('endorse') }}</fw-heading>
        <div class="relative mx-auto pt-5 pb-2">
          <UserAvatar :user="user" size="lg" class="flex-shrink-0" />
          <div class="absolute top-1 -right-0.5">
            <div class="relative h-10 w-6">
              <div
                class="bg-gray-100 rounded absolute"
                style="top: 19.5px; left: 0.5px; height: 15px; width: 14px;"
              ></div>
              <div class="bg-gray-100 shadow-md rounded-full py-1.5 px-3 font-semibold absolute top-2 -right-5">
                <fw-icon-thumbs-up class="h-5 w-5 text-primary"></fw-icon-thumbs-up>
              </div>
            </div>
          </div>
        </div>
        <fw-label class="mx-auto -mb-2 opacity-50">{{ $t('competence') }}</fw-label>
        <div
          v-if="competence && competence.name"
          class=" rounded-full mx-auto flex px-4 py-1.5 text-white font-semibold bg-primary gap-3 items-center"
        >
          {{ competence.name[language] }}
        </div>
        <fw-message class="mt-3 text-sm"
          >Ao endorsar uma competência está a demonstrar publicamente que <b>{{ user.name }}</b> detém a
          mesma.</fw-message
        >
      </div>
    </template>
    <template #footer>
      <div class="flex gap-3">
        <fw-button class="flex-1" type="white" expanded @click.native="reset()">{{ $t('cancel') }}</fw-button>
        <fw-button class="flex-1" type="black" expanded @click.native="confirm()">{{ $t('confirm') }}</fw-button>
      </div>
    </template>
  </fw-modal>
</template>

<script>
import UserAvatar from '@/fw-modules/fw-core-vue/id/components/user/UserAvatar'
export default {
  name: 'ModalEndorsement',
  components: {
    UserAvatar,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      competence: {
        name: {
          pt: '',
          en: '',
        },
      },
      showModal: false,
    }
  },
  computed: {
    me() {
      return this.$store.state.session.user
    },
    language() {
      return this.$store.state.language
    },
  },
  methods: {
    endorse(data) {
      if (data) {
        this.competence = JSON.parse(JSON.stringify(data))
      }
      this.showModal = true
    },
    confirm() {
      this.$emit('confirm', this.competence)
      this.reset()
    },
    reset() {
      this.newcompetence = {
        pt: '',
        en: '',
      }
      this.showModal = false
      this.editing = false
    },
  },
}
</script>

<i18n>
    {
      "pt": {
        "endorse": "Endossar competência",
        "cancel": "Cancelar",
        "confirm": "Confirmar",
        "competence": "Competência"
      },
      "en": {
        "endorse": "Endorse competence",
        "cancel": "Cancel",
        "confirm": "Confirm",
        "competence": "Competence"
      }
    }
    </i18n>
