const pt = {
  app: {
    description: 'Procedimentos Concursais na Universidade de Coimbra',
    client: 'University of Coimbra',
  },
  message: {
    hello: 'Olá mundo',
    internalErrorFound: 'Ocorreu um erro interno...',
    login: {
      loginFailed: 'Email ou password incorreta. Tente novamente ou clique em recuperar a password.',
    },
    form: {
      errorsFound: 'O formulário contém erros.',
      required: 'Obrigatório',
      emailRequired: 'Indique um endereço de email válido.',
      passwordRequired: 'Introduza a password.',
    },
  },
  error: {
    notfound: {
      title: 'Não encontrado',
      description: 'O recurso solicitado não foi encontrado.',
    },
    auth: {
      loginFailed: 'O seu email ou password não estão corretos.',
    },
  },
  title: {
    login: 'Autenticação',
    welcome: 'Bem-vindo',
    account: 'Perfil',
    notifications: 'Notificações',
    balance: 'Saldo e movimentos de conta',
    invoices: 'Faturas',
    mb: 'Referências MB para carregamento',
    credentials: 'Credenciais',
    transfer: 'Transferência de crédito',
    refund: 'Devolução',
    features: 'Funcionalidades',
    recoverPassword: 'Recuperar conta',
  },
  summary: {
    recoverPassword: 'Enter your email address',
    login: 'Plataforma de gestão de procedimentos concursais da Universidade de Coimbra.',
  },
  link: {
    home: 'Início',
    next: 'Continuar',
    login: 'Entrar',
    registerAccount: 'Registar conta',
    recoverPassword: 'Esqueceu-se da password?',
    balance: 'Balanço',
    credentials: '@:title.credentials',
    transfer: '@:title.transfer',
    invoices: '@:title.invoices',
    mb: 'MB',
    refund: '@:title.refund',
    anotherAccount: 'Outra conta?',
  },
}

export default pt
