<template>
  <div
    v-if="unitEditions.length && view === 'card' && !loading"
    class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3 mb-10 py-2 xl:max-w-screen-xl mx-auto"
  >
    <CardUnit
      v-for="unitEdition in unitEditions"
      :key="unitEdition.key"
      :unit="unitEdition"
      :show-semester="showSemester"
      :view="'card'"
      for="teacher"
      @click.native="$router.push({ name: 'unit', params: { key: unitEdition.key } })"
    />
  </div>
  <div
    v-else-if="unitEditions.length && view !== 'card' && !loading && !groupUnits"
    class="flex flex-col gap-1.5 mb-10 py-2 xl:max-w-screen-xl mx-auto"
  >
    <CardUnit
      v-for="unitEdition in unitEditions"
      :key="unitEdition.key"
      :unit="unitEdition"
      :show-semester="showSemester"
      :view="view"
      for="teacher"
      @click.native="$router.push({ name: 'unit', params: { key: unitEdition.key } })"
    />
  </div>
  <div
    v-else-if="unitEditions.length && view !== 'card' && !loading && groupUnits"
    class="flex flex-col gap-1.5 mb-10 py-2 xl:max-w-screen-xl mx-auto"
  >
    <div v-for="(unitGroup, u) in groupedUnits" :key="'unit_' + u">
      <div class="opacity-70 mb-0.5" :class="{ 'mt-3': u > 0 }">
        <h2 class="lg:text-md font-bold text-black">
          {{ unitGroup.title }}
        </h2>
        <div class="flex">
          <fw-label v-for="(course, c) in unitGroup.courses" :key="'course_' + u + '_' + c">
            <div v-if="c > 0" class="bg-gray-300 h-1.5 w-1.5 rounded-full mx-1 mb-0.5 inline-block"></div>
            {{ course }}
          </fw-label>
        </div>
      </div>
      <div class="flex flex-col gap-1.5">
        <CardUnit
          v-for="unitEdition in unitGroup.profiles"
          :key="unitEdition.key"
          :unit="unitEdition"
          :show-semester="showSemester"
          :only-profile="true"
          :view="view"
          for="teacher"
          @click.native="$router.push({ name: 'unit', params: { key: unitEdition.key } })"
        />
      </div>
    </div>
  </div>
  <div
    v-else-if="!loading"
    class="border border-gray-300 flex flex-col text-center items-center justify-center text-gray-500 p-10 rounded-xl xl:max-w-screen-xl mx-auto"
  >
    <div>Ainda não tem disciplinas associadas neste ano letivo.</div>
  </div>
  <LoadingPlaceholder
    v-else
    class="xl:max-w-screen-xl mx-auto"
    :list="view === 'row' || view === 'row_expanded'"
  ></LoadingPlaceholder>
</template>

<script>
import CardUnit from '@/fw-modules/fw-core-vue/academic/components/cards/CardUnit'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'

export default {
  components: {
    CardUnit,
    LoadingPlaceholder
  },

  props: {
    unitEditions: Array,
    view: {
      type: String,
      default: 'card'
    },
    loading: {
      type: Boolean,
      default: false
    },
    showSemester: {
      type: Boolean,
      default: false
    },
    groupUnits: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      groupedUnits: []
    }
  },
  created() {
    //console.log('created', this.groupUnits, this.unitEditions.length)
    this.processUnitGrouping()
  },
  methods: {
    processUnitGrouping() {
      this.groupedUnits = []
      let units = JSON.parse(JSON.stringify(this.unitEditions))
      console.log('processUnitGrouping', units)
      if (this.groupUnits && units.length > 0) {
        let results = []
        for (let i = 0; i < units.length; i++) {
          let unitData = units[i]
          if (unitData.processed !== true) {
            let newData = {
              title: unitData.title,
              regime: unitData.regime,
              regime_occurrence: unitData.regime_occurrence,
              courses: unitData.courses.map(c => c.title),
              //unit_buckets: [],
              //classes: unitData.classes,
              profiles: [
                {
                  profile: unitData.profile,
                  key: unitData.key,
                  stats: unitData.stats
                }
              ]
            }

            if (unitData.unit_key_for_bucket && unitData.unit_key_for_bucket !== null) {
              //verify if we have other related units
              for (let p = i + 1; p < units.length; p++) {
                if (unitData.unit_key_for_bucket === units[p].unit_key_for_bucket) {
                  //found related unit
                  let relatedUnitData = units[p]
                  //add profile of related units
                  newData.profiles = newData.profiles.concat({
                    profile: relatedUnitData.profile,
                    key: relatedUnitData.key,
                    stats: relatedUnitData.stats
                  })

                  // Add courses to unit profile
                  for (let j = 0; j < relatedUnitData.courses.length; j++) {
                    let courseData = relatedUnitData.courses[j]
                    if (!newData.courses.includes(courseData.title)) {
                      newData.courses.push(courseData.title)
                    }
                  }

                  //mark as processed
                  units[p]['processed'] = true
                }
              }
            }

            newData.profiles.sort(function(a, b) {
              return a['profile'].localeCompare(b['profile'])
            })

            //add data to results
            results.push(newData)
          }
        }
        this.groupedUnits = results
      }
    }
  }
}
</script>
