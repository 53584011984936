<template>
  <div class="items">
    <template v-if="items.length">
      <EditorBlockItem
        v-for="(item, index) in items"
        :key="index"
        :item="item"
        :is-active="index === selectedIndex"
        :language="language"
        @click.native="selectItem(index)"
      />
    </template>
    <div v-else class="no_results">
      {{ labels[language].no_results }}
    </div>
  </div>
</template>

<script>
import EditorBlockItem from './EditorBlockItem.vue'
export default {
  components: {
    EditorBlockItem
  },
  props: {
    items: {
      type: Array,
      required: true
    },

    command: {
      type: Function,
      required: true
    },
    editor: {
      type: Object
    }
  },

  data() {
    return {
      selectedIndex: 0,
      labels: {
        en: {
          no_results: 'No results'
        },
        pt: {
          no_results: 'Sem resultados'
        }
      }
    }
  },

  computed: {
    language() {
      return this.editor?.options?.language
    }
  },

  watch: {
    items() {
      this.selectedIndex = 0
    }
  },

  methods: {
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        this.downHandler()
        return true
      }

      if (event.key === 'Enter') {
        this.enterHandler()
        return true
      }

      return false
    },

    upHandler() {
      this.selectedIndex = (this.selectedIndex + this.items.length - 1) % this.items.length
      this.$nextTick(() => {
        this.scrollIntoView()
      })
    },

    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length
      this.$nextTick(() => {
        this.scrollIntoView()
      })
    },

    enterHandler() {
      this.selectItem(this.selectedIndex)
    },

    scrollIntoView() {
      const el = this.$el.querySelector('.item-active')
      if (el) {
        el.scrollIntoView({ block: 'nearest' })
      }
    },

    selectItem(index) {
      //console.log('click! selectItem', index)
      const item = this.items[index]

      if (item) {
        this.command({ type: item.type, attrs: item.attrs ? item.attrs : null })
      }
    }
  }
}
</script>

<style lang="scss">
.items {
  @apply w-56 overflow-y-auto max-h-52 text-sm shadow-md border border-gray-100 overflow-x-hidden relative text-gray-800 bg-white p-1 rounded-md;
}

.item {
  @apply border-transparent;
}
.item.item-active {
  @apply border-teal-500;
}
.no_results {
  @apply text-gray-500 text-center py-2;
}
</style>

<i18n>
{
  "en": {
    "no_results": "No results"
  },
  "pt": {
    "no_results": "Sem resultados"
  }
}
</i18n>
