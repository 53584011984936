export default {
  scrollIsAtBottom() {
    const elChat = document.getElementById('chat-messages')
    return elChat && elChat.scrollHeight - elChat.scrollTop - 50 <= elChat.clientHeight
  },
  scrollToBottom(ms, callback) {
    setTimeout(() => {
      const elChat = document.getElementById('chat-messages')
      if (elChat) elChat.scrollTop = elChat.scrollHeight + 20
      if (callback) callback()
    }, ms || 500)
  },
  announcementDaysLeft(announcement) {
    if (!announcement || announcement.expiry_date == null) return 0
    const now = new Date()
    //parse date from string
    const date = announcement.expiry_date.split('T')[0].split('-')
    const expiryDate = new Date(date[0], date[1] - 1, date[2])
    const daysLeft = Math.ceil((expiryDate - now) / (1000 * 60 * 60 * 24))
    return daysLeft == 0 ? 1 : daysLeft
  },
  timeDifferenceBetweenMessages(message1, message2) {
    if (message1 && message2) {
      //difference in minutes
      const time1 = new Date(message1.created_date)
      const time2 = new Date(message2.created_date)
      const difference = Math.abs(time1 - time2) / 60000
      return difference
    } else {
      return 0
    }
  }
}
