import DraggableBlock from '../editor/DraggableBlock.vue'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import { Image } from '@tiptap/extension-image'
const customImage = Image.extend({
  draggable: true,
  addNodeView() {
    return VueNodeViewRenderer(DraggableBlock)
  }
})
export default customImage
