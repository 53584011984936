import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  removeFromArray(items, removeIdx) {
    if (removeIdx.length) {
      for (let i = removeIdx.length - 1; i >= 0; i--) {
        items.splice(removeIdx[i], 1)
      }
      removeIdx.splice(0, removeIdx.length)
    }
  },
  getSessionIsActiveAfter() {
    // !WARN! This should match backend CLASS_MEETING_START_BEFORE_MINUTES
    return Dates.now().add(60, 'minute')
  },
  getSessionIgnoreAfter() {
    return Dates.now().subtract(30, 'minute')
  },
  buildSessionDates(sessions, activeSessions, todaySessions, weekSessions, nextWeekSessions) {
    const isActiveAfter = this.getSessionIsActiveAfter()
    const ignoreAfter = this.getSessionIgnoreAfter()
    const today = Dates.now().format('DD-MM-YYYY')
    const nextWeekStart = Dates.getCurrentWeekStartDay().add(7, 'days')

    for (let session of sessions) {
      if (session.start_date_obj <= isActiveAfter) {
        if (session.end_date_obj >= ignoreAfter) {
          activeSessions.push(session)
        }
      } else if (session.start_date_obj.format('DD-MM-YYYY') == today) {
        todaySessions.push(session)
      } else if (session.start_date_obj < nextWeekStart) {
        weekSessions.push(session)
      } else {
        nextWeekSessions.push(session)
      }
    }
  },
  checkSessionDates(activeSessions, todaySessions, weekSessions, nextWeekSessions) {
    const removeIdx = []
    if (activeSessions.length) {
      const ignoreAfter = this.getSessionIgnoreAfter()
      for (let idx = 0; idx < activeSessions.length; idx++) {
        const session = activeSessions[idx]
        if (session.end_date_obj < ignoreAfter) removeIdx.push(idx)
      }
      this.removeFromArray(activeSessions, removeIdx)
    }

    if (todaySessions.length) {
      const isActiveAfter = this.getSessionIsActiveAfter()
      for (let idx = 0; idx < todaySessions.length; idx++) {
        const session = todaySessions[idx]
        if (session.start_date_obj <= isActiveAfter) {
          activeSessions.push(session)
          removeIdx.push(idx)
        }
      }
      this.removeFromArray(todaySessions, removeIdx)
    }

    if (weekSessions.length) {
      const today = Dates.now().format('DD-MM-YYYY')
      for (let idx = 0; idx < weekSessions.length; idx++) {
        const session = weekSessions[idx]
        if (session.start_date_obj.format('DD-MM-YYYY') == today) {
          todaySessions.push(session)
          removeIdx.push(idx)
        }
      }
      this.removeFromArray(weekSessions, removeIdx)
    }

    if (nextWeekSessions.length) {
      const nextWeekStart = Dates.getCurrentWeekStartDay().add(7, 'days')
      for (let idx = 0; idx < nextWeekSessions.length; idx++) {
        const session = nextWeekSessions[idx]
        if (session.start_date_obj < nextWeekStart) {
          weekSessions.push(session)
          removeIdx.push(idx)
        }
      }
      this.removeFromArray(nextWeekSessions, removeIdx)
    }
  }
}
