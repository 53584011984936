<template>
  <div
    :class="{ 'cursor-not-allowed opacity-70': disabled, 'cursor-pointer': !disabled }"
    class="rounded-md border border-gray-100 pl-3 pr-1 py-1.5 flex flex-col gap-4 font-semibold text-sm "
  >
    <div class="flex gap-4 items-center">
      <div
        :class="{ 'bg-primary': checked, 'bg-gray-200': !checked }"
        class="rounded-md   h-6 w-6 flex items-center justify-center"
      >
        <fw-icon-check v-if="checked" class="h-5 text-white"></fw-icon-check>
      </div>
      <slot></slot>
    </div>
    <slot name="bottom"></slot>
  </div>
</template>
<script>
export default {
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
