<template>
  <div>
    <DashboardHero
      v-if="showBanner"
      :title="course.title"
      :background-image-url="editionUrlBanner"
      size="xs"
      class="mt-2 mb-5"
    >
      <template #default>
        {{ edition.title }}
      </template>
      <template #footer>
        <div class="flex gap-5 text-white items-center justify-center">
          <div v-if="edition.working_mode" class="flex gap-2.5 text-white font-semibold items-center">
            <fw-icon-course-mode class="h-5 hidden md:block"></fw-icon-course-mode>
            {{ edition.working_mode }}
          </div>
          <div v-if="edition.ects" class="flex gap-2.5 text-white font-semibold items-center">
            <fw-icon-course-ects class="h-5 hidden md:block"></fw-icon-course-ects>
            {{ edition.ects }}
          </div>
          <div v-if="edition.price && edition.price > 0" class="flex gap-2.5 text-white font-semibold items-center">
            <fw-icon-course-price class="h-5 hidden md:block"></fw-icon-course-price>
            {{ edition.price }}€
          </div>
          <div v-if="edition.duration" class="flex gap-2.5 text-white font-semibold items-center">
            <fw-icon-course-time class="h-4 hidden md:block"></fw-icon-course-time>
            {{ edition.duration }} {{ $t('hours') }}
          </div>
        </div>
      </template>
    </DashboardHero>
    <fw-heading size="xl">Sobre o espaço</fw-heading>
    <div v-if="course.edition.youtube_video_id || course.video" class="mt-4 mb-2 bg-black rounded-md overflow-hidden">
      <div class="max-w-7xl mx-auto">
        <div v-if="course.edition.youtube_video_id" class="aspect-w-16 aspect-h-9 w-full">
          <iframe
            :src="'https://www.youtube-nocookie.com/embed/' + course.edition.youtube_video_id"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            class="w-full"
          ></iframe>
        </div>
        <div v-else-if="course.edition.video" class="overflow-hidden image_direct">
          <video controls class="w-full">
            <source :src="course.edition.video" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
    <fw-panel v-if="units && units.length" title="Módulos" class="my-7">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-5">
        <div
          v-for="unit in units"
          :key="unit.index"
          class="w-full group h-full bg-gray-200 flex-col flex p-5 rounded-xl"
          @click="$emit('go-to-unit', unit.key)"
        >
          <div class="opacity-50 group-hover:opacity-100">
            <fw-icon-book-mark-solid class="w-5 h-5" />
          </div>
          <div class="hidden mb-5 font-bold text-xl">M{{ unit.index }}</div>
          <div class="flex-1 flex flex-col mt-3">
            <div class="flex-1">
              <v-clamp class="w-full flex-1 text-left text-lg items-end flex font-semibold" autoresize :max-lines="3">
                {{ unit.title }}
              </v-clamp>
            </div>
            <div v-if="typeof unit.topics == 'number'" class="w-full text-left mt-2 opacity-50 font-semibold">
              {{ unit.topics }} tema{{ unit.topics == 1 ? '' : 's' }}
            </div>
          </div>
        </div>
      </div>
    </fw-panel>
    <fw-panel
      v-for="meta in metadataFields"
      :key="meta"
      :title="$t('title.metadata.' + meta)"
      boxed="sm"
      custom-class="bg-gray-100 text-gray-800"
      class="my-5"
    >
      <div v-if="meta == 'files'">
        <template v-if="edition?.[meta]?.length > 0">
          <RecordFileEntry
            v-for="file in edition[meta]"
            :key="file.key"
            :file="file"
            :can-edit="false"
            :can-remove="false"
            :open-preview-modal="true"
            @download="downloadFile(file)"
          />
        </template>
        <div v-else class="text-gray-500">Sem conteúdo definido.</div>
      </div>
      <div
        v-else-if="edition[meta] && edition[meta] != null"
        class="content fixed-content"
        v-html="edition[meta] + (meta == 'duration' ? ' horas' : '')"
      ></div>
      <div v-else class="content fixed-content">
        <div class="text-gray-500">Sem conteúdo definido.</div>
      </div>
    </fw-panel>
  </div>
</template>

<script>
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import DashboardHero from '@/fw-modules/fw-core-vue/ui/components/dashboard/DashboardHero'
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'
export default {
  name: 'PanelAbout',
  components: {
    DashboardHero,
    RecordFileEntry
  },
  props: {
    course: Object,
    edition: Object,
    units: Array,
    editable: {
      type: Boolean,
      default: false
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    settings: {
      type: Boolean,
      default: false
    },
    showBanner: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      metadataFields: [
        'introduction',
        'duration',
        'ects',
        'summary',
        'goals',
        'professional_goals',
        'prerequisites',
        'methodology',
        'scientific_area',
        'certification',
        'evaluation',
        'bibliography',
        'observations',
        'promoters',
        'files'
      ]
    }
  },

  computed: {
    // TODO: Do not forget to remove this, Valentim
    isExceptionCourse() {
      return (process.env.VUE_APP_ENV == 'development' && this.course.key === 'oowvtc') || this.course.key === '4slkfk'
    },
    editionUrlBanner() {
      return this.edition.banner ? this.getImageViewUrl(this.edition.banner, 'max800') : ''
    }
  },
  methods: {
    async downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },
    getImageViewUrl(file, size) {
      return ServiceStorage.getImageViewUrl(file, size)
    },
    saveCourseMetadata() {
      this.$emit('save-metadata', { duration: this.course.duration, mode: this.course.mode, title: this.course.title })
    },
    saveMetadata(field, value) {
      this.$emit('save', { key: field, value: value })
    }
  }
}
</script>

<style lang="scss">
.content.fixed-content p:not(:last-child) {
  margin-bottom: 1rem;
}
</style>

<i18n>
{
  "en": {
    "title": {
      "metadata": {
        "introduction": "Introdução",
        "prerequisites": "Pré-Requisitos",
        "goals": "Objetivos do espaço",
        "professional_goals": "Competências a desenvolver / Objetivos Profissionais",
        "certification": "Certificação",
        "evaluation": "Avaliação",
        "observations": "Observações",
        "bibliography": "Bibliografia",
        "methodology": "Metodologia",
        "scientific_area": "Área Científica",
        "promoters": "Promotores",
        "summary": "Sumário",
        "files": "Ficheiros",
        "duration": "Duração do espaço",
        "ects": "ECTS"
      }
    },
    "save": "Save",
    "hours": "hours",
    "cancel": "Cancel",
    "edit": "Edit",
    "save_metadata": "Guardar metadados"
  },
  "pt": {
    "title": {
      "metadata": {
        "introduction": "Introdução",
        "prerequisites": "Pré-Requisitos",
        "goals": "Objetivos do espaço",
        "professional_goals": "Competências a desenvolver / Objetivos Profissionais",
        "certification": "Certificação",
        "evaluation": "Avaliação",
        "observations": "Observações",
        "bibliography": "Bibliografia",
        "methodology": "Metodologia",
        "scientific_area": "Scientific Area",
        "promoters": "Promotores",
        "summary": "Sumário",
        "files": "Ficheiros",
        "duration": "Duração do espaço",
        "ects": "ECTS"
      }
    },
    "save": "Guardar",
    "hours": "horas",
    "cancel": "Cancelar",
    "edit": "Editar",
    "save_metadata": "Guardar metadados"
  }
}
</i18n>
