var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white/60 border cursor-default select-none pr-2.5 border-black/20 rounded-md flex relative h-12 overflow-hidden pl-3 gap-2 items-start",class:{ 'border-b': _vm.isAttachement, 'border-b-2': !_vm.isAttachement }},[_c('div',{staticClass:"absolute left-0 top-0 bottom-0 w-1",class:{
      'bg-primary': !_vm.isSelf,
      'bg-white': _vm.isSelf
    }}),_c('UserAvatar',{staticClass:"flex-shrink-0 mt-1.5",attrs:{"user":_vm.user,"size":"xs"}}),_c('div',{staticClass:"py-1.5 pr-0.5 flex-1 flex flex-col items-start justify-center"},[_c('div',{staticClass:"text-sm font-semibold text-black leading-4"},[_vm._v(_vm._s(_vm.user.name))]),_c('div',{staticClass:"flex items-center gap-2"},[(_vm.message.message && _vm.message.message.length && !_vm.isDefaultFilesMessage && !_vm.isEmptyMessage)?_c('div',{staticClass:"overflow-hidden text-sm font-medium leading-4 line-clamp-1 flex-1",class:{
          'text-gray-500': !_vm.isSelf,
          'text-black/50': _vm.isSelf
        },domProps:{"innerHTML":_vm._s(_vm.message.message)}}):_vm._e(),(_vm.message.files.length)?_c('div',{staticClass:"flex gap-0.5 items-center text-xs",class:{
          'text-gray-500': !_vm.isSelf,
          'text-black/50': _vm.isSelf
        }},[_c('fw-icon-attachment',{staticClass:"h-3.5 w-3.5"}),_vm._v(" "+_vm._s(_vm.message.files.length)+" ")],1):_vm._e()])]),_c('div',{staticClass:"hidden absolute bg-gradient-to-t from-white to-transparent pointer-events-none left-12 right-0 bottom-0 h-4"}),(_vm.showClose)?_c('fw-button',{staticClass:"mt-2",attrs:{"type":"xlight","icon":"close","size":"xs"},nativeOn:{"click":function($event){return _vm.$emit('close')}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }