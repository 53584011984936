import Vue from 'vue'
import Vuex from 'vuex'

import messages from '@/i18n'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

import CoreStoreDefaults from '@/fw-modules/fw-core-vue/store/defaults'

Vue.use(Vuex)

const state = Object.assign(CoreStoreDefaults.state)
state.locales = messages['pt']

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
})
