import { render, staticRenderFns } from "./FwPanelInfo.vue?vue&type=template&id=60baad1a&scoped=true"
import script from "./FwPanelInfo.vue?vue&type=script&lang=js"
export * from "./FwPanelInfo.vue?vue&type=script&lang=js"
import style0 from "./FwPanelInfo.vue?vue&type=style&index=0&id=60baad1a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60baad1a",
  null
  
)

export default component.exports