<template>
  <div v-if="withJanus" class="flex items-center">
    <span v-if="!attendee.withRole('camera_allowed')" class="text-red-500">
      <svg
        class="fill-current w-5 h-5 md:w-6 md:h-6"
        aria-label="Vídeo não autorizado"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          d="M19.586 21H3a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h.586L1.393 2.808l1.415-1.415 19.799 19.8-1.415 1.414L19.586 21zm-14-14H4v12h13.586l-2.18-2.18A5.5 5.5 0 0 1 7.68 9.094L5.586 7zm3.524 3.525a3.5 3.5 0 0 0 4.865 4.865L9.11 10.525zM22 17.785l-2-2V7h-3.828l-2-2H9.828l-.307.307-1.414-1.414L9 3h6l2 2h4a1 1 0 0 1 1 1v11.786zM11.263 7.05a5.5 5.5 0 0 1 6.188 6.188l-2.338-2.338a3.515 3.515 0 0 0-1.512-1.512l-2.338-2.338z"
        />
      </svg>
    </span>
    <fw-button
      v-else
      v-shortkey="['meta', 'shift', 'v']"
      type="transparent"
      class="relative flex items-center justify-between"
      :class="{ 'text-primary': camera.active }"
      :aria-label="camera.active ? 'Desligar o vídeo' : 'Ligar o vídeo'"
      @shortkey="camera.toggle()"
      @click.native="camera.toggle()"
    >
      <fw-icon-loading v-if="camera.loading" class="w-5 h-5 md:w-6 md:h-6" />
      <span v-else>
        <svg
          v-if="camera.active"
          class="fill-current w-5 h-5 md:w-6 md:h-6 text-primary"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M17 9.2l5.213-3.65a.5.5 0 0 1 .787.41v12.08a.5.5 0 0 1-.787.41L17 14.8V19a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v4.2zm0 3.159l4 2.8V8.84l-4 2.8v.718zM3 6v12h12V6H3zm2 2h2v2H5V8z"
          />
        </svg>
        <svg
          v-else
          class="fill-current w-5 h-5 md:w-6 md:h-6 text-red-500"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M19.586 21H3a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h.586L1.393 2.808l1.415-1.415 19.799 19.8-1.415 1.414L19.586 21zm-14-14H4v12h13.586l-2.18-2.18A5.5 5.5 0 0 1 7.68 9.094L5.586 7zm3.524 3.525a3.5 3.5 0 0 0 4.865 4.865L9.11 10.525zM22 17.785l-2-2V7h-3.828l-2-2H9.828l-.307.307-1.414-1.414L9 3h6l2 2h4a1 1 0 0 1 1 1v11.786zM11.263 7.05a5.5 5.5 0 0 1 6.188 6.188l-2.338-2.338a3.515 3.515 0 0 0-1.512-1.512l-2.338-2.338z"
          />
        </svg>
      </span>
      <svg
        v-if="camera.active && attendee.with_hd"
        class="fill-current w-5 h-5 md:w-6 md:h-6"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          d="M4 5v14h16V5H4zM3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm4.5 8.25h2V9H11v6H9.5v-2.25h-2V15H6V9h1.5v2.25zm7-.75v3H16a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-1.5zM13 9h3a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-3V9z"
        />
      </svg>
    </fw-button>
    <div v-if="attendee.withRole('camera_allowed') && cameraOptions.length > 1" class="p-1">
      <b-dropdown :triggers="['click']" aria-role="list" position="is-top-left" :mobile-modal="false" animation="none">
        <a slot="trigger" class="p-1 text-gray-500" :class="{ 'has-text-danger': !camera.active }">
          <faicon size="xs" icon="chevron-up"></faicon>
        </a>
        <b-dropdown-item
          v-for="device in cameraOptions"
          :key="device.id"
          aria-role="listitem"
          @click="camera.setDevice(device.id)"
        >
          {{ device.label }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    withJanus: Boolean,
    attendee: Object,
    camera: Object,
    cameraOptions: Array
  }
}
</script>
