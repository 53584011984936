<template>
  <fw-modal
    v-if="showAcademicModal"
    :active.sync="showAcademicModal"
    :can-cancel="true"
    size="md"
    @close="showAcademicModal = false"
  >
    <template #default>
      <div class="text-center flex flex-col gap-3">
        <fw-heading size="h3">{{ editing ? $t('academic.edit') : $t('academic.add') }}</fw-heading>
        <fw-label>{{ $t('description.label') }}</fw-label>
        <TranslatedInput
          v-model="newcompetence"
          :placeholder="{ pt: $t('academic.pt'), en: $t('academic.en') }"
          :disable-autowrite="true"
          :multiline="false"
          :class="{
            error: $v.newcompetence.$error,
          }"
        />
        <fw-tip v-if="$v.newcompetence.$error" error>
          {{ $t('academic.required') }}
        </fw-tip>
        <fw-label>{{ $t('activitydate') }}</fw-label>
        <b-datepicker v-model="newcompetencedate" position="is-top-left"></b-datepicker>
        <fw-tip v-if="$v.newcompetencedate.$error" error>
          {{ $t('activitydaterequired') }}
        </fw-tip>
        <fw-label>{{ $t('location') }}</fw-label>
        <b-input v-model="location"></b-input>
        <fw-tip v-if="$v.location.$error" error>
          {{ $t('activitylocationrequired') }}
        </fw-tip>
      </div>
    </template>
    <template #footer>
      <fw-button type="black" expanded @click.native="save()">{{ editing ? $t('save') : $t('add') }}</fw-button>
    </template>
  </fw-modal>
</template>

<script>
import TranslatedInput from '@/fw-modules/fw-core-vue/ui/components/form/TranslatedInput'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'ModalCareerActivity',
  components: { TranslatedInput },
  data() {
    return {
      newcompetence: {
        pt: '',
        en: '',
      },
      newcompetencedate: null,
      location: '',
      showAcademicModal: false,
      editing: false,
    }
  },
  methods: {
    edit(data) {
      if (data) {
        this.newcompetence = JSON.parse(JSON.stringify(data.description))
        this.newcompetencedate = new Date(data.date + 'T12:00:00.000Z')
        this.location = data.location
        this.editing = true
      } else {
        this.newcompetence = {
          pt: '',
          en: '',
        }
        this.newcompetencedate = null
        this.location = ''
        this.editing = false
      }
      this.showAcademicModal = true
    },
    save() {
      this.$v.newcompetence.$touch()
      this.$v.newcompetencedate.$touch()
      this.$v.location.$touch()
      if (this.$v.newcompetence.$invalid || this.$v.newcompetencedate.$invalid || this.$v.location.$invalid) return
      let date =
        this.newcompetencedate.getFullYear() +
        '-' +
        (this.newcompetencedate.getMonth() + 1) +
        '-' +
        this.newcompetencedate.getDate()
      this.$emit('save', {
        data: {
          description: {
            pt: this.newcompetence.pt,
            en: this.newcompetence.en,
          },
          date: date,
          location: this.location,
        },
        editing: this.editing,
      })
      this.reset()
    },

    reset() {
      this.newcompetence = {
        pt: '',
        en: '',
      }
      this.newcompetencedate = null
      this.location = ''
      this.showAcademicModal = false
      this.$v.$reset()
      this.editing = false
    },
  },

  validations() {
    return {
      newcompetence: {
        pt: { required, min: minLength(1) },
        en: { required, min: minLength(1) },
      },
      newcompetencedate: { required },
      location: { required, min: minLength(1) },
    }
  },
}
</script>

<i18n>
    {
      "pt": {
        "save": "Guardar",
        "add":  "Adicionar",
        "location": "Local",
        "activitydate": "Data da atividade",
        "activitydaterequired": "A data da atividade é obrigatória",
        "activitylocationrequired": "A localização da atividade é obrigatória",
        "academic": {
          "pt": "Nome da atividade em Português",
          "en": "Nome da atividade em Inglês",
          "required": "Insira o nome da atividade em Português e Inglês.",
          "add": "Criar nova atividade",
          "edit": "Editar atividade"
        },
        "description": {
          "label": "Descrição",
          "pt": "Descrição em Português",
          "en": "Descrição em Inglês",
          "required": "Insira uma descrição em Português e Inglês."
        }
      },
      "en": {
        "save": "Save",
        "add": "Create",
        "location": "Location",
        "activitydate": "Activity date",
        "activitydaterequired": "Activity date is required",
        "activitylocationrequired": "Activity location is required",
        "academic": {
          "pt": "Name of the activity in Portuguese",
          "en": "Name of the activity in English",
          "required": "Enter the name of the activity in English and Portuguese",
          "add": "Creare new activity",
          "edit": "Edit activity"
        },
        "description": {
          "label": "Description",
          "pt": "Description in Portuguese",
          "en": "Description in English",
          "required": "Enter a description in English and Portuguese."
        }
      }
    }
    </i18n>
