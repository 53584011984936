<template>
  <div class="py-5">
    <div class="pl-5 pr-2 flex items-center pb-2">
      <fw-heading size="h3" class="flex-1">Ativar notificações</fw-heading>
      <fw-button type="link" @click.native="closeModal">Fechar</fw-button>
    </div>
    <div class="px-5">
      Seja notificado de novas edições do curso e de quando as inscrições estiverem abertas.
    </div>
    <div class="flex flex-col gap-3 px-5 pt-3">
      <CheckBoxButton checked disabled>
        <div class="py-2">Via Web</div>
      </CheckBoxButton>
      <CheckBoxButton checked disabled>
        <div class=" font-semibold text-sm flex-1 py-2">Via app UC One</div>
        <fw-button type="white" class="flex items-center gap-2" @click.native="openAppStore"
          >Instalar app <fw-icon-external-link class="h-5"></fw-icon-external-link
        ></fw-button>
      </CheckBoxButton>
      <CheckBoxButton checked disabled @click.native="emailActive = !emailActive">
        <div class="py-2">Via e-mail</div>
      </CheckBoxButton>
      <CheckBoxButton :checked="smsActive" @click.native="smsActive = !smsActive">
        <template>
          <div class="py-2">Via SMS</div>
        </template>
      </CheckBoxButton>
      <b-field
        v-if="smsActive"
        :label="$t('phoneNumberInstructions')"
        :type="$v.phoneNumber.$error ? 'is-danger' : ''"
        :message="$v.phoneNumber.$error ? $t('phoneNumberError') : ''"
        label-for="phoneNumber"
      >
        <b-input v-model="phoneNumber" name="phoneNumber" :placeholder="$t('phoneNumber')" type="text"></b-input>
      </b-field>
      <div class="flex gap-5 pt-1">
        <fw-button :type="saving ? 'disabled' : 'light'" size="lg" class="px-10 flex-1" @click.native="closeModal">
          {{ $t('cancel') }}
        </fw-button>

        <fw-button
          :type="!saving ? 'primary' : 'disabled'"
          size="lg"
          class="px-10 flex-1 flex items-center"
          :class="{ 'cursor-wait': saving }"
          @click.native="save"
        >
          <InnerLoader v-if="saving" class="my-1 mx-auto" />
          <div v-else class="mx-auto">{{ $t('save') }}</div>
        </fw-button>
      </div>
    </div>
  </div>
</template>
<script>
import CheckBoxButton from '@/fw-modules/fw-core-vue/ui/components/buttons/CheckboxButton.vue'
import ServiceAcademic from '@/fw-modules/fw-core-vue/academic/services/ServiceAcademic'
import { required } from 'vuelidate/lib/validators'
import InnerLoader from '@/fw-modules/fw-core-vue/ui/components/animation/InnerLoader.vue'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
const mustBeIntPhone = value => {
  var regex = /^\+(?:[0-9] ?){6,14}[0-9]$/
  if (regex.test(value)) {
    //is valid international phone number
    return true
  } else {
    //is not a valid international phone number
    return false
  }
}
export default {
  components: {
    CheckBoxButton,
    InnerLoader
  },
  props: {
    course: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      emailActive: false,
      smsActive: false,
      phoneNumber: '+351',
      saving: false
    }
  },
  validations: {
    phoneNumber: { required, mustBeIntPhone }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    openAppStore() {
      window.open('https://one.uc.pt/', '_blank').focus()
    },
    async save() {
      if (this.smsActive) {
        this.$v.phoneNumber.$touch()
        if (this.$v.phoneNumber.$error) {
          return
        }
      }
      this.saving = true
      try {
        var result = await ServiceAcademic.subscribeCourse(
          this.course.key,
          this.course.current_edition != null && this.course.current_edition.key != null
            ? this.course.current_edition.key
            : null,
          null,
          this.smsActive ? this.phoneNumber : null,
          false
        )
        console.log(result)
        this.saving = false
        if (result) {
          this.$emit('success')
          this.$emit('close')
        }
      } catch (error) {
        console.error(error)
        this.saving = false
        const errorKey = utils.errors(error).getKey()
        let message = this.$t(`errorMessages.${errorKey}` || 'errorMessages.GeneralError')
        console.error(message, error)

        this.$buefy.toast.open({
          duration: 5000,
          message: message,
          type: 'is-danger'
        })
      }
    }
  }
}
</script>
<i18n>
    {
      "en": {
        "phoneNumber": "Phone Number",
        "phoneNumberInstructions": "Please give us your phone number:",
        "phoneNumberError": "This phone number is not valid",
        "cancel": "Cancel",
        "save": "Save",
        "errorMessages": {
      "ApplicationsNotOpen": "Registration for this course is not yet open.",
      "GeneralError": "Unable to enroll in the course. Please try again later. If the problem persists, please contact us through our support channel.",
      "InvalidCC": "The citizen card number is not valid.",
      "InvalidDate": "The citizen card expiration date is not valid.",
      "MinDate": "The citizen card expiration date is not valid.",
      "InvalidNif": "The taxpayer number entered is not valid.",
      "InvalidPostalCode": "The postal code entered is not valid.",
      "InvalidEmail": "The email entered is not valid.",
      "InvalidPhoneNumber": "The phone number entered is not valid.",
      "InvalidFullName": "The full name entered is not valid.",
      "InvalidFiliationMother": "The mother name entered is not valid.",
      "InvalidFiliationFather": "The father name entered is not valid.",
      "InvalidAddress": "The address entered is not valid.",
      "InvalidCity": "The city entered is not valid.",
      "InvalidCountry": "The country entered is not valid."
    }
      },
      "pt": {
        "phoneNumber": "Nº telemóvel",
        "phoneNumberInstructions": "Por favor, indique o seu número de telemóvel:",
        "phoneNumberError": "O telemóvel inserido não é válido",
        "cancel": "Cancelar",
        "save": "Guardar",
        "errorMessages": {
      "ApplicationsNotOpen": "As inscrições para este curso ainda não estão abertas.",
      "GeneralError": "Não foi possível inscrever-se no curso. Por favor, tente mais tarde. Caso o problema persista, contacte-nos através do nosso canal de suporte.",
      "InvalidCC": "O número do cartão de cidadão não é válido.",
      "InvalidNif": "O número de contribuinte inserido não é válido.",
      "InvalidPostalCode": "O código postal inserido não é válido.",
      "InvalidDate": "A data de validade para o cartão de cidadão não é válido.",
      "MinDate": "The citizen card expiration date is not valid.",
      "InvalidEmail": "O email inserido não é válido.",
      "InvalidPhoneNumber": "O número de telefone inserido não é válido.",
      "InvalidFullName": "O nome inserido não é válido.",
      "InvalidFiliationMother": "O nome da mãe inserido não é válido.",
      "InvalidFiliationFather": "O nome do pai inserido não é válido.",
      "InvalidAddress": "O endereço inserido não é válido.",
      "InvalidCity": "A cidade inserida não é válida.",
      "InvalidCountry": "O país inserido não é válido."
    }
      }
    }
  </i18n>
