<template>
  <fw-layout
    full
    back-to="-1"
    main-sidebar-width="w-52"
    :right-sidebar-width="rightSideWidth"
    mobile-ready
    :main-content-position="isMobile && !notificationKey ? 'main-listbar' : 'main-content'"
  >
    <template #header-nav>
      <fw-heading>Notificações</fw-heading>
    </template>

    <template #main-sidebar>
      <div v-if="loading.notifications" class="flex flex-col p-5 gap-3">
        <div class="h-5 w-full rounded-lg bg-gray-200 animate-pulse"></div>
        <div class="h-5 w-3/4 rounded-lg bg-gray-200 animate-pulse"></div>
        <div class="h-5 w-3/4 rounded-lg bg-gray-200 animate-pulse"></div>
        <div class="h-5 w-3/4 rounded-lg bg-gray-200 animate-pulse"></div>
        <div class="h-5 w-full rounded-lg bg-gray-200 animate-pulse"></div>
      </div>
      <SidebarNotifications
        v-else
        :context-options="contextOptions"
        :stats="stats"
        :type="type"
        :app="app"
        @change-app="changeApp($event)"
        @change-type="changeType($event)"
      />
    </template>

    <template #main-listbar>
      <PanelNotificationList
        :key="type"
        ref="notificationList"
        use-pagination
        :hide-open="true"
        :app="app"
        :filter-type="type"
        :force-open-details="true"
        :active-notification-key="activeNotification ? activeNotification.key : null"
        @change-type="changeType($event)"
        @remove-from-list="removeFromList($event, false)"
      />
    </template>

    <template #main-content>
      <div class="relative h-full">
        <PanelNotification
          v-if="activeNotification"
          :notification="activeNotification"
          :view="type"
          @update="updateNotification($event)"
          @add-to-list="addToList($event)"
          @remove-from-list="removeFromList($event)"
        />
        <fw-loading-blur-panel v-if="loading.notification" />
      </div>
    </template>
  </fw-layout>
</template>

<script>
import SidebarNotifications from '../components/sidebars/SidebarNotifications'
import PanelNotificationList from '../components/panels/PanelNotificationList'
import PanelNotification from '../components/panels/PanelNotification'
import ServiceNotifications from '../services/ServiceNotifications'

export default {
  components: {
    SidebarNotifications,
    PanelNotificationList,
    PanelNotification
  },

  data() {
    return {
      app: null,
      type: 'inbox',
      loading: {
        notifications: true,
        notification: false
      },
      activeNotification: null,
      stats: null,
      contextOptions: [],
      isMobile: false
    }
  },

  computed: {
    notificationKey() {
      return this.$route.params.key
    },
    rightSideWidth() {
      return window.innerWidth > 1023 ? 'w-1/2' : 'w-full'
    }
  },

  watch: {
    notificationKey() {
      this.loadNotification()
    }
  },

  mounted() {
    this.loadNotification()
    this.verifyMobile()
    //create windows resize event listener
    window.addEventListener('resize', this.verifyMobile)
  },

  beforeDestroy() {
    //remove windows resize event listener
    window.removeEventListener('resize', this.verifyMobile)
  },

  methods: {
    verifyMobile() {
      this.isMobile = window.innerWidth < 1024
    },
    changeApp(app) {
      this.app = app
      this.activeNotification = null
      if (this.$route.name != 'notifications') {
        this.$router.push({ name: 'notifications' })
      }
    },
    addToList(notification) {
      this.$refs.notificationList.addNotificationToList(notification)
    },
    updateNotification(notification) {
      if (this.activeNotification && this.activeNotification.key == notification.key) {
        this.activeNotification = notification
      }
      this.$refs.notificationList.updateNotification(notification)
    },
    removeFromList(notificationKey, remove = true) {
      if (remove) {
        this.$refs.notificationList.removeNotificationFromList(notificationKey)
      }
      if (this.type == 'inbox' || this.type == 'unread') {
        if (this.stats.unread >= 1) {
          this.stats.unread -= 1
        }
        this.stats.archived += 1
      } else {
        //this.stats.unread += 1
        if (this.stats.archived >= 1) {
          this.stats.archived -= 1
        }
      }
      console.log('removeFromList', notificationKey, this.stats)
      this.resetNotification()
    },
    setContext(contextOptions, stats) {
      console.log('setContext', contextOptions, stats)
      this.loading.notifications = false
      this.contextOptions = contextOptions
      this.stats = stats
    },
    async loadNotification() {
      if (this.notificationKey) {
        this.loading.notification = true
        let updatedNotification = await ServiceNotifications.get(this.notificationKey, false) //this.view == 'unread')
        this.activeNotification = updatedNotification
        this.activeNotification.unread = false
        this.updateNotification(this.activeNotification)
        setTimeout(() => {
          this.loading.notification = false
        }, 500)
      }
    },
    changeType(type) {
      this.activeNotification = null
      if (type != 'unread') {
        this.app = null
      }
      if (type == 'unread' && this.type == 'unread') {
        this.type = 'inbox'
        return
      }
      this.type = type
      //push to route only if different
      this.resetNotification()
    },
    resetNotification() {
      this.activeNotification = null
      if (this.$route.name != 'notifications') {
        this.$router.push({ name: 'notifications' })
      }
    }
  }
}
</script>
