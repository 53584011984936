<template>
  <div
    class="w-full group text-center h-full flex bg-white justify-center flex-col p-2 pt-5 md:p-4 shadow-lg hover:shadow-2xl rounded-xl cursor-pointer"
  >
    <div class="flex-1 flex flex-col items-center justify-center">
      <fw-heading size="h3">{{ unitClass.title }}</fw-heading>
      <div class="mb-3 font-medium text-sm">{{ unitClass.profile }}</div>
      <div v-if="unitClass.course" class="has-margin-top">
        {{ unitClass.course.title }}
      </div>
    </div>
    <div class="w-full text-xs flex justify-between gap-2 text-gray-500 mt-5">
      <div class="flex items-center gap-1">
        {{ unitClass.stats.sessions }} aula{{ unitClass.stats.sessions == 1 ? '' : 's' }}
      </div>
      <div v-if="profile == 'teacher'">
        <div class="flex items-center gap-1">
          <fw-icon-people class="w-4 h-4" />
          {{ unitClass.stats.students }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    unitClass: Object,
    profile: {
      type: String,
      default: 'teacher'
    }
  }
}
</script>
