<template>
  <div>
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <fw-panel-info v-if="!loading && !subjects.length" empty>Não foram encontrados tópicos de suporte.</fw-panel-info>
      <div
        v-else
        class="overflow-auto p-1 bg-gray-100 rounded-lg flex flex-col gap-0.5 -mt-2.5"
        style="max-height: 500px"
      >
        <div v-for="subject in subjects" :key="subject.key" class="border-b pb-0.5">
          <RecordSubject
            class="cursor-pointer"
            :item="{ ...subject, space: spaces[subject.space_key] }"
            @click.native="$emit('open', subject)"
          />
        </div>
      </div>
      <BlockPagination
        v-if="pagination && pagination?.total_pages > 1 && !loading"
        :per-page="pagination.active_limit"
        :total="pagination.total_items"
        :total-pages="pagination.total_pages"
        :current.sync="pagination.current_page"
        @page-changed="$emit('page-changed', $event)"
      />
    </template>
  </div>
</template>

<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import RecordSubject from '@/fw-modules/fw-core-vue/tasks/components/records/RecordSubject'

export default {
  components: {
    RecordSubject,
    LoadingPlaceholder,
    BlockPagination
  },

  props: {
    loading: {
      type: Boolean,
      default: true
    },
    subjects: {
      type: Array,
      default: () => []
    },
    spaces: {
      type: Object,
      default: () => {}
    },
    pagination: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
