var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative rounded-xl bg-white",class:[
    _vm.customClass,
    {
      'p-5': !_vm.paddingless
    }
  ]},[(_vm.inviting && _vm.invitePeople)?[_c('PanelInviteUser',{attrs:{"email":_vm.invitingEmail,"cancel-btn-label":_vm.$t('button.back')},on:{"cancel":function($event){_vm.inviting = false},"save-invite":_vm.saveInviteEvent}})]:[(!_vm.showTransferMessageForm)?_c('div',[(_vm.showHeader)?_c('div',{staticClass:"flex mb-2"},[_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"text-lg font-bold"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"text-sm"},[_vm._v(_vm._s(_vm.instructions))])]),(_vm.selectionTypes.length > 0)?_c('b-dropdown',{attrs:{"scrollable":"","max-height":"500","aria-role":"list","position":"is-bottom-left"}},[_c('fw-button',{staticClass:"flex gap-1 items-center",attrs:{"slot":"trigger","type":"dropdown","size":"sm"},slot:"trigger"},[_vm._v(" "+_vm._s(_vm.selectionTypes[_vm.currentSelectionTypeIndex].text)+" "),_c('fw-icon-chevron-down',{staticClass:"w-4 h-4"})],1),_c('b-dropdown-item',{key:'criterium_label',staticClass:"font-semibold",attrs:{"aria-role":"menu-item","focusable":false,"paddingless":"","custom":""}},[_c('fw-label',{staticClass:"ml-4"},[_vm._v(_vm._s(_vm.$t('selectBy'))+":")])],1),_vm._l((_vm.selectionTypes),function(seltype,a){return _c('b-dropdown-item',{key:'seltype_selector_' + a,staticClass:"px-4 py-2 font-semibold flex justify-start gap-3 items-center",attrs:{"aria-role":"menu-item","focusable":true},nativeOn:{"click":function($event){return _vm.changeSelectionType(a)}}},[_c('div',{staticClass:"border-2 border-gray-200 h-4 w-4 rounded-full -mt-0.5",class:{
                'bg-primary bg-opacity-90': _vm.currentSelectionTypeIndex === a,
                'bg-gray-200': _vm.currentSelectionTypeIndex !== a
              }}),_c('div',[_vm._v(_vm._s(_vm.selectionTypes[a].text))])])})],2):_vm._e()],1):_vm._e(),_vm._t("previous"),_c('PeopleList',{attrs:{"endpoint":_vm.endpoint,"show-person-attributes":_vm.showPersonAttributes,"searchable":_vm.searchable,"users-list":_vm.usersList,"no-padding":true,"selectable":_vm.multiselect,"clickable":!_vm.multiselect,"max-selectable":_vm.maxSelectable,"not-alowed-users":_vm.notAlowedUsers,"id-key":_vm.idKey,"invite-people":_vm.invitePeople,"query-filters":_vm.queryFilters,"inject-payload":_vm.injectPayload},on:{"selected":_vm.selectedEvent,"clicked":_vm.clicked,"invite":_vm.goToInviteStep},scopedSlots:_vm._u([{key:"after",fn:function(){return [_vm._t("after-list")]},proxy:true}],null,true)})],2):_vm._t("transfer"),(_vm.multiselect || _vm.showConfirmation)?_c('div',{staticClass:"flex items-center justify-end mt-2 gap-5"},[_c('fw-button',{attrs:{"type":"link-muted"},nativeOn:{"click":function($event){return _vm.closeModal.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.cancelButtonText)+" ")]),(_vm.allowTransferMessage && !_vm.showTransferMessageForm)?_c('fw-button',{attrs:{"type":"black"},nativeOn:{"click":function($event){return _vm.askForTransferMessage.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('button.next'))+" ")]):_c('fw-button',{attrs:{"type":!_vm.confirmDisabled ? 'primary' : 'disabled',"disabled":_vm.confirmDisabled},nativeOn:{"click":function($event){return _vm.saveSelection.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.confirmButtonText)+" ")])],1):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }