<template>
  <div
    class="flex gap-1 items-center relative fw-card-file"
    :class="{
      'bg-teal-400': innerSelected || isPreviewOpen,
      'justify-between p-2 my-1 gap-2 group hover:bg-opacity-40 hover:shadow-xl rounded-lg': isRow,
      'flex-col items-center justify-between p-2 md:p-4 my-1 group hover:bg-opacity-40 hover:shadow-xl rounded-xl': !isRow,
      'bg-opacity-30 shadow-md': isPreviewOpen,
      'bg-opacity-10 shadow-sm': !isPreviewOpen
    }"
  >
    <div
      class="group-hover:opacity-95 -mr-3 mt-1"
      :class="{ 'opacity-95': innerSelected, 'opacity-0': !innerSelected, 'absolute top-3 left-3': !isRow }"
    >
      <b-checkbox v-model="innerSelected" size="is-small"></b-checkbox>
    </div>
    <!-- Icon -->
    <div>
      <button
        class="bg-opacity-50 group-hover:bg-opacity-90 group-hover:bg-white rounded-full inline-flex items-center flex-shrink-0 justify-center"
        :class="{
          'w-10 h-10 bg-gray-200 overflow-hidden group-hover:scale-105': isRow && type === 'row_expanded',
          'w-5 h-5 bg-white mt-1': isRow && type !== 'row_expanded',
          'w-14 h-14 bg-gray-200 overflow-hidden group-hover:scale-105': !isRow,
          'bg-gray-200 scale-110 group-hover:scale-110 bg-white bg-opacity-90 overflow-hidden group-hover:scale-105 ': isPreviewOpen
        }"
        @click="preview"
      >
        <component
          :is="'fw-icon-file'"
          class="opacity-90 group-hover:scale-105"
          :class="{ 'w-5 h-5': isRow, 'w-9 h-9': !isRow }"
        />
      </button>
    </div>
    <!-- Main metadata -->
    <div class="flex-1 text-sm md:text-base ml-1">
      <div class="flex flex-col" :class="{ 'items-center justify-center h-full': !isRow }">
        <div
          class="flex items-center justify-between"
          :class="{
            'flex-col': !isRow,
            'invisible group-hover:visible':
              !isRow && item.file && (item.file.type == 'image' || item.file.thumb_filename) && !isPreviewOpen
          }"
        >
          <button class="inline-flex break-all" :class="{ 'font-bold': item.unread }" @click="preview">
            <v-clamp autoresize :max-lines="1">{{
              originalPage && originalPage.title ? originalPage.title : 'Sem título'
            }}</v-clamp>
          </button>
          <span v-if="isMobile" class="opacity-60 text-sm leading-5" :class="{ hidden: !isRow }">
            {{ item.created_date | formatDateTime }}
          </span>
          <!-- <span v-if="!isMobile && type === 'row_expanded'" class="text-xs opacity-30" :class="{ hidden: !isRow }">
            {{ item.key }}
          </span>
          <span v-else class="opacity-60 text-sm leading-5" :class="{ hidden: !isRow }">
            {{ item.created_date | formatDateTime }}
          </span> -->
          <!-- <span class="opacity-60 text-sm leading-5" :class="{ hidden: !isRow }">
            {{ item.created_date | formatDateTime }}
          </span> -->
        </div>
        <div
          v-if="type === 'row_expanded'"
          class="text-xs md:text-sm flex opacity-60"
          :class="{
            'gap-1 justify-between leading-5': isRow,
            'flex-col items-center justify-center gap-0.5 mt-2': !isRow,
            'invisible group-hover:visible':
              !isRow && item.file && (item.file.type == 'image' || item.file.thumb_filename) && !isPreviewOpen
          }"
        ></div>
      </div>
    </div>
    <!-- Versions and language -->
    <div v-if="originalPage" class="hidden lg:flex w-48 gap-2 justify-end mr-1" :class="{ hidden: !isRow }">
      <fw-tag v-for="(lang, l) in languages" :key="'language_' + l" type="light-border-box" size="xs" class="uppercase">
        {{ lang }}
      </fw-tag>
      <fw-tag
        v-if="extraLanguages > 0"
        type="light-border-box"
        size="xs"
        class="flex items-center gap-0.5 justify-center"
      >
        +{{ extraLanguages }}
      </fw-tag>
    </div>
    <!-- Status -->
    <div v-if="originalPage" class="flex gap-1 mr-1" :class="{ hidden: !isRow }">
      <fw-tag v-if="originalPage.state == 'closed'" type="xlight">
        {{ $t('label.closed') }}
      </fw-tag>
      <fw-tag v-else-if="originalPage.state == 'draft'" type="orange">
        {{ $t('label.draft') }}
      </fw-tag>
      <fw-tag v-else-if="originalPage.state == 'published'" type="primary">
        {{ $t('label.published') }}
      </fw-tag>
      <fw-tag v-else-if="originalPage.state == 'review'" type="primary">
        {{ $t('label.review') }}
      </fw-tag>
      <fw-tag v-if="originalPage.state == 'ended'" type="xlight" class="flex gap-1 items-center flex-shrink-0">
        {{ $t('label.locked') }}
      </fw-tag>
    </div>
    <!-- Date -->
    <div v-if="!isMobile" class="opacity-60 text-sm leading-5" :class="{ hidden: !isRow }">
      {{ item.created_date | formatDateTime }}
    </div>
    <!-- Nav -->
    <div class="flex" :class="{ 'flex-shrink-0 items-center gap-1 md:ml-4': isRow, 'absolute top-1 right-2': !isRow }">
      <b-dropdown v-if="canDeleteItems" aria-role="list" position="is-bottom-left">
        <template #trigger="{ active }">
          <fw-button size="sm" :class="{ 'opacity-100': active }">
            <fw-icon-more class="w-5 h-5" />
          </fw-button>
        </template>
        <b-dropdown-item v-if="false" paddingless aria-role="listitem">
          <fw-button
            type="simple"
            size="sm"
            class="w-full flex items-center gap-2"
            label="Editar título"
            @click.native="editItemTitle"
          >
            <fw-icon-edit class="w-5 h-5" /> Editar título
          </fw-button>
        </b-dropdown-item>
        <b-dropdown-item v-if="false" paddingless aria-role="listitem">
          <fw-button
            type="simple"
            size="sm"
            class="w-full flex items-center gap-2"
            label="Copiar para..."
            @click.native="$emit('copy-to-repo', item)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              class="w-5 h-5 fill-current"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.006-1H7zM5.002 8L5 20h10V8H5.002zM9 6h8v10h2V4H9v2zm-2 5h6v2H7v-2zm0 4h6v2H7v-2z"
              />
            </svg>
            Copiar para...
          </fw-button>
        </b-dropdown-item>
        <b-dropdown-item v-if="canDeleteItems" paddingless aria-role="listitem">
          <fw-button
            type="simple"
            size="sm"
            class="w-full flex items-center gap-2"
            label="Eliminar"
            @click.native="$emit('delete', item)"
          >
            <fw-icon-trash class="w-5 h-5" /> Eliminar
          </fw-button>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>
<script>
import ServiceBuckets from '@/fw-modules/fw-core-vue/buckets/services/ServiceBuckets'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  props: {
    type: {
      type: String,
      default: 'row'
    },
    read: {
      type: Boolean,
      default: true
    },
    bucket: Object,
    isPreviewOpen: Boolean,
    canEditItems: Boolean,
    canDeleteItems: Boolean,
    canMoveItems: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      deep: true
    },
    initSelected: Boolean
  },

  data() {
    return {
      loading: false,
      inputOpened: false,
      newMetadataItem: this.getNewMetadataItem(),
      innerSelected: this.initSelected
    }
  },

  computed: {
    languages() {
      //limit only 3 languages
      return this.item.language ? Object.keys(this.item.language).splice(0, 3) : []
    },
    extraLanguages() {
      return this.languages.length > 3 ? 3 : this.languages.length - 1
    },
    originalPage() {
      let original = null
      for (let i = 0; i < this.languages.length; i++) {
        if (this.item.language[this.languages[i]].is_original) {
          original = this.item.language[this.languages[i]]
          break
        }
      }
      console.log('original', original)
      return original
    },
    isMobile() {
      return window.innerWidth < 640
    },
    isRow() {
      return this.type === 'row' || this.type === 'row_expanded'
    },
    newMetadataItemIsComplete() {
      return Boolean(this.newMetadataItem.title && this.newMetadataItem.value)
    },
    fileIconKey() {
      return utils.getFileIcon(this.item.file.filename)
    }
  },

  watch: {
    innerSelected(value) {
      this.$emit('set-selected', this.item.key, value)
    }
  },

  methods: {
    dowloadFile() {
      this.$emit('download', this.item.key, this.item.file)
    },
    updateInnerSelected(value) {
      this.innerSelected = value
    },
    getNewMetadataItem() {
      return { title: '', value: '' }
    },
    clearNewMetadataItem() {
      this.newMetadataItem = this.getNewMetadataItem()
    },
    getFileIcon(filename) {
      return utils.getFileIcon(filename)
    },
    getFileUrl(file) {
      return ServiceStorage.getFileUrl(file)
    },
    getImageViewUrl(file, size) {
      return ServiceStorage.getImageViewUrl(file, size)
    },

    openEdit() {
      if (this.canEditItems) {
        this.clearNewMetadataItem()
        this.inputOpened = true
        this.$nextTick(() => {
          const el = document.getElementById('input-title-' + this.item.key)
          if (el) el.focus()
        })
      }
    },
    addNewItemMetadata() {
      if (this.newMetadataItemIsComplete) {
        this.item.metadata.push({
          key: utils.randomLowerString(8),
          title: this.newMetadataItem.title,
          value: this.newMetadataItem.value
        })
        this.clearNewMetadataItem()
      }
    },
    removeItemMetadata(metadataItemKey) {
      for (let idx in this.item.metadata) {
        if (this.item.metadata[idx].key === metadataItemKey) {
          this.item.metadata.splice(idx, 1)
          break
        }
      }
    },
    editItemTitle() {
      if (!this.canEditItems) return
      this.$buefy.dialog.prompt({
        title: 'Título do recurso',
        inputAttrs: {
          type: 'text',
          placeholder: 'e.g. Nome do ficheiro ou pasta',
          maxlength: 150,
          min: 2,
          value: this.item.title
        },
        confirmText: 'Alterar',
        cancelText: 'Cancelar',
        trapFocus: true,
        onConfirm: async value => {
          this.item.title = value
          this.updateItem()
        }
      })
    },
    async updateItem() {
      if (this.canEditItems) {
        try {
          const data = { title: this.item.title }
          const updatedItem = await ServiceBuckets.updateBucketItem(this.bucket.key, this.item.key, data)
          Object.assign(this.item, updatedItem)
          this.inputOpened = false
        } catch (error) {
          this.$buefy.snackbar.open({
            message: 'Ocorreu um erro a guardar os dados do item.',
            type: 'is-warning',
            position: 'is-top-right',
            indefinite: true,
            duration: 2000,
            queue: false
          })
        }
      }
    },
    preview() {
      this.$emit('preview', this.item)
    }
  }
}
</script>
<style scoped>
.fw-card-file .no-description {
  display: none;
}
.fw-card-file:hover .no-description {
  display: block;
}
</style>

<i18n>
  {
    "pt": {
      "no_title": "Sem título",
      "created_at": "Criado em",
      "label.closed": "Fechado",
      "label.draft": "Rascunho",
      "label.published": "Publicado",
      "label.review": "Revisão",
      "label.locked": "Bloqueado"
    },
    "en": {
      "no_title": "No title",
      "created_at": "Created at",
      "label.closed": "Closed",
      "label.draft": "Draft",
      "label.published": "Published",
      "label.review": "Review",
      "label.locked": "Locked"
    }
  }
</i18n>
