<template>
  <button
    class="item flex items-center gap-3 w-full text-sm text-left cursor-pointer hover:bg-gray-50 border rounded-md px-2 py-1.5"
    :class="{ 'item-active': isActive }"
  >
    <div class="border border-gray-300 bg-gray-50 rounded-md h-6 w-6 flex items-center justify-center shadow-sm">
      <fw-icon-image v-if="item.type === 'image'" class="text-gray-500 w-4 h-4" />
      <fw-icon-video v-else-if="item.type === 'video' || item.type === 'youtube'" class="text-gray-500 w-4 h-4" />
      <fw-icon-file v-else-if="item.type === 'files'" class="text-gray-500 w-4 h-4" />
      <fw-icon-paragraph v-else-if="item.type === 'paragraph'" class="text-gray-500 w-4 h-4" />
      <fw-icon-quotes v-else-if="item.type === 'blockquote'" class="text-gray-500 w-4 h-4" />
      <fw-icon-unorderedlist v-else-if="item.type === 'bulletList'" class="text-gray-500 w-4 h-4" />
      <fw-icon-orderedlist v-else-if="item.type === 'orderedList'" class="text-gray-500 w-4 h-4" />
      <fw-icon-list-check v-else-if="item.type === 'taskList'" class="text-gray-500 w-4 h-4" />
      <fw-icon-table v-else-if="item.type === 'table'" class="text-gray-500 w-4 h-4" />
      <fw-icon-heading1 v-else-if="item.type === 'heading' && item.attrs.level == 1" class="text-gray-500 w-4 h-4" />
      <fw-icon-heading2 v-else-if="item.type === 'heading' && item.attrs.level == 2" class="text-gray-500 w-4 h-4" />
      <fw-icon-heading3 v-else-if="item.type === 'heading' && item.attrs.level == 3" class="text-gray-500 w-4 h-4" />
      <fw-icon-code v-else-if="item.type === 'codeBlock'" class="text-gray-500 w-4 h-4" />
      <span v-else>?</span>
    </div>
    {{ item.name[language] ? item.name[language] : item.name }}
  </button>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },

    isActive: {
      type: Boolean,
      default: false
    },

    language: {
      type: String,
      default: 'en'
    }
  }
}
</script>
