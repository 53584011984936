<template>
  <button
    :aria-label="text"
    class="relative align-middle text-white transition-all font-semibold focus:outline-none rounded-md h-9 flex items-center justify-center gap-1"
    :class="{
      'w-auto px-2': short,
      'w-auto pr-4 pl-2': !short,
      active: active,
      inactive: !active
    }"
    @click.stop="$emit('toogle')"
  >
    <InnerLoader v-if="saving"></InnerLoader>
    <fw-icon-notification-on-solid v-else-if="active" class="h-5"></fw-icon-notification-on-solid>
    <fw-icon-notification-on v-else class="h-5"></fw-icon-notification-on>
    <div v-if="!short && !active">{{ text }}</div>
    <fw-icon-check v-if="active && !saving"></fw-icon-check>
  </button>
</template>
<script>
import InnerLoader from '../../../ui/components/animation/InnerLoader.vue'
export default {
  name: 'ButtonNotificationsToogle',
  components: { InnerLoader },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'bell'
    },
    short: {
      type: Boolean,
      default: false
    },
    saving: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    text() {
      return this.type === 'bell'
        ? this.active
          ? this.$t('deactivate')
          : this.$t('activate')
        : this.active
        ? this.$t('deactivate')
        : this.$t('interested')
    }
  }
}
</script>
<i18n>
{
  "en": {
    "interested": "I am interested!",
    "activate": "Turn on notifications",
    "deactivate": "Turn off notifications"
  },
  "pt": {
    "interested": "Estou interessado/a!",
    "activate": "Ativar notificações",
    "deactivate": "Desativar notificações"
  }
}
</i18n>
<style scoped>
.active {
  background-color: #30a898;
  color: #ffffff;
}
.inactive {
  border: solid thin rgba(0, 0, 0, 0.1);
  background-color: rgba(21, 21, 21, 0.2);
}
</style>
