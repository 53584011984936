<template>
  <div v-if="withJanus" class="flex items-center">
    <span v-if="!attendee.withRole('audio_allowed')" class="text-red-500">
      <svg
        class="fill-current w-5 h-5 md:w-6 md:h-6"
        aria-label="Microfone não autorizado"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          d="M6 8V7a6 6 0 1 1 12 0v1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h2zm13 2H5v10h14V10zm-8 5.732a2 2 0 1 1 2 0V18h-2v-2.268zM8 8h8V7a4 4 0 1 0-8 0v1z"
        />
      </svg>
    </span>
    <fw-button
      v-else
      v-shortkey="['meta', 'shift', 'a']"
      type="transparent"
      :aria-label="audio.active ? 'Desligar o microfone' : 'Ligar o microfone'"
      @shortkey="audio.toggle()"
      @click.native="audioToggle()"
    >
      <fw-icon-loading v-if="audio.loading" class="w-5 h-5 md:w-6 md:h-6" />
      <span v-else>
        <span v-if="audio.active" class="flex items-center gap-2 text-primary font-semibold">
          <svg
            class="fill-current w-5 h-5 md:w-6 md:h-6 text-primary"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M12 3a3 3 0 0 0-3 3v4a3 3 0 0 0 6 0V6a3 3 0 0 0-3-3zm0-2a5 5 0 0 1 5 5v4a5 5 0 0 1-10 0V6a5 5 0 0 1 5-5zM3.055 11H5.07a7.002 7.002 0 0 0 13.858 0h2.016A9.004 9.004 0 0 1 13 18.945V23h-2v-4.055A9.004 9.004 0 0 1 3.055 11z"
            />
          </svg>
          <span v-if="withLabels">Microfone ligado</span>
        </span>
        <span v-else class="flex items-center gap-2 text-red-500">
          <svg
            class="fill-current w-5 h-5 md:w-6 md:h-6 text-red-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M16.425 17.839A8.941 8.941 0 0 1 13 18.945V23h-2v-4.055A9.004 9.004 0 0 1 3.055 11H5.07a7.002 7.002 0 0 0 9.87 5.354l-1.551-1.55A5 5 0 0 1 7 10V8.414L1.393 2.808l1.415-1.415 19.799 19.8-1.415 1.414-4.767-4.768zm-7.392-7.392l2.52 2.52a3.002 3.002 0 0 1-2.52-2.52zm10.342 4.713l-1.443-1.442c.509-.81.856-1.73.997-2.718h2.016a8.95 8.95 0 0 1-1.57 4.16zm-2.91-2.909l-1.548-1.548c.054-.226.083-.46.083-.703V6a3 3 0 0 0-5.818-1.032L7.686 3.471A5 5 0 0 1 17 6v4a4.98 4.98 0 0 1-.534 2.251z"
            />
          </svg>
          <span v-if="withLabels">Microfone desligado</span>
        </span>
      </span>
    </fw-button>
    <div v-if="attendee.withRole('audio_allowed') && audioInputOptions.length > 1" class="p-1">
      <b-dropdown :triggers="['click']" aria-role="list" position="is-top-left" :mobile-modal="false" animation="none">
        <a slot="trigger" class="p-1 text-gray-500" :class="{ 'has-text-danger': !audio.active }">
          <faicon size="xs" icon="chevron-up"></faicon>
        </a>
        <b-dropdown-item
          v-for="device in audioInputOptions"
          :key="device.id"
          aria-role="listitem"
          :style="{ maxWidth: maxWidth + 'px' }"
          class="text-ellipsis overflow-hidden whitespace-nowrap"
          @click="audio.setDevice(device.id)"
        >
          {{ device.label }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    withJanus: Boolean,
    attendee: Object,
    audio: Object,
    audioInputOptions: Array,
    withLabels: Boolean,
    maxWidth: {
      type: Number,
      default: 270
    }
  },

  methods: {
    audioToggle() {
      this.audio.toggle()
      this.$emit('audio-toggle')
    }
  }
}
</script>
