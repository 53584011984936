<template>
  <div
    class="player-container flex justify-center items-center bg-black min-w-[300px] sm:aspect-w-16 sm:aspect-h-9 lg:aspect-none"
  >
    <video
      controls
      width="250"
      muted
      class="min-w-[300px] sm:aspect-w-16 sm:aspect-h-9 lg:aspect-none"
      @play="$emit('play', $event)"
      @pause="$emit('pause', $event)"
    >
      <source :src="src" :type="type" />
    </video>
  </div>
</template>

<script>
export default {
  name: 'VideoPlayer',
  props: {
    src: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'video/mp4'
    },
    poster: {
      type: String,
      default: null
    }
  }
}
</script>
