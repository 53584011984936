<template>
  <div
    :class="{
      'px-1 py-0.5 text-center rounded-full font-semibold': type == 'rounded',
      'text-xs bg-opacity-50 font-normal leading-3 inline-flex': type == 'simple',
      'bg-teal-500 text-white bg-opacity-90': color == 'primary' && type != 'simple',
      'bg-red-600 bg-opacity-90 text-white': color == 'red' && type != 'simple',
      'bg-gray-500 bg-opacity-20 text-gray-600': color == 'light' && type != 'simple',
      'bg-gray-700 bg-opacity-60 text-gray-300': color == 'dark' && type != 'simple',
      'absolute -top-1 -right-2': !inline,
      'p-1': inline,
      'p-2': !inline && type == 'simple',
      'text-xs': size == 'xs',
      'text-sm': size == 'sm'
    }"
    :style="type == 'rounded' ? 'min-width: 18px;' : ''"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'rounded'
    },
    color: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: 'xs'
    },
    inline: Boolean
  }
}
</script>
