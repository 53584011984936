/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hr': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5 13a1 1 0 010-2h14a1 1 0 010 2H5z"/>'
  }
})
