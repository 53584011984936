var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center gap-1",class:[
    {
      'mt-2 mb-4': !_vm.marginless,
      uppercase: _vm.uppercase,
      'error-orange text-sm text-opacity-90 font-semibold': _vm.error,
      'text-gray-500 text-xs font-normal': !_vm.error
    }
  ]},[(_vm.error)?_c('fw-icon-error-warning',{staticClass:"w-5 h-5"}):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }