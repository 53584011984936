<template>
  <fw-layout wide :back-to-enable="false" :loading="loadingIntial">
    <template #main-content>
      <fw-panel-info type="orange" boxed icon class="mb-5">
        <div>
          A funcionalidades de Espaços de Aprendizagem está em
          <span class="font-bold">fase de testes</span>.<br />Caso se depare com algum erro ou identifique aspetos
          passíveis de melhoria, por favor, contacte-nos através do nosso
          <a class="underline" href="" @click.prevent="$router.push({ name: 'tasks-support-new' })">canal de suporte</a
          >.
        </div>
      </fw-panel-info>
      <LoadingPlaceholder v-if="loading" />
      <template v-else>
        <fw-panel
          v-if="courses.length"
          title="Espaços de aprendizagem"
          :counter="courses.length"
          :counter-total="pagination.total_items"
        >
          <ContextualSearch :loading="loading" :start-value="searchInput" @search="loadCourses"></ContextualSearch>
          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4 flex-1">
            <CardCourse v-for="course in courses" :key="`${course.key}_${course.edition.key}`" :course="course" />
          </div>
        </fw-panel>

        <fw-panel v-else centered clean title="Espaços de aprendizagem" class="mt-5">
          <div class="flex flex-col gap-5 items-center justify-center p-10">
            <div class="text-center">
              <div class="rounded-full p-5 bg-gray-300 text-white">
                <fw-icon-cube class="w-12 h-12" />
              </div>
            </div>
            <div>Sem espaços.</div>
          </div>
        </fw-panel>

        <BlockPagination
          v-if="courses.length"
          :per-page="pagination.active_limit"
          :total="pagination.total_items"
          :total-pages="pagination.total_pages"
          :current.sync="pagination.current_page"
          @page-changed="pageChanged"
        />
      </template>
    </template>
  </fw-layout>
</template>

<script>
import CardCourse from '@/fw-modules/fw-core-vue/courses/components/cards/CardCourse'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    ContextualSearch,
    CardCourse,
    LoadingPlaceholder,
    BlockPagination
  },

  data() {
    return {
      loadingIntial: true,
      loading: false,
      courses: [],
      searchInput: '',
      pagination: {
        current_page: 1,
        total_pages: 1,
        active_limit: 50,
        total_items: 0
      }
    }
  },

  computed: {},

  async created() {
    await this.loadCourses()
    this.loadingIntial = false
  },

  methods: {
    pageChanged(page) {
      console.log('pageChanged', page)
      this.pagination.current_page = page
      this.loadCourses({ term: this.searchInput })
    },
    async loadCourses({ term } = {}) {
      this.loading = true
      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.getUserCourses({
          query: term,
          limit: this.pagination.active_limit,
          page: this.pagination.current_page
        })
        this.courses = response.courses
        this.pagination = response.pagination
        console.log('loadCourses :>> ', response)
      })
      this.loading = false
    }
  }
}
</script>
