var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full flex flex-col h-full"},[(_vm.showHeader)?_c('div',{staticClass:"flex sm:flex-row flex-col gap-3",class:{
      'px-5 py-3 md:pt-4': !_vm.paddingless
    }},[_c('div',{staticClass:"flex flex-col gap-1 sm:flex-1"},[(_vm.app)?_c('fw-heading',{attrs:{"size":"lg"}},[_vm._v(_vm._s(_vm.appLabel(_vm.app)))]):_vm._e(),_c('fw-heading',{attrs:{"size":_vm.app ? 'md' : 'lg',"muted":!!_vm.app,"counter":_vm.loading ? 0 : _vm.notifications.length,"counter-total":_vm.loading ? 0 : _vm.totalResults}},[_vm._v(" "+_vm._s(_vm.filterType == 'inbox' || _vm.filterType == 'unread' ? 'Inbox' : _vm.filterType == 'archived' ? 'Arquivadas' : _vm.$t('notifications'))+" ")])],1),_c('div',{staticClass:"flex gap-1 items-center"},[(false)?_c('div',[(_vm.stats.unread)?_c('fw-tag',{attrs:{"type":"primary","size":"xs"}},[_vm._v(" "+_vm._s(_vm.stats.unread)+" por ler ")]):_vm._e()],1):_vm._e(),_c('div',[(_vm.filterType == 'inbox' || _vm.filterType == 'unread')?_c('fw-button',{attrs:{"type":_vm.filterType == 'unread' ? 'link' : 'link-muted'},nativeOn:{"click":function($event){return _vm.$emit('change-type', 'unread')}}},[_c('fw-icon-filter',{staticClass:"w-4 h-4 flex-shrink-0 mr-1 inline-flex"}),_vm._v(" "+_vm._s(_vm.$t('unread'))+" ")],1):_vm._e()],1),(_vm.filterType == 'inbox' || _vm.filterType == 'unread')?_c('div',[_c('fw-menu-more',[_c('fw-button',{attrs:{"type":'dropdown-item'},nativeOn:{"click":function($event){return _vm.markAll('read')}}},[_vm._v(" "+_vm._s(_vm.$t('markAsRead'))+" ")])],1)],1):_vm._e()])]):_vm._e(),(_vm.showHeader)?_c('div',{class:{
      'px-5 pb-3 md:px-4': !_vm.paddingless
    }},[_c('ContextualSearch',{attrs:{"type":"minimal","filter-options":_vm.filterOptions,"loading":_vm.loading,"applied-filters":_vm.appliedFilters,"show-filters":false},on:{"search":_vm.search}})],1):_vm._e(),_c('div',{staticClass:"flex-1 flex flex-col h-full overflow-y-auto",class:{
      'px-5': !_vm.paddingless,
      'pt-3': _vm.showHeader
    }},[(_vm.loading || (_vm.notifications.length == 0 && _vm.query))?_c('div',{staticClass:"text-sm text-gray-500 py-10 text-center flex justify-center items-center h-full"},[(_vm.loading)?_c('div',{staticClass:"animate-pulse"},[_vm._v("A carregar...")]):_c('div',[_vm._v(" Sem resultados para o termo "),_c('b',[_vm._v("\""+_vm._s(_vm.query)+"\"")])])]):_c('BlockListNotifications',{key:_vm.filterType,attrs:{"type":_vm.type,"notifications":_vm.notifications,"view":_vm.filterType,"query":_vm.query,"selected":_vm.activeNotificationKey,"hide-open":_vm.hideOpen,"force-open-details":_vm.forceOpenDetails},on:{"remove-from-list":_vm.removeNotificationFromList,"update":_vm.updateNotification}})],1),(_vm.usePagination && _vm.totalPages > 1 && !_vm.loading)?_c('BlockPagination',{attrs:{"per-page":_vm.limit,"total":_vm.totalResults,"total-pages":_vm.totalPages,"current":_vm.page},on:{"update:current":function($event){_vm.page=$event},"page-changed":function($event){return _vm.pageChanged($event)}}}):(!_vm.usePagination && _vm.totalPages > 1 && !_vm.loading)?_c('div',{staticClass:"text-center"},[_c('fw-button',{attrs:{"type":"link"},nativeOn:{"click":function($event){return _vm.seeAll()}}},[_vm._v("Ver todas as notificações")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }