<template>
  <a :href="openItem" class="group bg-gray-200 rounded-xl overflow-hidden flex flex-col gap-1 p-1">
    <div class="relative">
      <div v-if="backgroundImage" class="overflow-hidden rounded-t-xl">
        <img :src="backgroundImage" alt="Imagem de capa" class="w-full h-44 object-cover object-center" />
      </div>
      <div
        class="px-4 py-3 overflow-hidden rounded-lg"
        :class="{
          'absolute top-0 left-0 w-full z-10 bg-gradient-to-b from-gray-800 to-transparent h-20 text-white': backgroundImage
        }"
      >
        <div class="flex gap-3 justify-between items-center opacity-90">
          <fw-icon-cube class="w-6 h-6" />
          <div>
            <fw-tag :type="edition.status === 'published' ? 'primary' : backgroundImage ? 'white' : 'light'">{{
              statusLabels[edition.status]
            }}</fw-tag>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-1 px-4 py-3 text-gray-500 flex flex-col justify-end gap-2">
      <div class=" flex-1 font-bold uppercase text-lg">
        <v-clamp autoresize :max-lines="3">
          {{ edition.title }}
        </v-clamp>
      </div>

      <div class="flex flex-col gap-1 text-xs">
        <div>
          <fw-label :color="'text-gray-500'" marginless>Inscrições</fw-label>

          <div v-if="edition.signup_start_date && edition.signup_end_date">
            {{ edition.signup_start_date | formatDateTime }} a {{ edition.signup_end_date | formatDateTime }}
          </div>
          <div v-else>Prazo não definido.</div>
        </div>
        <div>
          <fw-label :color="'text-gray-500'" marginless>Realização</fw-label>

          <div v-if="edition.start_date && edition.start_date">
            {{ edition.start_date | formatDateTime }} a {{ edition.end_date | formatDateTime }}
          </div>
          <div v-else>Prazo não definido.</div>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

export default {
  props: {
    course: {
      type: Object,
      required: true
    }
  },

  computed: {
    edition() {
      return this.course.edition
    },
    statusLabels() {
      return {
        draft: 'Rascunho',
        published: 'Publicado',
        archived: 'Arquivado',
        closed: 'Terminado'
      }
    },
    backgroundImage() {
      return this.edition.cover ? ServiceStorage.getImageViewUrl(this.edition.cover) : null
    },
    openItem() {
      return this.$router.resolve({
        name: 'course-edition',
        params: {
          courseKey: this.course.key,
          editionKey: this.edition.key
        }
      }).href
    }
  }
}
</script>
