<template>
  <fw-panel :title="title || file?.filename">
    <template #toolbar>
      <DownloadButton @download="downloadFile" />
    </template>

    <template v-if="isPdf || isImage">
      <div ref="space-available" class="h-full">
        <v-zoomer
          v-if="previewUrl && hasSpace && isPdf"
          :ref="'zoomer'"
          :limit-translation="false"
          :style="{ width: widthSpace + 'px', height: heightSpace + 'px' }"
        >
          <img :src="previewUrl" class="h-full w-full object-contain" />
        </v-zoomer>
        <img
          v-if="previewUrl && hasSpace && isImage"
          :src="previewUrl"
          class="object-contain object-center mx-auto"
          :style="{ 'max-width': widthSpace + 'px', 'max-height': heightSpace + 'px' }"
        />
      </div>
      <BlockPagination
        v-if="pdfTotalPages > 1 && isPdf"
        :range-before="1"
        :range-after="1"
        :per-page="1"
        :total="pdfTotalPages"
        :total-pages="pdfTotalPages"
        :current.sync="pdfCurrentPage"
        @page-changed="pageChanged"
      />
    </template>
    <div v-else ref="space-available" class="h-full">
      <fw-panel-info type="basic" class="text-center my-5 text-gray-500">
        {{ $t('nopreview') }}
      </fw-panel-info>
    </div>
  </fw-panel>
</template>

<script>
import VueZoomer from 'vue-zoomer'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

import DownloadButton from '@/fw-modules/fw-core-vue/ui/components/buttons/DownloadButton'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'

export default {
  components: { DownloadButton, BlockPagination, VZoomer: VueZoomer.Zoomer },

  props: {
    title: {
      type: String,
      default: ''
    },
    file: {
      type: Object,
      default: null
    },
    emitDownload: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: true,
      uploading: false,
      pdfCurrentPage: 1,
      widthSpace: 200,
      heightSpace: 200,
      hasSpace: false
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },

    isPdf() {
      if (!this.file) return false
      const parts = this.file?.filename?.split('.')
      return parts && parts.at(-1) === 'pdf'
    },

    isImage() {
      if (!this.file) return false
      return this.file['type'].split('/')[0] === 'image'
    },

    pdfTotalPages() {
      return this.file?.pdf?.pages?.length
    },

    previewUrl() {
      const url = ServiceStorage.getFileUrl(
        {
          url_format: this.file.url_format,
          key: this.isPdf ? `${this.file.key}-${this.pdfCurrentPage}` : this.file.key,
          filename: this.isPdf ? `${this.file.filename.replace('.pdf', '.png')}` : this.file.filename
        },
        this.user.token
      )

      console.log('previewPdfUrl :>> ', url)

      return url
    }
  },

  mounted() {
    this.calculateSpace()
  },

  methods: {
    calculateSpace() {
      const space = this.$refs['space-available']
      if (space) {
        const spaceHeight = window.innerHeight - 200
        const spaceWidth = space.clientWidth
        this.heightSpace = spaceHeight
        this.widthSpace = spaceWidth
        this.$nextTick(() => {
          this.hasSpace = true
        })
      }
    },
    downloadFile(file) {
      if (this.emitDownload) {
        this.$emit('download', file)
      } else {
        const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
        utils.downloadFile(url, file.filename)
        this.$emit('downloaded', file)
      }
    },

    pageChanged(page) {
      //Reset zoom
      if (this.$refs.zoomer) {
        this.$refs.zoomer.reset()
      }
      if (page) this.pdfCurrentPage = page
      else this.pdfCurrentPage = 1
    },

    loadedImage() {
      console.log('loadImage')
      this.loading = false
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "close": "Fechar",
		"nopreview": "Sem prré-visualização disponível"
  },
  "en": {
    "close": "Close",
		"nopreview": "No preview available"
  }
}
</i18n>
