<template>
  <a v-if="linkUrl" :href="linkUrl">
    <Avatar :user="user" :size="size" :custom-class="customClass" />
  </a>
  <div v-else>
    <Avatar :user="user" :size="size" :custom-class="customClass" />
  </div>
</template>

<script>
import Avatar from '@/fw-modules/fw-core-vue/ui/components/users/Avatar'

export default {
  components: {
    Avatar
  },

  props: {
    linkUrl: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'sm'
    },
    customClass: {
      type: String,
      default: ''
    },
    user: {
      type: Object
    }
  }
}
</script>
