<template>
  <div class="relative">
    <LoadingPlaceholder v-if="loading" />

    <div v-else>
      <fw-panel :title="$t('career')" featured> </fw-panel>

      <fw-panel :title="'Metadados'" boxed="lg" class="mb-5" custom-class="bg-white flex flex-col gap-3">
        <template #toolbar>
          <div class="flex gap-1 items-center">
            <div v-if="$v.$error" class="p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium">
              <fw-icon-error-warning class="w-6 h-6"></fw-icon-error-warning>
              <span class="hidden lg:block">{{ $t('thereAreErrors') }}</span>
            </div>
            <div class="flex gap-5">
              <fw-button
                :type="isDirty ? 'primary' : 'light'"
                :disabled="savingData"
                :loading="savingData"
                @click.native="savePortfolio(true)"
                >{{ $t('save') }}</fw-button
              >
            </div>
          </div>
        </template>
        <div>
          <fw-label v-if="!portfolio.options.hasEnglish">{{ $t('description.label') }}</fw-label>
          <div v-if="portfolio.options.hasEnglish">
            <fw-label>{{ $t('description.pt') }}</fw-label>
            <fw-editor v-model="description['pt']" @input="isDirty = true"></fw-editor>
            <fw-label>{{ $t('description.en') }}</fw-label>
            <fw-editor v-model="description['en']" @input="isDirty = true"></fw-editor>
          </div>
          <fw-editor v-else v-model="description.pt" @input="isDirty = true"></fw-editor>
          <fw-tip v-if="$v.description.$error" error>
            {{ $t('description.required') }}
          </fw-tip>
        </div>

        <div class="hidden">
          <fw-label>{{ $t('files.label') }}</fw-label>
          <div
            v-if="portfolio.carrer_development.files && portfolio.carrer_development.files.length > 0"
            class="files mb-1.5"
          >
            <RecordFileEntry
              v-for="(file, f) in portfolio.carrer_development.files"
              :key="file.key"
              :can-edit="true"
              :allow-classified="false"
              :file="file"
              @save="saveFile(f, $event)"
              @remove="removeFile(f)"
              @download="downloadFile(file)"
            />
          </div>
          <Uploader
            :label="$t('files.upload')"
            :is-docked="true"
            layout="minimal"
            reference-id="uploader_update"
            allowed="all"
            :clear-after="true"
            input-id="upload_input"
            :files.sync="updateFilesToUpload"
            :size="0"
            :new-file-context="{}"
            file-type="file"
            file-code=""
            class="cursor-pointer update-modal-uploader"
            uploader-class="w-full rounded"
            @upload="uploaded"
          />
        </div>
      </fw-panel>
      <fw-panel :title="'Atividades desenvolvidas'" boxed="lg" class="mb-5" custom-class="bg-white flex flex-col gap-3">
        <fw-label>{{ $t('professional_experience') }}</fw-label>
        <div class="flex flex-col gap-5 mb-5">
          <div
            v-for="(item, i) in portfolio.carrer_development.professional_experience"
            :key="'professionalitem' + i"
            class="py-2 flex flex-col gap-3 border-b border-gray-200"
          >
            <div class="flex items-center">
              <div class="flex-1 text-lg font-bold text-black">
                {{ item.description[language] }}
              </div>
              <b-dropdown aria-role="list" position="is-bottom-left">
                <template #trigger="{ active }">
                  <fw-button type="white" size="sm"> <fw-icon-more class="w-5 h-5 text-gray-500"/></fw-button>
                </template>
                <b-dropdown-item paddingless aria-role="listitem">
                  <fw-button
                    type="white-gray"
                    class="w-full text-left"
                    @click.native="editCurriculumItem('professional_experience', i)"
                    >{{ $t('edit') }}</fw-button
                  >
                </b-dropdown-item>
                <b-dropdown-item paddingless aria-role="listitem">
                  <fw-button
                    type="white-gray"
                    class="w-full text-left"
                    @click.native="removeActivity('professional_experience', i)"
                    >{{ $t('delete') }}</fw-button
                  >
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="flex flex-col">
              <fw-label>{{ 'Data' }}</fw-label>
              <div class="text-sm text-gray-500">{{ item.date }}</div>
            </div>
            <div class="flex flex-col">
              <fw-label>{{ 'Local' }}</fw-label>
              <div class="text-sm text-gray-500">{{ item.location }}</div>
            </div>
            <div class="flex flex-col">
              <fw-label>{{ $t('developed_comeptences') }}</fw-label>
              <div class="flex flex-wrap items-center gap-3">
                <div
                  v-for="(competence, c) in item.competences"
                  :key="'academicitem' + i + c"
                  class="bg-gray-100 rounded-full pl-5 pr-3 py-1 font-semibold flex items-center cursor-pointer"
                  @click="removeCompetence('professional_experience', i, c)"
                >
                  {{ competence.name[language] }} <fw-icon-close class=" h-5 w-5 text-gray-400"></fw-icon-close>
                </div>
                <div
                  class=" bg-primary hover:opacity-80 cursor-pointer rounded-full font-semibold px-5 py-1 text-white"
                  @click="addCompetence('professional_experience', i)"
                >
                  {{ $t('add_competence') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <fw-button type="regular" expanded class="" @click.native="addCurriculumItem('professional_experience')">{{
          $t('add_activity')
        }}</fw-button>

        <fw-label>{{ $t('volunteering') }}</fw-label>
        <div class="flex flex-col gap-5 mb-5">
          <div
            v-for="(item, i) in portfolio.carrer_development.volunteering"
            :key="'volunteeringitem' + i"
            class="py-2 flex flex-col gap-3 border-b border-gray-200"
          >
            <div class="flex items-center">
              <div class="flex-1 text-lg font-bold text-black">
                {{ item.description[language] }}
              </div>
              <b-dropdown aria-role="list" position="is-bottom-left">
                <template #trigger="{ active }">
                  <fw-button type="white" size="sm"> <fw-icon-more class="w-5 h-5 text-gray-500"/></fw-button>
                </template>
                <b-dropdown-item paddingless aria-role="listitem">
                  <fw-button
                    type="white-gray"
                    class="w-full text-left"
                    @click.native="editCurriculumItem('volunteering', i)"
                    >{{ $t('edit') }}</fw-button
                  >
                </b-dropdown-item>
                <b-dropdown-item paddingless aria-role="listitem">
                  <fw-button
                    type="white-gray"
                    class="w-full text-left"
                    @click.native="removeActivity('volunteering', i)"
                    >{{ $t('delete') }}</fw-button
                  >
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="flex flex-col">
              <fw-label>{{ 'Data' }}</fw-label>
              <div class="text-sm text-gray-500">{{ item.date }}</div>
            </div>
            <div class="flex flex-col">
              <fw-label>{{ 'Local' }}</fw-label>
              <div class="text-sm text-gray-500">{{ item.location }}</div>
            </div>
            <div class="flex flex-col">
              <fw-label>{{ $t('developed_comeptences') }}</fw-label>
              <div class="flex flex-wrap items-center gap-3">
                <div
                  v-for="(competence, c) in item.competences"
                  :key="'volunteeringcitem' + i + c"
                  class="bg-gray-100 rounded-full pl-5 pr-3 py-1 font-semibold flex items-center cursor-pointer"
                  @click="removeCompetence('volunteering', i, c)"
                >
                  {{ competence.name[language] }} <fw-icon-close class=" h-5 w-5 text-gray-400"></fw-icon-close>
                </div>
                <div
                  class=" bg-primary hover:opacity-80 cursor-pointer rounded-full font-semibold px-5 py-1 text-white"
                  @click="addCompetence('volunteering', i)"
                >
                  {{ $t('add_competence') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <fw-button type="regular" expanded class="" @click.native="addCurriculumItem('volunteering')">{{
          $t('add_activity')
        }}</fw-button>

        <fw-label>{{ $t('formations') }}</fw-label>
        <div class="flex flex-col gap-5 mb-5">
          <div
            v-for="(item, i) in portfolio.carrer_development.certifications"
            :key="'formationsitem' + i"
            class="py-2 flex flex-col gap-3 border-b border-gray-200"
          >
            <div class="flex items-center">
              <div class="flex-1 text-lg font-bold text-black">
                {{ item.description[language] }}
              </div>
              <b-dropdown aria-role="list" position="is-bottom-left">
                <template #trigger="{ active }">
                  <fw-button type="white" size="sm"> <fw-icon-more class="w-5 h-5 text-gray-500"/></fw-button>
                </template>
                <b-dropdown-item paddingless aria-role="listitem">
                  <fw-button
                    type="white-gray"
                    class="w-full text-left"
                    @click.native="editCurriculumItem('certifications', i)"
                    >{{ $t('edit') }}</fw-button
                  >
                </b-dropdown-item>
                <b-dropdown-item paddingless aria-role="listitem">
                  <fw-button
                    type="white-gray"
                    class="w-full text-left"
                    @click.native="removeActivity('certifications', i)"
                    >{{ $t('delete') }}</fw-button
                  >
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="flex flex-col">
              <fw-label>{{ 'Data' }}</fw-label>
              <div class="text-sm text-gray-500">{{ item.date }}</div>
            </div>
            <div class="flex flex-col">
              <fw-label>{{ 'Local' }}</fw-label>
              <div class="text-sm text-gray-500">{{ item.location }}</div>
            </div>
            <div class="flex flex-col">
              <fw-label>{{ $t('developed_comeptences') }}</fw-label>
              <div class="flex flex-wrap items-center gap-3">
                <div
                  v-for="(competence, c) in item.competences"
                  :key="'formationsitem' + i + c"
                  class="bg-gray-100 rounded-full pl-5 pr-3 py-1 font-semibold flex items-center cursor-pointer"
                  @click="removeCompetence('certifications', i, c)"
                >
                  {{ competence.name[language] }} <fw-icon-close class="h-5 w-5 text-gray-400"></fw-icon-close>
                </div>
                <div
                  class=" bg-primary hover:opacity-80 cursor-pointer rounded-full font-semibold px-5 py-1 text-white"
                  @click="addCompetence('certifications', i)"
                >
                  {{ $t('add_competence') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <fw-button type="regular" expanded class="" @click.native="addCurriculumItem('certifications')">{{
          $t('add_activity')
        }}</fw-button>
      </fw-panel>

      <ModalCompetence ref="modalcompetence" @competence="createCompetence" />
      <ModalCareerActivity ref="modalcarreeractivity" @save="saveActivity" />
    </div>
  </div>
</template>

<script>
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import { required, minLength } from 'vuelidate/lib/validators'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/PathUploader.vue'
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import ModalCompetence from '@/components/modals/ModalCompetence'
import ModalCareerActivity from '@/components/modals/ModalCareerActivity'

export default {
  components: {
    LoadingPlaceholder,
    RecordFileEntry,
    Uploader,
    ModalCompetence,
    ModalCareerActivity,
  },

  props: {
    portfolio: {
      type: Object,
      default: () => {
        return {}
      },
    },

    savingData: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true,
      },
      updateFilesToUpload: [],
      pointer: {
        a: 0,
        i: 0,
      },
      description: {},
      isDirty: false,
    }
  },
  computed: {
    api() {
      return this.$store.state.api.base
    },

    language() {
      return this.$store.state.language
    },

    secondaryLanguage() {
      return this.language === 'pt' ? 'en' : 'pt'
    },
  },

  mounted() {
    this.description = this.portfolio.carrer_development.description
  },

  validations() {
    return {
      description: {
        pt: { required, min: minLength(1) },
      },
    }
  },

  methods: {
    removeActivity(a, i) {
      this.$buefy.dialog.confirm({
        title: this.language == 'pt' ? 'Remover atividade' : 'Remove activity',
        cancelText: this.language == 'pt' ? 'Cancelar' : 'Cancel',
        confirmText: this.language == 'pt' ? 'Remover' : 'Remove',
        message:
          this.language == 'pt'
            ? 'Tem a certeza que deseja remover esta atividade? Vai remover todas as competências associadas.'
            : 'Are you sure you want to remove this activity? You will remove all associated competences.',
        onConfirm: () => {
          this.portfolio.carrer_development[a].splice(i, 1)
          this.savePortfolio(false)
        },
      })
    },
    removeCompetence(a, i, c) {
      this.$buefy.dialog.confirm({
        message: this.$t('deleteCompetenceConfirm'),
        onConfirm: async () => {
          this.portfolio.carrer_development[a][i].competences.splice(c, 1)
          this.savePortfolio(false)
        },
      })
    },
    addAcademicYear() {
      let year = this.academic_years.length + 1
      this.academic_years.push({
        pt: year + 'º ano',
        en: year + ' year',
        curriculum: [],
        extracurriculum: [],
        autoevaluation: '',
      })
      this.savePortfolio(false)
    },
    addCurriculumItem(a) {
      this.pointer.a = a
      //this.showAcademicModal = true
      this.$refs.modalcarreeractivity.edit()
    },
    editCurriculumItem(a, i) {
      this.pointer.a = a
      this.pointer.i = i
      this.$refs.modalcarreeractivity.edit(this.portfolio.carrer_development[a][i])
    },
    addCompetence(a, i) {
      this.pointer.a = a
      this.pointer.i = i
      this.$refs.modalcompetence.show()
    },
    saveActivity(data) {
      if (data.editing) {
        this.portfolio.carrer_development[this.pointer.a][this.pointer.i].description = data.data.description
        this.portfolio.carrer_development[this.pointer.a][this.pointer.i].date = data.data.date
        this.portfolio.carrer_development[this.pointer.a][this.pointer.i].location = data.data.location
      } else {
        this.portfolio.carrer_development[this.pointer.a].push({
          description: data.data.description,
          competences: [],
          date: data.data.date,
          location: data.data.location,
        })
      }
      this.portfolio.carrer_development[this.pointer.a].sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
      })
      this.savePortfolio(false)
    },
    /*createActivity() {
      this.$v.newcompetence.$touch()
      this.$v.newcompetencedate.$touch()
      this.$v.location.$touch()
      if (this.$v.newcompetence.$invalid || this.$v.newcompetencedate.$invalid || this.$v.location.$invalid) return
      //convert date to YYYY-MM-DD
      this.portfolio.carrer_development[this.pointer.a].push({
        description: {
          pt: this.newcompetence.pt,
          en: this.newcompetence.en,
        },
        competences: [],
        date: this.newcompetencedate.toISOString().split('T')[0],
        location: this.location,
      })
      //order by date
      this.portfolio.carrer_development[this.pointer.a].sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
      })
      this.newcompetence = {
        pt: '',
        en: '',
      }
      this.newcompetencedate = null
      this.location = ''
      this.showAcademicModal = false
      this.savePortfolio(false)
    },*/
    async uploaded(files) {
      let newfiles = []
      for (const file of files) {
        if (file.response.data) {
          if (file.response.status === 'success') {
            newfiles.push(file.response.data.file)
          }
        }
      }

      this.task.files = this.task.files.concat(newfiles)
    },

    async downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },

    removeFile(f) {
      this.$buefy.dialog.confirm({
        message: this.$t('deleteFileConfirm'),
        onConfirm: async () => {
          this.task.files.splice(f, 1)
        },
      })
    },

    saveFile(f, updatedFile) {
      console.log('save filename', f, updatedFile)
      this.$set(this.task.files, f, { filename: updatedFile.title, key: updatedFile.key })
    },
    remove(i) {
      this.competences.splice(i, 1)
    },
    onDropSection(event) {
      console.log(event)
      let previousIndex = event.removedIndex
      this.$emit('change-order', event)
      console.log('previousIndex', previousIndex, event)
    },
    getChildPayload(index) {
      return this.competences[index]
    },
    savePortfolio(saveMetadata = true) {
      if (saveMetadata) {
        this.$v.description.$touch()
        if (this.$v.description.$invalid) return
      }
      let portfolio = JSON.parse(JSON.stringify(this.portfolio))
      if (saveMetadata) {
        portfolio.carrer_development.description = this.description
        this.isDirty = false
      }
      //replace competences with keys
      portfolio.academic_plan.items.forEach((item, i) => {
        item.curriculum.forEach((curriculum, c) => {
          portfolio.academic_plan.items[i].curriculum[c].competences.forEach((competence, cp) => {
            portfolio.academic_plan.items[i].curriculum[c].competences[cp] = competence.key
          })
        })

        item.extra_curriculum.forEach((extra_curriculum, c) => {
          portfolio.academic_plan.items[i].extra_curriculum[c].competences.forEach((competence, cp) => {
            portfolio.academic_plan.items[i].extra_curriculum[c].competences[cp] = competence.key
          })
        })
      })
      portfolio.carrer_development.professional_experience = portfolio.carrer_development.professional_experience.map(
        item => {
          item.competences = item.competences.map(competence => competence.key)
          return item
        }
      )
      portfolio.carrer_development.volunteering = portfolio.carrer_development.volunteering.map(item => {
        item.competences = item.competences.map(competence => competence.key)
        return item
      })
      portfolio.carrer_development.certifications = portfolio.carrer_development.certifications.map(item => {
        item.competences = item.competences.map(competence => competence.key)
        return item
      })
      this.$emit('save-portfolio', portfolio)
    },

    createCompetence(competence) {
      let found = this.portfolio.carrer_development[this.pointer.a][this.pointer.i].competences.find(
        c => c.key == competence.key
      )
      if (!found) {
        this.portfolio.carrer_development[this.pointer.a][this.pointer.i].competences.push(competence)
        this.savePortfolio(false)
      }
    },
  },
}
</script>
<i18n>
  {
    "pt": {
      "location": "Local",
      "add_competence": "+ Adicionar competência",
      "add_activity": "Adicionar atividade",
      "developed_comeptences": "Competências desenvolvidas",
      "deleteCompetenceConfirm": "Tem a certeza que deseja remover esta competência?",
      "activitydate": "Data da atividade",
      "formations": "Formações",
      "volunteering": "Voluntariado/Associativismo",
      "professional_experience": "Experiência profissional",
      "career": "Desenvolvimento de carreira",
      "activity": {
        "pt": "Nome da atividade em Português",
        "en": "Nome da atividade em Inglês",
        "required": "Insira o nome da atividade em Português e Inglês."
      },
      "academic_plan": "Plano académico",
      "files": {
        "label": "Ficheiros",
        "upload": "Carregar ficheiros"
      },
      "aboutme": "Sobre mim",
      "competences": "Competências",
      "settings": "Configurações",
      "thereAreErrors": "Existem erros no formulário",
      "close": "Fechar",
      "edit": "Editar",
      "save": "Guardar",
      "delete": "Remover",
      "personalInfo": "Informação pessoal",
      "tournamentTitle": "Título",
      "competence": {
        "pt": "Nome da competência em Português",
        "en": "Nome da competência em Inglês",
        "required": "Insira o nome da competência em Português e Inglês."
      },
      "description": {
        "label": "Sumário",
        "pt": "Sumário em Português",
        "en": "Sumário em Inglês",
        "required": "Insira um sumário em Português e Inglês."
      },
      "interests": {
        "label": "Interesses",
        "pt": "Interesses em Português",
        "en": "Interesses em Inglês",
        "required": "Insira os seus interesses em Português e Inglês."
      },
        "hobbies": {
            "label": "Hobbies",
            "pt": "Hobbies em Português",
            "en": "Hobbies em Inglês",
            "required": "Insira os seus hobbies em Português e Inglês."
        },
        "shortdescription": {
            "label": "Descrição curta",
            "pt": "Descrição curta em Português",
            "en": "Descrição curta em Inglês",
            "required": "Insira uma descrição curta em Português e Inglês."
        },
      "charsLimit": "Limite de {limit} caracteres.",
      "notDefined": "Não definido"
    },
    "en": {
      "developed_comeptences": "Developed competences",
      "add_competence": "+ Add competence",
      "add_activity": "Add activity",
      "location": "Location",
      "deleteCompetenceConfirm": "Are you sure you want to remove this competence?",
      "activitydate": "Activity date",
      "formations": "Formations",
      "volunteering": "Volunteering/Associativism",
      "professional_experience": "Profissional experience",
      "career": "Career development",
      "activity": {
        "pt": "Name of the activity in Portuguese",
        "en": "Name of the activity in English",
        "required": "Enter the name of the activity in English and Portuguese"
      },
      "academic_plan": "Academic plan",
      "files": {
        "label": "Files",
        "upload": "Upload files"
      },
      "aboutme": "About me",
      "competences": "Competences",
      "settings": "Settings",
      "generalDetails": "General details",
      "tournamentTitle": "Title",
      "thereAreErrors": "There are errors in the form",
      "personalInfo": "Personal info",
      "close": "Close",
      "edit": "Edit",
      "save": "Save",
      "delete": "Remove",
      "competence": {
        "pt": "Name of the competence in Portuguese",
        "en": "Name of the competence in English",
        "required": "Insert the name of the competence in Portuguese and English"
      },
      "description": {
        "label": "Summary",
        "pt": "Summary in Portuguese",
        "en": "Summary in English",
        "required": "Enter a summary in English and Portuguese."
      },
      "interests": {
        "label": "Interests",
        "pt": "Interests in Portuguese",
        "en": "Interests in English",
        "required": "Enter your interests in English and Portuguese."
      },
      "hobbies": {
        "label": "Hobbies",
        "pt": "Hobbies in Portuguese",
        "en": "Hobbies in English",
        "required": "Enter your hobbies in English and Portuguese."
      },
      "shortdescription": {
        "label": "Short description",
        "pt": "Short description in Portuguese",
        "en": "Short description in English",
        "required": "Enter a short description in English and Portuguese."
      },
      "charsLimit": "Limit of {limit} characters.",
      "notDefined": "Not defined"
    }
  }
  </i18n>

<style>
.drag-handle {
  @apply h-6 w-5 shadow-sm absolute top-0.5 left-1 bg-white rounded border border-gray-200 items-center flex justify-center text-center;
  cursor: grab;
}
.update-modal-uploader .file-uploads {
  @apply w-full;
}
.aboutme-title {
  @apply text-primary font-semibold text-lg;
}
.portfolio-label {
  @apply font-semibold text-gray-500;
}
.portfolio-completed {
  @apply bg-primary text-sm rounded-full text-white px-3 py-0.5 font-semibold flex items-center justify-center;
}
</style>
