<template>
  <div v-if="results && results.units">
    <fw-heading size="xl">Resultados</fw-heading>
    <fw-panel v-if="false" title="Avaliações" class="my-5">
      <div class="flex flex-col md:flex-row gap-10">
        <div class="w-full md:w-1/3">
          <fw-heading size="h3">Satisfação global</fw-heading>
          <div>Estou satisfeito de forma global com o módulo?</div>
          <fw-label>Total por módulo.</fw-label>
        </div>
        <div class="w-full md:flex-1">
          <fw-heading size="h3">Avaliação dos tópicos</fw-heading>
          <fw-label>Média por módulo.</fw-label>
        </div>
      </div>
      <div class="flex flex-col md:flex-row gap-10">
        <div class="w-full md:w-1/3">
          <ChartBar
            :key="'chart-bar-global'"
            :chart-data="globalSatisfactionStats"
            :options="{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true
                    }
                  }
                ]
              },
              legend: {
                display: true,
                position: 'top',
                labels: {
                  fontColor: 'rgb(0, 0, 0)'
                }
              }
            }"
          >
          </ChartBar>
        </div>
        <div class="w-full md:flex-1">
          <ChartBar
            :key="'chart-bar-per-unit'"
            :chart-data="perUnitStats"
            :options="{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true
                    }
                  }
                ]
              },
              legend: {
                display: true,
                position: 'top',
                labels: {
                  fontColor: 'rgb(0, 0, 0)'
                }
              }
            }"
          >
          </ChartBar>
        </div>
      </div>
    </fw-panel>
    <fw-panel title="Mapas de avaliação" subtitle="Por módulo" class="my-5">
      <template #default>
        <div class="mt-5 px-5">
          <div v-for="unit in results.units" :key="unit.index" class="my-5">
            <div class="flex gap-5">
              <div class="flex-1 flex gap-2">
                <div class="my-1 flex-shrink-0">
                  <fw-icon-book-mark-solid class="w-5 h-5" />
                </div>
                <div>
                  <v-clamp class="w-full flex-1 text-left font-bold text-lg items-end flex" autoresize :max-lines="3">
                    {{ unit.title }}
                  </v-clamp>
                </div>
              </div>
            </div>
            <div class="flex flex-col mt-3 bg-white py-3 px-4 rounded-lg">
              <div>
                <fw-label>Ficheiros de resultados disponíveis</fw-label>
              </div>
              <div
                v-if="false"
                class="flex items-center gap-5 bg-white hover:bg-gray-200 hover:bg-opacity-60 px-2 py-1.5 rounded-md border-b border-gray-100"
              >
                <div class="flex-1" :class="{ 'text-gray-500': !unit.feedback_file }">
                  Tópicos
                </div>
                <div v-if="unit.feedback_file">
                  <fw-button type="xlight" @click.native="downloadFile(unit.feedback_file)">
                    <fw-icon-cloud-download class="w-4 h-4 mr-2" /> Descarregar .csv
                  </fw-button>
                </div>
                <div v-else class="text-xs text-gray-500 py-2">Ficheiro não disponível.</div>
              </div>
              <template v-for="topic in unit.topics">
                <div
                  v-if="topic.type !== 'normal'"
                  :key="topic.key"
                  class="flex items-center gap-5 bg-white hover:bg-gray-200 hover:bg-opacity-60 px-2 py-1.5 rounded-md border-b border-gray-100"
                >
                  <div class="flex-1" :class="{ 'text-gray-500': !topic.file }">
                    {{ topic.title }}
                  </div>
                  <div v-if="topic.file">
                    <fw-button type="xlight" @click.native="downloadFile(topic.file)">
                      <fw-icon-cloud-download class="w-4 h-4 mr-2" /> Descarregar .csv
                    </fw-button>
                  </div>
                  <div v-else class="text-xs text-gray-500 py-2">Ficheiro não disponível.</div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </fw-panel>
  </div>
</template>

<script>
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ChartBar from '@/fw-modules/fw-core-vue/ui/components/charts/ChartBar'

export default {
  components: {
    ChartBar
  },
  props: {
    course: Object,
    edition: Object,
    units: [Object, Array],
    results: Object
  },
  data() {
    return {
      feedbackItems: {
        content_relevant: 'Os conteúdos são relevantes para a minha prática profissional.',
        content_organized: 'Os conteúdos estão bem organizados.',
        speaker: 'O orador comunicou de forma clara.',
        content_looks: 'Os conteúdos são esteticamente apelativos (áudio e vídeo).'
      },
      globalSatisfactionStats: null,
      perUnitStats: null,
      // https://www.heavy.ai/blog/12-color-palettes-for-telling-better-stories-with-your-data
      availableGraphColors: [
        '#fd7f6f',
        '#7eb0d5',
        '#b2e061',
        '#bd7ebe',
        '#ffb55a',
        '#ffee65',
        '#beb9db',
        '#fdcce5',
        '#8bd3c7'
      ]
    }
  },
  computed: {
    unitsByIndex() {
      // We should have units ordered by index...
      // but lets avoid any mistake and double check (make sure we get the correct progress)
      return this.units.reduce((obj, item) => {
        obj[item['index']] = item
        return obj
      }, {})
    }
  },
  // watch: {
  //   results(value) {
  //     if (value) this.initCharts()
  //   },
  // },
  mounted() {
    this.initCharts()
  },
  methods: {
    downloadFile(file) {
      utils.downloadAuthenticatedFileFromStorage(file)
    },
    initCharts() {
      this.globalSatisfactionStats = {
        labels: ['M1', 'M2', 'M3', 'M4', '53', 'M6'],
        datasets: [
          {
            label: 'Sim',
            data: [129, 1],
            backgroundColor: '#b2e061'
            // borderColor: 'rgb(75, 192, 192)',
            // tension: 0.1,
          },
          {
            label: 'Não',
            data: [19, 2],
            backgroundColor: '#999'
          }
        ]
      }
      this.perUnitStats = {
        labels: ['M1', 'M2', 'M3', 'M4', '53', 'M6'],
        datasets: [
          {
            label: 'content_relevant',
            data: [4, 4, 3, 2, 5],
            backgroundColor: this.getGraphColor('M1')
          },
          {
            label: 'content_organized',
            data: [4, 4, 3, 2, 5],
            backgroundColor: this.getGraphColor('M2')
          },
          {
            label: 'speaker',
            data: [4, 4, 3, 2, 5],
            backgroundColor: this.getGraphColor('M3')
          },
          {
            label: 'content_looks',
            data: [4, 4, 3, 2, 5],
            backgroundColor: this.getGraphColor('M4')
          }
        ]
      }
    },
    getGraphColor(code) {
      var sum = 0
      for (let i in code) {
        sum += code.charCodeAt(i)
      }
      return this.availableGraphColors[sum % this.availableGraphColors.length]
    }
  }
}
</script>

<i18n>
{
  "en": {},
  "pt": {}
}
</i18n>
