import { render, staticRenderFns } from "./PanelParticipants.vue?vue&type=template&id=211b3112&scoped=true"
import script from "./PanelParticipants.vue?vue&type=script&lang=js"
export * from "./PanelParticipants.vue?vue&type=script&lang=js"
import style0 from "./PanelParticipants.vue?vue&type=style&index=0&id=211b3112&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "211b3112",
  null
  
)

export default component.exports