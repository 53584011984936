var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-uc-id-hero md:p-4 md:rounded-xl items-center justify-center md:flex gap-2",class:{
    'md:bg-gray-200 md:bg-opacity-60': _vm.type == 'light',
    'md:bg-yellow-700 md:bg-opacity-10': _vm.type == 'yellow',
    'md:bg-primary md:bg-opacity-10': _vm.type == 'primary',
    'md:bg-gray-800 md:bg-opacity-80': _vm.type == 'dark',
    'md:bg-gradient-to-r md:from-gray-600 md:to-gray-800 bg-opacity-80': _vm.type == 'dark-gradient' || _vm.management
  }},[_c('div',{staticClass:"px-10 py-5"},[_c('div',{staticClass:"uc-id-avatar",class:{ 'is-dark': _vm.isDark }},[_c('BlockUCId',{staticClass:"md:shadow-xl",attrs:{"user":_vm.user || _vm.loggedUser,"intro":!_vm.isVersion2}})],1)]),_c('div',{staticClass:"flex flex-1 flex-col gap-2 justify-between",class:{ 'text-white': _vm.isDark }},[(_vm.isVersion2)?_c('div',{staticClass:"flex justify-center md:justify-start"},[_c('h2',{staticClass:"text-2xl font-bold text-center opacity-90 uc-id-name",class:{ 'md:text-white is-dark': _vm.isDark }},[_c('v-clamp',{attrs:{"autoresize":"","max-lines":2}},[_vm._v(_vm._s(_vm.$t('hello'))+", "+_vm._s((_vm.user || _vm.loggedUser).name)+".")])],1)]):_vm._e(),(!_vm.statsLoading && _vm.statsTitle)?_c('fw-heading',{staticClass:"hidden md:block md:opacity-40 text-center md:text-left",attrs:{"size":"h3"}},[_vm._v(_vm._s(_vm.statsTitle))]):_vm._e(),(_vm.$slots.stats || _vm.stats)?_vm._t("stats",function(){return [_c('PanelStats',{staticClass:"hidden md:inline-flex",attrs:{"is-dark":_vm.isDark,"stats":_vm.stats,"stats-warning":_vm.statsWarning,"loading":_vm.statsLoading,"hide-if-empty":_vm.statsHideIfEmpty}})]}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }