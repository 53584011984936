<template>
  <div>
    <div
      class="flex items-center rounded-md "
      :class="{
        'bg-gray-100': color == 'default',
        'bg-white': color == 'white'
      }"
    >
      <div
        v-if="hasPrefix"
        class="rounded-l-lg h-full px-2.5 py-2 text-gray-500 border shadow-sm border-gray-200 bg-gray-200 bg-opacity-40"
      >
        <slot name="prefix"></slot>
      </div>
      <div class="flex-1">
        <div v-if="hasMain" class="pr-1">
          <slot name="main"></slot>
        </div>
        <input
          v-else
          :id="id"
          :minlength="minlength"
          :maxlength="maxlength"
          :type="type"
          :value="value"
          :disabled="disabled"
          class="input w-full py-1 outline-none border-0 ring-0 focus:outline-none focus:border-0 focus:ring-0"
          :class="[
            {
              'rounded-none': hasPrefix || hasSuffix,
              'rounded-r-md': hasPrefix && !hasSuffix,
              'rounded-l-md': hasSuffix && !hasPrefix,
              'rounded-md': !hasPrefix && !hasSuffix,
              'px-2.5': !paddingless,
              'px-0': paddingless,
              'bg-white': color == 'white'
            },
            customClass
          ]"
          :placeholder="placeholder"
          :name="id"
          :autocomplete="autocomplete"
          @input="emitInput($event.target.value)"
          @change="emitChange($event.target.value)"
          @focus="emitFocus()"
          @blur="emitBlur()"
        />
      </div>
      <div
        v-if="hasSuffix"
        class="rounded-r-lg h-full px-2.5 py-2 text-gray-500 border shadow-sm border-gray-200 bg-gray-200 bg-opacity-40"
      >
        <slot name="suffix"></slot>
      </div>
    </div>
    <div v-if="counter" class="flex justify-end">
      <div class="text-xs text-gray-400 py-1">{{ value.length }}/{{ maxlength }}</div>
    </div>
    <div v-if="help" class="text-xs text-gray-400 py-1">
      {{ help }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextInput',
  props: {
    value: {
      type: [String, Number]
    },
    placeholder: {
      type: String
    },
    id: {
      type: String
    },
    type: {
      type: String,
      default: 'text'
    },
    help: {
      type: String
    },
    minlength: {
      type: Number,
      default: 1
    },
    maxlength: {
      type: Number,
      default: 250
    },
    counter: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    uppercase: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String
    },
    customClass: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'default'
    },
    paddingless: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasPrefix() {
      return !!this.$slots['prefix']
    },
    hasSuffix() {
      return !!this.$slots['suffix']
    },
    hasMain() {
      return !!this.$slots['main']
    }
  },

  mounted() {
    if (this.autofocus) {
      this.$nextTick(() => {
        this.$el.querySelector('input').focus()
      })
    }
  },
  methods: {
    emitFocus() {
      this.$emit('focus')
    },
    emitBlur() {
      this.$emit('blur')
    },
    emitInput(event) {
      if (this.uppercase) {
        event = event.toUpperCase()
      }

      this.$emit('input', event)
    },
    emitChange(event) {
      this.$emit('change', event)
    }
  }
}
</script>

<style scoped></style>
