var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fw-panel-stats flex gap-5"},[(_vm.loading || _vm.chartIcon)?_c('div',[(_vm.loading)?_c('fw-icon-loading',{staticClass:"w-6 h-6"}):(_vm.chartIcon)?_c('fw-icon-chart-box',{staticClass:"w-6 h-6 opacity-50"}):_vm._e()],1):_vm._e(),(!_vm.loading)?_c('div',[(_vm.title)?_c('fw-heading',{staticClass:"mb-1",attrs:{"size":"h4","muted":""}},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('div',{class:{
        'grid grid-flow-col-dense gap-5': _vm.type == 'grid',
        'flex gap-3': _vm.type == 'card',
        'text-white text-opacity-90': _vm.isDark,
        'text-gray-500': !_vm.isDark
      }},[_vm._l((_vm.stats),function(metric){return [((_vm.hideIfEmpty && !_vm.emptyMetric(metric.value)) || !_vm.hideIfEmpty)?_c('div',{key:metric.$index,class:[
            {
              'bg-gray-200 bg-opacity-50 p-5 rounded-xl flex flex-col gap-1': _vm.type == 'card',
              'text-center md:text-left flex flex-col': _vm.type == 'grid'
            },
            metric.customClass
          ]},[_c('fw-heading',{attrs:{"size":metric.featured ? 'h1' : 'h3'}},[_vm._v(_vm._s(metric.value)+" "),(metric.valueSuffix)?_c('span',{staticClass:"text-md md:text-lg"},[_vm._v(_vm._s(metric.valueSuffix))]):_vm._e()]),(metric.label && _vm.type == 'card')?_c('div',{staticClass:"text-sm"},[_vm._v(_vm._s(metric.label))]):_vm._e(),(metric.label && _vm.type == 'grid')?_c('div',{staticClass:"pr-1 font-bold text-sm",class:{
              'text-gray-400': !_vm.isDark,
              'text-white text-opacity-70': _vm.isDark
            }},[_vm._v(" "+_vm._s(metric.label)+" ")]):_vm._e(),(metric.description)?_c('div',{staticClass:"text-xs pr-2 font-medium",class:{
              'text-gray-400': !_vm.isDark,
              'text-white text-opacity-50': _vm.isDark
            }},[_vm._v(" "+_vm._s(metric.description)+" ")]):_vm._e()],1):_vm._e()]})],2)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }