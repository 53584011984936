<template>
  <b-modal
    :active.sync="isActive"
    :width="500"
    scroll="keep"
    has-modal-card
    trap-focus
    aria-role="dialog"
    aria-modal
    :on-cancel="closeModal"
  >
    <div class="modal-card" style="width: auto; min-width: 320px">
      <section class="modal-card-body">
        <div class="has-text-centered">
          <div>
            <div class="is-size-2">Personalizar ID</div>
            <div class="has-margin-top-medium has-margin-bottom-medium is-inline-block">
              <div class="label">Escolha um novo ID</div>
              <b-field :type="error ? 'is-danger' : null" :message="error">
                <b-input
                  v-model="meetingAlias"
                  custom-class="has-text-centered"
                  size="is-medium"
                  type="text"
                  maxlength="25"
                  :placeholder="meeting.key_alias"
                />
              </b-field>
              <div>
                <b-button
                  :disabled="!isAliasValid"
                  class="is-primary"
                  size="is-medium"
                  expanded
                  @click="setMeetingAlias"
                  >Guardar ID</b-button
                >
              </div>
              <div v-if="!error" class="has-text-tiny is-width-tiny has-text-muted has-margin-top">
                Defina uma palavra sem acentuação, sem espaços e com um máximo de 25 carateres (letras minúsculas e
                números).
              </div>
            </div>
            <div>
              <b-button type="is-text" outlined @click="closeModal">Cancelar</b-button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </b-modal>
</template>

<script>
import ServiceSettings from '@/fw-modules/fw-core-vue/id/services/ServiceSettings'
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  props: {
    show: Boolean,
    meeting: Object,
    intro: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isActive: this.show || false,
      meetingAlias: null,
      slide: 0,
      error: null
    }
  },

  computed: {
    isAliasValid() {
      return this.meetingAlias && this.meetingAlias.length >= 3 && this.meetingAlias != this.meeting.key_alias
    },
    loggedUser() {
      return this.$store.getters.getUser
    }
  },

  watch: {
    show(value) {
      this.isActive = value
    }
  },

  methods: {
    closeModal() {
      this.isActive = false
      this.error = null
      this.meetingAlias = null
      this.$emit('close', false)

      if (this.intro) ServiceSettings.setSetting('intro-personalize-meeting-' + this.meeting.key, true)
    },

    async setMeetingAlias() {
      this.meetingAlias = this.meetingAlias.toLowerCase()
      try {
        await ServiceMeetings.setMeetingAlias(this.meeting.key, this.meetingAlias)
        this.meeting.key_alias = this.meetingAlias
        this.meeting.alias = [this.meetingAlias]

        if (this.meeting.key === this.loggedUser.meeting.key) {
          await this.$store.dispatch('updateUserMeetingAlias', this.meetingAlias)
        }

        // Update route param
        this.$router.replace({ params: { key: this.meeting.key_alias } })
        this.$buefy.snackbar.open({
          message: 'O seu ID foi personalizado',
          type: 'is-primary',
          position: 'is-top-right',
          duration: 5000,
          queue: false
        })
        this.closeModal()
      } catch (error) {
        const errorKey = utils.errors(error).getKey()
        if (errorKey == 'InvalidCode') {
          this.error = `O ID não é válido. Defina uma palavra sem acentuação,
            sem espaços e com um máximo de 25 carateres (letras minúsculas e números).`
        } else if (errorKey == 'AliasAlreadyDefined' || errorKey == 'AliasNotAllowed') {
          this.error = 'O ID que indica já está atribuido a outro utilizador ou é uma palavra reservada.'
        } else {
          this.error = `Não foi possível alterar o ID. Por favor, tente mais tarde.`
        }
      }
    }
  }
}
</script>
