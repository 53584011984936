<template>
  <b-dropdown
    v-if="canDelete || canPromote || canReply || canTranslate"
    ref="message_options"
    aria-role="list"
    append-to-body
    :position="isLast ? 'is-top-left' : 'is-bottom-left'"
  >
    <template #trigger>
      <fw-button
        ref="options"
        type="white"
        size="xs"
        class="opacity-50 hover:opacity-100"
        @click.native="promote = false"
      >
        <fw-icon-more class="w-5 h-5" />
      </fw-button>
    </template>
    <div v-if="promote" class="pb-1">
      <b-dropdown-item
        custom
        class="font-semibold text-sm flex items-center pl-4 relative text-gray-500 pt-2 pb-2 bg-gray-100 gap-2 cursor-pointer"
        @click="promote = false"
      >
        <div class="flex-1">Promover anúncio</div>
        <fw-icon-mega-phone class="h-5 w-5 text-gray-400" />
      </b-dropdown-item>
      <b-dropdown-item custom paddingless>
        <fw-label size="xs" class="ml-4">Duração</fw-label>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="promote"
        aria-role="listitem"
        class="w-full hover:bg-gray-100 flex gap-2.5 cursor-pointer font-medium text-sm"
        @click.native="$emit('promote-message', 'day')"
      >
        1 dia
      </b-dropdown-item>
      <b-dropdown-item
        aria-role="listitem"
        class="w-full hover:bg-gray-100 flex gap-2.5 cursor-pointer font-medium text-sm"
        @click.native="$emit('promote-message', 'week')"
      >
        1 semana
      </b-dropdown-item>
      <b-dropdown-item
        aria-role="listitem"
        class="w-full hover:bg-gray-100 flex gap-2.5 cursor-pointer font-medium text-sm"
        @click.native="$emit('promote-message', 'month')"
      >
        1 mês
      </b-dropdown-item>
      <b-dropdown-item
        aria-role="listitem"
        class="w-full hover:bg-gray-100 flex gap-2.5 cursor-pointer font-medium text-sm"
        @click.native="$emit('promote-message', 'forever')"
      >
        Para sempre
      </b-dropdown-item>
    </div>
    <div v-else class="py-1">
      <b-dropdown-item
        v-if="canTranslate && !isTranslated"
        aria-role="listitem"
        class="w-full hover:bg-gray-100 flex gap-3 cursor-pointer font-medium text-sm"
        @click.native="$emit('translate-message')"
      >
        <fw-icon-translate class="h-5 w-5 text-gray-400" /> {{ $t('translate') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="canTranslate && isTranslated"
        aria-role="listitem"
        class="w-full hover:bg-gray-100 flex gap-3 cursor-pointer font-medium text-sm"
        @click.native="$emit('untranslate-message')"
      >
        <fw-icon-translate class="h-5 w-5 text-gray-400" /> {{ $t('see_original') }}
      </b-dropdown-item>

      <b-dropdown-item
        v-if="canReply"
        aria-role="listitem"
        class="w-full hover:bg-gray-100 flex gap-3 cursor-pointer font-medium text-sm"
        @click.native="$emit('reply-message')"
      >
        <fw-icon-reply class="h-5 w-5 text-gray-400" /> {{ $t('reply') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="isAnnouncement && canPromote"
        aria-role="listitem"
        class="w-full hover:bg-gray-100 flex gap-3 cursor-pointer font-medium text-sm"
        @click.native="$emit('unpromote-message')"
      >
        <div class="relative">
          <div class="h-6 w-2.5 border-r-2 border-gray-500 rotate-45 absolute left-0.5 -top-1"></div>
          <fw-icon-mega-phone class="h-5 w-5 text-gray-400" />
        </div>
        {{ $t('unpromote') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-else-if="canPromote"
        aria-role="listitem"
        custom
        class="w-full hover:bg-gray-100 flex gap-3 cursor-pointer font-medium text-sm"
        @click.native.stop="promoteMessage()"
      >
        <fw-icon-mega-phone class="h-5 w-5 text-gray-400" /> {{ $t('promote') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="canDelete"
        aria-role="listitem"
        class="w-full hover:bg-gray-100 flex gap-3 cursor-pointer font-medium text-sm"
        @click.native="$emit('delete-message')"
      >
        <fw-icon-trash class="h-5 w-5 text-gray-400" /> {{ $t('delete') }}
      </b-dropdown-item>
    </div>
  </b-dropdown>
</template>

<script>
export default {
  name: 'MessageOptions',
  props: {
    message: {
      type: Object,
      required: true
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    canReply: {
      type: Boolean,
      default: false
    },
    canPromote: {
      type: Boolean,
      default: false
    },
    canTranslate: {
      type: Boolean,
      default: false
    },
    isTranslated: {
      type: Boolean,
      default: false
    },
    isLast: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      promote: false
    }
  },
  computed: {
    isAnnouncement() {
      return this.message.announcement.is_announcement
    }
  },
  methods: {
    promoteMessage() {
      this.promote = true
    }
  }
}
</script>

<i18n>
{
  "en": {
    "reply": "Reply",
    "promote": "Promote",
    "delete": "Delete",
    "unpromote": "Unpromote",
    "translate": "Translate",
    "see_original": "See original"
  },
  "pt": {
    "reply": "Responder",
    "promote": "Promover",
    "delete": "Eliminar",
    "unpromote": "Despromover",
    "translate": "Traduzir",
    "see_original": "Ver original"
  }
}
</i18n>
