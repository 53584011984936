<template>
  <div>
    <fw-panel :title="$t('selectUser')">
      <div class="mt-2 max-h-80 overflow-y-auto">
        <Person
          v-for="user in availableUsersObjects"
          :key="user.key"
          :person="user"
          :checked="selection.includes(user.key)"
          :selectable="true"
          @selected="
            selection = selection.includes(user.key) ? selection.filter(r => r !== user.key) : [...selection, user.key]
          "
        ></Person>
      </div>
    </fw-panel>

    <div class="flex items-center justify-end pt-5 gap-5">
      <fw-button type="link-muted" @click.native="close">
        {{ $t('cancel') }}
      </fw-button>

      <fw-button type="primary" :disabled="savingMessage" :loading="savingMessage" @click.native="saveSelection">
        {{ $t('select') }}
      </fw-button>
    </div>
  </div>
</template>

<script>
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/PersonBase'
export default {
  components: {
    Person
  },

  props: {
    users: {
      type: Object,
      default: () => {
        return {}
      }
    },
    availableUsersKeys: {
      type: Array,
      default: () => []
    },
    savingMessage: {
      type: Boolean,
      default: false
    },
    showPublicPrivateToggle: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selection: []
    }
  },

  computed: {
    availableUsersObjects() {
      return this.availableUsersKeys.map(key => this.users[key])
    }
  },

  methods: {
    close() {
      this.$emit('close')
    },
    saveSelection() {
      this.$emit('select', this.selection)
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "close": "Fechar",
    "cancel": "Cancelar",
    "selectUser": "Selecionar utilizador",
    "select": "Ok",
    "delete": "Eliminar",
    "send": "Enviar",
    "description": {
      "label": "Mensagem",
      "placeholder": "Escreva a sua mensagem"
    },
    "editMessage": "Editar mensagem",
    "files": "Ficheiros",
    "newMessage": "Nova mensagem",
    "privateMessages": "Mensagem privada",
    "selectThisOption": "Selecione esta opção para os mensagems serem apenas vísiveis pelos responsáveis.",
    "noMessages": "Sem mensagems",
    "addMessage": "Adicionar nova mensagem",
    "deleteFileConfirm": "Tem a certeza que deseja eliminar o ficheiro?",
    "uploadFiles": "Carregar ficheiros",
    "deleteMessageConfirm": "Tem a certeza que deseja eliminar a mensagem?",
    "errors": {
      "requiredMessage": "Insira um mensagem",
      "invalidMessage": "mensagem inválido"
    }
  },
  "en": {
    "selectUser": "Select user",
    "select": "Ok",
    "close": "Close",
    "cancel": "Cancel",
    "delete": "Delete",
    "send": "Send",
    "description": {
      "label": "Message",
      "placeholder": "Write your message"
    },
    "files": "Ficheiros",
    "editMessage": "Edit message",
    "newMessage": "New message",
    "privateMessages": "Private message",
    "selectThisOption": "Select this option for messages to be visible only by those responsible.",
    "noMessages": "No messages",
    "addMessage": "Add new message",
    "deleteFileConfirm": "Are you sure you want to delete the file?",
    "uploadFiles": "Upload files",
    "errors": {
      "requiredMessage": "Enter a message",
      "invalidMessage": "Invalid message"
    }
  }
}
</i18n>
