<template>
  <button
    class="flex flex-col items-center transition-colors duration-150 leading-3 hover:text-primary"
    :class="{
      'text-primary': active || (checkActualRoute && $router.currentRoute.path == to),
      'text-gray-400': !active && checkActualRoute && $router.currentRoute.path != to
    }"
    @click="goToOrRun"
  >
    <div class="sr-only">{{ label }}</div>
    <slot name="svg">
      <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32">
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
        />
      </svg>
    </slot>
    <div
      style="font-size: .65rem"
      class="mt-1 font-regular leading-3 text-center text-xs break-words cursor-pointer box-border"
    >
      {{ label }}
    </div>
  </button>
</template>
<script>
export default {
  props: {
    to: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'sm'
    },
    exact: {
      type: Boolean,
      default: false
    },
    checkActualRoute: {
      type: Boolean,
      default: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    goToOrRun() {
      if (this.to) this.$router.push(this.to)
    }
  }
}
</script>
