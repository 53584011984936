<template>
  <div v-if="userLoggedIn" class="items-center gap-2 h-full min-w-max flex">
    <b-dropdown v-if="meeting" aria-role="list" position="is-bottom-left">
      <fw-button-dropdown slot="trigger" aria-role="listitem" type="transparent" label="Sala Virtual" :chevron="false">
        <span :class="{ 'text-primary': meeting.running }">
          <fw-icon-virtual-meeting class="w-5 h-5" />
          <fw-dot-active v-if="meeting.running" class="h-2 w-2 absolute top-1.5 right-1.5" />
        </span>
      </fw-button-dropdown>
      <b-dropdown-item has-link aria-role="menuitem">
        <a
          aria-label="Entrar na sala pessoal"
          :class="{
            'text-primary bg-primary bg-opacity-10': meeting.running
          }"
          @click.prevent="$router.push({ name: 'live', params: { key: meeting.key_alias } })"
        >
          <fw-icon-live class="w-5 h-5 inline-flex mr-2" />
          <span>Entrar na sala pessoal</span>
        </a>
      </b-dropdown-item>
      <b-dropdown-item has-link aria-role="menuitem">
        <a aria-label="Entrar numa sala com ID" @click.prevent="showEnterMeetingToJoin = true">
          <svg
            class="fill-current w-5 h-5 inline-flex mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0H24V24H0z" />
            <path d="M3 21v-2h2V4c0-.552.448-1 1-1h12c.552 0 1 .448 1 1v15h2v2H3zM17 5H7v14h10V5zm-2 6v2h-2v-2h2z" />
          </svg>
          <span>Entrar numa sala com ID</span>
        </a>
      </b-dropdown-item>
    </b-dropdown>

    <ModalEnterMeetingToJoin :show="showEnterMeetingToJoin" @close="showEnterMeetingToJoin = false" />
  </div>
</template>

<script>
import ModalEnterMeetingToJoin from '@/fw-modules/fw-meetings-vue/components/modals/ModalEnterMeetingToJoin'

export default {
  components: {
    ModalEnterMeetingToJoin
  },

  data() {
    return {
      showEnterMeetingToJoin: false
    }
  },

  computed: {
    userLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    user() {
      return this.$store.getters.getUser
    },
    meeting() {
      if (this.user && this.user.meeting) return this.user.meeting
      else return null
    }
  }
}
</script>
