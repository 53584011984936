import Mention from '@tiptap/extension-mention'
import { MentionPluginKey } from '@tiptap/extension-mention'
const SuggestionBlocks = Mention.extend({
  name: 'suggestionBlocks',
  addOptions() {
    return {
      HTMLAttributes: {},
      renderText() {
        return ``
      },
      renderHTML() {
        return []
      },
      suggestion: {
        char: '/',
        pluginKey: MentionPluginKey,
        command: ({ editor, range, props }) => {
          // increase range.to by one when the next node is of type "text"
          // and starts with a space character
          const nodeAfter = editor.view.state.selection.$to.nodeAfter
          const overrideSpace = nodeAfter?.text?.startsWith(' ')
          //console.log('editor', editor)
          if (overrideSpace) {
            range.to += 1
          }
          //console.log('type', props.type)
          if (props.type === 'text') {
            //add paragraph (reset all settings?)
            editor
              .chain()
              .focus()
              .insertContentAt(range, [
                {
                  type: 'text',
                  text: ' '
                }
              ])
              .setParagraph()
              .run()
          } else if (props.type === 'heading') {
            //add heading
            editor
              .chain()
              .focus()
              .insertContentAt(range, [
                {
                  type: 'text',
                  text: ' '
                }
              ])
              .toggleHeading(props.attrs)
              .run()
          } else if (props.type === 'image') {
            //add image
            editor
              .chain()
              .focus()
              .insertContentAt(range, [
                {
                  type: 'text',
                  text: ' '
                }
              ])
              .setImage({ src: null })
              .run()
          } else if (props.type === 'bulletList') {
            //add list
            editor
              .chain()
              .focus()
              .insertContentAt(range, [
                {
                  type: 'text',
                  text: ' '
                }
              ])
              .toggleBulletList()
              .run()
          } else if (props.type === 'taskList') {
            //add task list
            editor
              .chain()
              .focus()
              .insertContentAt(range, [
                {
                  type: 'text',
                  text: ' '
                }
              ])
              .toggleTaskList()
              .run()
          } else if (props.type === 'orderedList') {
            //add ordered list
            editor
              .chain()
              .focus()
              .insertContentAt(range, [
                {
                  type: 'text',
                  text: ' '
                }
              ])
              .toggleOrderedList()
              .run()
          } else if (props.type === 'table') {
            //add table
            editor
              .chain()
              .focus()
              .insertContentAt(range, [
                {
                  type: 'text',
                  text: ' '
                }
              ])
              .insertTable({ rows: 3, cols: 2, withHeaderRow: true })
              .run()
          } else if (props.type === 'codeBlock') {
            //add code
            editor
              .chain()
              .focus()
              .insertContentAt(range, [
                {
                  type: 'text',
                  text: ' '
                }
              ])
              .setCodeBlock()
              .run()
          } else if (props.type === 'blockquote') {
            //add quote
            editor
              .chain()
              .focus()
              .insertContentAt(range, [
                {
                  type: 'text',
                  text: ' '
                }
              ])
              .setBlockquote()
              .run()
          } else if (props.type === 'video') {
            //add video
            editor
              .chain()
              .focus()
              .insertContentAt(range, [
                {
                  type: 'text',
                  text: ' '
                }
              ])
              .setVideo({ src: '' })
              .run()
          } else if (props.type === 'youtube') {
            //add video
            editor
              .chain()
              .focus()
              .insertContentAt(range, [
                {
                  type: 'text',
                  text: ' '
                }
              ])
              .setYoutube({ src: '' })
              .run()
          } else {
            console.error('Unknown type', props.type)
          }

          /*editor
          .chain()
          .focus()
          .insertContentAt(range, [
            {
              type: this.name,
              attrs: props,
            },
            {
              type: 'text',
              text: ' ',
            },
          ])
          .run()*/

          window.getSelection()?.collapseToEnd()
        },
        allow: ({ state, range }) => {
          const $from = state.doc.resolve(range.from)
          const type = state.schema.nodes[this.name]
          const allow = !!$from.parent.type.contentMatch.matchType(type)

          return allow
        }
      }
    }
  },
  addAttributes() {
    return {}
  },

  parseHTML() {
    return []
  },

  renderHTML() {
    return ''
  },

  renderText({ node }) {
    if (this.options.renderLabel !== undefined) {
      console.warn('renderLabel is deprecated use renderText and renderHTML instead')
      return this.options.renderLabel({
        options: this.options,
        node
      })
    }
    return this.options.renderText({
      options: this.options,
      node
    })
  }
})

export default SuggestionBlocks
