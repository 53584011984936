<template>
  <fw-button
    class="flex items-center gap-1 text-primary"
    :class="{
      'w-full': expanded,
      'py-3': size === 'lg',
      'py-1.5': size !== 'lg',
      'mr-2': !noMargin
    }"
    label="Transferir"
    type="transparent"
    @click.native.stop="download"
  >
    <fw-icon-download class="w-5 h-5 mr-0.5" />
    <span v-if="customLabel">{{ customLabel }}</span>
    <span v-else-if="expanded && withLabel">{{ $t('download') }}</span>
    <span v-else-if="size !== 'sm' && !expanded && withLabel" class="hidden lg:flex">{{ $t('download') }}</span>
    <span v-if="downloadTotalSize > 0 && size !== 'sm'" class="font-semibold ml-0.5 text-xs mt-0.5">{{
      downloadTotalSize | bytesToString
    }}</span>
  </fw-button>
</template>

<script>
export default {
  name: 'DownloadButton',
  props: {
    withLabel: {
      type: Boolean,
      default: true
    },
    customLabel: {
      type: String,
      default: null
    },
    noMargin: {
      type: Boolean,
      default: false
    },
    downloadTotalSize: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: 'rounded'
    },
    size: {
      type: String,
      default: 'md'
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    download() {
      this.$emit('download')
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "download": "Download"
  },
  "en": {
    "download": "Download"
  }
}
</i18n>
