<template>
  <div
    class="panel-uc-id-hero md:p-4 md:rounded-xl items-center justify-center md:flex gap-2"
    :class="{
      'md:bg-gray-200 md:bg-opacity-60': type == 'light',
      'md:bg-yellow-700 md:bg-opacity-10': type == 'yellow',
      'md:bg-primary md:bg-opacity-10': type == 'primary',
      'md:bg-gray-800 md:bg-opacity-80': type == 'dark',
      'md:bg-gradient-to-r md:from-gray-600 md:to-gray-800 bg-opacity-80': type == 'dark-gradient' || management
    }"
  >
    <div class="px-10 py-5">
      <div class="uc-id-avatar" :class="{ 'is-dark': isDark }">
        <BlockUCId :user="user || loggedUser" :intro="!isVersion2" class="md:shadow-xl" />
      </div>
    </div>
    <div class="flex flex-1 flex-col gap-2 justify-between" :class="{ 'text-white': isDark }">
      <div v-if="isVersion2" class="flex justify-center md:justify-start">
        <h2 class="text-2xl font-bold text-center opacity-90 uc-id-name" :class="{ 'md:text-white is-dark': isDark }">
          <v-clamp autoresize :max-lines="2">{{ $t('hello') }}, {{ (user || loggedUser).name }}.</v-clamp>
        </h2>
      </div>
      <fw-heading
        v-if="!statsLoading && statsTitle"
        size="h3"
        class="hidden md:block md:opacity-40 text-center md:text-left"
        >{{ statsTitle }}</fw-heading
      >
      <slot v-if="$slots.stats || stats" name="stats">
        <PanelStats
          class="hidden md:inline-flex"
          :is-dark="isDark"
          :stats="stats"
          :stats-warning="statsWarning"
          :loading="statsLoading"
          :hide-if-empty="statsHideIfEmpty"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import BlockUCId from '@/fw-modules/fw-core-vue/id/components/blocks/BlockUCIdQRCode'
import PanelStats from '@/fw-modules/fw-core-vue/ui/components/panels/PanelStats'

export default {
  components: {
    BlockUCId,
    PanelStats
  },

  props: {
    user: {
      type: Object
    },
    stats: {
      type: Array,
      deep: true
    },
    statsWarning: {
      type: Boolean,
      default: false
    },
    statsLoading: {
      type: Boolean,
      default: false
    },
    statsTitle: {
      type: String,
      default: null
    },
    statsHideIfEmpty: {
      type: Boolean,
      default: false
    },
    version: {
      type: String,
      default: 'v1'
    },
    type: {
      type: String,
      default: 'light'
    },
    management: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    loggedUser() {
      return this.$store.getters.getUser
    },

    isDark() {
      return this.type === 'dark-gradient' || this.type === 'dark' || this.management
    },
    isVersion2() {
      return this.version === 'v2' || this.management
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "hello": "Olá"
  },
  "en": {
    "hello": "Hello"
  }
}
</i18n>

<style lang="postcss">
.panel-uc-id-hero {
  /*conic-gradient(at right bottom, rgb(126, 34, 206), rgb(7, 89, 133), rgb(45, 212, 191));*/

  .is-dark.uc-id-name {
    @media screen and (min-width: 768px) {
      /* background: conic-gradient(at left bottom, rgb(78, 162, 149), rgb(190, 242, 100), rgb(219, 234, 254));
      background: conic-gradient(at left bottom, rgb(251, 113, 133), rgb(190, 242, 100), rgb(219, 234, 254));
      background: conic-gradient(at right center, rgb(88, 28, 135), rgb(220, 252, 231), rgb(194, 65, 12));
      background: conic-gradient(at left bottom, rgb(226, 193, 101), rgb(190, 242, 100), rgb(219, 234, 254));
      background: conic-gradient(at left bottom, rgb(31, 176, 166), rgb(255, 255, 255), rgb(24, 97, 194));
      background: linear-gradient(to right, rgb(171, 164, 56), rgb(255, 255, 255));
      background: conic-gradient(at left bottom, rgb(78, 162, 149), rgb(190, 242, 100), rgb(219, 234, 254));
      background: linear-gradient(to right, rgb(40, 195, 177), rgb(74, 201, 186)); */
      background: linear-gradient(to right, rgb(203, 166, 122), rgb(229, 207, 179));
      //background: #cba67a;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .is-dark.uc-id-avatar {
    @media screen and (min-width: 768px) {
      /* background: conic-gradient(at left bottom, rgb(78, 162, 149), rgb(190, 242, 100), rgb(134, 173, 198));
      background: linear-gradient(to right, rgb(202, 192, 60), rgb(220, 218, 195));
      background: conic-gradient(at left bottom, rgb(78, 162, 149), rgb(190, 242, 100), rgb(219, 234, 254));
      background: rgb(203, 166, 122);
      background: linear-gradient(to right, rgb(203, 166, 122), rgb(229, 207, 179));
      background: linear-gradient(to right, rgb(40, 195, 177), rgb(91, 243, 226)); */
      background: linear-gradient(to right, rgb(203, 166, 122), rgb(229, 207, 179));
    }
    @apply p-1.5 rounded-2xl;
  }
}
</style>
