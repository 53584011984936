<template>
  <fw-modal
    v-if="showAcademicModal"
    :active.sync="showAcademicModal"
    :can-cancel="true"
    size="md"
    @close="showAcademicModal = false"
  >
    <template #default>
      <div class="text-center flex flex-col gap-3">
        <fw-heading size="h3">{{ editing ? $t('academic.edit') : $t('academic.add') }}</fw-heading>
        <fw-label>{{ $t('description.label') }}</fw-label>
        <TranslatedInput
          v-model="newcompetence"
          :placeholder="{ pt: $t('academic.pt'), en: $t('academic.en') }"
          :disable-autowrite="true"
          :multiline="false"
          :class="{
            error: $v.newcompetence.$error,
          }"
        />
        <fw-tip v-if="$v.newcompetence.$error" error>
          {{ $t('academic.required') }}
        </fw-tip>
      </div>
    </template>
    <template #footer>
      <fw-button type="black" expanded @click.native="save()">{{ editing ? $t('save') : $t('add') }}</fw-button>
    </template>
  </fw-modal>
</template>

<script>
import TranslatedInput from '@/fw-modules/fw-core-vue/ui/components/form/TranslatedInput'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'ModalAcademicActivity',
  components: { TranslatedInput },
  data() {
    return {
      newcompetence: {
        pt: '',
        en: '',
      },
      showAcademicModal: false,
      editing: false,
    }
  },
  methods: {
    edit(data) {
      if (data) {
        this.newcompetence = JSON.parse(JSON.stringify(data))
        this.editing = true
      } else {
        this.newcompetence = {
          pt: '',
          en: '',
        }
        this.editing = false
      }
      this.showAcademicModal = true
    },
    save() {
      this.$v.newcompetence.$touch()
      if (this.$v.newcompetence.$invalid) return
      this.$emit('save', {
        data: this.newcompetence,
        editing: this.editing,
      })
      this.reset()
    },
    reset() {
      this.newcompetence = {
        pt: '',
        en: '',
      }
      this.showAcademicModal = false
      this.$v.$reset()
      this.editing = false
    },
  },

  validations() {
    return {
      newcompetence: {
        pt: { required, min: minLength(1) },
        en: { required, min: minLength(1) },
      },
    }
  },
}
</script>

<i18n>
    {
      "pt": {
        "academic": {
          "pt": "Nome da atividade académica em Português",
          "en": "Nome da atividade académica em Inglês",
          "required": "Insira o nome da atividade académica em Português e Inglês.",
          "add": "Adicionar atividade académica",
          "edit": "Editar atividade académica"
        },
        "description": {
          "label": "Descrição",
          "pt": "Descrição em Português",
          "en": "Descrição em Inglês",
          "required": "Insira uma descrição em Português e Inglês."
        },
        "save": "Guardar",
        "add": "Adicionar"
      },
      "en": {
        "academic": {
          "pt": "Name of the academic activity in Portuguese",
          "en": "Name of the academic activity in English",
          "required": "Enter the name of the academic activity in English and Portuguese",
          "add": "Create new academic activity",
          "edit": "Edit academic activity"
        },
        "description": {
          "label": "Description",
          "pt": "Description in Portuguese",
          "en": "Description in English",
          "required": "Enter a description in English and Portuguese."
        },
        "save": "Save",
        "add": "Add"
      }
    }
    </i18n>
