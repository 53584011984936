<template>
  <div v-if="units">
    <fw-heading size="xl">Agendamentos</fw-heading>
    <div v-if="units[0].topics && units[0].topics[0] && units[0].topics[0].schedule" class="mt-5 px-5">
      <CalendarSessionBlock
        v-for="(unit, u) in units"
        :key="unit.key"
        :unit="unit"
        :course="course"
        :edition="edition"
        :classroom="classroom"
        class="my-5"
        @save="saved(u, $event)"
      ></CalendarSessionBlock>
    </div>
    <div v-else-if="!loading">
      <fw-message type="warning" class="mt-5">
        Não foi possível carregar a calendarização deste curso.
      </fw-message>
    </div>
  </div>
</template>

<script>
//import utils from '@/fw-modules/fw-core-vue/utilities/utils'
//import date from '@/fw-modules/fw-core-vue/utilities/dates'
import CalendarSessionBlock from '@/fw-modules/fw-core-vue/nondegree-courses/components/blocks/CalendarSessionBlock.vue'
export default {
  components: {
    CalendarSessionBlock
  },
  props: {
    course: Object,
    edition: Object,
    units: [Object, Array],
    classroom: Object
  },
  data() {
    return {
      loading: true
    }
  },
  computed: {
    hasData() {
      return (
        this.units &&
        this.units[0] &&
        this.units[0].topics &&
        this.units[0].topics[0] &&
        this.units[0].topics[0].schedule
      )
    }
  },
  mounted() {
    // Not the best approach but we need to wait for prop data to be ready
    if (this.hasData) {
      this.loading = false
    } else {
      setTimeout(() => {
        this.loading = false
      }, 1000)
    }
  },
  methods: {
    saved(unitIndex, type, schedule) {
      this.tmp[unitIndex].schedule[type] = schedule
      //send data to server
    }
  }
}
</script>

<i18n>
{
  "en": {},
  "pt": {}
}
</i18n>
