<template>
  <div class="flex flex-col h-full overflow-y-auto bg-gray-100">
    <div class="flex flex-col items-center gap-5 mt-16">
      <div>
        <fw-avatar v-if="chatActive.type == 'group'" :group="chatActive" :size="'md'" />
        <fw-avatar v-else-if="chatActive.type == 'user'" :user="chatActive.user" :size="'md'" />
        <fw-avatar v-else icon="people" :size="'md'" />
      </div>
      <div class="flex flex-col items-center text-center">
        <div
          class="flex gap-2 items-center rounded-lg px-2 py-0.5"
          :class="{
            'hover:bg-gray-200 cursor-pointer select-none': meetingPermissions.edit
          }"
          @click="changeGroupTitle()"
        >
          <div class="font-bold text-lg line-clamp-2 overflow-ellipsis">
            {{ chatActive.title }}
            <fw-icon-edit v-if="meetingPermissions.edit" class="w-5 h-5 text-gray-500 inline-block ml-0.5" />
          </div>
        </div>
        <div class="text-xs text-gray-500">
          {{ chatTypeDescription }}
        </div>
      </div>
    </div>
    <div class="bg-white p-1.5 rounded-lg flex mx-auto mt-5">
      <button
        v-if="chatActive.is_silent == true || chatActive.is_silent == false"
        class="flex flex-col p-3 text-center items-center rounded-lg gap-1.5 hover:bg-gray-50 cursor-pointer whitespace-nowrap"
        @click="silenceChat()"
      >
        <fw-icon-loading v-if="silencing" class="w-5 h-5 text-gray-500" />
        <fw-icon-bell v-else-if="!chatActive.is_silent" class="w-5 h-5 text-gray-500" />
        <fw-icon-bell-off v-else class="w-5 h-5 text-red-500" />
        <div v-if="chatActive.is_silent" class="text-xs text-red-500 font-medium">Silenciado</div>
        <div v-else class="text-xs text-gray-500 font-medium">Silenciar</div>
      </button>
      <div
        v-if="chatActive.is_silent == true || chatActive.is_silent == false"
        class="h-full w-2 mr-2 border-r border-gray-100"
      ></div>
      <button
        :class="{ 'text-primary': aiTranslateOn, 'text-gray-500': !aiTranslateOn }"
        class="flex flex-col p-3 text-center items-center rounded-lg gap-1.5 hover:bg-gray-50 cursor-pointer relative"
        @click="showAiTranslateModal"
      >
        <div class="flex">
          <fw-icon-translate class="w-5 h-5 mr-1" />
          <fw-icon-robot class="w-5 h-5" />
        </div>
        <fw-dot-active v-if="aiTranslateOn" class="h-2 w-2 absolute top-1 right-1" />
        <div v-if="aiTranslateOn" class="text-xs text-primary font-medium line-clamp-1 overflow-ellipsis uppercase">
          {{ aiTranslateLanguage }}
        </div>
        <div v-else class="text-xs text-gray-500 font-medium whitespace-nowrap">Traduzir</div>
      </button>
      <div v-if="chatActive.type == 'group'" class="h-full w-2 mr-2 border-r border-gray-100"></div>
      <button
        v-if="chatActive.type == 'group' && chatActive.meeting?.key_alias"
        class="flex flex-col p-3 text-center items-center rounded-lg gap-1.5 relative hover:bg-gray-50 cursor-pointer"
        @click="$router.push({ name: 'live', params: { key: chatActive.meeting.key_alias } })"
      >
        <fw-icon-live class="w-5 h-5 text-gray-500" />
        <div class="text-xs text-gray-500 font-medium whitespace-nowrap">Sala virtual</div>
        <fw-icon-arrow-right-up class="w-4 h-4 text-gray-500 absolute top-1 right-1" />
      </button>
    </div>
    <div v-if="users.length > 0 || chatActive.isManager" class="mt-5 mx-3 pt-5">
      <div class="flex mb-2">
        <fw-label :counter="users.length" class="flex-1">Participantes</fw-label>
        <fw-menu-more v-if="chatActive.isManager && chatActive.meeting" size="xs">
          <b-dropdown-item paddingless aria-role="listitem">
            <fw-button
              type="dropdown-item"
              class="w-full"
              label="Gerir utilizadores"
              @click.native="$emit('right-sidebar', 'users')"
            >
              Gerir utilizadores
            </fw-button>
          </b-dropdown-item>
        </fw-menu-more>
      </div>

      <div class="bg-white p-1.5 rounded-lg flex flex-col">
        <fw-person v-for="user in firstFiveUsers" :key="user.key" paddingless :person="user" :size="'xs'" class="p-1" />
        <fw-button
          v-if="users.length > 5"
          type="xlight"
          size="xs"
          class="mt-1 group"
          @click.native="$emit('right-sidebar', 'users')"
        >
          <div class="flex justify-center py-1 text-black/70 group-hover:text-primary">Ver todos</div>
        </fw-button>
      </div>
    </div>

    <div
      v-if="archiveAllowed || (chatActive.type == 'group' && meetingPermissions.delete)"
      class="mt-10 mx-3 pt-5 border-t border-gray-200 pb-4"
    >
      <fw-label class="mb-2">Operações</fw-label>
      <div class="bg-white p-1.5 rounded-lg flex flex-col">
        <fw-button
          v-if="chatActive.is_archived && archiveAllowed"
          type="transparent"
          :loading="archiving"
          size="sm"
          icon="archive"
          @click.native="unarchiveChat(chatActive.key)"
        >
          Desarquivar
        </fw-button>
        <fw-button
          v-else-if="archiveAllowed"
          type="transparent"
          :loading="archiving"
          size="sm"
          icon="archive"
          @click.native="archiveChat(chatActive.key)"
        >
          Arquivar
        </fw-button>
      </div>
      <div
        v-if="chatActive.type == 'group' && meetingPermissions.delete"
        class="border-2 border-red-500 rounded-xl px-3 pb-3 relative pt-3 mt-5"
      >
        <div class="bg-gray-100 p-1 absolute -top-3.5 left-[7px] font-semibold text-sm text-red-500">
          Zona de Perigo
        </div>
        <div class="text-gray-500 text-sm font-semibold">Eliminar grupo</div>
        <div class="text-gray-500 text-xs mt-1 mb-2">
          Ao eliminar o grupo, todos os dados associados serão apagados. Esta acção é irreverssível.
        </div>
        <fw-button
          type="danger"
          :expanded="isMobile"
          @click.native="deleteGroup(chatActive.key, chatActive.meeting.key)"
        >
          Eliminar grupo
        </fw-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PanelChatInfo',
  props: {
    chatActive: {
      type: Object,
      default: () => ({})
    },
    meetingPermissions: {
      type: Object,
      default: () => ({})
    },
    archiveAllowed: {
      type: Boolean,
      default: false
    },
    archiving: {
      type: Boolean,
      default: false
    },
    silencing: {
      type: Boolean,
      default: false
    },
    aiTranslateOn: {
      type: Boolean,
      default: false
    },
    aiTranslateLanguage: {
      type: String,
      default: ''
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    changeGroupTitle: {
      type: Function,
      default: () => {}
    },
    silenceChat: {
      type: Function,
      default: () => {}
    },
    showAiTranslateModal: {
      type: Function,
      default: () => {}
    },
    deleteGroup: {
      type: Function,
      default: () => {}
    },
    archiveChat: {
      type: Function,
      default: () => {}
    },
    unarchiveChat: {
      type: Function,
      default: () => {}
    },
    chatMeetingOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    users() {
      return this.chatActive.users
    },
    chatTypeDescription() {
      const typeMap = {
        group: 'Grupo',
        user: 'Utilizador/a',
        class: 'Turma'
      }
      return !this.chatMeetingOnly ? typeMap[this.chatActive.type] : 'Canal da sala'
    },
    firstFiveUsers() {
      return this.users.slice(0, 5)
    }
  }
}
</script>
