import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import Api from '@/fw-modules/fw-core-vue/api/Api'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import store from '@/store'

export default {
  base(config) {
    let api = Api(config)
    api.defaults.baseURL = FwEnvConfig.apiUrlAcademic
    return api
  },

  async getUser() {
    const response = await this.base().get('/v1/user')
    return response.data
  },

  async getBLCourses() {
    const response = await this.base().get('/v1/ucpages')
    return response.data
  },

  async getTeacherStats() {
    const response = await this.base({ ignoreError: true }).get('/v1/teacher/stats')
    return response.data
  },
  async getNextTeacherSessions() {
    const response = await this.base({ ignoreError: true }).get('/v1/teacher/sessions/next')
    this.buildClassSessionsData(response.data)
    return response.data
  },
  async getTeacherUnits(academicYear = null, withClasses = false) {
    let data
    if (academicYear && academicYear !== -1 && academicYear !== '-1') data = { params: { academic_year: academicYear } }

    let url = '/v2/teacher/units'

    if (withClasses) {
      url += '?no_stats&with_classes'
    }

    const response = await this.base().get(url, data)
    response.data.units.sort((a, b) => {
      const sortValue = a.title.localeCompare(b.title)
      if (sortValue === 0) return a.profile.localeCompare(b.profile)
      else return sortValue
    })
    for (let unit of response.data.units) {
      if (unit.courses.length) {
        unit.courses.sort((a, b) => a.title.localeCompare(b.title))
      }
    }
    return response.data
  },
  async getTeacherUnitClassEditions(key) {
    console.log('getTeacherUnitClassEditions')
    const response = await this.base().get(`/v1/teacher/unit/${key}/classes`)
    response.data.editions.sort((a, b) => a.title.localeCompare(b.title))
    return response.data
  },
  async getTeacherClass(key) {
    const response = await this.base().get(`/v1/teacher/class/${key}`)
    this.buildClassData(response.data)
    return response.data
  },
  async updateTeacherClass(editionKey, data) {
    const path = `/v1/teacher/class/${editionKey}`
    const response = await this.base().post(path, data)
    return response.data
  },

  async getTeacherClassNonioEditions(key, bucketKey) {
    const response = await this.base().get(`/v1/teacher/class/${key}/nonio/${bucketKey}/editions`)
    return response.data
  },
  async getTeacherClassNonioEditionsByKey(key) {
    const response = await this.base().get(`/v1/teacher/class/${key}/nonio/editions`)
    return response.data
  },
  async getTeacherClassNonioFiles(key, nonioId, bucketKey) {
    const response = await this.base().get(`/v1/teacher/class/${key}/nonio/${bucketKey}/${nonioId}/files`)
    return response.data
  },
  async importTeacherClassNonioFiles(key, nonioId, bucketKey, folderKey, fileIds) {
    const data = {
      folder_key: folderKey,
      files: fileIds
    }
    const response = await this.base().post(`/v1/teacher/class/${key}/nonio/${bucketKey}/${nonioId}/files`, data)
    return response.data
  },
  async getTeacherClassSession(edition_key, key) {
    const response = await this.base().get(`/v1/teacher/class/${edition_key}/session/${key}`)
    response.data.presences.sort((a, b) => a.user.full_name.localeCompare(b.user.full_name))
    return response.data
  },
  async setClassSessionSummaries(edition_key, key, summaries) {
    const response = await this.base().post(`/v1/teacher/class/${edition_key}/session/${key}/summaries`, summaries)
    return response.data
  },
  async setSessionAsDone(edition_key, key) {
    try {
      const path = `/v1/teacher/class/${edition_key}/session/${key}/done`
      await this.base().post(path)
      return true
    } catch (error) {
      if (utils.errors(error).exists('ClassSessionNotOpen')) {
        console.warn(`Class session ${key} already closed, cannot set done date`)
        return false
      }

      throw error
    }
  },
  async setPresence(edition_key, key, studentKey, type) {
    const data = {
      type: type,
      ignore_connection_id: store.state.socket.connectionId
    }

    try {
      const path = `/v1/teacher/class/${edition_key}/session/${key}/presence/${studentKey}`
      await this.base().post(path, data)
      return true
    } catch (error) {
      if (utils.errors(error).exists('ClassSessionNotOpen')) {
        console.warn(`Class session ${key} already closed, cannot set student ${studentKey} presence "${type}"`)
        return false
      }

      throw error
    }
  },
  async lockPresences(edition_key, key, studentKeys) {
    const data = {
      ignore_connection_id: store.state.socket.connectionId,
      keys: studentKeys
    }

    try {
      const path = `/v1/teacher/class/${edition_key}/session/${key}/lock-presences`
      await this.base().post(path, data)
      return true
    } catch (error) {
      if (utils.errors(error).exists('ClassSessionNotOpen')) {
        console.warn(`Class session ${key} already closed, cannot lock students ${studentKeys} presence`)
        return false
      }

      throw error
    }
  },
  async removePresence(edition_key, key, studentKey) {
    const data = {
      ignore_connection_id: store.state.socket.connectionId
    }

    try {
      const path = `/v1/teacher/class/${edition_key}/session/${key}/presence/${studentKey}`
      await this.base().delete(path, { data: data })
      return true
    } catch (error) {
      if (utils.errors(error).exists('ClassSessionNotOpen')) {
        console.warn(`Class session ${key} already closed, cannot delete student ${studentKey} presence`)
        return false
      }

      throw error
    }
  },

  async getStudentStats() {
    const response = await this.base({ ignoreError: true }).get('/v1/student/stats')
    return response.data
  },
  async getNextStudentSessions() {
    const response = await this.base({ ignoreError: true }).get('/v1/student/sessions/next')
    this.buildClassSessionsData(response.data)
    return response.data
  },
  async getStudentUnits(academicYear = null, withClasses = false) {
    let data
    if (academicYear && academicYear !== -1 && academicYear !== '-1') data = { params: { academic_year: academicYear } }

    let url = '/v2/student/units'

    if (withClasses) {
      url += '?no_stats&with_classes'
    }

    const response = await this.base().get(url, data)
    response.data.units.sort((a, b) => {
      const sortValue = a.title.localeCompare(b.title)
      if (sortValue === 0) return a.profile.localeCompare(b.profile)
      else return sortValue
    })
    for (let unit of response.data.units) {
      if (unit.courses.length) {
        unit.courses.sort((a, b) => a.title.localeCompare(b.title))
      }
    }
    return response.data
  },
  async getStudentUnitClassEditions(key) {
    const response = await this.base().get(`/v1/student/unit/${key}/classes`)
    response.data.editions.sort((a, b) => a.title.localeCompare(b.title))
    return response.data
  },
  async getStudentClass(key) {
    const response = await this.base().get(`/v1/student/class/${key}`)
    this.buildClassData(response.data)
    return response.data
  },
  async getStudentClassPresence(edition_key) {
    const response = await this.base().get(`/v1/student/class/${edition_key}/presence`)
    return response.data
  },
  async getStudentClassSession(edition_key, key) {
    const response = await this.base().get(`/v1/student/class/${edition_key}/session/${key}`)
    return response.data
  },
  async setSelfStudentPresence(edition_key, key, type) {
    const path = `/v1/student/class/${edition_key}/session/${key}/presence`
    await this.base().post(path, { type: type })
  },
  async deleteSelfStudentPresence(edition_key, key) {
    const path = `/v1/student/class/${edition_key}/session/${key}/presence`
    await this.base().delete(path)
  },

  buildClassData(data) {
    data.edition.teachers.sort((a, b) => a.full_name.localeCompare(b.full_name))
    data.teachers.sort((a, b) => a.user.full_name.localeCompare(b.user.full_name))
    data.students.sort((a, b) => a.user.full_name.localeCompare(b.user.full_name))
    this.buildClassSessionsData(data.sessions)
  },
  buildClassSessionsData(sessions) {
    for (let session of sessions) {
      session.start_date_obj = Dates.build(session.start_date)
      session.end_date_obj = Dates.build(session.end_date)
    }

    sessions.sort((a, b) => {
      if (a.start_date_obj < b.start_date_obj) return -1
      else if (a.start_date_obj > b.start_date_obj) return 1
      else return a.key.localeCompare(b.key)
    })
  },
  async loadActiveSession(isTeacher, edition, activeSessionKey = null, newSessionKey = null) {
    if (!edition.sessions.length) return

    if (!newSessionKey) {
      let now = Dates.now()
      const isActiveAfter = Dates.now().add(1, 'hour')
      newSessionKey = edition.sessions[0].key
      for (let session of edition.sessions) {
        if (session.start_date_obj <= isActiveAfter) {
          newSessionKey = session.key
          if (session.end_date_obj >= now) break
        } else {
          break
        }
      }
    }

    if (activeSessionKey !== newSessionKey) {
      if (isTeacher) return await this.getTeacherClassSession(edition.key, newSessionKey)
      else return await this.getStudentClassSession(edition.key, newSessionKey)
    }
  },

  async subscribe(editionKey, key) {
    const data = {
      application: 'academic',
      code: 'subscribe',
      edition_key: editionKey,
      key: key,
      as_teacher: process.env.VUE_APP_KEY == 'ucteacher'
    }

    store.commit('sendWSMessage', data)
    console.debug('Class session subscribe', editionKey, key)
  },
  async unsubscribe(editionKey, key) {
    const data = {
      application: 'academic',
      code: 'unsubscribe',
      edition_key: editionKey,
      key: key,
      as_teacher: process.env.VUE_APP_KEY == 'ucteacher'
    }

    store.commit('sendWSMessage', data)
    console.debug('Class session unsubscribe', editionKey, key)
  },

  createSubscription(editionKey, classSession, isStudentPresence) {
    const self = this
    const subscriptionName = `ClassSession-${editionKey}-${classSession.key}`
    const cls = {
      authUserKey: store.getters.getUser.key,
      editionKey: editionKey,
      key: classSession.key,
      session: classSession,
      isTx: process.env.VUE_APP_KEY == 'ucteacher',
      prefix: isStudentPresence ? '' : 'presence_',

      async subscribeClassSession() {
        await self.subscribe(cls.editionKey, cls.key)
      },
      async WSMessages(messages) {
        if (messages.classSessionPresenceDelta) {
          for (let message of messages.classSessionPresenceDelta) {
            if (message.key === cls.key) {
              if (cls.isTx) {
                for (let presence of cls.session.presences) {
                  if (presence.user.key === message.user_key) {
                    for (const [key, value] of Object.entries(message.delta)) {
                      presence[key] = value
                    }
                  }
                }
              } else if (cls.authUserKey === message.user_key) {
                for (const [key, value] of Object.entries(message.delta)) {
                  cls.session[cls.prefix + key] = value
                }
              }
            }
          }
        }
      },
      destroy() {
        self.unsubscribe(cls.editionKey, cls.key)
        store.commit('unsubscribeWS', { code: 'ws-reconnect', name: subscriptionName })
        store.commit('unsubscribeWS', { code: 'academic', name: subscriptionName })
      }
    }

    store.commit('subscribeWS', { code: 'ws-reconnect', name: subscriptionName, callback: cls.subscribeClassSession })
    store.commit('subscribeWS', { code: 'academic', name: subscriptionName, callback: cls.WSMessages })
    cls.subscribeClassSession()
    return cls
  },
  /**
   * Non-degree courses
   */
  async getCoursesCatalogue(filters) {
    var parsedFilter = ''
    if (filters) {
      //is_prr filter
      if (filters['is_prr'] != null) {
        parsedFilter += 'is_prr=' + filters['is_prr']
      }
      //scientific_area filter
      if (filters['scientific_area'] != null) {
        if (parsedFilter.length > 0) {
          parsedFilter += '&'
        }
        parsedFilter += 'scientific_area=' + filters['scientific_area']
      }
      //language filter
      if (filters['languages'] != null) {
        if (parsedFilter.length > 0) {
          parsedFilter += '&'
        }
        parsedFilter += 'lang=' + filters['languages']
      }
      //working_mode filter
      if (filters['working_mode'] != null) {
        if (parsedFilter.length > 0) {
          parsedFilter += '&'
        }
        parsedFilter += 'working_mode=' + filters['working_mode']
      }
      parsedFilter = '?' + parsedFilter
    }
    const response = await this.base().get(`/v1/non-degree-courses/catalogue${parsedFilter}`)
    return response.data
  },
  async getUserEnrollments() {
    const response = await this.base().get(`/v1/non-degree-courses/user`)
    return response.data
  },
  async getUserLastEnrollment() {
    const response = await this.base().get(`/v1/non-degree-courses/last-enrollment`)
    return response.data
  },
  async getCourseDetails(courseKey) {
    const response = await this.base().get(`/v1/non-degree-course/${courseKey}`)
    return response.data
  },
  async getCourseEdition(courseKey, editionKey, section, class_key = null) {
    const response = await this.base().get(`/v1/non-degree-course/${courseKey}/edition/${editionKey}`, {
      params: { section, class_key }
    })
    return response.data
  },
  async getClassProgress(courseKey, editionKey, classKey) {
    const response = await this.base().get(`/v1/non-degree-course/${courseKey}/edition/${editionKey}/progress`, {
      params: { class_key: classKey }
    })
    return response.data
  },
  async saveTopicFeedback(courseKey, editionKey, unitKey, topicKey, feedbackData) {
    const path = `/v1/non-degree-course/${courseKey}/edition/${editionKey}/progress`
    const response = await this.base().post(path, {
      unit_key: unitKey,
      topic_key: topicKey,
      feedback: feedbackData
    })
    return response.data
  },
  async enrollCourse(courseKey, data) {
    const path = `/v1/non-degree-course/${courseKey}/enroll`
    const response = await this.base().post(path, data)
    return response.data
  },
  async subscribeCourse(courseKey, editionKey = null, email = null, phone = null, savePhone = false) {
    const path = `/v1/non-degree-course/notification/new`
    var data = {
      course_key: courseKey,
      save_phone: savePhone
    }
    if (editionKey != null) {
      data['edition_key'] = editionKey
    }
    if (email != null) {
      data['notification_email'] = email
    }
    if (phone != null) {
      data['phone'] = phone
    }

    const response = await this.base().post(path, data)
    return response.data
  },
  async unsubscribeCourse(notificationKey) {
    const path = `/v1/non-degree-course/notification/${notificationKey}`
    const response = await this.base().delete(path)
    return response.data
  },
  async createCourseSession(courseKey, editionKey, classKey, data) {
    //data: {start_date, end_date, duration}
    const path = `/v1/non-degree-course/${courseKey}/edition/${editionKey}/classes/${classKey}/sessions`
    const response = await this.base().post(path, data)
    return response.data
  },
  async updateCourseSession(courseKey, editionKey, classKey, data) {
    //data: {start_date, end_date, duration}
    const path = `/v1/non-degree-course/${courseKey}/edition/${editionKey}/classes/${classKey}/sessions`
    const response = await this.base().put(path, data)
    return response.data
  }
}
