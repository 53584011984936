<template>
  <div class="relative anim-box">
    <div class="animation-copying-file border-2 border-gray-800 file"></div>
    <div class="border-2 border-gray-600 file flex flex-col p-1 py-2 justify-between bg-white">
      <div class="file-line bg-gray-500 w-full"></div>
      <div class="file-line bg-gray-500 w-full"></div>
      <div class="file-line bg-gray-500 w-full"></div>
      <div class="file-line bg-gray-500 w-full"></div>
      <div class="file-line bg-gray-500 w-full"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Copying'
}
</script>

<style scoped>
.animation-copying-file {
  animation: 1s linear infinite running copy-anim;
}
.file {
  height: 50px;
  width: 40px;
  position: absolute;
  border-width: 3px;
  top: 0px;
  left: 30px;
}
.anim-box {
  height: 50px;
  width: 110px;
}
.file-line {
  height: 3px;
  line-height: 3px;
}
@keyframes copy-anim {
  0% {
    transform: translate(1px) scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(20px) scale(0.7);
  }
}
</style>
