<template>
  <div class="relative">
    <LoadingPlaceholder v-if="loading" />
    <div v-else>
      <div class="flex justify-between items-center">
        <div class="text-xl font-bold pt-5 pb-4">{{ $t('welcome') }}</div>
        <fw-button
          v-if="user && key == user.key && editable"
          type="regular"
          @click.native="$router.push({ name: 'portfolio-manage', params: { key: key } })"
          >{{ $t('edit') }}</fw-button
        >
      </div>
      <PortfolioBanner
        v-if="user"
        :user="user"
        :cover="coverUrl"
        :portfolio="portfolio"
        :editable="me && portfolio.user_key == me.key"
        @edit="editCover"
      />
      <div v-if="portfolio.about_me.short_description" class="font-bold mt-5">
        {{ portfolio.about_me.short_description[language] }}
      </div>
      <div class="my-5 flex flex-col gap-2">
        <div
          v-for="(faculty, f) in portfolio.options.faculties"
          :key="'fac' + f"
          class="flex gap-2 items-center text-gray-600 font-semibold text-lg"
        >
          <div>{{ faculty.name[language] }}</div>
          <div class=" text-primary text-xs">{{ faculty.website[language] | formatWebsite }}</div>
        </div>
        <div class="flex gap-2 items-center text-gray-600 font-semibold text-base">
          <div>{{ $t('coimbra_uc') }}</div>
          <div class=" text-primary text-xs">{{ $t('coimbra_uc_website') }}</div>
        </div>
      </div>

      <div class=" border-t border-gray-300 text-gray-600 pt-5">
        <div class="font-bold">{{ $t('contact_title') }}</div>
        <div class="pb-3">{{ $t('contact_subtitle') }}</div>
        <div class="flex gap-5">
          <a
            class="gap-3 hidden text-white hover:text-opacity-70 hover:text-white text-sm px-5 py-2 items-center font-semibold rounded-full"
            :class="{
              'cursor-not-allowed bg-gray-600': !portfolio.contacts.chat_key.is_visible,
              'cursor-pointer bg-primary': portfolio.contacts.chat_key.is_visible,
            }"
            @click="open('chat')"
          >
            <fw-icon-message class="w-5 h-5"></fw-icon-message>
            <div>{{ $t('chat') }}</div>
          </a>
          <a
            class="flex gap-3 text-white text-sm hover:text-opacity-70 hover:text-white px-5 py-2 items-center font-semibold rounded-full"
            :class="{
              'cursor-not-allowed bg-gray-600': !portfolio.contacts.meeting_key.is_visible,
              'cursor-pointer bg-primary': portfolio.contacts.meeting_key.is_visible,
            }"
            @click="open('live')"
          >
            <fw-icon-video class="w-5 h-5"></fw-icon-video>
            <div>{{ $t('live') }}</div>
          </a>
          <a
            class="flex gap-3 text-white text-sm hover:text-opacity-70 hover:text-white px-5 py-2 items-center font-semibold rounded-full"
            :class="{
              'cursor-not-allowed bg-gray-600': !portfolio.contacts.email.is_visible,
              'cursor-pointer bg-primary': portfolio.contacts.email.is_visible,
            }"
            @click="open('mail')"
          >
            <fw-icon-mail class="w-5 h-5"></fw-icon-mail>
            <div>Email</div>
          </a>
        </div>
      </div>
      <div class="text-xs text-gray-500 py-10 text-center">
        {{ $t('update') }}{{ portfolio.updated_date ?? portfolio.created_date | formatDateTime }}
      </div>
      <fw-panel featured :title="$t('about')" :boxed="'md'" :custom-class="'bg-white p-5 rounded-2xl'">
        <div class="flex flex-col gap-7">
          <div v-if="portfolio.about_me.description" class="flex flex-col">
            <div class="aboutme-title">
              {{ $t('presentation') }}
            </div>
            <div
              v-if="portfolio.about_me.description[language]"
              class="aboutme-text"
              v-html="portfolio.about_me.description[language]"
            ></div>
            <div v-else class=" text-gray-400 text-sm mt-1">{{ $t('no_content') }}</div>
          </div>
          <div v-if="portfolio.about_me.hobbies">
            <div class="aboutme-title">
              {{ $t('hobbies.label') }}
            </div>
            <div
              v-if="portfolio.about_me.hobbies[language]"
              class="aboutme-text"
              v-html="portfolio.about_me.hobbies[language]"
            ></div>
            <div v-else class=" text-gray-400 text-sm mt-1">{{ $t('no_content') }}</div>
          </div>
          <div v-if="portfolio.about_me.interests">
            <div class="aboutme-title">
              {{ $t('interests.label') }}
            </div>
            <div
              v-if="portfolio.about_me.interests[language]"
              class="aboutme-text"
              v-html="portfolio.about_me.interests[language]"
            ></div>
            <div v-else class=" text-gray-400 text-sm mt-1">{{ $t('no_content') }}</div>
          </div>
        </div>
      </fw-panel>
      <fw-panel featured :title="$t('competences')" class="mt-5">
        <div
          v-for="(comp, c) in portfolio.competences"
          :key="c"
          class="flex border-b border-gray-300 text-xl py-3 font-semibold gap-5 items-center"
        >
          <div class=" flex-shrink-0 text-primary ">#{{ c + 1 }}</div>
          <div class=" rounded-full flex px-4 py-1.5 text-white bg-primary gap-3 items-center">
            {{ comp.name[language] }}
            <div v-if="comp.endorsements.length > 0" class="text-sm font-normal">
              {{ comp.endorsements.length }}
            </div>
          </div>
          <div class="flex-1"></div>
          <div class="flex gap-2 items-center">
            <div v-if="comp.endorsements[0]" class=" text-gray-500 text-xs text-right">
              Aprovado por <span class="font-bold">{{ users[comp.endorsements[0]].name }}</span>
              <div v-if="comp.endorsements.length - 1 > 0">e mais {{ comp.endorsements.length - 1 }} pessoas</div>
            </div>
            <UserAvatar v-if="comp.endorsements[0]" :user="users[comp.endorsements[0]]" />
            <UserAvatar v-if="comp.endorsements[1]" :user="users[comp.endorsements[1]]" />
            <UserAvatar v-if="comp.endorsements[2]" :user="users[comp.endorsements[2]]" />
            <div
              v-if="comp.endorsements.length - 3 > 0"
              class="bg-white flex flex-col rounded-lg items-center justify-center h-10 w-10 text-gray-400 font-semibold text-sm"
            >
              +{{ comp.endorsements.length - 3 }}
            </div>
          </div>
          <fw-button
            v-if="me.key && !comp.endorsements.includes(me.key) && me.key !== portfolio.user_key"
            type="white"
            @click.native="endorse(comp)"
          >
            <div class="flex gap-2">
              <fw-icon-thumbs-up class="w-5 h-5"></fw-icon-thumbs-up>
              <div class="hidden sm:inline">{{ $t('endorse') }}</div>
            </div>
          </fw-button>
          <fw-button
            v-else-if="me.key && comp.endorsements.includes(me.key) && me.key !== portfolio.user_key"
            type="white"
            @click.native="desendorse(comp)"
          >
            <div class="flex gap-2 text-gray-500">
              <fw-close class="w-5 h-5"></fw-close>
              <div class="hidden sm:inline">{{ $t('remove_endorsement') }}</div>
            </div>
          </fw-button>
        </div>
        <div v-if="portfolio.competences.length == 0" class="text-gray-400 text-sm">
          {{ $t('no_competences') }}
        </div>
      </fw-panel>
      <fw-panel featured :title="$t('academic_plan')" class="mt-5" :custom-class="'bg-white p-5 rounded-2xl'">
        <div class=" border-b border-gray-200 pb-5 mb-5">
          <div class="aboutme-title">
            {{ $t('description.label') }}
          </div>
          <div
            v-if="portfolio.academic_plan.description[language]"
            class="aboutme-text"
            v-html="portfolio.academic_plan.description[language]"
          ></div>
          <div v-else class="text-gray-400 text-sm mt-1">{{ $t('no_content') }}</div>
          <div class="portfolio-label hidden">Documentos</div>
          <div class="gap-5 text-sm py-2 hidden">
            <div class="font-semibold text-primary">Título do ficheiro</div>
            <div class="text-gray-500">12Mb</div>
          </div>
        </div>
        <div class="flex flex-col gap-5">
          <div v-for="(academic_year, a) in academic_years" :key="'academic_' + a">
            <div class="flex">
              <div class=" rounded-full flex px-4 py-1.5 font-bold text-white bg-primary gap-3 items-center">
                {{ academic_year.name[language] }}
              </div>
            </div>
            <div class="pl-5 pt-5">
              <div class="portfolio-label">{{ $t('curriculum_activities') }}</div>
              <div class="flex flex-col gap-3">
                <AcademicItem
                  v-for="(item, i) in academic_year.curriculum"
                  :key="'academicitem' + a + i"
                  :item="item"
                />
                <div v-if="academic_year.curriculum.length == 0" class="text-sm text-gray-400">
                  {{ $t('no_curriculum') }}
                </div>
              </div>
            </div>
            <div class="pl-5 pt-5">
              <div class="portfolio-label">{{ $t('extra_curriculum_activities') }}</div>
              <div class="flex flex-col gap-3">
                <AcademicItem
                  v-for="(item, i) in academic_year.extra_curriculum"
                  :key="'academicitemextra' + a + i"
                  :item="item"
                />

                <div v-if="academic_year.extra_curriculum.length == 0" class="text-sm text-gray-400">
                  {{ $t('no_extra_curriculum') }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="academic_years.length == 0" class="text-gray-400 text-sm">
            {{ $t('no_activity') }}
          </div>
        </div>
      </fw-panel>
      <fw-panel featured :title="$t('career_development')" class="mt-5" :custom-class="'bg-white p-5 rounded-2xl'">
        <div class="pb-5 mb-5">
          <div class="aboutme-title">
            {{ $t('summary') }}
          </div>
          <div
            v-if="portfolio.carrer_development.description[language]"
            class="aboutme-text"
            v-html="portfolio.carrer_development.description[language]"
          ></div>
          <div v-else class="text-gray-400 text-sm mt-1">{{ $t('no_content') }}</div>
          <div class="portfolio-label hidden">{{ $t('documents') }}</div>
          <div class="gap-5 text-sm py-2 hidden">
            <div class="font-semibold text-primary">Título do ficheiro</div>
            <div class="text-gray-500">12Mb</div>
          </div>
        </div>
        <div class="flex flex-col gap-5">
          <div class="pt-5">
            <div class="aboutme-title">{{ $t('professional_experience') }}</div>
            <div class="flex flex-col gap-3">
              <CareerItem
                v-for="(item, i) in portfolio.carrer_development.professional_experience"
                :key="'professional_experience' + i"
                :item="item"
              />
              <div
                v-if="portfolio.carrer_development.professional_experience.length == 0"
                class="text-sm text-gray-400"
              >
                {{ $t('no_professional_experience') }}
              </div>
            </div>
          </div>
          <div class="pt-5">
            <div class="aboutme-title">{{ $t('volunteering') }}</div>
            <div class="flex flex-col gap-3">
              <CareerItem
                v-for="(item, i) in portfolio.carrer_development.volunteering"
                :key="'volunteering' + i"
                :item="item"
              />
              <div v-if="portfolio.carrer_development.volunteering.length == 0" class="text-sm text-gray-400">
                {{ $t('no_volunteering') }}
              </div>
            </div>
          </div>

          <div class="pt-5">
            <div class="aboutme-title">{{ $t('certifications') }}</div>
            <div class="flex flex-col gap-3">
              <CareerItem
                v-for="(item, i) in portfolio.carrer_development.certifications"
                :key="'certifications' + i"
                :item="item"
              />
              <div v-if="portfolio.carrer_development.certifications.length == 0" class="text-sm text-gray-400">
                {{ $t('no_certifications') }}
              </div>
            </div>
          </div>
        </div>
      </fw-panel>
    </div>
    <ModalCoverImage ref="modalcoverimage" @save="saveCover" />
    <ModalEndorsement ref="modalendorsement" :user="users[portfolio.user_key]" @confirm="saveCover" />
  </div>
</template>

<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import PortfolioBanner from './components/PortfolioBanner.vue'
import CareerItem from './components/CareerItem.vue'
import AcademicItem from './components/AcademicItem.vue'
import ModalCoverImage from '@/components/modals/ModalCoverImage'
import ModalEndorsement from '@/components/modals/ModalEndorsement'
import UserAvatar from '../../fw-modules/fw-core-vue/id/components/user/UserAvatar.vue'

export default {
  components: {
    LoadingPlaceholder,
    PortfolioBanner,
    CareerItem,
    AcademicItem,
    ModalCoverImage,
    ModalEndorsement,
    UserAvatar,
  },

  filters: {
    formatWebsite: function(value) {
      if (!value) return ''
      return value.replace('https://www.', '')
    },
  },

  props: {
    portfolio: {
      type: Object,
      default: () => {
        return {}
      },
    },

    editable: {
      type: Boolean,
      default: false,
    },

    users: {
      type: Object,
      default: () => {
        return {}
      },
    },

    savingData: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {
    academic_years() {
      return this.portfolio.academic_plan.items
    },
    api() {
      return this.$store.state.api.base
    },

    language() {
      let setLanguage = this.$store.state.language
      return this.portfolio.options.hasEnglish && setLanguage === 'en' ? 'en' : 'pt'
    },

    secondaryLanguage() {
      return this.language === 'pt' ? 'en' : 'pt'
    },
    user() {
      return this.users[this.portfolio.user_key]
    },
    coverUrl() {
      return null
    },
    key() {
      return this.$route.params.key
    },
    me() {
      return this.$store.state.session.user
    },
  },

  methods: {
    endorse(competence) {
      this.$refs.modalendorsement.endorse(competence)
    },
    desendorse(competence) {
      this.$buefy.dialog.confirm({
        title: this.language == 'pt' ? 'Remover endosso' : 'Remove endorsement',
        cancelText: this.language == 'pt' ? 'Cancelar' : 'Cancel',
        confirmText: this.language == 'pt' ? 'Remover' : 'Remove',
        message:
          this.language == 'pt'
            ? 'Tem a certeza que deseja remover o endosso desta competência?'
            : 'Are you sure you want to remove the endorsement of this competence?',
        onConfirm: () => {
          //this.portfolio.competences.splice(i, 1)
          //this.saveCompetences()
          console.log(competence)
        },
      })
    },
    editCover() {
      if (this.me && this.me.key == this.portfolio.user_key)
        this.$refs.modalcoverimage.edit(
          this.portfolio.cover != null
            ? this.portfolio.cover
            : this.portfolio.options.cover == null
            ? 'abstract-01'
            : this.portfolio.options.cover
        )
    },
    saveCover(image) {
      if (typeof image == 'string') {
        this.portfolio.options.cover = image
        this.portfolio.cover = null
      } else {
        this.portfolio.options.cover = null
        this.portfolio.cover = image
      }
      this.savePortfolio()
    },
    savePortfolio() {
      let portfolio = JSON.parse(JSON.stringify(this.portfolio))
      //replace competences by key
      portfolio.academic_plan.items.forEach((item, i) => {
        item.curriculum.forEach((curriculum, c) => {
          portfolio.academic_plan.items[i].curriculum[c].competences.forEach((competence, cp) => {
            portfolio.academic_plan.items[i].curriculum[c].competences[cp] = competence.key
          })
        })

        item.extra_curriculum.forEach((extra_curriculum, c) => {
          portfolio.academic_plan.items[i].extra_curriculum[c].competences.forEach((competence, cp) => {
            portfolio.academic_plan.items[i].extra_curriculum[c].competences[cp] = competence.key
          })
        })
      })
      portfolio.carrer_development.professional_experience = portfolio.carrer_development.professional_experience.map(
        item => {
          item.competences = item.competences.map(competence => competence.key)
          return item
        }
      )
      portfolio.carrer_development.volunteering = portfolio.carrer_development.volunteering.map(item => {
        item.competences = item.competences.map(competence => competence.key)
        return item
      })
      portfolio.carrer_development.certifications = portfolio.carrer_development.certifications.map(item => {
        item.competences = item.competences.map(competence => competence.key)
        return item
      })

      this.$emit('save-portfolio', portfolio)
    },
    open(what) {
      if (what == 'chat' && this.portfolio.contacts.chat_key.is_visible) {
        this.$router.push({ name: 'chat', params: { key: this.portfolio.contacts.chat_key.value } })
      } else if (what == 'live' && this.portfolio.contacts.meeting_key.is_visible) {
        this.$router.push({ name: 'live', params: { key: this.portfolio.contacts.meeting_key.value } })
      } else if (what == 'mail' && this.portfolio.contacts.email.is_visible) {
        location.href = 'mailto:' + this.portfolio.contacts.email.value
      }
    },
  },
}
</script>
<i18n>
  {
    "pt": { 
      "endorse": "Endossar",
      "remove_endorsement": "Remover endosso",
      "contact_title": "Entrar em contacto",
      "contact_subtitle": "Converse comigo de forma segura, através das ferramentas digitais da UC.",
      "chat": "Conversar",
      "live": "Sala virtual",
      "update": "Esta página foi atualizada a ",
      "presentation": "Apresentação pessoal",
      "no_content": "Sem conteúdo",
      "no_competences": "Competências não definidas",
      "description": "Descrição",
      "documents": "Documentos",
      "file_title": "Título do ficheiro",
      "curriculum_activities": "Atividades curriculares",
      "no_curriculum": "Sem atividades curriculares definidas",
      "extra_curriculum_activities": "Atividades extra-curriculares",
      "no_extra_curriculum": "Sem atividades extra-curriculares definidas",
      "no_activity": "Sem atividades definidas",
      "summary" : "Resumo",
      "professional_experience": "Experiência profissional",
      "no_professional_experience": "Sem experiência profissional definida",
      "volunteering": "Voluntariado / Associativismo",
      "no_volunteering": "Sem voluntariado / associativismo definidos",
      "certifications": "Formações",
      "no_certifications": "Sem formações definidas",

      "coimbra_uc": "Universidade de Coimbra",
      "coimbra_uc_website": "uc.pt",
      "career_development": "Desenvolvimento de carreira",
      "welcome": "Bem-vindo",
      "academic_plan": "Plano académico",
      "competences": "Competências",
      "about": "Sobre mim",
      "settings": "Configurações",
      "thereAreErrors": "Existem erros no formulário",
      "close": "Fechar",
      "edit": "Editar",
      "save": "Guardar",
      "delete": "Remover",
      "personalInfo": "Informação pessoal",
      "tournamentTitle": "Título",
      "description": {
        "label": "Descrição",
        "pt": "Descrição em Português",
        "en": "Descrição em Inglês",
        "required": "Insira uma descrição em Português e Inglês."
      },
      "interests": {
        "label": "Interesses",
        "pt": "Interesses em Português",
        "en": "Interesses em Inglês",
        "required": "Insira os seus interesses em Português e Inglês."
      },
        "hobbies": {
            "label": "Hobbies",
            "pt": "Hobbies em Português",
            "en": "Hobbies em Inglês",
            "required": "Insira os seus hobbies em Português e Inglês."
        },
        "shortdescription": {
            "label": "Descrição curta",
            "pt": "Descrição curta em Português",
            "en": "Descrição curta em Inglês",
            "required": "Insira uma descrição curta em Português e Inglês."
        },
      "charsLimit": "Limite de {limit} caracteres.",
      "notDefined": "Não definido"
    },
    "en": {
      "endorse": "Endorse",
      "remove_endorsement": "Remove endorsement",
      "contact_title": "Contact me",
      "contact_subtitle": "Talk to me securely, through UC's digital tools.",
      "chat": "Chat",
      "live": "Virtual room",
      "update": "This page was updated at ",
      "presentation": "Personal presentation",
      "no_content": "No content",
      "no_competences": "No competences defined",
      "documents": "Documents",
      "file_title": "File title",
      "curriculum_activities": "Curriculum activities",
      "no_curriculum": "No curriculum activities defined",
      "extra_curriculum_activities": "Extra-curricular activities",
      "no_extra_curriculum": "No extra-curricular activities defined",
      "no_activity": "No activities defined",
      "summary" : "Summary",
      "professional_experience": "Professional experience",
      "no_professional_experience": "No professional experience defined",
      "volunteering": "Volunteering / Associativism",
      "no_volunteering": "No volunteering / associativism defined",
      "certifications": "Certifications",
      "no_certifications": "No certifications defined",

      "coimbra_uc": "University of Coimbra",
      "coimbra_uc_website": "uc.pt/en",
      "career_development": "Career development",
      "welcome": "Welcome",
      "competences": "Competences",
      "academic_plan": "Academic plan",
      "settings": "Settings",
      "about": "About me",
      "generalDetails": "General details",
      "tournamentTitle": "Title",
      "thereAreErrors": "There are errors in the form",
      "personalInfo": "Personal info",
      "close": "Close",
      "edit": "Edit",
      "save": "Save",
      "delete": "Remove",
      "description": {
        "label": "Description",
        "pt": "Description in Portuguese",
        "en": "Description in English",
        "required": "Enter a description in English and Portuguese."
      },
      "interests": {
        "label": "Interests",
        "pt": "Interests in Portuguese",
        "en": "Interests in English",
        "required": "Enter your interests in English and Portuguese."
      },
      "hobbies": {
        "label": "Hobbies",
        "pt": "Hobbies in Portuguese",
        "en": "Hobbies in English",
        "required": "Enter your hobbies in English and Portuguese."
      },
      "shortdescription": {
        "label": "Short description",
        "pt": "Short description in Portuguese",
        "en": "Short description in English",
        "required": "Enter a short description in English and Portuguese."
      },
      "charsLimit": "Limit of {limit} characters.",
      "notDefined": "Not defined"
    }
  }
  </i18n>
<style scoped>
.aboutme-title {
  @apply text-primary font-semibold text-lg;
}
.portfolio-label {
  @apply font-semibold text-gray-500;
}
.portfolio-completed {
  @apply bg-primary text-sm rounded-full text-white px-3 py-0.5 font-semibold;
}
.portfolio-date {
  @apply bg-gray-500 text-sm rounded-full text-white px-3 py-0.5 font-semibold;
}
.aboutme-text {
  @apply text-black text-base;
}
</style>
