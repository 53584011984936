<template>
  <div
    class="ucmeetings my-webcam z-20 absolute overflow-hidden"
    :class="{
      'is-minimal w-28 max-h-16 top-0 right-0': isMinimal,
      'right-4 top-20 w-72 drop-shadow': !isMinimal,
      'mirror-disabled': camera.mirrorDisabled,
      'cursor-pointer': !isMobile,
      'screen-sharing': screenShare.active,
      hidden: !camera.active
    }"
    @click="minimal = isMobile ? false : !minimal"
  >
    <div class="video-stream z-0">
      <video
        id="video-my-webcam"
        class="webcam"
        :class="{ 'video-off': !camera.active }"
        autoplay
        playsinline
        muted="muted"
        poster="/img/pod-video-poster.png?v=2"
      />
      <video id="video-my-screen" class="screenshare" autoplay playsinline muted="muted" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    attendee: Object,
    audio: Object,
    camera: Object,
    screenShare: Object,
    activePod: Object,
    view: String,
    podsLength: Number
  },

  data() {
    return {
      minimal: false,
      minimalLastState: false
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },
    isMinimal() {
      return this.minimal || this.view != 'participants' || this.$device.isMobile()
    },
    isMobile() {
      return this.$device.isMobile()
    }
  },

  watch: {
    minimal(newVal, oldVal) {
      if (newVal !== oldVal) this.$emit('self-pod-size', this.minimal ? 'minimal' : 'normal')
    },
    activePod(pod) {
      if (pod && pod.type === 'screen_share') {
        this.minimal = true
      } else {
        this.minimal = false
      }
    }
  }
}
</script>

<style lang="scss">
.ucmeetings.my-webcam {
  video.webcam {
    transform: rotateY(180deg);
  }

  &.mirror-disabled {
    video.webcam {
      &:not(.video-off) {
        transform: none;
      }
    }
  }
}
</style>
