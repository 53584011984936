var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat-files group overflow-visible",attrs:{"data-key":_vm.message.key}},[(_vm.attachedFiles.length)?_c('div',_vm._l((_vm.attachedFiles),function(file,f){return _c('div',{key:file.key,staticClass:"group flex gap-2.5 pr-3 items-center cursor-pointer",class:{
        'pb-2': f < _vm.attachedFiles.length - 1,
        'pl-1': !_vm.paddingless
      },on:{"click":function($event){return _vm.$emit('preview-file', file)}}},[_c('div',{staticClass:"h-10 w-10 rounded-md overflow-hidden flex-shrink-0 bg-black/10"},[(file.type === 'image')?_c('div',{staticClass:"h-full w-full",style:({
            backgroundImage: `url(${_vm.getImageViewUrl(file)})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          })}):(file.type === 'video' || file.type === 'audio')?_c('div',{staticClass:"h-full w-full flex items-center justify-center"},[_c('fw-icon-play-circle',{staticClass:"w-5 h-5 opacity-50"})],1):_c('div',{staticClass:"h-full w-full flex items-center justify-center"},[_c('fw-icon-file',{staticClass:"w-5 h-5 opacity-60"})],1)]),_c('div',{staticClass:"flex-1 text-sm font-medium"},[_c('v-clamp',{attrs:{"autoresize":"","max-lines":1}},[_vm._v(" "+_vm._s(file.filename)+" ")]),_c('div',{staticClass:"flex gap-2 text-xs opacity-60"},[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(_vm.message.created_date))+" ")])],1),(_vm.allowDownloads)?_c('a',{staticClass:"font-bold text-sm inline-flex",attrs:{"target":"_blank","href":_vm.getFileUrl(file)},on:{"click":function($event){$event.stopPropagation();}}},[_c('fw-icon-download',{staticClass:"w-5 h-5"})],1):_vm._e()])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }