<template>
  <div
    class="flex flex-col h-full"
    :class="{
      'md:p-4 p-3': !paddingless
    }"
  >
    <div class="flex-1"><slot name="main"></slot><slot name="default"></slot></div>
    <div v-if="$slots.footer"><slot name="footer"></slot></div>
  </div>
</template>

<script>
export default {
  name: 'SidebarBase',
  props: {
    paddingless: {
      type: Boolean,
      default: false
    }
  }
}
</script>
