<template>
  <div class="flex flex-col gap-5 bg-white h-full">
    <div class="flex gap-5 items-center pl-5 pr-2 py-2">
      <div>
        <fw-heading size="md" muted>Notificação</fw-heading>
      </div>
      <div class="flex-1 flex gap-1 items-center justify-end">
        <fw-button
          v-if="!notification.date && !notification.archived_date"
          type="link-muted"
          class="flex items-center gap-1"
          @click.native="update(notification, 'read')"
        >
          <fw-icon-check class="w-5 h-5" />
          {{ $t('markAsRead') }}
        </fw-button>
        <fw-button
          v-if="notification.date"
          type="link-muted"
          class="flex items-center gap-1"
          @click.native="update(notification, 'unread')"
        >
          <fw-icon-check class="w-5 h-5" />
          {{ $t('markAsUnread') }}
        </fw-button>
        <fw-button
          v-if="!notification.archived_date"
          type="link-muted"
          class="flex items-center gap-1"
          @click.native="update(notification, 'archive')"
        >
          <fw-icon-archive class="w-5 h-5" />
          {{ $t('archive') }}
        </fw-button>
        <fw-button
          v-if="notification.archived_date"
          type="link-muted"
          class="flex items-center gap-1"
          @click.native="update(notification, 'unarchive')"
        >
          <fw-icon-unarchive class="w-5 h-5" />
          {{ $t('moveToInbox') }}
        </fw-button>
      </div>
    </div>
    <div class="overflow-auto px-5 h-full flex-1">
      <div class="flex gap-7 max-w-xl mx-auto">
        <div>
          <svg
            v-if="getAppIcon(notification) == 'default'"
            class="fill-current w-12 h-12 md:w-16 md:h-16 rounded-lg p-2 lg:p-4 bg-gray-900 text-white shadow-md"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M18 10a6 6 0 1 0-12 0v8h12v-8zm2 8.667l.4.533a.5.5 0 0 1-.4.8H4a.5.5 0 0 1-.4-.8l.4-.533V10a8 8 0 1 1 16 0v8.667zM9.5 21h5a2.5 2.5 0 1 1-5 0z"
            />
          </svg>
          <div
            v-else
            class="w-10 h-10 md:w-16 md:h-16 overflow-hidden rounded-lg flex items-center justify-center object-cover shadow-md"
          >
            <img :src="getAppIcon(notification)" />
          </div>
        </div>
        <div class="flex-1 flex flex-col gap-3 pt-1">
          <div class="flex gap-5 justify-between text-gray-500 text-sm">
            <div class="font-bold uppercase">{{ getAppName(notification) }}</div>
            <div class="text-gray-500 font-medium">{{ notification.created_date | formatDateTime }}</div>
          </div>
          <div class="font-medium flex flex-col gap-3">
            <div class="text-2xl font-bold" v-html="notification.title" />
          </div>
          <div v-if="notification.type == 'acceptance' && !notification.date">
            <fw-button
              type="primary"
              class="flex items-center justify-center gap-1"
              @click.native="update(notification, 'accept')"
            >
              {{ $t('accept') }}
            </fw-button>
          </div>
        </div>
      </div>
      <div class="border-t my-5"></div>
      <div class="overflow-x-auto max-w-xl mx-auto">
        <div class="text-base" v-html="notification.full_message || notification.message" />
        <div v-if="notification.redirect_url !== null" class="flex justify-end mt-5">
          <fw-button
            type="link"
            rounded="full"
            class="flex items-center justify-center gap-1"
            @click.native="goToNotification(notification.key, false)"
          >
            {{ $t('openOnContext') }} <fw-icon-arrow-right class="w-5 h-5" />
          </fw-button>
        </div>
      </div>
    </div>
    <div class="text-xs text-gray-500 px-5 pb-5 text-center">Notification key {{ notification.key }}</div>
  </div>
</template>

<script>
import ServiceNotifications from '@/fw-modules/fw-core-vue/notifications/services/ServiceNotifications'
import config from '@/fw-modules/fw-core-vue/config.js'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
export default {
  props: {
    notification: {
      type: Object,
      deep: true
    },
    view: {
      type: String,
      default: 'unread'
    }
  },

  data() {
    return {
      loading: false
    }
  },

  computed: {
    acceptWaiting() {
      return this.notification.type == 'acceptance' && !this.notification.date
    },
    apps() {
      return this.$store.getters.getApps
    }
  },

  methods: {
    closeDetailsModal() {
      if (this.activeNotification && this.view == 'unread') {
        this.$store.dispatch('removeUnreadNotification', this.activeNotification.key)
      }

      this.activeNotification = null
      this.isDetailsModalOpen = false
    },

    async goToNotification(key, forceModal = true) {
      this.activeNotification = await ServiceNotifications.get(key, this.view == 'unread')
      //console.log(this.activeNotification)
      if (
        this.activeNotification.redirect_url !== null &&
        typeof this.activeNotification.redirect_url === 'string' &&
        this.activeNotification.redirect_url.length > 0 &&
        forceModal === false
      ) {
        const redirectUrl = this.activeNotification.redirect_url
        let hostname_withoutprotocol = redirectUrl.startsWith('http://') ? redirectUrl.substr(7) : redirectUrl.substr(8)
        //console.log('hostname_withoutprotocol', hostname_withoutprotocol)
        let parsed_host = hostname_withoutprotocol
          .split('/')[0]
          .split('.')
          .slice(-2)
          .join('.')
          .toLowerCase()
        //console.log('parsed_host', parsed_host)
        if (config.secureDomains.includes(parsed_host)) {
          window.open(this.activeNotification.redirect_url, '_self')
        } else {
          console.error(this.activeNotification.redirect_url + ' is not a secure domain')
        }
      } else {
        this.isDetailsModalOpen = true
      }
    },

    getAppName(notification) {
      if (!notification.application_title) {
        if (!notification.application) {
          notification.application_title = 'Sistema'
        } else {
          for (let app of this.$store.getters.getApps) {
            if (app.code == notification.application) {
              notification.application_title = app.title
              break
            }
          }
          if (!notification.application_title) {
            notification.application_title = notification.application
          }
        }
      }
      return utils.appLabels()[notification.application_title]
        ? utils.appLabels()[notification.application_title]
        : notification.application_title
    },

    getAppIcon(notification) {
      if (!notification.application_icon) {
        notification.application_icon = 'default'
        if (notification.application) {
          for (let app of this.$store.getters.getApps) {
            if (app.code == notification.application) {
              if (app.icon) {
                notification.application_icon = app.icon.url.replace('{SIZE}', '65')
              }
              break
            }
          }
        }
      }
      return notification.application_icon
    },

    async update(notification, action) {
      const updatedNotification = await ServiceNotifications.update(notification.key, action)
      if (action != 'unarchive') {
        this.$store.dispatch('removeUnreadNotification', notification.key)
        if (action == 'read' || action == 'accept') {
          notification.unread = false
          notification.date = updatedNotification.date
        } else if (action == 'unread') {
          notification.unread = true
          notification.date = null
        } else {
          notification.unread = false
          notification.date = updatedNotification.date
          notification.archived_date = updatedNotification.archived_date
        }
      } else {
        notification.archived_date = null
      }
      if (this.isDetailsModalOpen) {
        this.closeDetailsModal()
      }

      if ((action == 'archive' && this.view == 'inbox') || (action == 'unarchive' && this.view == 'archived')) {
        this.$emit('remove-from-list', notification.key)
      } else if ((action == 'unarchive' && this.view == 'inbox') || (action == 'archive' && this.view == 'archived')) {
        this.$emit('add-to-list', notification)
      }
      this.$emit('update', notification)
    },

    redirect() {
      window.location.href = this.notification.redirect_url
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "notification": "Notificação",
    "archive":"Arquivar",
    "unarchive": "Remover do arquivo",
    "accept": "Aceitar",
    "goToPage": "Ir para página",
    "markAsRead": "Marcar como lida",
    "moveToInbox": "Mover para a inbox",
    "open": "Abrir",
    "openOnContext": "Abrir contexto",
    "markAsUnread": "Marcar como não lida"
  },
  "en": {
    "notification": "Notifications",
    "archive":"Archive",
    "unarchive": "Unarchive",
    "accept": "Accept",
    "goToPage": "Go to page",
    "markAsRead": "Mark as read",
    "moveToInbox": "Move to inbox",
    "open": "Open",
    "openOnContext": "Open context",
    "markAsUnread": "Mark as unread"
  }
}
</i18n>
