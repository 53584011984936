<template>
  <div class="h-full">
    <PanelPost
      v-if="collectionId && postId"
      :id="postId"
      :key="postId"
      :collection-key="collectionId"
      show-navigation
      @saved="postSaved($event)"
      @open="openPost($event)"
      @deleted="postDeleted($event)"
      @reset-route="resetRoute"
    />
    <PanelPosts
      v-else
      :key="type"
      :type="type"
      :collections="collections"
      :params="{ status: ['published'] }"
      @open="openPost($event)"
    />
    <fw-modal
      v-if="showModalEditPost && collectionId"
      :active.sync="showModalEditPost"
      :can-cancel="true"
      paddingless
      size="min"
      height-fit-screen
      @close="close"
    >
      <template #default>
        <BlockEditPost
          :collection-key="collectionId"
          :context="'post'"
          :post="null"
          :type="type"
          @saved="postSaved($event)"
          @close="close"
        />
      </template>
    </fw-modal>
  </div>
</template>

<script>
import PanelPost from '@/fw-modules/fw-core-vue/posts/components/panels/PanelPost'
import ServicePosts from '@/fw-modules/fw-core-vue/posts/services/ServicePosts'
import BlockEditPost from '@/fw-modules/fw-core-vue/posts/components/blocks/BlockEditPost.vue'
import PanelPosts from '@/fw-modules/fw-core-vue/posts/components/panels/PanelPosts.vue'

export default {
  components: {
    PanelPost,
    BlockEditPost,
    PanelPosts
  },
  props: {
    course: {
      type: Object,
      default: () => ({})
    },
    edition: {
      type: Object,
      default: () => ({})
    },
    view: {
      type: String,
      default: 'posts'
    },
    collections: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      posts: [],
      users: {},
      pagination: {
        page: 1,
        totalResults: 0,
        totalPages: 1,
        limit: 25
      },
      showModalEditPost: false
    }
  },
  computed: {
    type() {
      return this.view === 'posts' ? 'post' : 'discussion'
    },
    collectionName() {
      return this.collection ? ` - ${this.collection.title}` : ''
    },
    collectionId() {
      return this.$route.params.collectionId ?? null
    },
    postId() {
      return this.$route.params.postId ?? null
    }
  },
  watch: {
    view() {
      // this.loadPosts()
    }
  },
  mounted() {
    console.log('PanelCourseEditionPosts', this.course, this.edition)
    // this.loadPosts()
  },
  methods: {
    resetRoute() {
      this.$router.push({
        name: 'course-edition-post-collection',
        params: { id: this.course.key, editionId: this.edition.key, collectionId: this.collectionId }
      })
    },
    postSaved(post) {
      console.log('postSaved', post)
      this.showModalEditPost = false
      if (this.posts.find(p => p.key === post.key)) {
        this.posts = this.posts.map(p => {
          if (p.key === post.key) {
            return post
          }
          return p
        })
      } else {
        this.posts.unshift(post)
      }

      this.openPost(post)
    },
    postDeleted(post) {
      console.log('postDeleted', post)
      this.posts = this.posts.filter(p => p.key !== post.key)
    },
    newPost() {
      this.showModalEditPost = true
    },
    close() {
      this.showModalEditPost = false
    },
    async loadPosts() {
      this.loading = true
      try {
        let data = await ServicePosts.getFeed(
          this.collections.map(c => c.key),
          this.view == 'discussions' ? 'discussion' : 'post',
          {
            page: this.pagination.page,
            limit: this.pagination.limit,
            status: ['published']
          }
        )
        console.log('loadPosts PanelCourseEditionPosts :>> ', data)
        this.posts = data.posts
        this.users = { ...this.users, ...data.users }
        if (data.pagination) {
          this.pagination = {
            totalPages: data.pagination.total_pages,
            page: data.pagination.current_page,
            totalResults: data.pagination.total_items,
            limit: data.pagination.active_limit
          }
        }
      } finally {
        this.loading = false
      }
    },
    openPost(post) {
      console.log('openPost', post)
      this.$router.push({
        name: 'course-edition-post-collection',
        params: {
          postId: post.key,
          collectionId: post.collection.key,
          courseKey: this.course.key,
          editionKey: this.edition.key,
          subpage: this.view
        }
      })
    }
  }
}
</script>
