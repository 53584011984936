import Vue from 'vue'
import VueRouter from 'vue-router'

import BaseRoutes from './routes'

import NonDregreeCoursesRoutes from '@/fw-modules/fw-core-vue/nondegree-courses/routes'
import CoursesRoutes from '@/fw-modules/fw-core-vue/courses/routes.js'
import CoreRouter from '@/fw-modules/fw-core-vue/router'
import CoreRoutes from '@/fw-modules/fw-core-vue/routes'
import ExamsRoutes from '@/fw-modules/fw-core-vue/exams/routes-student' // New exams

Vue.use(VueRouter)

const routes = [].concat(BaseRoutes, NonDregreeCoursesRoutes, ExamsRoutes, CoursesRoutes, CoreRoutes)

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_ROUTER_BASE || '/',
  routes,
})

router.beforeResolve((to, from, next) => {
  return CoreRouter.beforeResolve(to, from, next)
})
router.afterEach((to, from, next) => {
  return CoreRouter.afterEach(to, from, next)
})
router.beforeEach((to, from, next) => {
  return CoreRouter.beforeEach(to, from, next)
})

export default router
