<template>
  <fw-avatar :user="user" :size="size" :borderless="borderless" :custom-class="customClass" />
</template>

<script>
export default {
  props: {
    user: {
      type: Object
    },
    size: {
      type: String,
      default: 'sm'
    },
    customClass: {
      type: String,
      default: ''
    },
    borderless: {
      type: Boolean,
      default: false
    }
  }
}
</script>
