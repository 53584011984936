<template>
  <div
    class="card-bucket bg-white w-full group h-full flex items-center justify-center flex-col p-4 shadow-lg hover:shadow-2xl rounded-xl cursor-pointer relative"
    @click="$router.push({ name: 'bucket', params: { key: bucket.key } })"
  >
    <div v-if="bucket.unread" class="absolute top-1.5 right-4 w-2 h-2">
      <b-tooltip type="is-light" label="Novos ficheiros" position="is-left" class="h-full">
        <fw-dot-active class="h-2 w-2"></fw-dot-active>
      </b-tooltip>
    </div>
    <div class="flex-1 flex flex-col items-center justify-center w-full">
      <div class="flex items-center text-primary">
        <div class="relative w-8 h-8">
          <fw-icon-box-open class="w-8 h-8" />
          <div
            v-if="bucket.type == 'unit_edition' || bucket.type == 'unit'"
            class="absolute shadow -bottom-1.5 -right-1.5 bg-teal-500 rounded-full text-center text-2xl"
            style="height: 16px; width: 16px; padding: 3px"
          >
            <svg
              title="Repositório da disciplina"
              class="shared-bucket text-white fill-current h-full w-full"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M2 19h20v2H2v-2zM2 5l5 3 5-6 5 6 5-3v12H2V5z" />
            </svg>
          </div>
        </div>
      </div>
      <v-clamp class="text-center w-full text-md font-bold mt-2 mb-2 text-teal-800" autoresize :max-lines="2">
        {{ bucket.type == 'unit_edition' || bucket.type == 'unit' ? bucket.context.unit_title : bucket.title }}
      </v-clamp>
      <div class="flex flex-col text-xs text-gray-500 justify-between mb-2">
        <div
          v-if="
            !bucket.is_owner &&
              !(bucket.type == 'class_edition' || bucket.type == 'unit_edition' || bucket.type == 'unit')
          "
        >
          Partilhado comigo
        </div>
        <div
          v-if="bucket.type == 'class_edition' || bucket.type == 'unit_edition' || bucket.type == 'unit'"
          class="flex flex-col items-center justify-center"
        >
          <v-clamp autoresize :max-lines="2" class="font-medium text-center text-teal-800 mb-2">
            {{
              bucket.type == 'unit_edition' || bucket.type == 'unit'
                ? 'Repositório da disciplina'
                : bucket.context.unit_title
            }}
          </v-clamp>
          <div
            v-if="bucket.context.courses && bucket.context.courses.length > 0 && appName === 'ucteacher'"
            class="w-full"
          >
            <v-clamp
              v-for="(course, c) in bucket.context.courses"
              :key="bucket.id + '_' + c"
              autoresize
              :max-lines="1"
              class="font-medium text-center mb-1"
            >
              {{ course }}
            </v-clamp>
          </div>

          <div class="flex mt-1 item-center">
            <div
              v-if="bucket.context.profile_initial"
              class="bg-gray-100 rounded px-1 font-semibold mx-1 text-xs leading-4 pt-0.5 whitespace-nowrap"
            >
              {{ bucket.context.profile_initial }}
            </div>
            <div
              v-else-if="bucket.type === 'unit'"
              class="text-primary bg-gray-100 rounded px-1 font-semibold mx-1 text-xs leading-4 pt-0.5"
            >
              GERAL
            </div>
            <div
              v-if="bucket.context.regime && bucket.context.regime === 'semiannual'"
              class="opacity-90 mt-0.5 mr-2 ml-1.5 whitespace-nowrap"
            >
              {{ bucket.context.regime_occurrence }}º&nbsp;Semestre
            </div>
            <div v-else class="opacity-90 mt-0.5">{{ bucket.context.academic_year }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full text-xs flex justify-between gap-2 text-gray-500">
      <div class="flex items-center gap-1">
        <svg
          v-if="bucket.shared"
          title="Repositório partilhado"
          class="shared-bucket fill-current w-4 h-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M2 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H2zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm8.284 3.703A8.002 8.002 0 0 1 23 22h-2a6.001 6.001 0 0 0-3.537-5.473l.82-1.824zm-.688-11.29A5.5 5.5 0 0 1 21 8.5a5.499 5.499 0 0 1-5 5.478v-2.013a3.5 3.5 0 0 0 1.041-6.609l.555-1.943z"
          />
        </svg>
        <svg
          v-else
          title="Repositório privado"
          class="fill-current w-4 h-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12.414 5H21a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7.414l2 2zM4 5v14h16V7h-8.414l-2-2H4zm11 8h1v4H8v-4h1v-1a3 3 0 0 1 6 0v1zm-2 0v-1a1 1 0 0 0-2 0v1h2z"
          />
        </svg>
      </div>
      <div>
        <div class="flex items-center gap-1">
          <fw-icon-file class="w-4 h-4"></fw-icon-file>
          {{ bucket.stats.files }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bucket: Object,
    appName: String
  }
}
</script>
