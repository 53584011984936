<template>
  <div
    class="cursor-pointer flex px-4 gap-3 items-center text-lg transition-all"
    :class="{
      'pb-7 pt-4 opacity-90 hover:opacity-100': type == 'comment',
      'pb-3 pt-3 opacity-70 hover:opacity-100': type != 'comment'
    }"
    @click="$emit('create')"
  >
    <div v-if="type == 'comment'">
      <Avatar :user="user" size="sm"></Avatar>
    </div>
    <div
      class="flex-1 pl-0.5"
      :class="{
        'text-gray-500': type != 'comment',
        'text-gray-600': type == 'comment'
      }"
    >
      {{ type == 'comment' ? 'Nova resposta' : 'Criar nova publicação' }}
    </div>
    <div class="bg-primary text-white text-sm py-2 px-4 font-bold rounded-full opacity-40">
      {{ type == 'comment' ? 'Responder' : 'Publicar' }}
    </div>
  </div>
</template>

<script>
import Avatar from '@/fw-modules/fw-core-vue/ui/components/FwAvatar'
export default {
  name: 'BlockNewPostPlaceholder',
  components: {
    Avatar
  },
  props: {
    type: {
      type: String,
      default: 'post'
    },
    user: {
      type: Object,
      default: null
    }
  }
}
</script>
