<template>
  <div v-if="page.subpages?.length">
    <div class="border-b border-gray-100">
      <fw-label>Sub-páginas</fw-label>
    </div>
    <SubpageRecord
      v-for="subpage in page.subpages"
      :key="subpage.key"
      :instance="subpage"
      :users="users"
      @open="$emit('open', $event)"
    >
    </SubpageRecord>
  </div>
</template>

<script>
import SubpageRecord from '@/fw-modules/fw-core-vue/pages/components/records/SubpageRecord'
export default {
  name: 'PanelPageSubpages',

  components: {
    SubpageRecord
  },

  props: {
    page: Object,
    users: Object
  },

  data() {
    return {}
  }
}
</script>
