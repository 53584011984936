<template>
  <div class="relative w-5 h-6 block rounded-md" :class="[inactiveColor]">
    <svg
      :class="{
        'text-primary': order.toUpperCase() === 'ASC',
        'opacity-90': order.toUpperCase() === 'ASC',
        '-top-1.5 -left-1': size === 'md',
        '-top-1 -left-0.5 h-6 w-6': size === 'sm'
      }"
      class="absolute fill-current"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12 11.828l-2.828 2.829-1.415-1.414L12 9l4.243 4.243-1.415 1.414L12 11.828z" />
    </svg>
    <svg
      class="absolute fill-current"
      :class="{
        'text-primary': order.toUpperCase() === 'DESC',
        'opacity-90': order.toUpperCase() === 'DESC',
        '-bottom-1.5 -left-1': size === 'md',
        '-bottom-1 -left-0.5 h-6 w-6': size === 'sm'
      }"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12 15l-4.243-4.243 1.415-1.414L12 12.172l2.828-2.829 1.415 1.414z" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'OrderToggle',
  props: {
    order: {
      type: String
    },
    size: {
      type: String,
      default: 'md'
    },
    inactiveColor: {
      type: String,
      default: 'text-gray-300'
    }
  }
}
</script>
