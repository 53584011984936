import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import Api from '@/fw-modules/fw-core-vue/api/Api'
import store from '@/store'

export default {
  base() {
    let api = Api()
    api.defaults.baseURL = FwEnvConfig.apiUrlCourses
    return api
  },

  //PUBLIC
  async getCourseEdition(courseKey, courseEditionKey, section, class_key = null) {
    const response = await this.base().get(`/v1/courses/${courseKey}/editions/${courseEditionKey}`, {
      params: { section, class_key }
    })
    return response.data
  },
  async getCourseEditionUnit(courseKey, courseEditionKey, unitKey, params = {}) {
    const response = await this.base().get(`/v1/courses/${courseKey}/editions/${courseEditionKey}/units/${unitKey}`, {
      params
    })
    return response.data
  },
  async getUserCourses(params = {}) {
    const response = await this.base().get(`/v1/courses`, { params })
    return response.data
  },
  async getUserEnrollments() {
    const response = await this.base().get(`/v1/user`)
    return response.data
  },
  async getUserLastEnrollment() {
    const response = await this.base().get(`/v1/courses/last-enrollment`)
    return response.data
  },
  async getStudentChats(courseKey, courseEditionKey, class_key = null) {
    const response = await this.base().get(`/v1/courses/${courseKey}/editions/${courseEditionKey}/chats`, {
      params: { class_key }
    })
    return response.data
  },
  async getCourseDetails(courseKey) {
    const response = await this.base().get(`/v1/courses/${courseKey}`)
    return response.data
  },
  async signUpCourseEdition(courseKey, editionKey) {
    const response = await this.base().post(`/v1/courses/${courseKey}/editions/${editionKey}`)
    return response.data
  },
  async saveProgress(courseKey, editionKey, unitKey, topicKey) {
    const response = await this.base().post(`/v1/courses/${courseKey}/editions/${editionKey}/progress`, {
      unit_key: unitKey,
      topic_key: topicKey
    })
    return response.data
  },
  //END PUBLIC

  //MANAGEMENT
  async getAllCourses(data = {}) {
    return (
      await this.base().get('/v1/manage/courses', {
        params: data
      })
    ).data
  },
  async createCourse(course) {
    const response = await this.base().post('/v1/manage/courses', course)
    return response.data
  },
  async getCourse(courseKey) {
    return (await this.base().get(`/v1/manage/courses/${courseKey}`)).data
  },
  async updateCourse(courseKey, course) {
    const response = await this.base().put(`/v1/manage/courses/${courseKey}`, course)
    return response.data
  },
  async getCourseManagers(courseKey, params = {}) {
    return (
      await this.base().get(`/v1/manage/courses/${courseKey}/managers`, {
        params
      })
    ).data
  },
  async addCourseManagers(courseKey, managers = []) {
    return (await this.base().post(`/v1/manage/courses/${courseKey}/managers`, managers)).data
  },
  async removeCourseManagers(courseKey, managers = []) {
    const response = await this.base().delete(`/v1/manage/courses/${courseKey}/managers`, {
      data: managers
    })
    return response.data
  },
  async updateCourseOwner(courseKey, ownerKey) {
    const response = await this.base().put(`/v1/manage/courses/${courseKey}`, {
      user_key: ownerKey
    })
    return response.data
  },
  //EDITIONS
  async createEdition(courseKey, edition) {
    const response = await this.base().post(`/v1/manage/courses/${courseKey}/editions`, edition)
    return response.data
  },
  async getEditions(courseKey) {
    const response = await this.base().get(`/v1/manage/courses/${courseKey}/editions`)
    return response.data
  },
  async updateEdition(courseKey, courseEditionKey, edition) {
    const response = await this.base().put(`/v1/manage/courses/${courseKey}/editions/${courseEditionKey}`, edition)
    return response.data
  },
  async updateEditionStatus(courseKey, courseEditionKey, status) {
    return (
      await this.base().post(`/v1/manage/courses/${courseKey}/editions/${courseEditionKey}`, {
        status: status
      })
    ).data
  },
  async getEdition(courseKey, courseEditionKey) {
    return (await this.base().get(`/v1/manage/courses/${courseKey}/editions/${courseEditionKey}`)).data
  },
  async getStatistics(courseKey, courseEditionKey) {
    const response = await this.base().get(`/v1/manage/courses/${courseKey}/editions/${courseEditionKey}/statistics`)
    return response.data
  },
  async deleteEdition(courseKey, courseEditionKey) {
    const response = await this.base().delete(`/v1/manage/courses/${courseKey}/editions/${courseEditionKey}`)
    return response.data
  },
  //CLASSES
  async getClasses(courseKey, courseEditionKey, params = { page: 1 }) {
    return (
      await this.base().get(`/v1/manage/courses/${courseKey}/editions/${courseEditionKey}/classes`, {
        params
      })
    ).data
  },
  async createClass(courseKey, courseEditionKey, courseClass) {
    const response = await this.base().post(
      `/v1/manage/courses/${courseKey}/editions/${courseEditionKey}/classes`,
      courseClass
    )
    return response.data
  },
  async updateClass(courseKey, courseEditionKey, classKey, courseClass) {
    const response = await this.base().put(
      `/v1/manage/courses/${courseKey}/editions/${courseEditionKey}/classes/${classKey}`,
      courseClass
    )
    return response.data
  },
  async getClass(courseKey, courseEditionKey, classKey) {
    const response = await this.base().get(
      `/v1/manage/courses/${courseKey}/editions/${courseEditionKey}/classes/${classKey}`
    )
    return response.data
  },
  async deleteClass(courseKey, courseEditionKey, classKey) {
    const response = await this.base().delete(
      `/v1/manage/courses/${courseKey}/editions/${courseEditionKey}/classes/${classKey}`
    )
    return response.data
  },
  async searchUsers(courseKey, editionKey = null, classKey = null, params = {}) {
    return await this.base().get('/v1/manage/users/search', {
      params: {
        ...params,
        course_key: courseKey,
        edition_key: editionKey,
        class_key: classKey
      }
    })
  },
  async bulkSearchUsers(courseKey, editionKey, data = []) {
    let params = {}
    params['course_key'] = courseKey
    if (editionKey) {
      params['edition_key'] = editionKey
    }
    return await this.base().post('/v1/manage/users/search-bulk', data, {
      params: params
    })
  },
  // async addStudentsToClass(courseKey, courseEditionKey, classKey, students = []) {
  //   return await this.base().post(
  //     `/v1/manage/courses/${courseKey}/editions/${courseEditionKey}/classes/${classKey}/students`,
  //     students
  //   )
  // },
  async getUnitsManagers(courseKey, editionKey, params) {
    const response = await this.base().get(`/v1/manage/courses/${courseKey}/editions/${editionKey}/units-managers`, {
      params
    })
    return response.data
  },
  async addUnitsManagers(courseKey, editionKey, payload) {
    const response = await this.base().post(
      `/v1/manage/courses/${courseKey}/editions/${editionKey}/units-managers`,
      payload
    )
    return response.data
  },
  async updateUnitsManagers(courseKey, editionKey, userkey, unitKeys = [], is_global = false) {
    const response = await this.base().put(`/v1/manage/courses/${courseKey}/editions/${editionKey}/units-managers`, {
      user_key: userkey,
      unit_keys: unitKeys,
      is_global
    })
    return response.data
  },
  async deleteUnitsManager(courseKey, editionKey, userkey) {
    const response = await this.base().delete(`/v1/manage/courses/${courseKey}/editions/${editionKey}/units-managers`, {
      data: {
        user_key: userkey
      }
    })
    return response.data
  },
  async getUnits(courseKey, editionKey, params = { page: 1 }) {
    const response = await this.base().get(`/v1/manage/courses/${courseKey}/editions/${editionKey}/units`, {
      params
    })
    return response.data
  },
  async createUnit(courseKey, editionKey, unit) {
    const response = await this.base().post(`/v1/manage/courses/${courseKey}/editions/${editionKey}/units`, unit)
    return response.data
  },
  async updateUnit(courseKey, editionKey, unitKey, unit) {
    const response = await this.base().put(
      `/v1/manage/courses/${courseKey}/editions/${editionKey}/units/${unitKey}`,
      unit
    )
    return response.data
  },
  async changeOrderUnit(courseKey, editionKey, unitKey, order) {
    const response = await this.base().put(`/v1/manage/courses/${courseKey}/editions/${editionKey}/units/${unitKey}`, {
      index: order
    })
    return response.data
  },
  async deleteUnit(courseKey, editionKey, unitKey) {
    const response = await this.base().delete(`/v1/manage/courses/${courseKey}/editions/${editionKey}/units/${unitKey}`)
    return response.data
  },
  async getUnit(courseKey, editionKey, unitKey) {
    const response = await this.base().get(`/v1/manage/courses/${courseKey}/editions/${editionKey}/units/${unitKey}`)
    return response.data
  },

  async getUnitManagers(courseKey, editionKey, unitKey, payload) {
    const response = await this.base().get(
      `/v1/manage/courses/${courseKey}/editions/${editionKey}/units/${unitKey}/managers`,
      payload
    )
    return response.data
  },

  async addUnitManagers(courseKey, editionKey, unitKey, payload) {
    const response = await this.base().post(
      `/v1/manage/courses/${courseKey}/editions/${editionKey}/units/${unitKey}/managers`,
      payload
    )
    return response.data
  },

  async deleteUnitManager(courseKey, editionKey, unitKey, payload) {
    const response = await this.base().delete(
      `/v1/manage/courses/${courseKey}/editions/${editionKey}/units/${unitKey}/managers`,
      { data: payload }
    )
    return response.data
  },

  async getUnitPostsCollections(courseKey, editionKey, unitKey, params) {
    return (
      await this.base().get(`/v1/manage/courses/${courseKey}/editions/${editionKey}/units/${unitKey}/collections`, {
        params
      })
    ).data
  },

  async getTopics(courseKey, editionKey, unitKey, params = { page: 1 }) {
    const response = await this.base().get(
      `/v1/manage/courses/${courseKey}/editions/${editionKey}/units/${unitKey}/topics`,
      {
        params
      }
    )

    return response.data
  },
  async createTopic(courseKey, editionKey, unitKey, topic) {
    return (
      await this.base().post(`/v1/manage/courses/${courseKey}/editions/${editionKey}/units/${unitKey}/topics`, topic)
    ).data
  },
  async updateTopic(courseKey, editionKey, unitKey, topicKey, topic) {
    return (
      await this.base().put(
        `/v1/manage/courses/${courseKey}/editions/${editionKey}/units/${unitKey}/topics/${topicKey}`,
        topic
      )
    ).data
  },
  async changeOrderTopic(courseKey, editionKey, unitKey, topicKey, order) {
    return await this.base().put(
      `/v1/manage/courses/${courseKey}/editions/${editionKey}/units/${unitKey}/topics/${topicKey}`,
      {
        index: order
      }
    )
  },
  async deleteTopic(courseKey, editionKey, unitKey, topicKey) {
    return await this.base().delete(
      `/v1/manage/courses/${courseKey}/editions/${editionKey}/units/${unitKey}/topics/${topicKey}`
    )
  },
  async getTopic(courseKey, editionKey, unitKey, topicKey) {
    return (
      await this.base().get(
        `/v1/manage/courses/${courseKey}/editions/${editionKey}/units/${unitKey}/topics/${topicKey}`
      )
    ).data
  },

  async getTopicSessions(courseKey, editionKey, unitKey, topicKey, params) {
    return (
      await this.base().get(
        `/v1/manage/courses/${courseKey}/editions/${editionKey}/units/${unitKey}/topics/${topicKey}/sessions`,
        { params }
      )
    ).data
  },
  async getUnitTopicCollections(courseKey, editionKey, unitKey, topicKey, params) {
    return (
      await this.base().get(
        `/v1/manage/courses/${courseKey}/editions/${editionKey}/units/${unitKey}/topics/${topicKey}/collections`,
        { params }
      )
    ).data
  },
  async getClassStudents(courseKey, editionKey, classKey, params) {
    const response = await this.base().get(
      `/v1/manage/courses/${courseKey}/editions/${editionKey}/classes/${classKey}/students`,
      {
        params: params
      }
    )

    return response.data
  },
  async getClassStudent(courseKey, editionKey, classKey, userKey, params) {
    const response = await this.base().get(
      `/v1/manage/courses/${courseKey}/editions/${editionKey}/classes/${classKey}/students/${userKey}`,
      {
        params: params
      }
    )

    return response.data
  },
  async addClassStudent(courseKey, editionKey, classKey, students = []) {
    const response = await this.base().post(
      `/v1/manage/courses/${courseKey}/editions/${editionKey}/classes/${classKey}/students`,
      students
    )

    return response.data
  },
  async removeClassStudent(courseKey, editionKey, classKey, students = []) {
    return (
      await this.base().delete(`/v1/manage/courses/${courseKey}/editions/${editionKey}/classes/${classKey}/students`, {
        data: students
      })
    ).data
  },
  // async getClassStaff(courseKey, editionKey, classKey, params) {
  //   return await this.base().get(
  //     '/v1/manage/courses/' + courseKey + '/editions/' + editionKey + '/classes/' + classKey + '/staff',
  //     {
  //       params: params
  //     }
  //   )
  // },
  // async addClassStaff(courseKey, editionKey, classKey, staff = []) {
  //   return await this.base().post(
  //     '/v1/manage/courses/' + courseKey + '/editions/' + editionKey + '/classes/' + classKey + '/staff',
  //     staff
  //   )
  // },
  // async removeClassStaff(courseKey, editionKey, classKey, staff = []) {
  //   return await this.base().delete(
  //     '/v1/manage/courses/' + courseKey + '/editions/' + editionKey + '/classes/' + classKey + '/staff',
  //     {
  //       data: staff
  //     }
  //   )
  // },
  async getClassSessions(courseKey, editionKey, classKey, params) {
    const response = await this.base().get(
      `/v1/manage/courses/${courseKey}/editions/${editionKey}/classes/${classKey}/sessions`,
      {
        params: params
      }
    )

    return response.data
  },
  async createClassSession(courseKey, editionKey, classKey, unitSession) {
    //unitSession = {module_key: { topic_key: {start_date, end_date, duration}}}
    return (
      await this.base().post(
        `/v1/manage/courses/${courseKey}/editions/${editionKey}/classes/${classKey}/sessions`,
        unitSession
      )
    ).data
  },
  async updateClassSession(courseKey, editionKey, classKey, session) {
    //session = {session_key: {start_date, end_date, duration}}
    return (
      await this.base().put(
        `/v1/manage/courses/${courseKey}/editions/${editionKey}/classes/${classKey}/sessions`,
        session
      )
    ).data
  },

  async getEditionPostsCollections(courseKey, editionKey, params) {
    return (await this.base().get(`/v1/manage/courses/${courseKey}/editions/${editionKey}/collections`, { params }))
      .data
  },

  async getClassGroups(courseKey, editionKey, classKey, users = false) {
    const response = await this.base().get(
      `/v1/manage/courses/${courseKey}/editions/${editionKey}/classes/${classKey}/groups`,
      { params: { users } }
    )
    return response.data
  },

  async addClassGroups(courseKey, editionKey, classKey, payload) {
    const response = await this.base().post(
      `/v1/manage/courses/${courseKey}/editions/${editionKey}/classes/${classKey}/groups`,
      payload
    )
    return response.data
  },

  async deleteClassGroup(courseKey, editionKey, classKey, groupkey) {
    const response = await this.base().delete(
      `/v1/manage/courses/${courseKey}/editions/${editionKey}/classes/${classKey}/groups/${groupkey}`
    )
    return response.data
  },

  async updateClassGroupsUsers(courseKey, editionKey, classKey, userkeys, groupkeys) {
    const response = await this.base().post(
      `/v1/manage/courses/${courseKey}/editions/${editionKey}/classes/${classKey}/students/groups`,
      { user_keys: userkeys, group_keys: groupkeys }
    )
    return response.data
  },

  async addClassGroupUsers(courseKey, editionKey, classKey, groupkey, userkeys) {
    const response = await this.base().post(
      `/v1/manage/courses/${courseKey}/editions/${editionKey}/classes/${classKey}/groups/${groupkey}/students`,
      { user_keys: userkeys }
    )
    return response.data
  },

  async deleteClassGroupUsers(courseKey, editionKey, classKey, groupkey, userkey) {
    const response = await this.base().delete(
      `/v1/manage/courses/${courseKey}/editions/${editionKey}/classes/${classKey}/groups/${groupkey}/students`,
      { data: { user_key: userkey } }
    )
    return response.data
  },

  // ActivityAPI handler
  async getActivity(courseKey, editionKey, params) {
    console.log('getActivityLogs :>> ', { courseKey, editionKey, params })

    let url = `/v1/manage/courses/${courseKey}`
    if (editionKey) {
      url = url + `/editions/${editionKey}`
    }
    const response = await this.base().get(url + '/activity', { params })
    return response.data
  },

  // NotificationsAPI handler
  async getNotifications(courseKey, editionKey, params) {
    console.log('getNotifications :>> ', { courseKey, editionKey, params })
    let url = `/v1/manage/courses/${courseKey}`
    if (editionKey) {
      url = url + `/editions/${editionKey}`
    }
    const response = await this.base().get(url + '/notifications', { params })
    return response.data
  },

  async getNotification(courseKey, editionKey, key) {
    let url = `/v1/manage/courses/${courseKey}`
    if (editionKey) {
      url = url + `/editions/${editionKey}`
    }
    const response = await this.base().get(url + '/notifications/' + key)
    return response.data
  },

  async subscribeEditionWS(connectionId, editionKey, code = 'subscribe_edition') {
    const response = await this.base().post('/v1/ws', {
      code: code,
      connectionId: connectionId,
      body: {
        edition_key: editionKey
      }
    })
    return response.data
  },

  async getChats(courseKey, editionKey) {
    const response = await this.base().get(`/v1/manage/courses/${courseKey}/editions/${editionKey}/chats`)
    return response.data
  },

  async createUsersInvites(users, notify = false) {
    const response = await this.base().post(`/v1/manage/invites`, { users, notify })
    return response.data
  },

  async sendUserInviteNotification(userkey) {
    const response = await this.base().post(`/v1/manage/invites/${userkey}/send`)
    return response.data
  },

  createEditionSubscription(editionKey, callbackFunc) {
    const cls = {
      authUserKey: store.getters.getUser.key,
      key: editionKey,
      callbackFunc: callbackFunc,

      subscribe() {
        store.commit('sendWSMessage', {
          application: 'courses',
          code: 'subscribe_edition',
          edition_key: cls.key
        })
        console.log('Edition subscribe', cls.key)
      },

      unsubscribe() {
        store.commit('sendWSMessage', {
          application: 'courses',
          code: 'unsubscribe_edition',
          edition_key: cls.key
        })
        console.log('Edition unsubscribe', cls.key)
      },

      reconnect() {
        cls.subscribe()
      },

      WSMessages(message) {
        console.log('Received WSMessages', message)
        if (message) cls.callbackFunc(message)
      },

      destroy() {
        cls.unsubscribe()
        store.commit('unsubscribeWS', { code: 'ws-reconnect', name: `courses-${cls.key}` })
        store.commit('unsubscribeWS', { code: 'courses', name: `courses-${cls.key}` })
      }
    }

    store.commit('subscribeWS', { code: 'ws-reconnect', name: `courses-${cls.key}`, callback: cls.reconnect })
    store.commit('subscribeWS', { code: 'courses', name: `courses-${cls.key}`, callback: cls.WSMessages })
    cls.subscribe()
    return cls
  }
}
