<template>
  <div class="editor-page relative">
    <div
      v-if="showChapters"
      class="fixed hidden 2xl:flex w-52 2xl:w-64 right-7 text-sm flex-col bottom-5 overflow-y-auto gap-1 text-right items-end"
      :style="{ top: chaptersOffset }"
    >
      <div v-if="version.title.length > 0 || chapters.length > 0" class="font-semibold text-gray-300 pb-0.5">
        {{ $t('index') }}
      </div>
      <div
        class="text-gray-500 hover:text-gray-700 text-sm cursor-pointer line-clamp-1 w-full overflow-hidden"
        @click="selectDocumentBlock(true)"
      >
        {{ version.title }}
      </div>
      <div
        class="transition-all flex flex-col gap-1"
        :class="{ 'opacity-0': chapters.length == 0, 'opacity-100': chapters.length > 0 }"
      >
        <div
          v-for="chapter in chapters"
          :key="chapter.id"
          class="text-gray-500 hover:text-gray-700 text-sm cursor-pointer line-clamp-1 w-full overflow-hidden"
          @click="selectBlock(chapter, true)"
        >
          {{ chapter.level == 2 ? '&nbsp;&nbsp;&nbsp;' : chapter.level == 3 ? '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' : '' }}
          {{ chapter.title }}
        </div>
      </div>
      <fw-icon-loading v-if="!chapterProcessed" class="w-5 h-5 text-gray-500" />
    </div>
    <editor-content class="main-text body-editor" :editor="editor" />
    <div v-if="isEmpty" class="py-20 text-center text-gray-400 text-sm">Página em branco</div>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
//import EditorBlockItem from '../editor/EditorBlockItem.vue'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import Focus from '@tiptap/extension-focus'
import StarterKit from '@tiptap/starter-kit'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Collaboration from '@tiptap/extension-collaboration'
import { isChangeOrigin } from '@tiptap/extension-collaboration'
import UniqueID from '@tiptap-pro/extension-unique-id'
import FileHandler from '@tiptap-pro/extension-file-handler'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
//import PanelPagesTitlePreview from '@/fw-modules/fw-core-vue/pages/components/panels/PanelPagesTitlePreview'
import CustomImage from '@/fw-modules/fw-core-vue/pages/components/editor/customImage'
import CustomVideo from '@/fw-modules/fw-core-vue/pages/components/editor/customVideo'
import youtubeVideo from '@/fw-modules/fw-core-vue/pages/components/editor/youtubeVideo'
import CustomFiles from '@/fw-modules/fw-core-vue/pages/components/editor/customFiles'
import { TrailingNode } from '@/fw-modules/fw-core-vue/pages/components/editor/trailingNode'
import Dropcursor from '@tiptap/extension-dropcursor'
import Link from '@tiptap/extension-link'
import Suggestions from '@/fw-modules/fw-core-vue/pages/components/editor/blockSuggestions'
import suggestion from '@/fw-modules/fw-core-vue/pages/components/editor/suggestions.js'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import ServicePages from '../../services/ServicePages'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import 'tippy.js/animations/shift-toward.css'
// import { Slice, Fragment, Node } from 'prosemirror-model'
export default {
  components: {
    EditorContent
    //PanelPagesTitlePreview
    //EditorBlockItem,
  },
  props: {
    mainContainer: {
      type: String,
      default: '.inner-main-content'
    },
    chaptersOffset: {
      type: String,
      default: '0'
    },
    showChapters: {
      type: Boolean,
      default: false
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    collaborationProvider: {
      type: Object,
      required: true
    },
    pageNumber: {
      type: Number,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    version: {
      type: Object,
      required: true
    },
    page: {
      type: Object,
      required: true
    },
    editorBlocks: {
      type: Array,
      default: () => [
        'paragraph',
        'heading',
        'image',
        'video',
        'blockquote',
        'codeBlock',
        'bulletList',
        'orderedList',
        //'taskList',
        'table'
      ]
    }
  },
  data() {
    return {
      chapters: [],
      filesToUpload: [],
      //blocks: [],
      blocksHeight: [],
      originalBlocksHeight: [],
      originalVersionContent: null,
      dirtyBlocks: new Set(),
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true
      },
      loading: true,
      showOriginal: false,
      loadingOriginal: false,
      editor: null,
      titleEditor: null,
      hpstatus: 'connecting',
      isEmpty: false,
      noPermissions: false,
      permissions: {
        write: true,
        read: true
      },
      firstFocus: true,
      firstFocusTitle: true,
      statesLabes: {
        pt: {
          draft: 'Rascunho',
          review: 'Em revisão',
          closed: 'Bloqueado',
          published: 'Publicado',
          deleted: 'Eliminado',
          archived: 'Arquivado'
        },
        en: {
          draft: 'Draft',
          review: 'Under review',
          closed: 'Locked',
          published: 'Published',
          deleted: 'Deleted',
          archived: 'Archived'
        }
      },
      chapterProcessed: false
    }
  },
  computed: {
    status() {
      return this.version.state
    },
    language() {
      return this.$i18n.locale
    },
    currentUser() {
      return {
        name: this.me.name,
        color: this.getRandomColor()
      }
    },
    originalVersion() {
      return this.page != null && this.page.versions != null && this.page.versions.length > 0
        ? this.page.versions.filter(el => el.language == 'pt')[0]
        : null
    },
    versions() {
      return this.page != null && this.page.versions != null ? this.page.versions : []
    },
    versionKey() {
      //from router param
      return this.$route.params.version
    },
    editorKey() {
      return this.page.key + '_' + this.version.key
    },
    blocks() {
      return this.version.blocks
    },
    me() {
      return this.$store.state.session.user
    },
    isOriginal() {
      return this.version.is_original
    }
  },
  created() {
    this.prepareEditor()
  },
  beforeDestroy() {
    this.titleEditor?.destroy()
    this.editor?.destroy()
  },
  methods: {
    openTranslationMode() {
      this.$router.push({
        name: 'content-pages-translator',
        params: { key: this.page.key, version: this.originalVersion.key }
      })
    },
    createChapters() {
      //console.log('createChapters')
      //get all headings: h1, h2, h3
      let headings = document.querySelectorAll('.body-editor h1, .body-editor h2, .body-editor h3')
      //console.log('headings', headings)
      let chapters = []
      headings.forEach(el => {
        let level = el.tagName == 'H1' ? 1 : el.tagName == 'H2' ? 2 : 3
        //id from parent node
        let id = el.getAttribute('data-id')
        let text = el.innerText
        chapters.push({
          id: id,
          title: text,
          level: level,
          type: 'heading-' + level,
          chars: text.length,
          words: text.split(' ').length
        })
      })
      this.chapters = chapters
      setTimeout(() => {
        this.chapterProcessed = true
      }, 2000)
    },
    scrollToBlock(id) {
      console.log('scrollToBlock', id)
      if (id == 'title') {
        //scroll to top
        let container = document.querySelector(this.mainContainer)
        container.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        return
      }
      //scroll smothly to element with the id
      let element = document.querySelector(`[data-id="${id}"]`)
      let container = document.querySelector(this.mainContainer)
      if (element) {
        //console.log('scrolling to element', element)
        container.scrollTo({
          top: element.offsetTop - 100,
          behavior: 'smooth'
        })
      }
    },
    selectDocumentBlock(scroll = false) {
      if (this.editor) {
        let text = this.editor.getText()
        let words = text.split(' ').length
        let chars = text.length
        this.$emit('set-active-block', {
          segmentId: 'document',
          type: 'document',
          words: words,
          characters: chars
        })
        if (scroll) {
          this.scrollToBlock('title')
        }
      }
    },
    selectBlock(data, scroll = false) {
      //console.log('selectBlock callback!', data)
      if (scroll) {
        this.scrollToBlock(data.id)
      }
      this.$emit('set-active-block', {
        segmentId: data.id,
        type: data.type,
        words: data.words,
        characters: data.chars
      })
    },
    prepareEditor() {
      //let self = this
      try {
        this.editor = new Editor({
          autoFocus: false,
          editable: this.editable,
          editorProps: {
            attributes: {
              spellcheck: 'false'
            }
          },
          extensions: [
            TrailingNode,
            Focus.configure({
              className: 'active',
              mode: 'shallowest'
            }),
            Underline,
            Placeholder.configure({
              placeholder: () => {
                return this.$t('no_content')
              }
            }),
            youtubeVideo,
            CustomFiles,
            CustomVideo,
            StarterKit.configure({
              // The Collaboration extension comes with its own history handling
              paragraph: true,
              heading: true,
              history: false,
              dropcursor: false,
              link: false,
              codeBlock: true,
              bulletList: true,
              orderedList: true,
              blockquote: true
            }),
            Superscript,
            Subscript,
            // Register the document with Tiptap
            Collaboration.configure({
              document: this.collaborationProvider.document,
              field: 'body' //this.pageNumber == 0 ? 'body' : 'page.' + this.pageNumber,
            }),
            CustomImage.configure({
              HTMLAttributes: {
                class: 'editor-image'
              }
            }),
            Dropcursor.configure({
              color: '#666666',
              width: 1.5
            }),
            UniqueID.configure({
              types: [
                'heading',
                'paragraph',
                'image',
                'taskList',
                'table',
                'codeBlock',
                'video',
                'bulletList',
                'blockquote',
                'orderedList'
              ],
              filterTransaction: transaction => !isChangeOrigin(transaction)
            }),
            FileHandler.configure({
              allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
              onDrop: (currentEditor, files, pos) => {
                if (files.length === 0) {
                  return
                }
                if (this.editorBlocks.includes('image')) {
                  files.forEach(file => {
                    const fileReader = new FileReader()
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                      currentEditor
                        .chain()
                        .insertContentAt(pos, {
                          type: 'image',
                          attrs: {
                            src: fileReader.result
                          }
                        })
                        .focus()
                        .run()
                    }
                  })
                }
              },
              onPaste: (currentEditor, files, htmlContent) => {
                files.forEach(file => {
                  if (htmlContent) {
                    // if there is htmlContent, stop manual insertion & let other extensions handle insertion via inputRule
                    // you could extract the pasted file from this url string and upload it to a server for example
                    //console.log(htmlContent) // eslint-disable-line no-console
                    return false
                  }

                  if (this.editorBlocks.includes('image')) {
                    const fileReader = new FileReader()
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                      currentEditor
                        .chain()
                        .insertContentAt(currentEditor.state.selection.anchor, {
                          type: 'image',
                          attrs: {
                            src: fileReader.result
                          }
                        })
                        .focus()
                        .run()
                    }
                  }
                })
              }
            }),
            Link.configure({
              openOnClick: false,
              autoLink: this.editorBlocks.includes('link')
            }),
            Suggestions.configure({
              onEnter: () => {
                //editor, suggestion
                //console.log('onEnter', suggestion)
                /*if (suggestion.type == 'link') {
              editor
                .chain()
                .focus()
                .insertContentAt(editor.state.selection.anchor, {
                  type: 'link',
                  attrs: {
                    href: suggestion.value,
                  },
                  content: suggestion.value,
                })
                .run()
            }*/
              },
              suggestion
            })
          ],
          pageKey: this.page.key,
          versionKey: this.version.key,
          onUpdate: async ({ editor }) => {
            console.log('EDITOR onUpdate')
            let editorJSON = await editor.getJSON()
            console.log('EDITOR JSON', editorJSON)
            if (
              editorJSON.content.length == 0 ||
              (editorJSON.content.length == 1 &&
                editorJSON.content[0].type == 'paragraph' &&
                (typeof editorJSON.content[0].content == 'undefined' || editorJSON.content[0].content.length == 0))
            ) {
              this.isEmpty = true
            } else {
              this.isEmpty = false
            }
            if (this.showChapters) {
              this.createChapters()
            }
            //
            //this.selectDocumentBlock()
          }
        })
      } catch (e) {
        console.log('PAGE VIEWER error!')
        console.error(e)
      }
      console.log('PAGE VIEWER created!')
      if (this.pageNumber == 0 && this.showTitle) {
        //this.version.title
        try {
          this.titleEditor = new Editor({
            autoFocus: false,
            editable: this.editable,
            extensions: [
              Collaboration.configure({
                document: this.collaborationProvider.document,
                field: 'title'
              }),
              Document.extend({
                content: 'paragraph'
                //title: 'text*',
              }),
              Paragraph,
              Text,
              Placeholder.configure({
                placeholder: () => {
                  return this.$t('no_title')
                }
              })
            ]
          })
        } catch (e) {
          console.log('TITLE EDITOR error')
          console.error(e)
        }
      }
    },
    getRandomElement(list) {
      return list[Math.floor(Math.random() * list.length)]
    },
    getRandomColor() {
      return this.getRandomElement(['#958DF1', '#F98181', '#FBBC88', '#FAF594', '#70CFF8', '#94FADB', '#B9F18D'])
    },
    async downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },
    getFileUrl(file) {
      return ServiceStorage.getFileUrl(file, null)
    },
    getThumbnail(file) {
      if (file) {
        return file.thumb_url_format
          .replace('{KEY}', file.key)
          .replace('{TOKEN}', file.token)
          .replace('{SIZE}', 'max2k')
          .replace('{FILENAME}', file.thumb_filename || file.filename)
      } else {
        return ''
      }
    },
    execCommand(key, cmd) {
      /*if (cmd == 'h1' || cmd == 'h2' || cmd == 'h3') {
        let block = this.blocks.find(b => b.key == key)
        if (block) {
          block.content.style = cmd
          this.$emit('mark-dirty', block.key)
          this.$emit('selected', cmd)
        }
      } else { */
      console.log('execCommand inside editor', key, cmd)
      this.$refs[key][0].execCommand(cmd)
      //}
    },
    async removeInput(i) {
      let blocksnapshot = this.blocks[i]
      this.$buefy.dialog.confirm({
        title: 'Apagar conteúdo',
        message: 'Tem a certeza que deseja <b>eliminar</b> este conteúdo? Esta ação não pode ser desfeita.',
        confirmText: 'Apagar',
        type: 'is-danger',
        hasIcon: false,
        onConfirm: async () => {
          this.$emit('remove-content', i)
          try {
            let result = await ServicePages.deleteContentBlock(this.page.key, this.version.key, blocksnapshot.key)
            console.log(result)
          } catch (e) {
            console.error(e)
            this.$emit('restore-block', i, blocksnapshot)
          }
        }
      })
    },
    getChildPayload(index) {
      return this.blocks[index]
    },
    dataChanged(blockIndex, event) {
      console.log('dataChanged, block: ' + blockIndex, event)
      if (this.blocks[blockIndex].content == null) {
        this.blocks[blockIndex].content = {}
      }
      if (this.blocks[blockIndex].type == 'text') {
        this.blocks[blockIndex]['content']['text'] = event
      } else if (this.blocks[blockIndex].type == 'image') {
        //this.blocks[blockIndex]['content']['text'] = this.blocks[blockIndex].content.text
      }
      this.$nextTick(() => {
        this.recalculateOneBlockHeight(blockIndex, 'original')
      })
      this.$emit('mark-dirty', this.blocks[blockIndex].key)
    }
  }
}
</script>

<i18n>
    {
      "pt": {
        "no_read_permissions": "Não tem permissões de leitura para este documento.",
        "no_write_permissions": "Não tem permissões de escrita para este documento.",
        "index": "Índice",
        "original_version": "Versão original",
        "show_original": "Mostrar original",
        "instructions": "Comece por adicionar conteúdo ao seu documento:",
        "subtitle": "Subtítulo",
        "text": "Texto",
        "image": "Imagem",
        "video": "Vídeo",
        "blockquote": "Citação",
        "code": "Bloco de código",
        "bullet_list": "Lista",
        "ordered_list": "Lista numerada",
        "heading": "Título",
        "task_list": "Lista de tarefas",
        "table": "Tabela",
        "insert_subtitle": "Insira subtítulo",
        "write_here": "Escreva aqui",
        "insert_title": "Insira título",
        "use_translation_mode": "Este documento é uma tradução. Pode usar o modo de tradução para editá-lo.",
        "use_translation_mode_btn": "Usar modo de tradução",
        "no_title": "Sem título",
        "no_content": "Sem conteúdo"
      },
      "en": {
        "no_read_permissions": "You don't have read permissions for this document.",
        "no_write_permissions": "You don't have write permissions for this document.",
        "index": "Index",
        "original_version": "Original version",
        "show_original": "Show original",
        "instructions": "Start by adding content to your document:",
        "subtitle": "Subtitle",
        "text": "Text",
        "image": "Image",
        "video": "Video",
        "blockquote": "Blockquote",
        "code": "Code block",
        "bullet_list": "Bullet list",
        "ordered_list": "Ordered list",
        "heading": "Heading",
        "task_list": "Task list",
        "table": "Table",
        "insert_subtitle": "Insert subtitle",
        "write_here": "Write here",
        "insert_title": "Insert title",
        "use_translation_mode": "This document is a translation. You can use translation mode to edit it.",
        "use_translation_mode_btn": "Use translation mode",
        "no_title": "No title",
        "no_content": "No content"
      }
    }
  </i18n>

<style lang="scss">
.ProseMirror .more-options a {
  @apply text-gray-600;
}
.titleeditor {
  @apply w-full text-3xl font-bold px-3;
}
.body-editor p {
  @apply text-base text-black mx-3;
}
.body-editor h1,
.body-editor h2,
.body-editor h3 {
  @apply text-black mx-3;
}
.titleeditor .is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
.editor-menu {
  @apply flex gap-1 rounded-md py-0.5 px-1 border border-gray-200 shadow-sm;
  background-color: #fafafa;
  button {
    @apply border border-transparent bg-gray-50 rounded-md px-0.5 h-8 w-8 text-center flex items-center justify-center opacity-80;

    &:hover {
      @apply opacity-100;
    }

    &.is-active {
      @apply opacity-100 bg-gray-200 border-gray-300;
    }
  }
}
.tiptap :not(pre) code {
  @apply bg-gray-100 px-1 mx-1 text-sm py-0.5 rounded-md border border-gray-200 font-mono;
}
.tiptap .is-editor-empty .content {
  min-height: 120px;
}
.tiptap div.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror ul.editor-task-list {
  padding-left: 0rem;
}
.no-draggable-content .draggable-item .block-options {
  display: none;
}
.draggable-item {
  @apply bg-white rounded-md min-h-7 relative pl-3 pr-3 py-0.5;

  .editor-drag-handle {
    width: 1rem;
    height: 1.2rem;
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16"><path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>');
    background-size: 0.7rem 0.7rem;
    cursor: grab;
    @apply shadow-sm bg-center bg-no-repeat bg-white rounded border border-gray-200 ml-1;
  }
  .block-options {
    @apply absolute top-0.5 text-gray-800 transition-all opacity-0;
    gap: 0.07rem;
    left: -3.35rem;
  }
  .content {
    flex: 1 1 auto;
  }
}
.draggable-item:hover,
.draggable-item.active {
  .block-options {
    @apply opacity-100;
  }
  .editor-drag-handle {
    @apply cursor-move;
  }
}

ul.editor-task-list {
  @apply list-none;
}
ul.editor-task-list li {
  @apply list-none flex items-center;
}
ul.editor-task-list li input {
  @apply border border-gray-200 outline-none rounded-sm w-4 h-4 mr-1;
}
ul.editor-task-list li div {
  @apply flex-1;
}
table.editor-table {
  @apply w-full border-collapse;
}
table.editor-table th,
table.editor-table td {
  @apply border border-gray-200;
}
table.editor-table td {
  @apply w-1/2;
}
.image-uploader .file-uploads {
  @apply py-10 w-full;
}
.change-image-uploader .file-uploads {
  @apply py-2 w-full;
}
.content-block .editor-drag-handle {
  @apply transition-all opacity-0;
}
.content-block:hover .editor-drag-handle {
  @apply transition-all opacity-100;
}
.content-block .add-content {
  @apply transition-all opacity-0;
}
.content-block:hover .add-content {
  @apply transition-all opacity-100;
}
.content-block-container {
  min-height: 15px;
  @apply relative;
}
.image-uploader label {
  z-index: 0 !important;
}
/* Give a remote user a caret */
.collaboration-cursor__caret {
  position: relative;
  margin-left: -1px;
  margin-right: -1px;
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  word-break: normal;
  pointer-events: none;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  position: absolute;
  top: -1.4em;
  left: -1px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  user-select: none;
  color: #0d0d0d;
  padding: 0.1rem 0.3rem;
  border-radius: 3px 3px 3px 0;
  white-space: nowrap;
}
</style>
