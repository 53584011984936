var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-1 flex-col gap-2 p-4"},[_c('fw-heading',{attrs:{"size":"h3","muted":""}},[_vm._v(" Temas "),_c('fw-badge',{attrs:{"inline":"","type":"simple"}},[_vm._v(_vm._s(_vm.topics.length))])],1),_c('router-link',{attrs:{"to":{ name: 'course-edition-unit', params: { unitKey: _vm.unit.key, view: 'introduction' } }}},[_c('div',{staticClass:"px-4 py-3 font-medium gap-3 my-1 relative w-full text-left group rounded-lg flex items-center",class:{
        'text-primary bg-white shadow-md': _vm.view == 'introduction',
        'text-gray-600 bg-gray-300 bg-opacity-20 shadow-sm hover:text-gray-800': _vm.view != 'introduction'
      }},[_c('fw-icon-book-mark-solid',{staticClass:"w-5 h-5 flex-shrink-0"}),_vm._v(" Apresentação do módulo ")],1)]),_c('router-link',{attrs:{"to":{ name: 'course-edition-unit-posts', params: { unitKey: _vm.unit.key, view: 'posts' } }}},[_c('div',{staticClass:"px-4 py-3 flex font-medium gap-3 my-1 relative w-full text-left group rounded-lg items-center",class:{
        'text-primary bg-white shadow-md': _vm.view == 'posts',
        'text-gray-600 bg-gray-300 bg-opacity-20 shadow-sm hover:text-gray-800': _vm.view != 'posts'
      }},[_c('fw-icon-discuss',{staticClass:"w-5 h-5 flex-shrink-0"}),_vm._v(" Tópicos de discussão ")],1)]),(_vm.topics && _vm.topics.length > 0)?_c('RecycleScroller',{attrs:{"items":_vm.topics,"item-size":57,"buffer":25,"key-field":"key"},scopedSlots:_vm._u([{key:"default",fn:function({ item, index }){return [_c('router-link',{staticClass:"flex p-2 gap-3 my-1 relative w-full text-left group rounded-lg",class:{
        'text-primary bg-white shadow-md': _vm.activeTopicKey == item.key,
        'text-gray-600 bg-gray-300 bg-opacity-20 shadow-sm hover:text-gray-800': _vm.activeTopicKey != item.key
      },attrs:{"to":{ name: 'course-edition-unit-topic', params: { unitKey: _vm.unit.key, topicKey: item.key } }}},[_c('div',{staticClass:"font-bold text-sm p-1 w-5",class:{
          'text-primary': _vm.checkTopicAvailable(item),
          'text-gray-500': !_vm.checkTopicAvailable(item)
        }},[(item.type == 'normal')?_c('span',[_vm._v(_vm._s(item.index + 1))]):_c('fw-icon-survey',{staticClass:"w-4 h-4"})],1),_c('div',{staticClass:"flex-1"},[_c('v-clamp',{staticClass:"font-medium",attrs:{"autoresize":"","max-lines":1}},[_vm._v(_vm._s(item.title)+" ")]),(item.type == 'normal')?_c('div',{staticClass:"text-xs opacity-80"},[(_vm.checkTopicAvailable(item))?_c('div',{staticClass:"font-medium"},[_vm._v("Disponível")]):(item.session?.time_to_start > 0)?_c('div',{staticClass:"font-medium"},[_vm._v(" Disponível a "+_vm._s(_vm._f("formatDateTime")(item.session.start_date))+" ")]):(_vm.checkTopicExpired(item) && item.seen_date)?_c('div',{staticClass:"font-medium"},[_vm._v(" Terminado ")]):(_vm.checkTopicBlocked(item))?_c('div',{staticClass:"font-medium"},[_vm._v("Bloqueado")]):_c('div',[_vm._v("Indisponível")])]):_c('div',{staticClass:"text-xs opacity-80"},[_c('div',{staticClass:"font-medium"},[_vm._v("Disponível a "+_vm._s(_vm._f("formatDateTime")(item.session.start_date))+".")])])],1),_c('div',{staticClass:"relative"},[(item.seen_date)?_c('fw-icon-check',{staticClass:"w-5 h-5 text-primary"}):(_vm.checkTopicAvailable(item))?_c('fw-dot'):_vm._e()],1)])]}}],null,false,1131566653)}):_c('div',{staticClass:"flex text-center justify-center items-center flex-col h-full text-sm text-gray-400 py-10"},[_vm._v(" Sem temas para apresentar. ")]),_c('fw-panel-info',{attrs:{"debug":"","label":"Data (raw)"}},[_c('json-viewer',{attrs:{"value":_vm.topics}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }