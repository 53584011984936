<template>
  <ChartLine class="h-64" :chart-data="datacollection"></ChartLine>
</template>

<script>
import ChartLine from '../../../ui/components/charts/ChartLine'

export default {
  components: {
    ChartLine
  },

  props: {
    totalStats: Array,
    nextStats: Array,
    doneStats: Array
  },

  data() {
    return {
      datacollection: null
    }
  },

  watch: {
    nextStats(value) {
      this.fillData(value, null)
    },
    doneStats(value) {
      this.fillData(null, value)
    }
  },

  mounted() {
    this.fillData()
  },

  methods: {
    fillData(total = null, next = null, done = null) {
      this.datacollection = {
        labels: ['Set', 'Out', 'Nov', 'Dez', 'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul'],
        datasets: [
          {
            label: 'Aulas',
            data: total || this.totalStats,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          },
          {
            label: 'Aulas previstas',
            data: next || this.nextStats,
            fill: false,
            borderColor: 'teal',
            tension: 0.1
          },
          {
            label: 'Aulas dadas',
            data: done || this.doneStats,
            fill: false,
            borderColor: 'cyan',
            tension: 0.1
          }
        ]
      }
    }
  }
}
</script>
