<template>
  <div v-if="checks.isCourseEditionUnlocked" class="w-full h-full flex justify-between border-l border-gray-200">
    <!-- Sidebar -->
    <div v-if="!isMobile" class="w-full md:w-1/3 h-full flex flex-col" :style="!isMobile ? 'max-width: 300px' : ''">
      <div class="px-5 py-4 flex flex-col">
        <div
          v-if="isMobile"
          class="mb-5 shadow bg-gradient-to-r from-teal-300 to-teal-500 w-full rounded-xl overflow-hidden text-teal-800 h-28 p-5 flex flex-col gap-1 text-sm font-medium items-center justify-center"
        ></div>
        <fw-heading size="h3" muted>
          Tópicos
          <fw-badge inline type="simple">{{ topicsAsList.length }}</fw-badge>
        </fw-heading>
      </div>
      <div class="flex flex-1 flex-col gap-2 overflow-y-auto">
        <RecycleScroller
          v-if="topicsAsList"
          v-slot="{ item, index }"
          :items="topics"
          :item-size="57"
          :buffer="25"
          key-field="key"
          class="px-2"
        >
          <div class="px-2">
            <button
              class="flex p-2 gap-3 my-1 relative w-full text-left group rounded-lg"
              :class="{
                'text-primary bg-white shadow-md': isActive(item.key),
                'text-gray-600 bg-gray-300 bg-opacity-20 shadow-sm hover:text-gray-800': !isActive(item.key)
              }"
              @click="loadTopic(item, typeof topics[index + 1] !== 'undefined', index)"
            >
              <div
                class="font-bold text-sm p-1 w-5"
                :class="{
                  'text-primary': checkTopicAvailable(item),
                  'text-gray-500': !checkTopicAvailable(item)
                }"
              >
                <span v-if="item.type == 'normal'">{{ item.index + 1 }}</span>
                <fw-icon-survey v-else class="w-4 h-4" />
              </div>
              <div class="flex-1">
                <v-clamp class="font-medium" autoresize :max-lines="1">{{ item.title.replace('.', '') }} </v-clamp>
                <div v-if="item.type == 'normal'" class="text-xs opacity-80">
                  <div v-if="checkTopicAvailable(item)" class="font-medium">Disponível</div>
                  <div v-else-if="checkTopicAvailableSoon(item)" class="font-medium">
                    Disponível a {{ item.sessions.start_date | formatDateTime }}
                  </div>
                  <div v-else-if="checkTopicExpired(item) && isTopicDone(item.key)" class="font-medium">
                    Terminado
                  </div>
                  <div v-else-if="checkTopicBlocked(item)" class="font-medium">Bloqueado</div>
                  <div v-else>Indisponível</div>
                </div>
                <div v-else class="text-xs opacity-80">
                  <div class="font-medium">Disponível a {{ item.sessions.start_date | formatDateTime }}.</div>
                </div>
              </div>
              <div class="relative">
                <fw-icon-check v-if="isTopicDone(item.key)" class="w-5 h-5 text-primary" />
                <fw-dot v-else-if="checkTopicAvailable(item)" />
              </div>
            </button>
          </div>
        </RecycleScroller>
      </div>
    </div>

    <!-- Main -->
    <div
      v-if="!isMobile && activeTopic && !isExceptionCourse"
      :key="activeTopic.key"
      class="flex-1 h-full border-l border-gray-200"
    >
      <div class="flex flex-col h-full relative">
        <div class="w-full h-full flex flex-col justify-between bg-white">
          <!-- Unit view -->
          <div class="flex-1 overflow-y-auto h-full relative p-5 lg:p-10 flex flex-col">
            <!-- Normal -->
            <div v-if="activeTopic.type == 'normal'" class="fw-active-course w-full h-full mx-auto max-w-screen-lg">
              <!-- flex flex-col -->
              <div v-if="isManager || (isActiveTopicAvailable && !isActiveTopicEmpty)" class="fw-course-video relative">
                <div
                  v-if="activeTopic.video_key && activeTopic.video_key !== null"
                  id="cloudflare-video"
                  style="position: relative"
                  :style="{ height: videoHeight + 'px' }"
                  class="cloudflare-video overflow-hidden"
                >
                  <iframe
                    :key="activeTopic.video_key"
                    :src="getCloudFlareVideoUrl(activeTopic)"
                    style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%"
                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                    allowfullscreen="true"
                  ></iframe>
                </div>
                <video-player v-else-if="activeTopic.video" :src="activeTopic.video" />
                <!-- PRR Overlay -->
                <div
                  v-if="showOverlay"
                  class="absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-75 flex flex-col items-center justify-center gap-10"
                  style="backdrop-filter: blur(10px)"
                  @click="hideOverlay"
                >
                  <div class="flex justify-center"><img src="../../assets/barra_prr.png" class="w-1/2" /></div>
                  <div class="font-semibold opacity-80">Vídeo terminado</div>
                </div>
              </div>

              <div
                v-else-if="
                  isActiveTopicAvailableSoon ||
                    isActiveTopicNotAvailable ||
                    isActiveTopicExpired ||
                    (isActiveTopicBlocked && nextAvailableTopic !== null && !isActiveTopicFirst)
                "
                class="flex-1 flex flex-col items-center justify-center mx-auto max-w-screen-sm h-full"
              >
                <fw-heading size="h2" class="text-center my-5">{{ activeTopic.title }}</fw-heading>
                <div>
                  <fw-tag
                    v-if="isTopicDone(activeTopic.key)"
                    type="primary"
                    size="md"
                    class="flex items-center gap-1 my-5"
                    >Tópico concluído <fw-icon-check class="w-5 h-5"
                  /></fw-tag>
                  <fw-tag v-else class="my-5" type="light" size="md">Tópico não concluído</fw-tag>
                </div>
                <div
                  v-if="
                    !isActiveTopicNotAvailable &&
                      isActiveTopicBlocked &&
                      nextAvailableTopic !== null &&
                      !isActiveTopicFirst
                  "
                  class="flex flex-col gap-5 items-center justify-center"
                >
                  <fw-icon-lock-line class="h-10 w-10 fill-current text-gray-500 opacity-50" />
                  <div v-if="edition.options.topics_consume_mode == 'sequential'" class="text-center text-sm">
                    O conteúdo deste tópico ficará disponível depois de avaliar o tópico anterior.
                  </div>
                  <div v-else class="text-center text-sm">
                    O conteúdo deste tópico ficará disponível a partir de
                    {{ nextAvailableTopic.sessions.start_date | formatDateTime }}.
                  </div>
                </div>
                <div v-if="isActiveTopicAvailableSoon" class="flex flex-col gap-5 items-center justify-center">
                  <div class="text-center text-bold text-primary mx-auto max-w-xs text-lg">
                    Este tópico estará disponível entre
                    {{ activeTopic.sessions.start_date | formatDateTime }} e
                    {{ activeTopic.sessions.end_date | formatDateTime }}.
                  </div>
                </div>
                <div v-if="isActiveTopicNotAvailable" class="flex flex-col gap-5 items-center justify-center">
                  <fw-icon-lock-line class="h-10 w-10 fill-current text-gray-500 opacity-50" />
                  <div class="text-center text-sm text-gray-500">
                    Este tópico não está disponível para a sua turma ou ainda não foi agendada a sua disponibilização.
                  </div>
                </div>
                <div v-if="isActiveTopicExpired" class="flex flex-col gap-5 items-center justify-center">
                  <div class="text-center text-sm font-medium text-gray-500">
                    <div>Este tópico já não está disponível para a sua turma.</div>
                  </div>
                </div>
              </div>

              <!-- Topic header -->
              <div v-if="isManager || isActiveTopicAvailable">
                <div class="flex items-start justify-between gap-5 mt-5 mb-2">
                  <fw-heading :size="activeTopic.video ? 'h3' : 'h2'">{{ activeTopic.title }}</fw-heading>
                </div>
                <div class="text-sm">
                  <div v-if="activeTopic.sessions && activeTopic.sessions.start_date && activeTopic.sessions.end_date">
                    Este tópico está disponível entre
                    {{ activeTopic.sessions.start_date | formatDateTime }} e
                    {{ activeTopic.sessions.end_date | formatDateTime }}.
                  </div>
                  <div v-else>Este tópico não tem calendarização definida para a turma.</div>
                  <fw-panel-info v-if="isManager" type="orange" icon boxed class="mt-3">
                    Enquanto gestor(a) desta edição do curso consegue visualizar este conteúdo em qualquer altura.
                  </fw-panel-info>
                </div>

                <fw-panel title="Avaliação do tópico" class="mt-10 pb-10">
                  <PanelFeedback
                    :feedback-items="feedbackItems"
                    :write-comment="true"
                    :feedback="feedback"
                    :saving="savingFeedback"
                    @save="saveTopicFeedback"
                  ></PanelFeedback>
                </fw-panel>
              </div>

              <div
                v-if="isActiveTopicAvailable && isActiveTopicEmpty"
                class="flex flex-col gap-5 items-center justify-center flex-1 m-5"
              >
                <fw-icon-sun class="w-16 h-16 text-gray-500 opacity-40"></fw-icon-sun>
                <div class="text-center text-sm font-medium text-gray-500">
                  Sem conteúdos para apresentar neste tópico.
                </div>
              </div>
            </div>

            <!-- Evaluation -->
            <div v-if="activeTopic.type == 'evaluation'" class="w-full h-full flex flex-col">
              <div class="flex-1 flex flex-col gap-4 items-center justify-center mx-auto max-w-prose">
                <fw-icon-survey
                  class="h-14 drop-shadow-lg w-14 fill-current text-gray-800 text-opacity-40 flex-shrink-0"
                />
                <div class="flex-shrink-0">
                  <fw-heading size="h2" class="text-center">Sessão de avaliação do módulo</fw-heading>
                  <fw-heading size="h3" class="text-center mb-5">{{ unit.title }}</fw-heading>
                  <div v-if="!isManager" class="flex items-center justify-center">
                    <fw-tag v-if="examSubmitted" type="primary" size="md" class="flex items-center gap-1 my-5"
                      >Exame realizado <fw-icon-check class="w-5 h-5"
                    /></fw-tag>
                    <fw-tag v-else type="light" size="md" class="my-5">Exame por realizar</fw-tag>
                  </div>
                </div>
                <div class="mt-5">
                  <div
                    v-if="
                      isActiveTopicAvailable &&
                        activeTopic.sessions &&
                        activeTopic.sessions.item_key &&
                        activeTopic.sessions.context == null
                    "
                    class="flex flex-col items-center"
                  >
                    <!-- activeTopic.sessions.context = null when there is no error -->
                    <OpenExamButton
                      :instance-id="activeTopic.sessions.item_key"
                      :resume="examSubmitted"
                      :type="'exam'"
                    ></OpenExamButton>
                    <div class="text-center text-sm mt-5">
                      Disponível até {{ activeTopic.sessions.end_date | formatDateTime }}
                    </div>
                  </div>
                  <div
                    v-else-if="
                      (!isActiveTopicAvailable && activeTopic.sessions) ||
                        (isActiveTopicAvailable &&
                          activeTopic.sessions &&
                          activeTopic.sessions.context &&
                          activeTopic.sessions.context.code == 'missing_topics')
                    "
                    class="text-center"
                  >
                    <div
                      v-if="
                        activeTopic.sessions &&
                          activeTopic.sessions.context &&
                          activeTopic.sessions.context.code == 'missing_topics' &&
                          isActiveTopicAvailable
                      "
                      class=" text-gray-600 mb-5 font-bold pt-5"
                    >
                      O exame está disponível mas existem tópicos deste módulo por avaliar.
                    </div>
                    <div v-else class="text-gray-600 mb-5 font-bold pt-5">Não disponível.</div>
                    <div class="text-sm">
                      Exame disponível entre<br />
                      {{ activeTopic.sessions.start_date | formatDateTime }} e
                      {{ activeTopic.sessions.end_date | formatDateTime }}.
                    </div>
                  </div>
                  <div v-else class="text-center text-gray-500 text-sm">
                    Este exame não está disponível para a sua turma ou ainda não foi agendado a sua disponibilização.
                  </div>
                  <div
                    v-if="
                      activeTopic.sessions &&
                        activeTopic.sessions.context &&
                        activeTopic.sessions.context.code == 'missing_topics'
                    "
                    class="py-5"
                  >
                    <div
                      v-if="
                        !isManager &&
                          !examSubmitted &&
                          activeTopic.sessions &&
                          activeTopic.sessions.context &&
                          activeTopic.sessions.context.keys
                      "
                      :class="{ ' max-h-48 overflow-hidden': !expandMissingTopics }"
                      class="text-center bg-yellow-50 rounded-sm p-7 relative text-sm"
                      @click="expandMissingTopics = !expandMissingTopics"
                    >
                      <div class="text-left pb-5">
                        Para conseguir aceder à avaliação (de acordo com o agendamento definido), todos os tópicos do
                        módulo devem estar concluídos / avaliados. Por favor, avalie os tópicos seguintes em falta:
                      </div>
                      <div
                        v-for="topic in activeTopic.sessions.context.keys"
                        :key="topic"
                        class="flex items-center gap-3 px-7 py-2"
                      >
                        <div class="opacity-80">
                          <fw-tag type="border-light" size="xs">Não avaliado</fw-tag>
                        </div>
                        <div class="font-semibold">
                          <v-clamp autoresize :max-lines="1">{{ unit.topics[topic].title }}</v-clamp>
                        </div>
                      </div>
                      <div
                        v-if="!expandMissingTopics"
                        class="absolute bottom-0 left-0 right-0 p-3 text-center bg-gradient-to-t from-yellow-50"
                      >
                        <div
                          class="px-5 py-1.5 font-semibold inline-block text-gray-800 mx-auto shadow-md rounded-full bg-gradient-to-t from-gray-100 to-white"
                        >
                          Ver todos
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Quality Evaluation -->
            <div v-if="activeTopic.type == 'quality_evaluation'" class="w-full h-full flex flex-col">
              <div class="flex-1 flex flex-col gap-4 items-center justify-center mx-auto max-w-prose">
                <fw-icon-exam class="h-20 drop-shadow-lg w-20 fill-current text-gray-800 text-opacity-40" />
                <div>
                  <fw-heading size="h3" class="text-center" marginless>Questionário de satisfação</fw-heading>
                  <div class="font-medium text-gray-500 text-center">{{ unit.title }}</div>
                </div>

                <fw-panel
                  v-if="isActiveTopicAvailable && activeTopic.sessions && activeTopic.sessions.context == null"
                  title="Avaliação do módulo"
                  class="mt-10 pb-10"
                >
                  <PanelFeedbackModule
                    :feedback="feedback"
                    :saving="savingFeedback"
                    @save="saveTopicFeedback"
                  ></PanelFeedbackModule>
                </fw-panel>
                <div
                  v-else-if="
                    (!isActiveTopicAvailable && activeTopic.sessions) ||
                      (isActiveTopicAvailable &&
                        activeTopic.sessions &&
                        activeTopic.sessions.context &&
                        activeTopic.sessions.context.code == 'missing_topics')
                  "
                  class="text-center"
                >
                  <div
                    v-if="
                      activeTopic.sessions &&
                        activeTopic.sessions.context &&
                        activeTopic.sessions.context.code == 'missing_topics' &&
                        isActiveTopicAvailable
                    "
                    class=" text-gray-600 mb-5 font-bold pt-5"
                  >
                    O questionário está disponível mas existem tópicos deste módulo por avaliar ou exame por realizar.
                  </div>
                  <div v-else class="text-gray-600 mb-5 font-bold pt-5">Não disponível.</div>
                  <div class="text-sm">
                    Questionário disponível entre<br />
                    {{ activeTopic.sessions.start_date | formatDateTime }} e
                    {{ activeTopic.sessions.end_date | formatDateTime }}.
                  </div>
                </div>
                <div v-else class="text-center text-gray-500 text-sm">
                  Este questionário não está disponível para a sua turma ou ainda não foi agendada a sua
                  disponibilização.
                </div>
                <div
                  v-if="
                    activeTopic.sessions &&
                      activeTopic.sessions.context &&
                      activeTopic.sessions.context.code == 'missing_topics'
                  "
                  class="py-5"
                >
                  <div
                    v-if="
                      !isManager &&
                        !examSubmitted &&
                        activeTopic.sessions &&
                        activeTopic.sessions.context &&
                        activeTopic.sessions.context.keys
                    "
                    :class="{ ' max-h-48 overflow-hidden': !expandMissingTopics }"
                    class="text-center bg-yellow-50 rounded-sm p-7 relative text-sm"
                    @click="expandMissingTopics = !expandMissingTopics"
                  >
                    <div class="text-left pb-5">
                      Para conseguir aceder ao questionário de satisfação, todos os tópicos do módulo devem estar
                      concluídos / avaliados. Por favor, avalie os tópicos seguintes em falta:
                    </div>
                    <div
                      v-for="topic in activeTopic.sessions.context.keys"
                      :key="topic"
                      class="flex items-center gap-3 px-7 py-2"
                    >
                      <div class="opacity-80">
                        <fw-tag type="border-light" size="xs">Não avaliado</fw-tag>
                      </div>
                      <div class="font-semibold">
                        <v-clamp autoresize :max-lines="1">{{ unit.topics[topic].title }}</v-clamp>
                      </div>
                    </div>
                    <div
                      v-if="!expandMissingTopics"
                      class="absolute bottom-0 left-0 right-0 p-3 text-center bg-gradient-to-t from-yellow-50"
                    >
                      <div
                        class="px-5 py-1.5 font-semibold inline-block text-gray-800 mx-auto shadow-md rounded-full bg-gradient-to-t from-gray-100 to-white"
                      >
                        Ver todos
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Main for the exception course (to be removed in 2024) -->
    <div v-else-if="!isMobile && activeTopic" :key="activeTopic.key" class="flex-1 h-full border-l border-gray-200">
      <div class="flex flex-col h-full relative">
        <div class="w-full h-full flex flex-col justify-between bg-white">
          <!-- Unit view -->
          <div class="flex-1 overflow-y-auto h-full relative p-5 lg:p-10 flex flex-col">
            <!-- Normal -->
            <div
              v-if="activeTopic.type == 'normal' && isActiveTopicAvailable"
              class="fw-active-course w-full h-full mx-auto max-w-screen-lg flex flex-col gap-5"
            >
              <div>
                <fw-heading :size="activeTopic.video ? 'h3' : 'h2'">{{ activeTopic.title }}</fw-heading>
              </div>
              <div v-if="activeTopic.summary">
                <fw-heading muted class="mb-2">Sumário</fw-heading>
                <div class="content fixed-content" v-html="activeTopic.summary"></div>
              </div>
              <div v-if="activeTopic.goals">
                <fw-heading muted class="mb-2">Objetivos</fw-heading>
                <div class="content fixed-content" v-html="activeTopic.goals"></div>
              </div>
              <div v-if="activeTopic.blocks.length" class="flex flex-col gap-5">
                <div v-for="block in activeTopic.blocks" :key="block.$index">
                  <div v-if="block.type === 'attachments' && block.files.length">
                    <fw-panel
                      :title="block.title"
                      :counter="block.files.length"
                      boxed="sm"
                      custom-class="flex flex-col gap-2"
                    >
                      <div v-if="block.summary" class="text-sm text-gray-600 mb-2" v-html="block.summary"></div>
                      <div v-for="file in block.files" :key="file.url">
                        <a
                          :href="file.url"
                          target="_blank"
                          class="flex gap-2 bg-gray-50 hover:bg-gray-100 rounded-lg p-2"
                        >
                          <div class="pt-0.5"><fw-icon-file class="w-4 h-4 flex-shrink-0" /></div>
                          <div>
                            <div class="font-semibold">{{ file.title }}</div>
                            <div class="text-xs text-gray-500 flex gap-3">
                              <div>
                                <v-clamp autoresize :max-lines="1">{{ file.filename }}</v-clamp>
                              </div>
                              <div class="font-semibold">{{ file.filesize }}</div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </fw-panel>
                  </div>
                  <div v-else-if="block.type === 'body_content' && block.content.length">
                    <fw-heading muted class="mb-2">{{ block.title }}</fw-heading>
                    <div v-if="block.summary" class="text-sm text-gray-600 mb-2" v-html="block.summary"></div>
                    <div class="content fixed-content" v-html="block.content"></div>
                  </div>
                  <div v-else-if="block.type === 'links' && block.links.length">
                    <fw-panel
                      :title="block.title"
                      :counter="block.links.length"
                      boxed="sm"
                      custom-class="flex flex-col gap-2 -mt-2"
                    >
                      <div v-if="block.summary" class="text-sm text-gray-600 mb-2" v-html="block.summary"></div>
                      <div v-for="link in block.links" :key="link.url">
                        <a
                          :href="link.links"
                          target="_blank"
                          class="flex gap-2 bg-gray-50 hover:bg-gray-100 rounded-lg p-2 group"
                        >
                          <div class="pt-0.5"><fw-icon-globe class="w-5 h-5 flex-shrink-0" /></div>
                          <div class="font-semibold">
                            {{ link.title }}
                            <span class="invisible group-hover:visible font-normal text-xs opacity-40 ml-3"
                              >abrir em nova janela</span
                            >
                          </div>
                        </a>
                      </div>
                    </fw-panel>
                  </div>
                  <div v-else-if="block.type === 'videos' && block.videos.length">
                    <fw-panel
                      :title="block.title"
                      :counter="block.videos.length"
                      boxed="sm"
                      custom-class="flex flex-col gap-2 -mt-2"
                    >
                      <div v-if="block.summary" class="text-sm text-gray-600 mb-2" v-html="block.summary"></div>
                      <div v-for="video in block.videos" :key="video.file" class="mb-2">
                        <div v-if="video.title" class="font-semibold text-lg">{{ video.title }}</div>
                        <div v-if="video.summary" class="text-sm text-gray-600 mb-3" v-html="video.summary"></div>
                        <video-player :src="video.file" />
                      </div>
                    </fw-panel>
                  </div>
                </div>
              </div>
              <div
                v-if="!activeTopic.summary && !activeTopic.goals && !activeTopic.blocks.length"
                class="text-sm text-gray-500"
              >
                Este tópico ainda não tem conteúdo para apresentar.
              </div>
            </div>
            <div v-else class="h-full justify-center flex flex-col">
              <div class="flex flex-col gap-5 items-center justify-center max-w-md mx-auto text-center">
                <fw-icon-lock-line class="h-10 w-10 fill-current text-gray-500 opacity-50" />
                <fw-heading>{{ activeTopic.title.replace('.', '') }}</fw-heading>
                <div class="text-center text-sm text-gray-500">
                  Este tópico ainda não está disponível para a sua turma.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="isMobile" class="flex-1 h-full flex flex-col opacity-40 justify-center gap-2 text-center px-10">
      <fw-heading>Este curso não está<br />disponível para telemóvel</fw-heading>
      <div>Por favor, utilize um computador<br />para aceder a este curso</div>
    </div>

    <!-- Congrats modal -->
    <b-modal
      :active="showCongrats"
      :width="550"
      scroll="keep"
      trap-focus
      aria-role="dialog"
      aria-modal
      :can-cancel="true"
      custom-class="z-100 bg-gray-900 bg-opacity-50 backdrop-blur-sm rounded-buefy-modal"
      style="z-index: 9999"
      @close="showCongrats = false"
    >
      <CongratsModal
        :title="'Obrigado!'"
        :subtitle="'Tópico concluído.'"
        :next-text="'Próximo tópico'"
        :has-next="hasNextTopic"
        @close="showCongrats = false"
        @next="openNextTopic()"
      />
    </b-modal>

    <!-- Answers modal -->
    <user-answers-modal v-if="seeAnswers !== null" :instance-id="seeAnswers" @close="seeAnswers = null" />
  </div>
</template>

<script>
import ServiceAcademic from '@/fw-modules/fw-core-vue/academic/services/ServiceAcademic'
import { RecycleScroller } from 'vue-virtual-scroller'
import VideoPlayer from '@/fw-modules/fw-core-vue/ui/components/video/VideoPlayer'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import CongratsModal from '../../../ui/components/modals/CongratsModal'
import ServiceExams from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
import Vue from 'vue'
import OpenExamButton from '../../../ui/components/buttons/OpenExamButton'
import UserAnswersModal from '../../../exams/views/UserAnswers'
import PanelFeedback from './PanelFeedback'
import PanelFeedbackModule from './PanelFeedbackModule'

export default {
  components: {
    RecycleScroller,
    VideoPlayer,
    CongratsModal,
    OpenExamButton,
    UserAnswersModal,
    PanelFeedback,
    PanelFeedbackModule
  },

  props: {
    course: Object,
    edition: Object,
    checks: Object,
    unit: Object,
    isManager: Boolean
  },

  data() {
    return {
      feedbackItems: [
        { text: 'Os conteúdos são relevantes para a minha prática profissional.', key: 'content_relevant' },
        { text: 'Os conteúdos estão bem organizados.', key: 'content_organized' },
        { text: 'O orador comunicou de forma clara.', key: 'speaker' },
        { text: 'Os conteúdos são esteticamente apelativos (áudio e vídeo).', key: 'content_looks' }
      ],
      feedback: this.activeTopic ? this.activeTopic.feedback || {} : {},
      savingFeedback: false,
      activeTopic: null,
      allowFeedback: false,
      isActiveTopicAvailable: false,
      isActiveTopicBlocked: false,
      isActiveTopicEmpty: true,
      showCongrats: false,
      showOverlay: false,
      hasNextTopic: false,
      currentActiveTopicIndex: 0,
      currentExamInstance: null,
      videoHeight: 300,
      examSubmitted: false,
      seeAnswers: null,
      allowSeeAnswers: false,
      numAnswers: 0,
      nextAvailableTopic: null,
      expandMissingTopics: false
    }
  },

  computed: {
    now() {
      return this.$store.state.now
    },
    isMobile() {
      return window.innerWidth < 640
    },
    // TODO: Do not forget to remove this, Valentim
    isExceptionCourse() {
      return (process.env.VUE_APP_ENV == 'development' && this.course.key === 'oowvtc') || this.course.key === '4slkfk'
      // return this.course.key === 'oowvtc'
    },
    isActiveTopicFirst() {
      return this.topicsAsList.length > 0 ? this.topicsAsList[0].key == this.activeTopic.key : false
    },
    topicsAsList() {
      let topics = []
      if (this.unit.topics && Object.keys(this.unit.topics).length) {
        topics = Object.entries(this.unit.topics).map(([key, topic]) => {
          topic['key'] = key
          return topic
        })
        topics.sort((a, b) => a.index - b.index)
      }
      return topics
    },
    topics() {
      let topics = []
      for (let topic of this.topicsAsList) {
        // Add user session by class id
        if (topic.sessions && topic.sessions.length) {
          for (let session of topic.sessions) {
            // Ignore invalid sessions
            if (!session.class_id || !session.start_date || !session.end_date) break
            if (session.class_id == this.activeClassKey) {
              topic.session = session
            }
          }
        }
        // TODO: Showing topic even ff no session or user is does not have a class id
        topics.push(topic)
      }
      return topics
    },

    // Tests
    isUnitDone() {
      if (!this.progress) return 0
      const done = this.progress.unitsDone[this.unit.key]
      const todo = this.progress.unitsTodo[this.unit.key]
      return Math.round((100 * this.progress.unitsDone[this.unit.key]) / done + todo) || 0
    },
    isUnitAllNormalTopicsDone() {
      const progress = this.unitNormalTopicsProgress
      return progress.done === progress.total
    },
    unitNormalTopicsProgress() {
      let normalTopicsDone = 0
      let totalTopics = 0

      // Make sure every normal / video topic is done
      for (let topic of this.topics) {
        if (topic.type == 'normal') {
          if (this.isTopicDone(topic.key)) normalTopicsDone++
          totalTopics++
        }
      }
      return {
        done: normalTopicsDone,
        todo: Math.abs(normalTopicsDone - totalTopics),
        total: totalTopics
      }
    },
    isActiveTopicHasSession() {
      return this.checkTopicHasSession(this.activeTopic)
    },
    /*isActiveTopicAvailable() {
      return this.checkTopicAvailable(this.activeTopic)
    },*/
    /*isActiveTopicEmpty() {
      return this.checkTopicEmpty(this.activeTopic)
    },*/
    isActiveTopicAvailableSoon() {
      return this.checkTopicAvailableSoon(this.activeTopic)
    },
    isActiveTopicNotAvailable() {
      return this.checkTopicNotAvailable(this.activeTopic)
    },
    isActiveTopicExpired() {
      return this.checkTopicExpired(this.activeTopic)
    }
  },

  watch: {
    now: function() {
      if (this.activeTopic !== null) {
        let isActiveTopicAvailable = this.checkTopicAvailable(this.activeTopic)
        //let isActiveTopicAvailableSoon = this.checkTopicAvailableSoon(this.activeTopic)
        //let isActiveTopicNotAvailable = this.checkTopicNotAvailable(this.activeTopic)

        let isActiveTopicEmpty = this.checkTopicEmpty(this.activeTopic)
        //let isActiveTopicBlocked = this.checkTopicBlocked(this.activeTopic)
        if (isActiveTopicAvailable !== this.isActiveTopicAvailable) {
          this.isActiveTopicAvailable = isActiveTopicAvailable
        }
        //if (isActiveTopicAvailableSoon !== this.isActiveTopicAvailableSoon) {
        //  this.isActiveTopicAvailableSoon = isActiveTopicAvailableSoon
        //}
        /*if (isActiveTopicNotAvailable !== this.isActiveTopicNotAvailable) {
          this.isActiveTopicNotAvailable = isActiveTopicNotAvailable
        }*/
        //if (isActiveTopicBlocked !== this.isActiveTopicBlocked) {
        //  this.isActiveTopicBlocked = isActiveTopicBlocked
        //}
        if (isActiveTopicEmpty !== this.isActiveTopicEmpty) {
          this.isActiveTopicEmpty = isActiveTopicEmpty
        }
      }
    }
    /*topics(newValue, oldValue) {
      if (newValue != oldValue && typeof newValue === 'object') {
        this.initData()
      }
    },*/
  },

  mounted() {
    this.initData()
  },

  beforeDestroy() {
    let self = this
    window.removeEventListener(
      'message',
      event => {
        //console.log(event.origin)
        if (event.origin !== 'https://iframe.videodelivery.net') return
        if (event.data.eventName === 'resize' || event.data.eventName === 'canplay') {
          self.adjustVideoSize()
        }
        if (event.data.eventName === 'ended') {
          console.log('cf-video-ended')
          self.onPlayerEnded(null)
        }
      },
      false
    )
  },

  methods: {
    async updateModuleData() {
      let classKey = this.$route.query.class_key || null
      let editionKey = this.edition.key
      let sections = ['units']
      let courseKey = this.course.key
      let data = await ServiceAcademic.getCourseEdition(courseKey, editionKey, sections, classKey)
      console.log('updateModuleData', data)
      for (let index = 0; index < data.units.length; index++) {
        const element = data.units[index]
        if (element.key == this.unit.key) {
          for (const topicKey in element.topics) {
            //Update topic sessions to see if we can access the topic and which topics are missing in an exam
            this.unit.topics[topicKey]['sessions'] = element.topics[topicKey]['sessions']
          }
          break
        }
      }
    },
    initData() {
      this.nextAvailableTopic = null
      console.log('Loading init data from units')

      if (this.unit && this.topics && this.topics && !this.activeTopic) {
        this.activeTopic = this.topics[0]

        if (this.activeTopic && this.activeTopic != null) {
          this.isActiveTopicBlocked = false
          console.log('Loading first unit topic: ', this.activeTopic)
          this.loadTopic(this.activeTopic, false, 0, false)
        }
        if (typeof this.topics[1] !== 'undefined') {
          this.hasNextTopic = true
        }

        if (this.unit.topics) {
          const firstSession = this.topicsAsList.find(
            element =>
              element.sessions != null &&
              element.sessions &&
              element.sessions.start_date != null &&
              element.sessions.time_to_start != null
          )
          console.log('firstSession', firstSession)

          if (firstSession) {
            //correct current time - sync it with server
            //add time offset
            let nowDate = new Date()
            let startDate = new Date(firstSession.sessions.start_date)
            console.log('expected', firstSession.sessions.time_to_start)
            let real = Math.floor((startDate.getTime() - nowDate.getTime()) / 1000)
            console.log('real', real)
            let diff = real - firstSession.sessions.time_to_start
            console.log('diff', diff)
            let override = nowDate.getTime() + diff //* 1000
            console.log('override', override)
            this.$store.dispatch('setTimerRef', override)
          }
        }

        this.isActiveTopicAvailable = this.checkTopicAvailable(this.topics[0])
        this.isActiveTopicEmpty = this.checkTopicEmpty(this.topics[0])

        let self = this
        window.addEventListener(
          'message',
          event => {
            //console.log(event.origin)
            if (event.origin !== 'https://iframe.videodelivery.net') return
            if (event.data.eventName === 'resize' || event.data.eventName === 'canplay') {
              self.adjustVideoSize()
            }
            if (event.data.eventName === 'ended') {
              console.log('cf-video-ended')
              self.onPlayerEnded(null)
            } else if (event.data.eventName === 'play') {
              self.onPlayerPlay(null)
            } else if (event.data.eventName === 'pause') {
              self.onPlayerPause(null)
            }
          },
          false
        )
      }

      let self = this
      Vue.nextTick(function() {
        self.adjustVideoSize()
        self.verifyNextAvailableTopic()
        //console.log(document.getElementById('cloudflare-video').firstChild)
      })
    },
    verifyNextAvailableTopic() {
      this.nextAvailableTopic = null
      // this method is responsible to check what is the next available topic not seen
      // if there is no next available topic, it will set the next topic to the first one
      let nextTopic
      for (let index = 0; index < this.topicsAsList.length; index++) {
        const element = this.topicsAsList[index]
        if (element.seen_date == null) {
          nextTopic = element
          break
        }
      }

      if (nextTopic) this.nextAvailableTopic = nextTopic.key
    },
    hideOverlay() {
      this.showOverlay = false
    },
    loadTopic(topic, hasNext = false, currentIndex = 0, click = true) {
      this.showOverlay = false
      this.activeTopic = topic
      this.hasNextTopic = hasNext
      this.currentActiveTopicIndex = currentIndex
      this.isActiveTopicAvailable = this.checkTopicAvailable(topic)
      this.isActiveTopicBlocked = this.checkTopicBlocked(topic)
      this.isActiveTopicEmpty = this.checkTopicEmpty(topic)
      this.examSubmitted = false //reset examSubmitted
      this.allowSeeAnswers = false //reset see answers permission
      if (topic.sessions && topic.sessions.item_key) {
        //get instance type
        this.loadExamInstanceInfo(topic.sessions.item_key)
      } else {
        this.currentExamInstance = null
      }
      if (topic.type === 'quality_evaluation') {
        //verify if we can answer the quality evaluation
        //get the latest information from the backend
        this.updateModuleData()
      }
      this.adjustVideoSize()

      // Set feedback
      this.feedback = this.activeTopic.feedback || {}

      this.$store.dispatch('saveActivityAction', {
        service: 'non-degree-courses',
        metadata: {
          course: this.course.key,
          edition: this.edition.key
        },
        action: 'open',
        elementType: 'course-unit-topic',
        elementID: this.activeTopic.key,
        userInteraction: click
      })
    },

    // Helpers
    getCloudFlareVideoUrl(topic) {
      let videoDeliveryUrl = `https://iframe.videodelivery.net/${topic.video_key}?preload=true`
      if (topic.video_cover) {
        videoDeliveryUrl =
          videoDeliveryUrl + '&poster=' + encodeURIComponent(topic.video_cover) + '%3Ftime%3D%26height%3D600'
      }
      return videoDeliveryUrl
    },
    adjustVideoSize() {
      let videoplayer = document.getElementById('cloudflare-video')
      //document.querySelector('#')
      if (videoplayer) {
        let width = videoplayer.offsetWidth
        let height = width / (16 / 9)
        //videoplayer.style.height = height + 'px'
        this.videoHeight = height
      }
    },
    openNextTopic() {
      this.showCongrats = false
      this.showOverlay = false
      if (typeof this.topics[this.currentActiveTopicIndex + 1] !== 'undefined') {
        this.loadTopic(
          this.topics[this.currentActiveTopicIndex + 1],
          typeof this.topics[this.currentActiveTopicIndex + 2] !== 'undefined',
          this.currentActiveTopicIndex + 1
        )
      }
    },

    // Exams
    openAnswersModal() {
      // TODO: PARA TESTES USAR INSTANCIA 2z2j72u5gsjq
      // this.seeAnswers = '2z2j72u5gsjq'
      if (this.activeTopic.sessions && this.activeTopic.sessions.item_key) {
        this.seeAnswers = this.activeTopic.sessions.item_key
      }
    },
    /*openExam() {
      console.log(this.activeTopic)
      if (this.activeTopic && this.activeTopic.session.exam_key) {
        this.$router.push({ path: `/ex/${this.activeTopic.session.exam_key}` })
      }
    },
    openQuestionary() {
      if (this.activeTopic && this.activeTopic.session.exam_key) {
        this.$router.push({ path: `/form/${this.activeTopic.session.exam_key}` })
      }
    },*/
    async loadExamInstanceInfo(instaceKey) {
      try {
        let instance = await ServiceExams.getExamineeInstance(instaceKey)
        if (instance) {
          this.currentExamInstance = instance
          if (instance.withAnswer) {
            this.examSubmitted = true
          } else {
            this.examSubmitted = false
          }
          // If user has answered the exam and the exam is now closed, markit has done
          /*if (instance.withAnswer && !this.isTopicDone(this.activeTopic.key)) {
            this.toggleTopic(this.activeTopic.key)
          }*/
        } else {
          this.examSubmitted = false
        }
      } catch (e) {
        console.error(e)
      }
    },

    isActive(topicId) {
      return this.activeTopic && this.activeTopic.key == topicId
    },

    // Check sessions
    checkTopicHasSession(topic) {
      // TODO: Remove this, Valentim
      if (this.isExceptionCourse && topic) {
        if (topic.title.startsWith('.') && !this.isManager) return false
        else return true
      }

      return topic && topic.sessions && topic.sessions.start_date && topic.sessions.end_date
    },
    isNextTopic(topic) {
      return this.nextAvailableTopic == topic.key
    },
    isTopicSeen(topic) {
      return topic.seen_date !== null
    },
    checkTopicAvailable(topic) {
      // TODO: Remove this, Valentim
      if (this.isExceptionCourse && topic) {
        if (topic.title.startsWith('.') && !this.isManager) return false
        else return true
      }

      if (!this.checkTopicHasSession(topic)) return false
      if (this.isManager && topic.type === 'normal') return true

      const sessionStart = Dates.build(topic.sessions.start_date)
      const sessionEnd = Dates.build(topic.sessions.end_date)
      /*
      SHOW DEBUG INFO:
      if (topic.key == this.activeTopic.key) {
        console.log(
          sessionStart.valueOf(),
          this.now,
          sessionEnd.valueOf(),
          'faltam: ' + Math.floor((sessionStart.valueOf() - this.now) / 1000) + 's',
          this.now >= sessionStart.valueOf() && this.now < sessionEnd.valueOf()
        )
      }*/
      // console.log('checkTopicAvailable', sessionStart.valueOf(), sessionEnd.valueOf(), this.now)
      let isAvailable = topic.type == 'evaluation' || this.isTopicSeen(topic) || this.isNextTopic(topic)
      return (
        topic && topic.sessions && this.now >= sessionStart.valueOf() && this.now < sessionEnd.valueOf() && isAvailable
      )
      // return topic.sessions.can_view
    },
    checkTopicEmpty(topic) {
      return topic && topic.type == 'normal' && topic.video_key === null
    },
    checkTopicAvailableSoon(topic) {
      if (!this.checkTopicHasSession(topic)) return false
      return topic.sessions.time_to_start > 0
    },
    checkTopicNotAvailable(topic) {
      return !this.checkTopicHasSession(topic) && !this.checkTopicAvailableSoon(topic) && !this.checkTopicExpired(topic)
    },
    checkTopicExpired(topic) {
      if (!this.checkTopicHasSession(topic)) return false
      const sessionEnd = Dates.build(topic.sessions.end_date)
      return sessionEnd.valueOf() < this.now && topic.sessions.can_view === false
    },
    //Check if the topic is blocked because the previous topics were not completed
    checkTopicBlocked(topic) {
      if (!this.checkTopicHasSession(topic)) return false
      if (this.isManager) return false

      const sessionStart = Dates.build(topic.sessions.start_date)
      const sessionEnd = Dates.build(topic.sessions.end_date)
      // console.log('checkTopicAvailable', sessionStart.valueOf(), sessionEnd.valueOf(), this.now)
      let isBlocked = !this.isTopicSeen(topic) && !this.isNextTopic(topic)
      return (
        topic && topic.sessions && this.now >= sessionStart.valueOf() && this.now < sessionEnd.valueOf() && isBlocked
      )
    },

    // Progress
    isTopicDone(topicKey) {
      if (!!this.unit.topics && this.unit.topics[topicKey]) {
        return !!this.unit.topics[topicKey].seen_date
      }
      return false
    },
    async saveTopicFeedback() {
      this.savingFeedback = true

      // Verify if we have feedback of all topics
      let canSave = true
      console.log('quality_evaluation', this.activeTopic.type)
      if (this.activeTopic.type === 'quality_evaluation') {
        //verify if all questions were answered
        let feedbackItems = [
          'content_quality',
          'speakers_competence',
          'clinicalcases_interest',
          'video_quality',
          'ucstudent',
          'recommendation',
          'global_satisfation'
        ]

        for (let index = 0; index < feedbackItems.length; index++) {
          if (this.feedback[feedbackItems[index]] === undefined) {
            canSave = false
            break
          }
        }
      } else {
        for (let index = 0; index < this.feedbackItems.length; index++) {
          if (this.feedback[this.feedbackItems[index].key] === undefined) {
            canSave = false
            break
          }
        }
      }

      if (!canSave) {
        this.$buefy.dialog.alert({
          message: 'Por favor, responda a todas as questões.',
          type: 'is-danger',
          hasIcon: false,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        this.savingFeedback = false
        return
      }

      try {
        const feedbackUpdated = await ServiceAcademic.saveTopicFeedback(
          this.course.key,
          this.edition.key,
          this.unit.key,
          this.activeTopic.key,
          this.feedback
        )
        // await ServiceSettings.setSetting(
        //   `feedback-course-${this.course.key}-${this.unit.key}-${this.activeTopic.key}`,
        //   JSON.stringify(this.feedback)
        // )
        /*this.$buefy.dialog.alert({
          message: 'Feedback recebido. Obrigado!',
          ariaRole: 'alertdialog',
          ariaModal: true,
        })*/

        this.$emit('update-course-progress', feedbackUpdated.progress)

        if (this.activeTopic.type === 'quality_evaluation') {
          this.$buefy.dialog.alert({
            message: 'Feedback recebido. Obrigado!',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        } else {
          this.showCongrats = true
        }

        // Update all topics feedback
        const unitTopicsFeedback = feedbackUpdated['units'][this.unit.key]['topics']

        this.$emit(
          'update-unit-progress',
          feedbackUpdated['units'][this.unit.key].progess ?? feedbackUpdated['units'][this.unit.key].progress
        )

        // Verify missing topics for evaluation

        for (let topicKey of Object.keys(unitTopicsFeedback)) {
          if (unitTopicsFeedback[topicKey]) {
            //this.unit.topics[topicKey]['feedback'] = unitTopicsFeedback[topicKey].feedback
            //this.unit.topics[topicKey]['seen_date'] = unitTopicsFeedback[topicKey].seen_date
            console.log('received', unitTopicsFeedback[topicKey])
            Vue.set(this.unit.topics[topicKey], 'feedback', unitTopicsFeedback[topicKey].feedback)
            Vue.set(this.unit.topics[topicKey], 'seen_date', unitTopicsFeedback[topicKey].seen_date)
          }
        }
        this.verifyNextAvailableTopic()
        this.updateModuleData()
      } catch (e) {
        console.error(e)
      } finally {
        this.savingFeedback = false
      }
    },

    // Video player
    onPlayerPlay(player) {
      console.debug('player play!', player)
      this.$store.dispatch('saveActivityAction', {
        service: 'non-degree-courses',
        metadata: {
          course: this.course.key,
          edition: this.edition.key
        },
        action: 'play-video',
        elementType: 'course-unit-topic',
        elementID: this.activeTopic.key,
        userInteraction: true
      })
    },
    onPlayerPause(player) {
      console.debug('player pause!', player)
      this.$store.dispatch('saveActivityAction', {
        service: 'non-degree-courses',
        metadata: {
          course: this.course.key,
          edition: this.edition.key
        },
        action: 'pause-video',
        elementType: 'course-unit-topic',
        elementID: this.activeTopic.key,
        userInteraction: true
      })
    },
    onPlayerEnded(player) {
      console.debug('player ended!', player)
      this.showOverlay = true
      this.$store.dispatch('saveActivityAction', {
        service: 'non-degree-courses',
        metadata: {
          course: this.course.key,
          edition: this.edition.key
        },
        action: 'ended-video',
        elementType: 'course-unit-topic',
        elementID: this.activeTopic.key,
        userInteraction: true
      })
    },
    playerStateChanged(playerCurrentState) {
      console.debug('player current update state', playerCurrentState)
    },
    playerReadied(player) {
      console.debug('the player is readied', player)
    }
  }
}
</script>

<style lang="scss">
.content.fixed-content p:not(:last-child) {
  margin-bottom: 1rem;
}
</style>
