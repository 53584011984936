<template>
  <div
    class="fw-message font-medium flex gap-2"
    :class="[{ 'text-center': centered, 'rounded-lg': rounded, 'px-4 py-3': padding }, type, `text-${size}`]"
  >
    <div v-if="icon">
      <component :is="'fw-icon-' + icon" v-if="icon" class="w-5 h-5 flex-shrink-0" />
    </div>
    <div>
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FwMessage',
  props: {
    type: {
      type: String,
      default: 'info' // info, success, warning, error
    },
    padding: {
      type: Boolean,
      default: true
    },
    rounded: {
      type: Boolean,
      default: true
    },
    centered: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'sm'
    },
    icon: {
      type: String,
      default: null
    }
  }
}
</script>
<style lang="scss" scoped>
.fw-message.info {
  background-color: #394a851f;
  color: #394a85;
}
.fw-message.primary {
  @apply text-primary bg-primary bg-opacity-10;
}
.fw-message.light {
  @apply text-gray-500 bg-gray-500 bg-opacity-10;
}
.fw-message.warning {
  @apply text-yellow-700 bg-yellow-600 bg-opacity-10;
}
.fw-message.tip-light {
  @apply text-gray-500 text-sm;
}
.fw-message.error {
  background-color: #d92d211d;
  color: #853f39;
}
</style>
