import store from '@/store'
import ViewHome from '@/views/ViewHome'
import ViewUnits from '@/views/ViewUnits'
import ViewUnit from '@/views/ViewUnit'
import ViewClass from '@/views/ViewClass'
import ServiceAuth from '@/fw-modules/fw-core-vue/id/services/ServiceAuth'
import ViewPortfolio from '@/views/ViewPortfolio'

export default [
  {
    path: '/',
    name: 'home',
    component: ViewHome,
    meta: { requiresRole: 'student' },
  },
  {
    path: '/t/:token',
    name: 'nonio_auth',
    meta: {
      requiresNoAuth: true,
      allowPlugin: true,
      redirect: async function(route, isLoggedIn) {
        let data
        try {
          data = await ServiceAuth.checkToken(route.params.token)
        } catch (error) {
          console.error(`Failed to check nonio token ${route.params.token}`, error)
        }

        if (!data) {
          return { name: isLoggedIn ? 'home' : 'login' }
        }

        if (data.token) {
          store.dispatch('setNewLogin', data)
        }

        if (data.context == 'units') {
          return { name: 'units' }
        } else {
          return { name: 'home' }
        }
      },
    },
  },
  {
    path: '/units',
    name: 'units',
    component: ViewUnits,
    meta: { requiresRole: 'student' },
  },
  {
    path: '/unit/:key',
    name: 'unit',
    component: ViewUnit,
    meta: { requiresRole: 'student' },
  },
  {
    path: '/unit/:key/:subpage',
    name: 'unit_subpage',
    component: ViewUnit,
    meta: { requiresRole: 'student' },
  },
  {
    path: '/class/:key',
    name: 'class',
    component: ViewClass,
    meta: { requiresRole: 'student' },
  },
  {
    path: '/class/:key/:subpage',
    name: 'class_subpage',
    component: ViewClass,
    meta: { requiresRole: 'student' },
  },
  {
    path: '/portfolio/:key',
    name: 'portfolio',
    component: ViewPortfolio,
    meta: { manage: false },
  },
  {
    path: '/portfolio/:key/edit',
    name: 'portfolio-manage',
    component: ViewPortfolio,
    meta: { manage: true },
  },
  {
    path: '/portfolio/:key/edit/:view',
    name: 'portfolio-manage-view',
    component: ViewPortfolio,
    meta: { manage: true },
  },
]
