var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.asLink && !_vm.disabled ? 'router-link' : 'button',{tag:"component",staticClass:"sidebar-button",attrs:{"to":_vm.url,"disabled":_vm.disabled},on:{"click":_vm.goToOrRun}},[_c('div',{staticClass:"group text-sm flex-shrink-0 rounded flex gap-1 p-2 items-center transition-colors duration-150 font-medium",class:{
      'hover:text-black cursor-pointer': !_vm.disabled,
      'text-gray-500 opacity-60 cursor-not-allowed': _vm.disabled,
      'w-full': _vm.fullWidth,
      'text-primary': _vm.isRouteActive,
      'bg-gray-300 bg-opacity-30 rounded-lg': _vm.selected,
      'border-b pb-3 mb-2': _vm.bordered
    }},[(_vm.hasIcon)?_c('div',{staticClass:"rounded-xl w-8 flex-shrink-0",class:{
        'text-primary': _vm.isRouteActive && !_vm.disabled,
        'text-gray-900 opacity-90': !_vm.isRouteActive && !_vm.disabled
      }},[_vm._t("svg",function(){return [_c('fw-icon-' + _vm.icon,{tag:"component",staticClass:"w-6 h-6"})]})],2):_vm._e(),_c('div',{staticClass:"group-opacity-100 text-left flex-1 flex-shrink-0",class:[`text-${_vm.size}`]},[(_vm.truncateLabel)?_c('div',{staticClass:"line-clamp-1",class:{
          'text-primary': _vm.isRouteActive && !_vm.disabled,
          'text-gray-800 group-hover:text-black group-hover:opacity-100': !_vm.isRouteActive && !_vm.disabled
        }},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_c('div',{class:{
          'text-primary': _vm.isRouteActive && !_vm.disabled,
          'text-gray-800 opacity-80': !_vm.isRouteActive && !_vm.disabled,
          'group-hover:text-primary group-hover:opacity-90': !_vm.disabled
        }},[_vm._v(" "+_vm._s(_vm.label)+" ")])]),(_vm.counter)?_c('div',{staticClass:"text-xs px-1.5 py-0.5 rounded-full flex-shrink-0 min-w-7 text-center font-bold",class:{
        'bg-primary text-white': _vm.counterType === 'primary',
        'bg-gray-500 bg-opacity-20 text-gray-500': _vm.counterType === 'light',
        'bg-gray-500 bg-opacity-10 text-gray-500': _vm.counterType === 'xlight'
      }},[_vm._v(" "+_vm._s(_vm.counter)+" ")]):_vm._e(),(_vm.$slots.suffix)?_c('div',{staticClass:"flex-shrink-0"},[_vm._t("suffix")],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }