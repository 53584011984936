<template>
  <div
    v-if="onlyOtherUsers.length"
    class="flex items-center gap-1 text-teal-800 bg-teal-500 bg-opacity-10 pl-2 pr-1 py-0.5 rounded-full"
  >
    <b-tooltip label="Utilizadores online" type="is-dark" position="is-bottom" class="text-primary opacity-90">
      <fw-icon-eye class="w-5 h-5" />
    </b-tooltip>
    <div v-for="user in onlyOtherUsers" :key="user.key" class="flex gap-1">
      <b-tooltip :label="user.name || user.email" type="is-dark" position="is-bottom">
        <fw-avatar size="2xs" :user="user"></fw-avatar>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    users: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  data() {
    return {}
  },

  computed: {
    loggedUser() {
      return this.$store.state.session && this.$store.state.session.user ? this.$store.state.session.user : null
    },
    onlyOtherUsers() {
      return this.users.filter(user => user.key !== this.loggedUser.key)
    }
  }
}
</script>

<i18n>
{
  "pt": {},
  "en": {}
}
</i18n>
