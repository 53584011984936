<template>
  <div class="flex flex-col h-full bg-gray-100">
    <fw-heading class="mx-4 py-3.5">Ficheiros</fw-heading>
    <div id="chat-files" class="flex-1 overflow-y-auto flex flex-col mt-2 px-3">
      <div v-for="(file, f) in files" :key="'file_' + file.key">
        <div
          v-if="f == 0 || getMonth(files[f - 1]) != getMonth(file)"
          class="text-xs uppercase text-gray-500 pb-1 px-2 font-semibold sticky top-0 bg-gray-100 z-[1]"
          :class="{ 'pt-3': f != 0, 'pt-0': f == 0 }"
        >
          {{ displayMonth(file.created_date) }}
        </div>
        <div v-if="f == 0 || getMonth(files[f - 1]) != getMonth(file)" class="bg-white h-2 rounded-t-md"></div>
        <div class="bg-white px-1">
          <ChatFiles :message="file" @preview-file="previewFile(file, $event)" />
        </div>
        <div
          v-if="files.length - 1 == f || getMonth(files[f + 1]) != getMonth(file)"
          class="bg-white h-2 rounded-b-md"
        ></div>
        <div v-else class="h-2 bg-white"></div>
      </div>
      <div v-if="files.length == 0" class="h-full flex flex-col items-center justify-center relative">
        <fw-icon-file class="w-10 h-10 text-gray-300" />
        <fw-label class="mt-2 opacity-60 pb-16">Sem ficheiros partilhados</fw-label>
      </div>
    </div>
  </div>
</template>

<script>
import { MONTHS } from '@/fw-modules/fw-core-vue/utilities/utils'
import ChatFiles from '@/fw-modules/fw-core-vue/chats/components/ChatFiles'
export default {
  components: { ChatFiles },
  props: {
    files: {
      type: Array,
      default: () => []
    },
    language: {
      type: String,
      default: 'pt'
    }
  },
  computed: {
    monthsLabels() {
      return MONTHS[this.language]
    }
  },
  methods: {
    getMonth(message) {
      return new Date(message.created_date).getMonth()
    },
    displayMonth(date) {
      let d = date.split('T')[0].split('-')
      return this.monthsLabels[parseInt(d[1]) - 1] + ' ' + d[0]
    },
    previewFile(message, file) {
      this.$emit('preview-file', message, file)
    }
  }
}
</script>
