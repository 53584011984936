<template>
  <div class="meetings-handsup-sidebar">
    <div class="record is-link" @click="showWaitingAttendees">
      <div class="record-icon has-text-white animate-flicker">
        <faicon size="sm" icon="user-clock"></faicon>
      </div>
      <div class="record-content has-text-weight-bold has-text-small">
        <v-clamp v-if="waitingAttendees.length == 1" autoresize :max-lines="2">
          {{ waitingAttendees.length }} utilizador em espera
        </v-clamp>
        <v-clamp v-else autoresize :max-lines="2">{{ waitingAttendees.length }} utilizadores em espera</v-clamp>
      </div>
      <div class="record-nav" style="margin-top: 1px">
        <b-button
          size="is-small"
          icon-left="chevron-up"
          aria-label="Abrir painel de participantes em espera"
          @click="showWaitingAttendees"
        ></b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    waitingAttendees: Array
  },

  methods: {
    showWaitingAttendees() {
      this.$emit('show-waiting-attendees')
    }
  }
}
</script>
