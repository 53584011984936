<template>
  <div class="view_selector flex gap-1 items-center">
    <b-tooltip v-if="viewOptions.includes('row')" label="Vista em lista" type="is-light" position="is-bottom">
      <fw-button
        :class="{ 'text-primary': selectedView == 'row', 'text-gray-700': selectedView != 'row' }"
        label="Ver lista"
        @click.native="selectView('row')"
      >
        <svg class="w-5 h-5 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M8 4h13v2H8V4zm-5-.5h3v3H3v-3zm0 7h3v3H3v-3zm0 7h3v3H3v-3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z" />
        </svg>
      </fw-button>
    </b-tooltip>

    <b-tooltip v-if="viewOptions.includes('row_expanded')" label="Vista em lista" type="is-light" position="is-bottom">
      <fw-button
        :class="{ 'text-primary': selectedView == 'row_expanded', 'text-gray-700': selectedView != 'row_expanded' }"
        label="Ver lista detalhada"
        @click.native="selectView('row_expanded')"
      >
        <fw-icon-list-row class="w-5 h-5" />
      </fw-button>
    </b-tooltip>

    <b-tooltip v-if="viewOptions.includes('card')" label="Vista em cartões" type="is-light" position="is-bottom">
      <fw-button
        :class="{ 'text-primary': selectedView == 'card', 'text-gray-700': selectedView != 'card' }"
        label="Ver em cartões"
        @click.native="selectView('card')"
      >
        <fw-icon-list-card class="w-5 h-5" />
      </fw-button>
    </b-tooltip>
  </div>
</template>

<script>
export default {
  name: 'ViewSelector',
  props: {
    viewOptions: {
      type: Array,
      default: function() {
        return []
      }
    },
    selectedView: {
      type: String
    }
  },
  methods: {
    selectView(type) {
      this.$emit('select-view', type)
    }
  }
}
</script>

<style scoped></style>
