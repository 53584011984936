<template>
  <b-modal
    :active.sync="isActive"
    :on-cancel="closeModal"
    has-modal-card
    aria-role="dialog"
    aria-modal
    class="meetings-modal-pods-enter-queue"
  >
    <div class="modal-card is-width-small">
      <section class="modal-card-body">
        <div class="is-flex is-flex-space-between">
          <div class="is-flex is-flex-align-center">
            <div class="label is-size-3">Permissões do browser</div>
          </div>
          <div>
            <b-button type="is-text" icon-right="times" @click="closeModal">Fechar</b-button>
          </div>
        </div>
        <div class="is-size-4 has-margin-bottom has-margin-top-medium">
          O seu navegador (browser) identificou os seguintes erros:
        </div>
        <div
          v-if="camera.errorType"
          class="has-background-warning has-padding has-margin-bottom-tiny has-text-weight-bold"
        >
          <div v-if="camera.errorType == 'notAllowed'">Não tem permissão para utilizar a câmara.</div>
          <div v-if="camera.errorType == 'noDevices'">Não foram encontrados dispositivos de vídeo.</div>
          <div v-if="camera.errorType == 'alreadyInUse'">
            O seu dispositivo de vídeo parece estar a ser utilizado por outra aplicação e não está acessível.
          </div>
        </div>
        <div
          v-if="audio.errorType"
          class="has-background-warning has-padding has-margin-bottom-tiny has-text-weight-bold"
        >
          <div v-if="audio.errorType == 'notAllowed'">Não tem permissão para utilizar o microfone.</div>
          <div v-if="audio.errorType == 'noDevices'">Não foram encontrados dispositivos de áudio.</div>
          <div v-if="audio.errorType == 'alreadyInUse'">
            O seu dispositivo de vídeo parece estar a ser utilizado por outra aplicação e não está acessível.
          </div>
        </div>
        <div
          v-if="screenShare.errorType"
          class="has-background-warning has-padding has-margin-bottom-tiny has-text-weight-bold"
        >
          <div v-if="screenShare.errorType == 'notAllowed'">Não tem permissão para utilizar aceder ao ecrã.</div>
        </div>
        <div v-if="dummyAudio.errorType" class="has-background-warning has-padding has-margin-bottom-tiny">
          <div v-if="dummyAudio.errorType == 'notAllowed'">
            <div class="has-text-weight-bold">Não tem permissão para receber áudio da sessão.</div>
            <div>
              O seu navegador tem uma limitação que impede a receção de áudio dos restantes participantes. Por isso,
              para manter a receção de áudio estável, o seu navegador vai solicitar permissão para usar o microfone.
              Apesar desta autorização, o estado do seu microfone (ligado ou desligado) será sempre refletido no ícone
              <faicon icon="microphone" size="xs"></faicon>.
            </div>
          </div>
        </div>
        <div class="has-margin-top has-text-small">
          Quando tenta ligar o video ou o microfone, o seu navegador deverá solicitar autorização para aceder ao
          dispositivo. Normalmente, a caixa de autorização surge junto à barra de endereço. Caso tal não tenha
          acontecido, poderá ter negado o acesso em situações anteriores. Para resolver, procure nas definições do
          navegador a opção para permitir o acesso aos dispositivos em causa.
        </div>
        <div class="has-text-tiny has-margin-top">
          Qualquer dúvida, consulte os nossos tutoriais vídeo ou peça-nos ajuda através do nosso email de suporte
          (identificado no rodapé da página inicial).
        </div>
      </section>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    camera: Object,
    audio: Object,
    screenShare: Object,
    dummyAudio: Object
  },

  data() {
    return {
      isActive: this.withMediaErrors
    }
  },

  computed: {
    withMediaErrors() {
      return Boolean(
        this.camera.errorType ||
          this.audio.errorType ||
          this.screenShare.errorType ||
          (this.dummyAudio.errorType && !this.dummyAudio.show)
      )
    }
  },

  watch: {
    withMediaErrors(value) {
      this.isActive = value
    }
  },

  methods: {
    closeModal() {
      this.isActive = false
      this.$emit('close', false)
      this.camera.errorType = this.audio.errorType = this.screenShare.errorType = this.dummyAudio.errorType = null
    }
  }
}
</script>
