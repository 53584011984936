<template>
  <fw-layout back-to="-1">
    <template #main-sidebar>
      <SidebarCourses
        v-if="false"
        :filters="statistics"
        :selected-filters="selectedFilters"
        @change-filter="filtersChanged"
      />
      <SidebarMain v-else :show-non-degree-courses="hasNonDegreeCourses"></SidebarMain>
    </template>

    <template #main-content>
      <div v-if="loading" class="w-full">
        <fw-panel title="Cursos disponíveis">
          <LoadingPlaceholder :list="false"></LoadingPlaceholder>
        </fw-panel>
      </div>
      <div v-else-if="mycourses.length > 0" class="w-full">
        <!-- v-else-if="featuredcourses.length > 0 || availablecourses.length > 0 || mycourses.length > 0" -->
        <PanelCourses
          v-if="mycourses.length > 0"
          :my-courses="true"
          :loading="loading"
          title="Os meus cursos"
          :courses="mycourses"
        />
        <fw-panel v-if="false && featuredcourses.length > 0" title="Em destaque" class="mb-7">
          <CourseBanner
            :course="featuredcourses[0]"
            :show-footer="false"
            @register="registerInto"
            @subscribe="subscribeCourse"
          />
        </fw-panel>
        <PanelCourses
          v-if="false"
          :loading="loading"
          title="Cursos disponíveis"
          :courses="availablecourses"
          @register="registerInto"
          @subscribe="subscribeCourse"
        />
      </div>
      <div v-else class="flex flex-col text-center gap-3 w-full h-full items-center justify-center pt-10">
        <fw-icon-b-learning class="h-16 drop-shadow-lg w-16 fill-current text-gray-800 text-opacity-40" />
        <div class="text-center text-gray-800 text-opacity-60 mx-auto max-w-sm p-5">
          Sem cursos disponíveis de momento. Por favor, volte mais tarde.
        </div>
      </div>
    </template>

    <template #tapbar>
      <TapbarMain />
    </template>

    <template #modals>
      <b-modal
        :width="850"
        class="rounded-buefy-modal"
        :can-cancel="true"
        :active.sync="openEnrollmentModal"
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        scroll="keep"
      >
        <ModalCourseEnrollment
          v-if="selectedCourse != null"
          :course="selectedCourse"
          :edition="
            selectedCourse.current_edition != null
              ? selectedCourse.editions[selectedCourse.current_edition.edition_key]
              : null
          "
          @close="closeModal"
        ></ModalCourseEnrollment>
      </b-modal>
      <b-modal
        :width="550"
        class="rounded-buefy-modal"
        :can-cancel="true"
        :active.sync="openNotificationModal"
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        scroll="keep"
      >
        <ModalNotification
          :course="selectedCourse"
          @success="successfulNotification"
          @close="openNotificationModal = false"
        ></ModalNotification>
      </b-modal>
    </template>
  </fw-layout>
</template>

<script>
import PanelCourses from '@/fw-modules/fw-core-vue/nondegree-courses/components/panels/PanelCourses'
import CourseBanner from '@/fw-modules/fw-core-vue/nondegree-courses/components/blocks/CourseBanner'
import TapbarMain from '@/components/tapbars/TapbarMain'
import ServiceAcademic from '@/fw-modules/fw-core-vue/academic/services/ServiceAcademic'
import ModalCourseEnrollment from '@/fw-modules/fw-core-vue/nondegree-courses/components/modals/ModalCourseEnrollment'
import ModalNotification from '@/fw-modules/fw-core-vue/nondegree-courses/components/modals/ModalNotification'
import SidebarCourses from '@/fw-modules/fw-core-vue/nondegree-courses/components/sidebars/SidebarCourses.vue'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import SidebarMain from '@/components/sidebars/SidebarMain'
export default {
  components: {
    PanelCourses,
    TapbarMain,
    SidebarMain,
    ModalCourseEnrollment,
    CourseBanner,
    SidebarCourses,
    ModalNotification,
    LoadingPlaceholder
  },

  data() {
    return {
      availablecourses: [],
      featuredcourses: [],
      mycourses: [],
      openEnrollmentModal: false,
      openNotificationModal: false,
      selectedCourse: null,
      loading: true,
      statistics: {
        is_prr: [],
        languages: [],
        scientific_area: [],
        working_mode: []
      },
      selectedFilters: {
        is_prr: null,
        languages: null,
        scientific_area: null,
        working_mode: null
      }
    }
  },

  computed: {
    hasNonDegreeCourses() {
      return !!this.mycourses.length
    }
  },

  created() {
    this.loadNonDegreeCourses()
  },

  methods: {
    subscribeCourse(course) {
      if (course.activeNotifications && course.notifications.length > 0) {
        course.savingNotification = true
        ServiceAcademic.unsubscribeCourse(course.notifications[0].key).then(() => {
          course.activeNotifications = false
          course.savingNotification = false
        })
      } else {
        this.selectedCourse = course
        this.openNotificationModal = true
      }
    },
    successfulNotification() {
      this.selectedCourse.activeNotifications = !this.selectedCourse.activeNotifications
    },
    registerInto(course) {
      if (course && course.current_edition != null) {
        this.selectedCourse = course
        this.openEnrollmentModal = true
      }
    },

    async filtersChanged(newfilters) {
      console.log(newfilters)
      const data = await ServiceAcademic.getCoursesCatalogue(newfilters)
      const courses = data.courses
      if (Array.isArray(courses) && courses.length)
        this.availablecourses = courses.map(el => {
          el.activeNotifications = el.notifications.length > 0
          el.dates = this.courseDates(el)
          return el
        })
    },
    async loadNonDegreeCourses() {
      try {
        const data = await ServiceAcademic.getCoursesCatalogue()
        const courses = data.courses
        const remoteStatistics = data.statistics
        var statistics = {
          is_prr: { yes: 0, no: 0 },
          languages: [],
          scientific_area: [],
          working_mode: []
        }
        console.log('remoteStatistics')
        console.log(remoteStatistics)
        for (const [key, value] of Object.entries(remoteStatistics)) {
          var result = []
          console.log(key)
          if (key === 'is_prr') {
            console.log('analyzing prr', value['Sim'], value['Não'])
            statistics['is_prr']['yes'] = value['Sim'].ammount
            statistics['is_prr']['no'] = value['Não'].ammount
          } else {
            for (const [subkey, subvalue] of Object.entries(value)) {
              result.push({
                text: subkey,
                id: subvalue['id'],
                ammount: subvalue['ammount']
              })
            }
            statistics[key] = result
          }
        }
        console.log(statistics)

        this.statistics = statistics
        const mydata = await ServiceAcademic.getUserEnrollments()
        const mycourses = mydata.courses
        const featured = data.featured
        console.log(courses)
        console.log('== MY COURSES')
        console.log(mycourses)
        if (Array.isArray(courses) && courses.length)
          this.availablecourses = courses.map(el => {
            el.activeNotifications = el.notifications.length > 0
            el.dates = this.courseDates(el)
            return el
          })
        if (Array.isArray(featured) && featured.length)
          this.featuredcourses = featured.map(el => {
            el.activeNotifications = el.notifications.length > 0
            el.dates = this.courseDates(el)
            return el
          })
        if (Array.isArray(mycourses) && mycourses.length)
          this.mycourses = mycourses.map(el => {
            if (el.editions && el.editions.length > 0) {
              el['current_edition'] = el.editions[0]
            }
            el.dates = this.courseDates(el)
            return el
          })
        this.loading = false
      } catch (error) {
        console.error(error)
        this.loading = false
      }
    },
    closeModal() {
      this.openEnrollmentModal = false
    },
    courseDates(course) {
      var result = {
        is_enrolled_current_edition: false,
        is_current_edition_open: false, //enable/disable open button
        is_signup_open: false, //enable/disable signup button
        signup_passed: false,
        can_signup: true, //are there enough vacancies?
        next_signup_date: null, //date when next edition signup will be open
        signup_end_date: null, //when signup will be closed
        next_session_date: null, //date when next edition will start
        show_notification_button: false, //show notification button
        has_current_edition: false,
        is_active: false
      }
      if (course.current_edition != null) {
        var now = new Date()
        var courseOpenDate = new Date(course.current_edition.start_date)
        var courseEndDate = new Date(course.current_edition.end)
        var courseSignupOpenDate = new Date(course.current_edition.signup_start_date)
        var courseSignupEndDate = new Date(course.current_edition.signup_end_date)
        result['has_current_edition'] = true
        result['is_enrolled_current_edition'] =
          course.enrolled_in != null &&
          course.enrolled_in.length > 0 &&
          course.enrolled_in.findIndex(element => element['course_edition_key'] == course.current_edition.key) >= 0

        result['is_current_edition_open'] = now >= courseOpenDate
        result['is_signup_open'] = now >= courseSignupOpenDate && now <= courseSignupEndDate
        result['is_active'] = now >= courseOpenDate && now <= courseEndDate
        result['can_signup'] =
          course.current_edition.seats_total != null && course.current_edition.seats_occupied != null
            ? course.current_edition.seats_occupied < course.current_edition.seats_total
            : true
        result['signup_passed'] = now >= courseSignupEndDate
        result['next_signup_date'] = this.$options.filters.formatDate(courseSignupOpenDate)
        result['signup_end_date'] = this.$options.filters.formatDate(courseSignupEndDate)
        result['next_session_date'] = this.$options.filters.formatDate(courseOpenDate)
        result['show_notification_button'] = !(
          result['is_enrolled_current_edition'] ||
          result['is_current_edition_open'] ||
          result['is_signup_open']
        )
      } else {
        result['show_notification_button'] = true
      }
      return result
    }
  }
}
</script>
