<template>
  <fw-panel title="Docentes" featured :counter="teachers.length" class="p-5">
    <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 gap-3">
      <fw-card v-for="teacher in teachers" :key="teacher.key" no-clamp no-link :title="teacher.user.full_name">
        <template #header>
          <UserAvatar size="lg" :user="teacher.user" />
        </template>
        <template v-if="profile == 'teacher' && teacher.user.number" #main>
          <div class="text-sm text-gray-500">{{ teacher.user.number }}</div>
        </template>
        <template v-if="teacher.user.chat" #footer>
          <fw-button
            label="Abrir canal de conversação"
            type="light-primary"
            class="flex gap-2 items-center justify-center mt-1"
            @click.native="$emit('go-to-user-chat', teacher.user)"
          >
            <span>Conversa</span>
            <fw-icon-chats class="w-5 h-5" />
            <fw-badge v-if="teacher.user.chat && teacher.user.chat.unread" inline color="primary">{{
              teacher.user.chat.unread
            }}</fw-badge>
          </fw-button>
        </template>
      </fw-card>
    </div>
  </fw-panel>
</template>

<script>
import UserAvatar from '@/fw-modules/fw-core-vue/id/components/user/UserAvatar'

export default {
  components: {
    UserAvatar
  },

  props: {
    view: {
      type: String,
      default: 'liveclasses'
    },
    teachers: {
      type: Array
    },
    profile: {
      type: String,
      default: 'teacher'
    }
  }
}
</script>
