<template>
  <form class="bg-white rounded-lg invite-users" @submit.prevent="saveInvite()">
    <fw-panel :title="$t('inviteUser')" custom-class="flex flex-col gap-1">
      <div>
        <fw-label>{{ $t('name') }}</fw-label>
        <TextInput
          v-model="inviteData.name"
          :minlength="2"
          :maxlength="250"
          :class="{
            error: $v.inviteData.name.$error
          }"
          autofocus
        >
        </TextInput>

        <fw-tip v-if="$v.inviteData.name.$error" error>
          <span v-if="!$v.inviteData.name.min">{{ $t('minChars', { min: 2 }) }}</span>
          <span v-if="!$v.inviteData.name.max">{{ $t('maxChars', { max: 150 }) }}</span>
          <span v-else>{{ $t('nameRequired') }}</span>
        </fw-tip>
      </div>

      <div class="mt-2">
        <fw-label>{{ $t('email') }}</fw-label>
        <TextInput
          v-model="inviteData.email"
          type="email"
          :minlength="2"
          :maxlength="150"
          :class="{
            error: $v.inviteData.email.$error
          }"
        >
        </TextInput>

        <fw-tip v-if="$v.inviteData.email.$error" error>
          <span v-if="!$v.inviteData.email.min">{{ $t('minChars', { min: 5 }) }}</span>
          <span v-if="!$v.inviteData.email.max">{{ $t('maxChars', { max: 255 }) }}</span>
          <span v-if="!$v.inviteData.email.email">{{ $t('emailInvalid') }}</span>
          <span v-else>{{ $t('emailRequired') }}</span>
        </fw-tip>
      </div>
    </fw-panel>

    <div class="flex-1 flex justify-end items-center gap-4 mt-4">
      <fw-button type="link-muted" @click.native="$emit('cancel')">{{ cancelBtnLabel }}</fw-button>
      <fw-button type="primary" role="submit">{{ $t('invite') }}</fw-button>
    </div>
  </form>
</template>

<script>
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import utilsId from '@/fw-modules/fw-core-vue/id/utils'
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'

export default {
  name: 'InviteUsersModal',
  components: {
    TextInput
  },

  props: {
    email: {
      type: String
    },
    cancelBtnLabel: {
      type: String,
      default: function() {
        return this.$t('cancel')
      }
    }
  },

  data() {
    return {
      searchQueryId: '',
      inviteSelected: [],
      inviteData: {
        email: '',
        name: ''
      }
    }
  },

  computed: {
    canInvite() {
      return !this.$v.$invalid && this.isAllowedEmail
    },

    allowExternalAccountsRegister() {
      return FwEnvConfig.allowExternalAccountsRegister
    },

    isAllowedEmail() {
      return this.allowExternalAccountsRegister || (!this.allowExternalAccountsRegister && !this.isInstitutionalEmail)
    },

    isInstitutionalEmail() {
      if (this.inviteData.email) return utilsId.isInstitutionalEmail(this.inviteData.email)
      return false
    }
  },

  mounted() {
    if (this.email) {
      this.inviteData.email = this.email
    }
  },

  validations: {
    inviteData: {
      email: { required, email, min: minLength(5), max: maxLength(255) },
      name: { required, min: minLength(1), max: maxLength(150) }
    }
  },

  methods: {
    saveInvite() {
      this.$v.$touch()
      if (!this.canInvite) return

      this.$emit('save-invite', this.inviteData)

      this.inviteData = {
        email: '',
        name: ''
      }

      this.$v.$reset()
    }
  }
}
</script>

<style>
.invite-users.keyboard_input .textarea:focus,
.invite-users.keyboard_input .input:focus,
.invite-users.keyboard_input .taginput .taginput-container.is-focusable:focus,
.invite-users.keyboard_input .datepicker .dropdown .input:focus,
.invite-users.keyboard_input .datepicker .dropdown-trigger .input[readonly]:focus,
.invite-users.keyboard_input button:focus,
.invite-users.keyboard_input select:focus,
.invite-users.keyboard_input button:focus {
  outline: solid 2px rgba(3, 164, 121, 0.7) !important;
  outline-offset: 2px !important;
}
</style>

<i18n lang="json">
{
  "pt": {
    "inviteUser": "Convidar utilizador",
    "name": "Nome completo",
		"minChars": "Insira pelo menos {min} caracteres",
		"maxChars": "Insira no maximo {max} caracteres",
		"nameRequired": "Insira o nome do utilizador",
		"email": "Email",
		"emailRequired": "Insira o email do utilizador",
		"emailInvalid": "Insira um email é válido",
		"invite": "Convidar",
		"cancel": "Cancelar"
  },
  "en": {
    "inviteUser": "Invite user",
		"name": "Full name",
		"minChars": "Enter at least {min} characters",
		"maxChars": "Enter no more than {max} characters",
		"nameRequired": "Enter user name",
		"email": "Email",
		"emailRequired": "Enter user email",
		"emailInvalid": "Enter a valid email",
		"invite": "Invite",
		"cancel": "Cancel"
  }
}
</i18n>
