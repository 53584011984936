<template>
  <fw-button :type="!resume ? 'primary' : 'border-primary'" :label="buttonText" @click.native="openExam()">{{
    buttonText
  }}</fw-button>
</template>

<script>
export default {
  name: 'OpenExamButton',
  props: {
    type: {
      type: String,
      default: 'exam'
    },
    instanceId: {
      type: String,
      required: true
    },
    resume: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonText() {
      let start = this.resume ? 'Continuar ' : 'Abrir '
      if (this.type === 'form') {
        return start + 'Inquérito'
      } else {
        return start + 'Exame'
      }
    }
  },
  methods: {
    openExam() {
      let linktype = 'ex'
      if (this.type === 'form') {
        linktype = 'form'
      }
      this.$store.commit('setLastRoutePath', this.$router.currentRoute.path)
      this.$router.push({ path: `/${linktype}/${this.instanceId}` })
    }
  }
}
</script>

<style scoped></style>
