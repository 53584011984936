var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-unit w-full group flex bg-white flex-col cursor-pointer",class:{
    'text-center h-full justify-center md:p-4 shadow-lg hover:shadow-2xl rounded-xl p-3 pt-5': _vm.view === 'card',
    'shadow-sm hover:shadow-lg rounded-lg p-3 px-4 border border-gray-100 md:border-none': _vm.view !== 'card'
  }},[_c('div',{staticClass:"flex",class:{
      'justify-center items-center flex-col flex-1': _vm.view === 'card',
      'text-sm md:text-base': _vm.view === 'row'
    }},[(_vm.view === 'row')?_c('div',{attrs:{"aria-label":_vm.onlyProfile ? _vm.unit.profile : _vm.unit.title}},[_vm._v(" "+_vm._s(_vm.onlyProfile ? _vm.unit.profile : _vm.unit.title)+" ")]):_c('fw-heading',{attrs:{"size":'h5'}},[_vm._v(_vm._s(_vm.onlyProfile ? _vm.unit.profile : _vm.unit.title))]),(_vm.view === 'row')?_c('div',{staticClass:"flex-1 flex justify-end items-center text-xs gap-2 text-gray-500 opacity-90"},[(_vm.unit.stats && _vm.unit.stats.classes)?_c('div',{staticClass:"flex items-center gap-1 leading-6"},[_vm._v(" "+_vm._s(_vm.unit.stats.classes)+" Turma"+_vm._s(_vm.unit.stats.classes == 1 ? '' : 's')+" ")]):_vm._e(),(_vm.unit.stats && _vm.unit.stats.classes && _vm.unit.stats.students)?_c('div',{staticClass:"bg-gray-200 h-1.5 w-1.5 rounded-full mx-1 mb-0.5 inline-block"}):_vm._e(),(_vm.unit.stats && _vm.unit.stats.students)?_c('div',{staticClass:"flex items-center gap-1"},[_c('fw-icon-people',{staticClass:"w-4 h-4"}),_vm._v(" "+_vm._s(_vm.unit.stats.students)+" ")],1):_vm._e()]):_vm._e(),(!_vm.onlyProfile)?_c('div',{staticClass:"font-medium text-sm",class:{
        'mb-3': _vm.view === 'card'
      }},[_vm._v(" "+_vm._s(_vm.unit.profile)+" ")]):_vm._e(),(_vm.unit.courses && _vm.unit.courses.length && _vm.view === 'card')?_c('div',{staticClass:"p-2 flex flex-col gap-2 items-center justify-center text-gray-500"},_vm._l((_vm.unit.courses),function(course){return _c('div',{key:course.key,staticClass:"text-xs"},[_vm._v(" "+_vm._s(course.title)+" ")])}),0):_vm._e()],1),(_vm.view === 'card')?_c('div',{staticClass:"w-full text-xs flex justify-between gap-2 text-gray-500 mt-5"},[_c('div',{staticClass:"flex items-center gap-1"},[(_vm.unit.regime === 'semiannual' && _vm.showSemester)?_c('span',{staticClass:"mr-0.5"},[_vm._v(" "+_vm._s(_vm.unit.regime_occurrence)+"º Semestre "),_c('div',{staticClass:"inline-block rounded-full bg-gray-200 mb-0.5 ml-0.5",staticStyle:{"height":"4px","width":"4px"}})]):_vm._e(),_vm._v(" "+_vm._s(_vm.unit.stats.classes)+" Turma"+_vm._s(_vm.unit.stats.classes == 1 ? '' : 's')+" ")]),_c('div',[_c('div',{staticClass:"flex items-center gap-1"},[_c('fw-icon-people',{staticClass:"w-4 h-4"}),_vm._v(" "+_vm._s(_vm.unit.stats.students)+" ")],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }