<template>
  <fw-modal
    :active.sync="isCreateTaskModalActive"
    size="min"
    width="44rem"
    :can-cancel="false"
    @close="closeModalActive('create-task', $event)"
  >
    <ModalCreateTask
      v-if="isCreateTaskModalActive"
      @close="closeModalActive('create-task', $event)"
      @update-tasks-list="$emit('update-tasks-list')"
      @created="goToTask"
    />
  </fw-modal>
</template>

<script>
import ModalCreateTask from '@/fw-modules/fw-core-vue/tasks/components/modals/ModalCreateTask'

export default {
  components: {
    ModalCreateTask
  },

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isCreateTaskModalActive: this.show
    }
  },

  watch: {
    show(newVal) {
      this.isCreateTaskModalActive = newVal
    }
  },

  methods: {
    closeModalActive(modal, bypass = false) {
      if (modal === 'create-task') {
        // Do not confirm closing the modal if bypass is true
        if (bypass) {
          this.isCreateTaskModalActive = false
          this.$emit('close')
          return
        }
        // Make sure user wants to close the modal
        this.$buefy.dialog.confirm({
          title: 'Cancelar criação de pedido de suporte',
          message: `Tem a certeza que deseja cancelar a criação do pedido de suporte? Ao sair desta janela,
            irá perder todas as informações inseridas no forumlário e os ficheiros adicionados.`,
          confirmText: 'Sair da janela',
          cancelText: 'Cancelar',
          type: 'is-dark',
          onConfirm: () => {
            this.isCreateTaskModalActive = false
            this.$emit('close')
          }
        })
      }
    },
    openCreateTaskModal() {
      this.isCreateTaskModalActive = true
    },
    goToTask(task) {
      const routeData = this.$router.resolve({
        name: 'tasks-support-task',
        params: { key: task.id }
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<i18n>
{
  "pt": {},
  "en": {}
}
</i18n>
