import ViewNotifications from './views/ViewNotifications'

export default [
  {
    path: '/notifications',
    name: 'notifications',
    component: ViewNotifications
  },
  {
    path: '/notification/:key',
    name: 'notification',
    component: ViewNotifications
  }
]
