var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.course)?_c('SidebarBase',{staticClass:"sticky top-0",scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('div',{staticClass:"mx-2 mb-3"},[(_vm.classroom || _vm.isManager)?_c('div',[_c('fw-label',{staticClass:"flex gap-1 items-center",attrs:{"size":"sm","marginless":""}},[_vm._v(" Turma ")]),_c('div',{staticClass:"font-semibold"},[_vm._v(" "+_vm._s(_vm.classroom.name)+" ")]),(_vm.classes.length > 1)?_c('div',{staticClass:"mb-2"},[_c('b-dropdown',{staticClass:"w-72",attrs:{"aria-role":"list","scrollable":true,"max-height":200}},[_c('fw-button-dropdown',{attrs:{"slot":"trigger","aria-role":"listitem","type":"link","size":"xs","centered":false,"expanded":""},slot:"trigger",scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v(" Escolher outra ")]},proxy:true}],null,false,2905066658)}),_vm._l((_vm.classes),function(classItem,classItemKey){return _c('b-dropdown-item',{key:classItemKey,staticClass:"font-medium text-sm cursor-pointer px-5 py-1.5 border-b",attrs:{"aria-role":"listitem","has-link":"","focusable":false},nativeOn:{"click":function($event){return _vm.setActiveClass(classItem)}}},[_vm._v(" "+_vm._s(classItem.title)+" ")])})],2)],1):_vm._e(),(
            (_vm.classroom.meeting && _vm.classroom.meeting.key_alias) || (_vm.classroom.meeting && _vm.classroom.meeting.key_alias)
          )?_c('div',{staticClass:"flex gap-2 mt-2"},[(_vm.classroom.meeting && _vm.classroom.meeting.key_alias)?_c('b-tooltip',{attrs:{"label":"Sala Virtual","position":"is-bottom","type":"is-light"}},[_c('fw-button',{attrs:{"type":"light-primary","label":"Sala virtual"},nativeOn:{"click":function($event){return _vm.$router.push({ name: 'live', params: { key: _vm.classroom.meeting.key_alias } })}}},[_c('fw-icon-live',{staticClass:"w-5 h-5"}),(_vm.classroom.meeting.running)?_c('fw-dot'):_vm._e()],1)],1):_vm._e(),(_vm.classroom.chat && _vm.classroom.chat.key)?_c('b-tooltip',{attrs:{"label":"Canal de conversação","position":"is-bottom","type":"is-light"}},[_c('fw-button',{attrs:{"type":"light-primary","label":"Canal de conversação"},nativeOn:{"click":function($event){return _vm.$router.push({ name: 'chats', hash: '#' + _vm.classroom.chat.key })}}},[_c('fw-icon-chats',{staticClass:"w-5 h-5"})],1)],1):_vm._e()],1):_vm._e()],1):_vm._e()]),(_vm.checks.isCourseEditionUnlocked && !_vm.isExceptionCourse)?_c('div',{staticClass:"mx-2 mt-2 mb-4"},[_c('fw-label',{attrs:{"size":"sm"}},[_vm._v("O seu progresso")]),_c('ProgressIcon',{attrs:{"progress":_vm.progress,"show-percentage":"","percentage-pos":"right","color":!_vm.activeUnitKey || _vm.progress == 100
            ? 'bg-gradient-to-r from-teal-300 to-teal-400'
            : 'bg-gray-500 bg-opacity-80'}})],1):_vm._e(),_c('ButtonSidebar',{attrs:{"active":!_vm.view || _vm.view == 'dashboard',"label":"Dashboard"},nativeOn:{"click":function($event){return _vm.$emit('go-to-view', 'dashboard')}},scopedSlots:_vm._u([{key:"svg",fn:function(){return [_c('fw-icon-dashboard',{staticClass:"w-6 h-6"})]},proxy:true}],null,false,3849824173)}),_c('ButtonSidebar',{attrs:{"active":_vm.checks.isCourseEditionUnlocked && (_vm.view == 'units' || _vm.view == 'unit'),"disabled":!_vm.checks.isCourseEditionUnlocked,"label":"Módulos"},nativeOn:{"click":function($event){return _vm.$emit('go-to-view', 'units')}},scopedSlots:_vm._u([{key:"svg",fn:function(){return [_c('fw-icon-book-mark-solid',{staticClass:"w-6 h-6"})]},proxy:true}],null,false,3073216200)}),(_vm.checks.isCourseEditionUnlocked && _vm.editionUnits && _vm.activeUnitKey && (_vm.view == 'units' || _vm.view == 'unit'))?_c('ul',{staticClass:"ml-1.5"},[(false)?_c('li',{staticClass:"mt-4 mb-3"},[_c('fw-label',[_vm._v("Módulos ("+_vm._s(_vm.editionUnits.length)+")")])],1):_vm._e(),_vm._l((_vm.editionUnits),function(unit,index){return _c('li',{key:unit.key + '_' + _vm.edition.key,staticClass:"my-2"},[_c('button',{staticClass:"w-full font-medium transition-colors duration-150",class:{
            'text-primary hover:text-primary':
              _vm.activeUnitKey && _vm.activeUnitKey == unit.key && _vm.checks.isCourseEditionUnlocked,
            'text-gray-500 hover:text-primary':
              (!_vm.activeUnitKey || _vm.activeUnitKey != unit.key) && _vm.checks.isCourseEditionUnlocked,
            'text-gray-400 cursor-not-allowed': !_vm.checks.isCourseEditionUnlocked || !Object.keys(unit.topics).length,
            'mb-2': _vm.activeUnitKey && _vm.activeUnitKey == unit.key
          },attrs:{"disabled":!_vm.checks.isCourseEditionUnlocked ||
              (_vm.activeUnitKey && _vm.activeUnitKey == unit.key) ||
              !Object.keys(unit.topics).length},on:{"click":function($event){return _vm.$emit('go-to-unit', unit.key)}}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"ml-2 mr-2.5 opacity-50"},[_c('fw-icon-list-view',{staticClass:"w-5 h-5"})],1),_c('span',{staticClass:"hidden font-bold w-10 text-sm mr-1"},[_vm._v("M"+_vm._s(index + 1))]),_c('div',{staticClass:"flex flex-1 flex-col"},[_c('v-clamp',{staticClass:"w-full text-sm text-left",attrs:{"autoresize":"","max-lines":2}},[_vm._v(" "+_vm._s(unit.title)+" ")]),(
                  _vm.activeUnitKey && _vm.activeUnitKey == unit.key && _vm.checks.isCourseEditionUnlocked && !_vm.isExceptionCourse
                )?_c('ProgressIcon',{staticClass:"mt-1",attrs:{"progress":unit.progress,"show-percentage":"","percentage-pos":"right","color":"bg-primary bg-opacity-80"}}):_vm._e()],1)])])])})],2):_vm._e(),(_vm.checks.isManager)?_c('ButtonSidebar',{attrs:{"active":_vm.view == 'people',"label":"Pessoas"},nativeOn:{"click":function($event){return _vm.$emit('go-to-view', 'people')}},scopedSlots:_vm._u([{key:"svg",fn:function(){return [_c('fw-icon-people',{staticClass:"w-6 h-6"})]},proxy:true}],null,false,3210053130)}):_vm._e(),(_vm.checks.isManager && !_vm.isExceptionCourse)?_c('ButtonSidebar',{attrs:{"active":_vm.view == 'results',"label":"Resultados"},nativeOn:{"click":function($event){return _vm.$emit('go-to-view', 'results')}},scopedSlots:_vm._u([{key:"svg",fn:function(){return [_c('fw-icon-survey',{staticClass:"w-6 h-6"})]},proxy:true}],null,false,1110587447)}):_vm._e(),(_vm.checks.isManager && !_vm.isExceptionCourse)?_c('ButtonSidebar',{attrs:{"label":"Agendamentos"},nativeOn:{"click":function($event){return _vm.$emit('go-to-view', 'calendar')}},scopedSlots:_vm._u([{key:"svg",fn:function(){return [_c('fw-icon-calendar',{staticClass:"w-6 h-6"})]},proxy:true}],null,false,726241531)}):_vm._e(),(false && _vm.checks.isStudent && !_vm.isExceptionCourse)?_c('ButtonSidebar',{attrs:{"active":_vm.view == 'enrollment',"label":"Inscrição"},nativeOn:{"click":function($event){return _vm.$emit('go-to-view', 'enrollment')}},scopedSlots:_vm._u([{key:"svg",fn:function(){return [_c('fw-icon-info',{staticClass:"w-6 h-6"})]},proxy:true}],null,false,3760553863)}):_vm._e(),(_vm.checks.isCourseEditionUnlocked)?_c('ButtonSidebar',{attrs:{"active":_vm.view == 'about',"label":"Sobre o curso"},nativeOn:{"click":function($event){return _vm.$emit('go-to-view', 'about')}},scopedSlots:_vm._u([{key:"svg",fn:function(){return [_c('fw-icon-article',{staticClass:"w-6 h-6"})]},proxy:true}],null,false,358610701)}):_vm._e()]},proxy:true}],null,false,2083137156)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }