var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.userLoggedIn && !_vm.isNotificationsRouteView)?_c('div',[_c('b-dropdown',{staticClass:"block-header-notifications h-full dropdown-notifications",attrs:{"max-height":"500","aria-role":"list","position":"is-bottom-left"}},[_c('fw-button-dropdown',{class:{ 'text-primary': _vm.unread },attrs:{"slot":"trigger","type":"transparent","label":_vm.$t('seeNotifications'),"chevron":false},slot:"trigger"},[_c('fw-icon-bell',{staticClass:"w-5 h-5"}),(_vm.unread)?_c('span',{staticClass:"absolute top-1 shadow-lg -right-1 py-0.5 text-xs font-bold text-white inline-block w-auto h-auto transform translate-x-1 -translate-y-1 bg-primary rounded-full dark:border-gray-800",class:{ 'px-1': _vm.unread >= 10, 'px-2': _vm.unread < 10 },attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm.unread))]):_vm._e()],1),_c('b-dropdown-item',{staticClass:"pl-4 pr-2 flex justify-between gap-2 items-center z-20",style:({
        boxShadow: _vm.isScrolling ? '0 1px 6px -1px rgba(0, 0, 0, 0.1), 0 -1px 4px -1px rgba(0, 0, 0, 0.06)' : 'none'
      }),attrs:{"aria-role":"menu-item","focusable":false,"custom":""}},[_c('fw-heading',{staticClass:"text-gray-500 mt-1",attrs:{"size":"h3"}},[_vm._v(_vm._s(_vm.$t('notifications')))]),_c('fw-button',{staticClass:"mt-0.5",attrs:{"type":"link","size":"sm"},nativeOn:{"click":function($event){return _vm.$router.push('/notifications')}}},[_vm._v(_vm._s(_vm.$t('seeAll')))])],1),_c('b-dropdown-item',{staticClass:"md:w-96 z-20",attrs:{"focusable":false,"custom":"","paddingless":""}},[_c('div',{ref:"notificationsScroll",staticClass:"overflow-y-auto px-2",staticStyle:{"max-height":"450px"}},[(_vm.notifications.length)?_c('BlockListNotifications',{staticClass:"w-full",attrs:{"notifications":_vm.notifications,"view":"unread"}}):_c('div',{staticClass:"text-base text-gray-400 text-center pt-24 pb-36"},[_c('fw-icon-smile',{staticClass:"opacity-70 h-14 w-14 mx-auto mb-2"}),_c('div',[_vm._v(_vm._s(_vm.$t('noUnread')))]),_c('fw-button',{staticClass:"mt-6 bg-gray-100 px-4 bg-opacity-90 h-9 justify-center hover:opacity-80 text-gray-700",attrs:{"type":"link","size":"sm"},nativeOn:{"click":function($event){return _vm.$router.push('/notifications')}}},[_vm._v(_vm._s(_vm.$t('seeOld')))])],1)],1)]),(_vm.notifications.length - _vm.acceptanceNotifications.length > 1)?_c('b-dropdown-item',{style:({
        boxShadow:
          _vm.notifications.length - _vm.acceptanceNotifications.length > 4
            ? '0 1px 6px -1px rgba(0, 0, 0, 0.1), 0 -1px 4px -1px rgba(0, 0, 0, 0.06)'
            : 'none'
      }),attrs:{"aria-role":"menu-item","focusable":false,"custom":"","class_edition":"z-20"}},[_c('div',{staticClass:"flex justify-around py-1 gap-1"},[_c('fw-button',{attrs:{"type":"link","size":"sm"},nativeOn:{"click":function($event){return _vm.markAll('archive')}}},[_c('fw-icon-archive',{staticClass:"h-4 w-4 mr-1"}),_c('span',[_vm._v(_vm._s(_vm.$t('archiveAll')))])],1),_c('fw-button',{attrs:{"type":"link","size":"sm"},nativeOn:{"click":function($event){return _vm.markAll('read')}}},[_c('fw-icon-check-all',{staticClass:"h-4 w-4 mr-1"}),_c('span',[_vm._v(_vm._s(_vm.$t('markAsRead')))])],1)],1)]):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }