<template>
  <div class="flex flex-col h-full bg-gray-100">
    <div class="flex pl-4 pr-3 items-center py-3.5 gap-1">
      <fw-button type="transparent" @click.native="$emit('right-sidebar', 'info')">
        <fw-icon-chevron-left class="w-5 h-5" />
      </fw-button>
      <fw-heading class="flex-1">Participantes</fw-heading>
      <fw-button
        v-if="!isTeacher && chatActive.isManager && chatActive.meeting"
        type="light-primary"
        icon="add"
        @click.native="addNewUser()"
      >
        Adicionar
      </fw-button>
      <b-dropdown v-else-if="chatActive.isManager && chatActive.meeting" aria-role="list" position="is-bottom-left">
        <fw-button slot="trigger" type="light-primary">Adicionar</fw-button>
        <b-dropdown-item aria-role="listitem" @click="choosePeople('chose_people')">
          <span>Pessoa individual</span>
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem" @click="choosePeople('import_bulk')">
          <span>Lista de pessoas</span>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div id="users-list" class="flex-1 overflow-y-auto flex flex-col px-3">
      <div v-if="users.length > 0" class="bg-white p-1.5 rounded-lg flex flex-col">
        <fw-person v-for="user in users" :key="user.key" paddingless :person="user" :size="'xs'" class="p-1">
          <template #options>
            <b-tooltip
              v-if="user.permissions && user.permissions.includes('is_manager')"
              position="is-left"
              multilined
              type="is-light"
              size="is-small"
            >
              <fw-icon-crown-solid class="h-5 w-5 text-primary" />
              <template v-slot:content>
                <div class="flex flex-col">
                  <div class="flex gap-1 items-center whitespace-nowrap">
                    <fw-icon-crown-solid class="h-4 w-4 text-primary flex-shrink-0" />
                    Gestor
                  </div>
                  <div
                    v-if="user.permissions && user.permissions.includes('see_chat_history')"
                    class="flex gap-1 items-center whitespace-nowrap"
                  >
                    <fw-icon-checkbox-circle class="h-4 w-4 text-primary flex-shrink-0" />
                    Ver histórico do chat
                  </div>
                </div>
              </template>
            </b-tooltip>
            <b-tooltip
              v-else-if="chatActive.isManager && user.permissions && user.permissions.length > 0"
              position="is-left"
              multilined
              type="is-light"
              size="is-small"
            >
              <fw-icon-eye class="h-5 w-5 text-gray-500" />
              <template v-slot:content>
                <div class="flex flex-col">
                  <div
                    v-if="user.permissions && user.permissions.includes('add_users')"
                    class="flex gap-1 items-center whitespace-nowrap"
                  >
                    <fw-icon-checkbox-circle class="h-4 w-4 text-primary flex-shrink-0" />
                    Adicionar pessoas
                  </div>
                  <div
                    v-if="user.permissions && user.permissions.includes('see_chat_history')"
                    class="flex gap-1 items-center whitespace-nowrap"
                  >
                    <fw-icon-checkbox-circle class="h-4 w-4 text-primary flex-shrink-0" />
                    Ver histórico do chat
                  </div>
                </div>
              </template>
            </b-tooltip>
            <fw-menu-more
              v-if="chatActive.isManager && chatActive.meeting && user.key != me.key"
              size="xs"
              class="ml-1"
            >
              <b-dropdown-item paddingless aria-role="listitem">
                <fw-button
                  type="dropdown-item"
                  class="w-full"
                  label="Alterar permissões"
                  @click.native="editPermissions(user)"
                >
                  Alterar permissões
                </fw-button>
              </b-dropdown-item>
              <b-dropdown-item paddingless aria-role="listitem">
                <fw-button type="dropdown-item" class="w-full" label="Remover" @click.native="deleteUser(user.key)">
                  Remover
                </fw-button>
              </b-dropdown-item>
            </fw-menu-more>
          </template>
        </fw-person>
      </div>
      <div v-if="users.length == 0" class="h-full flex flex-col items-center justify-center relative">
        <fw-icon-user class="w-10 h-10 text-gray-300" />
        <fw-label class="mt-2 opacity-60 pb-16">Sem participantes</fw-label>
      </div>
    </div>
    <b-modal
      v-if="showAddForm"
      :can-cancel="true"
      trap-focus
      :active.sync="showAddForm"
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="360"
      scroll="keep"
    >
      <div class="px-5 pt-3 pb-5">
        <div class="flex items-center justify-between mb-4">
          <fw-label v-if="selectedUser">Editar permissões</fw-label>
          <fw-label v-else>Adicionar utilizador/a</fw-label>
          <fw-button type="link" size="sm" @click.native="showAddForm = false">Fechar</fw-button>
        </div>
        <form @submit.prevent="addUser">
          <b-field v-if="errorMessage" class="notification is-danger has-padding">{{ errorMessage }}</b-field>
          <b-field v-if="!selectedUser" label="Email">
            <b-input v-model="email" placeholder="utilizador@uc.pt" expanded autofocus></b-input>
          </b-field>
          <fw-label v-if="!selectedUser">Permissões</fw-label>
          <div class="flex flex-col gap-2 py-2">
            <b-checkbox v-model="isManager" size="is-small">Gestor</b-checkbox>
            <b-checkbox v-if="!isManager" v-model="canAddUsers" size="is-small">Adicionar utilizadores</b-checkbox>
            <b-checkbox v-model="seeChatHistory" size="is-small">Permitir visualizar histórico do chat</b-checkbox>
          </div>
        </form>
        <div class="mt-5 flex justify-end items-center gap-2">
          <fw-button
            v-if="!selectedUser"
            :type="!isReady ? 'disabled' : 'regular'"
            :disabled="!isReady"
            :class="{ 'is-primary': isReady }"
            @click.native="addUser"
          >
            Adicionar
          </fw-button>
          <fw-button v-else :type="'regular'" @click.native="updatePermission"> Guardar </fw-button>
        </div>
      </div>
    </b-modal>
    <b-modal
      :active="modal != null"
      scroll="keep"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="700"
      :on-cancel="closeModal"
      :custom-class="'rounded-buefy-modal'"
    >
      <ChoosePeopleModal
        v-if="modal == 'chose_people'"
        :multiselect="true"
        :not-alowed-users="notAlowedUsers"
        :invite-people="false"
        :endpoint="searchEndpoint"
        @selected="selectedPeople"
        @close="closeModal"
      ></ChoosePeopleModal>
      <ModalImportBulkUsers
        v-if="modal == 'import_bulk'"
        :search-engine="bulkSearch"
        @selected="selectedPeople"
        @import="selectedPeople"
        @close="closeModal"
      ></ModalImportBulkUsers>
    </b-modal>
  </div>
</template>

<script>
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal'
import ModalImportBulkUsers from '@/fw-modules/fw-core-vue/ui/components/modals/ModalImportBulkUsers'

export default {
  name: 'PanelChatUsers',
  components: {
    ChoosePeopleModal,
    ModalImportBulkUsers
  },
  props: {
    chatActive: {
      type: Object,
      required: true
    }
  },
  data() {
    this.getPermissions()
    return {
      loading: false,
      email: null,
      selectedUser: null,
      isManager: false,
      canAddUsers: false,
      seeChatHistory: false,
      users: [],
      errorMessage: '',
      showAddForm: false,
      modal: null,
      currentSelectedPeople: []
    }
  },

  computed: {
    isReady() {
      if (this.selectedUser != null) return true
      if (!this.email) return false

      const parts = this.email
        .toLocaleLowerCase()
        .trim()
        .split('@')
      return parts.length == 2
    },
    me() {
      return this.$store.getters.getUser
    },
    isTeacher() {
      return Boolean(this.user && this.user.key && this.user.roles.includes('teacher'))
    },
    notAlowedUsers() {
      return this.users.map(el => el.key)
    },

    debugmode() {
      return Boolean(localStorage.getItem('fw-debug'))
    }
  },

  mounted() {
    //order users by name
    let users = JSON.parse(JSON.stringify(this.chatActive.users))
    users.sort((a, b) => a.name.localeCompare(b.name))
    this.users = users
  },

  methods: {
    addNewUser() {
      this.selectedUser = null
      this.showAddForm = true
      this.isManager = false
      this.canAddUsers = false
      this.seeChatHistory = false
    },
    deleteUser(user_key) {
      this.$buefy.dialog.confirm({
        title: 'Remover utilizador',
        message: 'Tem a certeza que pretende remover este utilizador?',
        confirmText: 'Remover',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            const users = await ServiceMeetings.deletePermission(this.chatActive.meeting.key, user_key)
            this.users = this.users.filter(el => el.key !== user_key)
            this.$emit('set-users', users)
          } catch (error) {
            this.$buefy.toast.open({
              message: 'Ocorreu um erro ao remover o utilizador',
              type: 'is-danger'
            })
          }
        }
      })
    },
    async updatePermission() {
      this.errorMessage = ''
      try {
        const data = {
          is_manager: this.isManager,
          add_users: this.canAddUsers,
          see_chat_history: this.seeChatHistory
        }
        const users = await ServiceMeetings.updatePermission(this.chatActive.meeting.key, this.selectedUser, data)
        this.users = users
        this.showAddForm = false
      } catch (error) {
        console.error(
          `Failed to update user ${this.selectedUser} permissions in meeting ${this.chatActive.meeting.key}`,
          error
        )
      }
    },
    editPermissions(user) {
      let currentPermissions = user.permissions
      this.isManager = currentPermissions.includes('is_manager')
      this.canAddUsers = currentPermissions.includes('add_users')
      this.seeChatHistory = currentPermissions.includes('see_chat_history')
      this.selectedUser = user.key
      this.showAddForm = true
    },
    async searchEndpoint(params) {
      return await ServiceMeetings.searchUsers(this.chatActive.meeting.key, params)
    },
    async bulkSearch(query) {
      query = query.split(',')
      return await ServiceMeetings.bulkSearchUsers(this.chatActive.meeting.key, query)
    },
    closeModal() {
      this.modal = null
    },
    async selectedPeople(selection) {
      console.log('selection :>> ', selection)
      let users = []
      if (selection.users) {
        users = selection.users.map(el => el.key)
      } else {
        users = selection.map(el => el.key)
      }
      this.loading = true
      try {
        const response = await ServiceMeetings.addPermission(this.chatActive.meeting.key, {
          user_keys: users,
          is_manager: this.isManager,
          add_users: this.canAddUsers,
          see_chat_history: this.seeChatHistory
        })

        this.users = response.users
        this.errorMessage = ''
        this.email = ''
        this.isManager = false
        this.canAddUsers = false
        this.seeChatHistory = false

        for (let user of this.users) {
          if (user.key === response.new_user_key) {
            this.$emit('add-chat-user', user)
            break
          }
        }
        this.$emit('sort-chat-users')
        this.modal = null
      } catch (error) {
        const errorKey = utils.errors(error).getFieldKey('email')
        if (!errorKey) {
          this.errorMessage = 'Ocorreu um erro, por favor tente novamente mais tarde'
        } else if (errorKey == 'ItemNotFound') {
          this.errorMessage = 'O email indicado não está registado.'
        } else if (errorKey == 'InvalidEmail') {
          this.errorMessage = 'O email não é válido.'
        } else if (errorKey == 'OwnerNotAllowed') {
          this.errorMessage = 'Não precisa adicionar o seu endereço de email. Já tem acesso completo à gestão ao grupo.'
        } else if (errorKey == 'PermissionAlreadyDefined') {
          this.errorMessage = 'O email indicado já foi adicionado.'
        } else {
          this.errorMessage = `Não foi possível adicionar o(s) utilizador(es). Por favor, tente mais tarde.
            Caso o problema persista, contacte-nos através do nosso canal de suporte.`
        }
      } finally {
        this.loading = false
      }
    },
    choosePeople(type) {
      this.modal = type //import_bulk || chose_people
    },
    setUsers(users) {
      this.users = users
    },

    async getPermissions() {
      if (this.chatActive.meeting) {
        this.loading = true
        try {
          let users = await ServiceMeetings.getPermissions(this.chatActive.meeting.key)
          users = [this.me, ...users]
          users.sort((a, b) => a.name.localeCompare(b.name))
          this.users = users
        } finally {
          this.loading = false
        }
      }
    },
    async addUser() {
      if (this.chatActive.meeting) {
        this.loading = true
        try {
          const response = await ServiceMeetings.addPermission(this.chatActive.meeting.key, {
            email: this.email,
            is_manager: this.isManager,
            add_users: this.canAddUsers,
            see_chat_history: this.seeChatHistory
          })

          this.users = response.users
          this.errorMessage = ''
          this.email = ''
          this.isManager = false
          this.canAddUsers = false
          this.seeChatHistory = false

          for (let user of this.users) {
            if (user.key === response.new_user_key) {
              this.$emit('add-chat-user', user)
              break
            }
          }
          this.$emit('sort-chat-users')
          this.showAddForm = false
        } catch (error) {
          const errorKey = utils.errors(error).getFieldKey('email')
          if (!errorKey) {
            this.errorMessage = 'Ocorreu um erro, por favor tente novamente mais tarde'
          } else if (errorKey == 'ItemNotFound') {
            this.errorMessage = 'O email indicado não está registado.'
          } else if (errorKey == 'InvalidEmail') {
            this.errorMessage = 'O email não é válido.'
          } else if (errorKey == 'OwnerNotAllowed') {
            this.errorMessage =
              'Não precisa adicionar o seu endereço de email. Já tem acesso completo à gestão ao grupo.'
          } else if (errorKey == 'PermissionAlreadyDefined') {
            this.errorMessage = 'O email indicado já foi adicionado.'
          } else {
            this.errorMessage = `Não foi possível adicionar o email. Por favor, tente mais tarde.
              Caso o problema persista, contacte-nos através do nosso canal de suporte.`
          }
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>
