var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"items-center flex flex-shrink-0 font-medium",class:[
    {
      'bg-primary text-white': _vm.type === 'primary',
      'bg-primary bg-opacity-20 text-primary': _vm.type === 'light-primary',
      'bg-gray-500 bg-opacity-10 text-gray-500': _vm.type === 'light-gray',
      'bg-yellow-600 bg-opacity-20 text-yellow-600': _vm.type === 'light-orange',
      'bg-red-600 bg-opacity-20 text-red-600': _vm.type === 'light-danger',
      'bg-cyan-600 bg-opacity-20 text-cyan-600': _vm.type === 'light-blue',
      'border border-yellow-600 text-yellow-600': _vm.type === 'border-warning',
      'border border-primary text-primary': _vm.type === 'border-primary',
      'border border-primary border-opacity-40 text-primary': _vm.type === 'border-light-primary',
      'border border-gray-500 text-gray-500': _vm.type === 'border-light',
      'border border-opacity-40 border-gray-800 text-gray-500 px-1.5 py-0.5 rounded-lg': _vm.type === 'light-border-box',
      'border border-opacity-40 border-primary text-primary px-1.5 py-0.5 rounded-lg':
        _vm.type === 'light-primary-border-box',
      'border border-opacity-90 border-primary text-primary px-1.5 py-0.5 rounded-lg': _vm.type === 'primary-border-box',
      'border border-opacity-90 border-red-600 text-red-600 px-1.5 py-0.5 rounded-lg': _vm.type === 'danger-border-box',
      'border border-opacity-40 border-white text-white px-1.5 py-0.5 rounded-lg': _vm.type === 'light-white-border-box',
      'bg-yellow-500 text-white': _vm.type === 'orange',
      'bg-red-600 text-white': _vm.type === 'danger',
      'bg-gray-500 text-white': _vm.type === 'medium',
      'bg-gray-500 bg-opacity-50 text-white': _vm.type === 'light',
      'bg-white text-gray-800': _vm.type === 'white',
      'text-white bg-gray-800 bg-opacity-20 backdrop-blur-sm': _vm.type === 'dark-blur',
      'text-gray-800 bg-white bg-opacity-40 backdrop-blur-sm': _vm.type === 'white-blur',
      'text-primary bg-white bg-opacity-20 backdrop-blur-sm': _vm.type === 'primary-blur',
      'bg-gray-100 text-gray-500': _vm.type === 'xlight',
      'bg-gray-800 text-white': _vm.type === 'dark',
      'border text-gray-800 border-gray-800': _vm.type === 'bordered',
      'rounded-full':
        _vm.rounded &&
        !['primary-border-box', 'light-border-box', 'light-primary-border-box', 'danger-border-box'].includes(_vm.type),
      'rounded-md': !_vm.rounded,
      'min-h-7': _vm.size !== 'xs',
      'min-h-5': _vm.size === 'xs',
      'justify-center': _vm.align === 'center',
      'w-full': _vm.expanded,
      'inline-flex': !_vm.expanded,
      'px-3 py-1': ![
        'primary-border-box',
        'light-border-box',
        'light-primary-border-box',
        'danger-border-box'
      ].includes(_vm.type)
    },
    `text-${_vm.size}`,
    `text-${_vm.align}`,
    _vm.customClass
  ]},[_c('div',{staticClass:"items-center flex flex-shrink-0",class:{ 'flex-1': _vm.expanded && _vm.hasCounter }},[_vm._t("default")],2),(_vm.hasCounter)?_c('div',{staticClass:"ml-2 text-xs font-medium pl-1.5 min-w-10 text-center"},[_vm._v(_vm._s(_vm.counter))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }