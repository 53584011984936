<template>
  <b-dropdown
    v-if="academicYears.length > 0"
    ref="academicYearsDropdown"
    scrollable
    max-height="500"
    aria-role="list"
    position="is-bottom-left"
  >
    <fw-button
      v-if="selectedAcademicYear !== -1"
      slot="trigger"
      aria-label="Escolher ano letivo"
      type="dropdown"
      size="sm"
      class="flex gap-1 items-center"
    >
      <svg
        v-if="showSemesters"
        class="fill-current h-6 w-6 mr-1 text-gray-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
      </svg>
      <span v-if="showSemesters && !isMobile">{{ selectedSemester }}º Semestre</span>
      <span v-if="!isMobile">{{ selectedAcademicYear }}</span>
      <fw-icon-chevron-down class="w-4 h-4" />
    </fw-button>
    <b-dropdown-item
      v-if="showSemesters"
      :key="'semester_label'"
      aria-role="menu-item"
      :focusable="false"
      class="font-semibold"
      paddingless
      custom
    >
      <fw-label class="ml-4">Semestre</fw-label>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="showSemesters"
      aria-role="menu-item"
      :focusable="true"
      class="px-4 py-2 font-semibold flex justify-start gap-3 items-center"
      @click.native="selectSemester(1)"
    >
      <div
        class="border-2 border-gray-200 h-4 w-4 rounded-full -mt-0.5"
        :class="{
          'bg-primary bg-opacity-90': selectedSemester === 1,
          'bg-gray-200': selectedSemester !== 1
        }"
      ></div>
      <div>1º Semestre</div>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="showSemesters"
      aria-role="menu-item"
      :focusable="true"
      class="px-4 py-2 font-semibold flex justify-start gap-3 items-center"
      @click.native="selectSemester(2)"
    >
      <div
        class="border-2 border-gray-200 h-4 w-4 rounded-full -mt-0.5"
        :class="{
          'bg-primary bg-opacity-90': selectedSemester === 2,
          'bg-gray-200': selectedSemester !== 2
        }"
      ></div>
      <div>2º Semestre</div>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="academicYears.length > 0"
      :key="'academic_year_label'"
      aria-role="menu-item"
      :focusable="false"
      class="font-semibold"
      paddingless
      custom
    >
      <fw-label class="ml-4">Ano lectivo</fw-label>
    </b-dropdown-item>
    <b-dropdown-item
      v-for="(academicYear, a) in academicYearsSorted"
      :key="'academic_selector_' + a"
      aria-role="menu-item"
      :focusable="true"
      class="px-4 py-2 font-semibold flex justify-start gap-3 items-center"
      @click.native="selectAcademicYear(academicYear)"
    >
      <div
        class="border-2 border-gray-200 h-4 w-4 rounded-full -mt-0.5"
        :class="{
          'bg-primary bg-opacity-90': selectedAcademicYear === academicYear,
          'bg-gray-200': selectedAcademicYear !== academicYear
        }"
      ></div>
      <div>{{ academicYear }}</div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'AcademicSelector',
  props: {
    selectedAcademicYear: {
      type: [Number, String]
    },
    academicYears: {
      type: Array,
      default: function() {
        return []
      }
    },
    selectedSemester: {
      type: [Number, String]
    },
    showSemesters: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth < 640
    },
    academicYearsSorted() {
      let academicYear = this.academicYears
      return academicYear.sort().reverse()
    }
  },
  methods: {
    selectAcademicYear: function(academicYear) {
      this.$emit('select-academic-year', academicYear)
    },
    selectSemester: function(semester) {
      this.$emit('select-semester', semester)
    },
    toogleDropdown: function() {
      this.$refs.academicYearsDropdown.toggle()
    }
  }
}
</script>
