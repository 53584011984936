<template>
  <fw-layout full mobile-ready back-to="/">
    <template #main-content>
      <PanelBucket />
    </template>
  </fw-layout>
</template>

<script>
import PanelBucket from '../components/panels/PanelBucket'

export default {
  components: {
    PanelBucket
  }
}
</script>
