<template>
  <fw-layout full mobile-ready :back-to-enable="false">
    <template #main-content>
      <PanelChats
        v-if="!loading"
        ref="chat"
        :chat-pagination="chatPagination"
        :chat-archived="chatArchived"
        :chats="chats"
        :chat-users="chatUsers"
        :all-chat-users="allChatUsers"
        :chat-active="chatActive"
        :add-allowed="true"
        :view-chats-list="viewChatsList"
        :load-chat-on-create="loadChatOnCreate"
        :chat-locked="chatLocked"
        :load-old-messages-core="loadOldMessagesCore"
        :load-more-chats-core="loadMoreChatsCore"
        @load-chat="loadChatAndSetNav"
        @update-active-chat="updateActiveChat"
        @load-main-chat="loadMainChat"
        @unload-chat="unloadChatAndSetNav"
        @set-chat="setChat"
        @set-chat-messages="setChatMessages"
        @delete-chat-message="deleteChatMessage"
      />
    </template>
  </fw-layout>
</template>

<script>
import PanelChats from '../components/panels/PanelChats'
import ChatLive from '../mixins/ChatLive'
import ServiceChat from '../services/ServiceChat'

export default {
  components: {
    PanelChats
  },

  mixins: [ChatLive],

  data() {
    this.loadChats()
    const data = this.getChatDefaultData()
    data.loading = true
    data.loadChatOnCreate = true
    data.chatFilters = []
    data.destroying = false
    data.viewChatsList = true
    return data
  },

  computed: {
    isMobile() {
      return window.innerWidth < 640
    }
  },

  beforeDestroy() {
    this.destroying = true
    this.unregisterChat()
  },

  created() {
    this.registerChat()
  },

  methods: {
    backTo() {
      console.log('backTo', this.viewChatsList, this.isMobile)
      if (this.viewChatsList && !this.isMobile) this.$router.back()
      else this.viewChatsList = true
    },
    loadChatAndSetNav($data) {
      this.viewChatsList = false
      this.loadChat($data)
    },
    unloadChatAndSetNav($data) {
      this.viewChatsList = true
      this.unloadChat($data)
    },
    async loadChats() {
      let hash = this.$route.hash
      hash = hash && hash.length > 2 ? hash.substr(1) : null

      const response = await ServiceChat.getChannels()
      this.chatFilters = response.types
      for (let channel of response.channels) {
        let loadAfter = channel.key == hash
        this.setChat(channel, loadAfter)
        if (loadAfter) this.loadChatOnCreate = false
      }
      this.setPagination(response.pagination)
      this.setArchivedStats(response.archived)
      this.setAllChatUnread()
      this.loading = false
    },
    async loadMoreChatsCore() {
      console.log('loadMoreChatsCore', this.chatPagination.current_page)
      const response = await ServiceChat.getChannels(
        null,
        null,
        this.chatPagination && this.chatPagination.current_page ? this.chatPagination.current_page + 1 : 1
      )
      for (let channel of response.channels) {
        this.setChat(channel)
      }
      this.setPagination(response.pagination)
    },
    isInChatView() {
      return !this.destroying
    }
  }
}
</script>
