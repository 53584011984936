<template>
  <div class="time-selector flex items-center">
    <select v-model="selectedHour" class="rounded-none rounded-l" @change="emitCurrentValue">
      <option disabled value="">{{ placeholderHour }}</option>
      <option v-for="(hr, h) in hours" :key="'hour_' + h" :value="hr">
        {{ hr }}
      </option>
    </select>
    <div
      class="z-10 px-2 bg-input-gray px-2.5 py-1 text-gray-500 font-semibold border shadow-sm border-gray-200 bg-gray-200 bg-opacity-40"
    >
      :
    </div>
    <select v-model="selectedMin" class="rounded-none rounded-r" @change="emitCurrentValue">
      <option disabled value="">{{ placeholderMinutes }}</option>
      <option v-for="(minute, m) in minutes" :key="'min_' + m" :value="minute">
        {{ minute }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'TimeSelector',
  props: {
    placeholderHour: {
      type: String,
      default: function() {
        return this.$t('hour')
      }
    },
    placeholderMinutes: {
      type: String,
      default: function() {
        return this.$t('min')
      }
    },
    hour: {
      type: String
    },
    min: {
      type: String
    },
    minOptions: {
      type: Function,
      default: function() {
        return null
      }
    },
    hourOptions: {
      type: Function,
      default: function() {
        return null
      }
    }
  },
  data: function() {
    return {
      selectedMin: '00',
      selectedHour: '00'
    }
  },
  computed: {
    hours() {
      if (typeof this.hourOptions === 'function') {
        return [...Array(24).keys()].filter(this.hourOptions).map(value => {
          if (value === 0) {
            return '00'
          } else if (value < 10) {
            return '0' + value
          } else {
            return value.toString()
          }
        })
      } else {
        return [...Array(24).keys()].map(value => {
          if (value === 0) {
            return '00'
          } else if (value < 10) {
            return '0' + value
          } else {
            return value.toString()
          }
        })
      }
    },
    minutes() {
      if (typeof this.minOptions === 'function') {
        return [...Array(60).keys()].filter(this.minOptions).map(value => {
          if (value === 0) {
            return '00'
          } else if (value < 10) {
            return '0' + value
          } else {
            return value.toString()
          }
        })
      } else {
        return [...Array(60).keys()].map(value => {
          if (value === 0) {
            return '00'
          } else if (value < 10) {
            return '0' + value
          } else {
            return value.toString()
          }
        })
      }
    }
  },
  created() {
    const d = new Date()
    let min = d.getMinutes()
    let hour = d.getHours()
    let minResult = '00',
      hourResult = '00'
    if (this.min === '' || typeof this.min === 'undefined' || this.min === null) {
      if (min === 0) {
        minResult = '00'
      } else if (min < 10) {
        minResult = '0' + min
      } else {
        minResult = min.toString()
      }
      //try to find more near value
      let minoptions = this.minutes
      if (min <= parseInt(minoptions[0])) {
        minResult = minoptions[0]
      } else if (min >= parseInt(minoptions[minoptions.length - 1])) {
        minResult = minoptions[minoptions.length - 1]
      } else {
        for (let i = 0; i < minoptions.length; i++) {
          let option = minoptions[i]
          if (parseInt(option) >= min) {
            minResult = option
            break
          }
        }
      }
    } else {
      minResult = this.min.toString()
    }

    if (this.hour === '' || typeof this.hour === 'undefined' || this.hour === null) {
      if (hour === 0) {
        hourResult = '00'
      } else if (hour < 10) {
        hourResult = '0' + hour
      } else {
        hourResult = hour.toString()
      }

      //try to find more near value
      let houroptions = this.hours
      if (hour <= parseInt(houroptions[0])) {
        console.log('min hour!')
        hourResult = houroptions[0]
      } else if (hour >= parseInt(houroptions[houroptions.length - 1])) {
        console.log('max hour!')
        hourResult = houroptions[houroptions.length - 1]
      } else {
        console.log('find hour:')
        for (let i = 0; i < houroptions.length; i++) {
          let option = houroptions[i]
          if (parseInt(option) >= hour) {
            console.log('found hour! ' + hour + '>=' + option)
            hourResult = option
            break
          }
        }
      }
    } else {
      hourResult = this.hour.toString()
    }
    this.selectedMin = minResult
    this.selectedHour = hourResult
  },
  mounted() {
    this.emitCurrentValue()
  },
  methods: {
    emitCurrentValue() {
      this.$emit('changed', this.selectedHour + ':' + this.selectedMin)
    }
  }
}
</script>

<style lang="postcss">
.time-selector {
  @apply font-bold text-lg rounded;
}
.bg-input-gray {
  background-color: #f5f5f5;
}
.time-selector select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-color: #f5f5f5;
  @apply px-2 w-12 py-1.5 text-lg text-center;
}
</style>

<i18n>
{
  "pt": {
    "min":"Min",
    "hour": "Hora"
  },
  "en": {
    "min":"Min",
    "hour": "Hour"
  }
}
</i18n>
